import React from 'react';
import SettingLimits from 'components/molecules/hypertensionUi/SettingLimits';
import SettingBGCheck from 'components/molecules/hypertensionUi/SettingBGCheck';
import { CircularProgress, Grid, Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import { useDispatch, useSelector } from 'react-redux';
import { saveHypertension } from 'redux/actions/hypertensionActions';
import { useParams } from 'react-router-dom';

function Settings() {
  const { loading } = useSelector((state) => state.hypertensionSave);
  const dispatch = useDispatch();
  const { pk } = useParams();
  return (
    <div style={{ minWidth: '1300px' }}>
      <Grid container mt={3}>
        <Grid xs={12} item>
          <SettingLimits />
        </Grid>
        <Grid
          xs={12}
          item
          sx={{ borderTop: '1px solid #E8ECF1' }}
          mt={4}
          pt={4}
        >
          <SettingBGCheck />
        </Grid>
        <Grid xs={12} item container justifyContent='center' mt={8}>
          <Button
            title='Save'
            sx={{
              height: '54px',
              width: '240px',
              marginRight: '-130px',
              backgroundColor: '#1A598D !important',
              fontSize: '17px !important',
            }}
            onClick={() => dispatch(saveHypertension(pk))}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default Settings;
