import React from 'react';
import Step1 from './Step1';
import Step2 from './Step2';

function ForgetPassword() {
  const [step, setStep] = React.useState(2);

  return step === 1 ? <Step1 setStep={setStep} /> : <Step2 />;
}

export default ForgetPassword;
