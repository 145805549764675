import React from 'react';
import { Grid, Typography } from '@mui/material';
import SectionTitle from 'components/atoms/PatientChart/SectionTitle';
import { capitalize } from 'redux/actions/helperFunctions';
import { usePatient } from 'redux/reusables/patients';

export default function MedicalHistory() {
  const { patient } = usePatient();
  const [diagnosesData, setDiagnosesData] = React.useState([]);
  React.useEffect(() => {
    if (patient.diagnosisData) setDiagnosesData(patient.diagnosisData);
  }, [patient.diagnosisData]);

  return (
    <div
      style={{
        maxWidth: '676px',
        minHeight: '352px',

        borderRadius: '16px',
        background: 'white',
      }}
      className='overview-sections'
    >
      <Grid container p={3}>
        <SectionTitle title='Medical history' tab={3} />
        <Grid
          container
          item
          pt={3}
          mb={1}
          sx={{ borderBottom: '1px solid #D4D5D7', paddingBottom: '15px' }}
          className=' overview-headings '
        >
          {/* HEadings here */}
          <Grid item xs={10}>
            <Typography
              sx={{ color: '#7E8186', fontSize: '16px', fontWeight: '400' }}
            >
              Diagnosis
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <Typography
              sx={{
                color: '#7E8186',
                fontSize: '16px',
                fontWeight: '400',
              }}
            >
              Code
            </Typography>
          </Grid>
        </Grid>

        {/* Data here */}
        {diagnosesData ? (
          diagnosesData.map((data, index) => (
            <Grid
              container
              item
              pt={3}
              alignItems='center'
              key={index}
              className='populated'
            >
              <Grid item xs={10}>
                <Typography variant='p'>
                  {data.nickname ? data.nickname  : capitalize(data.label)}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className='code'>{data.code}</Typography>
              </Grid>
            </Grid>
          ))
        ) : (
          <Grid container item pt={3} alignItems='center'>
            <Grid item xs={10}>
              <Typography
                sx={{ color: '#004680', fontSize: '17px', fontWeight: '500' }}
                variant='p'
              >
                No Diagnosis Selected
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                sx={{ color: '#282D36', fontSize: '17px', fontWeight: '400' }}
               />
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
