import React, { useEffect , useState } from 'react';
import Button from 'components/atoms/Button';
import { makeStyles } from '@mui/styles';
import './index.scss';
import AddIcon from '@mui/icons-material/Add';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate , useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

import moment from 'moment';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {
  getEncountersEditorData,
  showEncounters,
} from 'redux/actions/encountersNotesActions';
import { ENCOUNTERS_SINGLE_FETCH_RESET } from 'redux/constants/encountersNotesConstants';
import { encountersDateStyle } from 'styles/components/encounter';
import ThreeDotsMenu from 'components/organisms/encountersUi/TableMenu';

export default function EncountersNotes(props) {
  const { pk, trackerType } = useParams();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state?.authDetails);
  useEffect(() => {
    if (!authState?.loading) setUser(authState.auth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dateClasses = encountersDateStyle();
  const [a, setA] = useState('inline-flex');
  const [b, setB] = useState('none');
  const [user, setUser] = useState({});
  function createData(Sheduledtime, Provider, Reason, CPTCode) {
    return { Sheduledtime, Provider, Reason, CPTCode };
  }
  const patientState = useSelector((state) => state?.patientData);
  const encountersNotes = useSelector((state) => state?.encountersNotes);
  const { loading, notes } = encountersNotes;

  const [dataFetch, setdataFetch] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [diagnosis, setDiagnosis] = useState([]);

  // useEffect to dispatch main data
  useEffect(() => {
    if (!authState?.loading) {
      setUser(authState.auth);
    }

    dispatch(showEncounters(patientState.patient.pk));

    dispatch(getEncountersEditorData());
    setDiagnosis(patientState.patient.diagnosisData);
    dispatch({ type: ENCOUNTERS_SINGLE_FETCH_RESET });
  }, []);

  // Set list here
  React.useEffect(() => {
    setdataFetch([...notes]);
  }, [notes]);

  // Page changes here:
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [dateValue, setDateValue] = React.useState('');
  const [dateShowvalue, setDateShowValue] = React.useState(new Date());

  const handleChange = (newValue) => {
    if (newValue?.toString() !== 'Invalid Date' && newValue) {
      const date = moment.utc(newValue?.toISOString())?.local();
      setDateValue(date?.format('DD/MM/YYYY'));
    } else {
      setDateValue('');
    }

    setDateShowValue(newValue);
  };

  const create = () => {
    navigate(`/encounter-create/${pk}`, {
      state: {
        firstname: patientState.patient.first_name,
        lastname: patientState.patient.last_name,
        trackerType,
        pk,
      }
    });
  };

  const read = (id) => {
    navigate(`/encounter-read/${pk}/${id}`, {
      state: {
        id,
        firstname: patientState.patient.first_name,
        lastname: patientState.patient.last_name,
        trackerType,
        pk,
      }
    });
    //  history.push("/encounter-read");
  };
  const navigate = useNavigate();
  const rows = [createData(dataFetch)];
  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    TableRow: {
      position: 'static',
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '21px',
      letterSpacing: '-0.31px',
      color: '#7E8186',
    },
    Tabledata: {
      fontSize: '17px',
      fontFamily: '\'Work Sans\', sans-serif',
      color: '#282D36',
    },
  });
  const classes = useStyles();

  // function findReason(id) {
  //   if (id && diagnosis.length) {
  //     let key = diagnosis.find((element) => element.id == id)?.code;
  //     return key || '';
  //   }
  // }
  function findCPT(id) {
    if (id && patientState?.patient?.cptCodesApi) {
      const key = patientState?.patient?.cptCodesApi.find(
        (element) => element.id == id
      )?.code;
      return key || '';
    }
  }
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  React.useEffect(() => {
    if (dataFetch.length === 0) {
      setOpenBackdrop(true);
    } else {
      setOpenBackdrop(false);
    }
  }, [dataFetch]);
  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: '1%',
          bgcolor: '#004680',
        }}
      >
        <Button
          title='Add new note'
          secondary
          onClick={create}
          startIcon={<AddIcon />}
          sx={{
            textTransform: 'initial !important',
            width: '167px !important',
            height: '37px !important',
            borderRadius: '8px !important',
            color: '#fff !important',
            fontSize: '16px !important',
            background: '#004680 !important',
            fontWeight: '600 !important',
          }}
        />
        <div
          onClick={() => {
            setA('none');
            setB('block');
          }}
        >
          <Button
            title='Jump to date'
            secondary
            label='Date desktop'
            startIcon={<CalendarTodayIcon />}
            sx={{
              textTransform: 'initial !important',
              width: '167px !important',
              height: '37px !important',
              borderRadius: '8px !important',
              border: '1px solid #D8D8DC',
              color: '#7E8186 !important',
              fontSize: '16px !important',
              background: 'white !important',
              fontWeight: '400 !important',
              display: a,
            }}
          />
        </div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={3} sx={{ display: b }}>
            <DesktopDatePicker
              inputFormat='MM/dd/yyyy'
              value={dateShowvalue}
              onChange={handleChange}
              renderInput={(params) => (
                <TextField {...params} classes={dateClasses} />
              )}
              onOpen
            />
          </Stack>
        </LocalizationProvider>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 1200 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell className={classes.TableRow}>Sheduled time</TableCell>
              <TableCell className={classes.TableRow}>Provider</TableCell>
              {/* <TableCell className={classes.TableRow}>Reason</TableCell> */}
              <TableCell className={classes.TableRow}>CPT Code</TableCell>
              <TableCell className={classes.TableRow} />
            </TableRow>
          </TableHead>
          <TableBody>
            {dataFetch

              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .filter((x) => {
                const date = moment.utc(x.encounter_time)?.local();

                if (dateValue === '') return true;
                return date?.format('DD/MM/YYYY') === dateValue;
              })
              .map((row) => (
                <TableRow style={{ cursor: 'pointer' }}>
                  <TableCell
                    onClick={() => read(row.id)}
                    className={classes.Tabledata}
                  >
                    {/* <TableCell onClick={read} className={classes.Tabledata}> */}
                    {moment
                      .utc(row.encounter_time)
                      .format('ddd MMM Do, YYYY HH:mm')}
                  </TableCell>
                  <TableCell
                    onClick={() => read(row.id)}
                    className={classes.Tabledata}
                  >
                    {authState.auth.username}
                  </TableCell>
                  {/* <TableCell
                    onClick={() => read(row.id)}
                    className={classes.Tabledata}
                  >
                    {findReason(row.diagnosis)}
                  </TableCell> */}
                  <TableCell
                    onClick={() => read(row.id)}
                    className={classes.Tabledata}
                  >
                    {findCPT(row.cpt_codes)}
                  </TableCell>
                  <TableCell>
                    <ThreeDotsMenu
                      encounterData={row}
                      id={row.id}
                      patientState={patientState}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10]}
        variant='regular'
        style={{
          fontFamily: ' Work Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '13px',
          color: '#7E8186',
        }}
        classes={classes}
        labelRowsPerPage="Number of lines"
        component='div'
        count={dataFetch.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
