export default function index() {
  return (
    <div
      style={{
        // background: '#004680',
        height: '32px',
        width: '100vw',
        marginTop: '160px',
      }}
     />
  );
}
