import React from 'react';
import Navbar from 'components/molecules/dashboardUi/Navbar';
import './index.scss';
import Encounterform from 'components/organisms/encounter/Encounterform';
import { startTimer, stopTimer  } from 'redux/actions/timersActions';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

export default function EncounterCreate() {
  const dispatch = useDispatch();
  const location = useLocation();

  React.useEffect(() => {
    const { trackerType, pk } = location.state;
    if(trackerType && pk) dispatch(startTimer(trackerType, pk))

    return () => {
      if(trackerType && pk) dispatch(stopTimer(trackerType, pk));
    }
  }, []);
  return (
    <div className='dashboard-ui-container'>
      <Navbar />
      <hr style={{ color: 'grey', opacity: '0.5' }} />
      <Encounterform mode="CREATE" />
    </div>
  );
}
