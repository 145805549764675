import * as React from 'react';
import { Grid, IconButton } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useNavigate , Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { useAuth } from 'redux/reusables/auth';

import { trackMixPanel } from 'redux/proxies/mixpanel';
import {
  TRACK_USER_SIGNED_OUT,
  TRACK_RESET_PASSWORD_SCREEN,
} from 'redux/constants/mixPanel';
import { Tokens } from 'redux/config/getToken';

export default function Navbar({ hiddenSearch, borderDown }) {
 const navigate = useNavigate();
  const { auth } = useAuth();
  const logoutHandle = () => {
    try {
      Tokens.removeRefresh();
      Tokens.removeAccess();
      trackMixPanel(TRACK_USER_SIGNED_OUT);
      navigate('/signin');
    } catch (err) {
    }
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <Grid
      container
      xs={12}
      justifyContent='space-between'
      pl={3}
      pr={3}
      sx={{
        borderBottom: borderDown ? '1px solid #E8ECF1' : '',
        background: 'white',
      }}
    >
      <Grid item container xs={5} alignItems='center'>
        <Link to='/dashboard'>
          <img
            src='/images/PrecinaLogo.png'
            style={{ width: '105px', height: '32px' }}
            alt='logo here'
          />
        </Link>
      </Grid>
      <Grid item container alignItems='center' xs={7} justifyContent='flex-end'>
        <IconButton
          size='large'
          edge='end'
          aria-label='account of current user'
          aria-haspopup='true'
          color='inherit'
        >
          <AccountCircle fontSize='large' />
        </IconButton>

        <Button
          id='fade-button'
          aria-controls={open ? 'fade-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{
            // styleName: Bold/Subheadline;
            fontFamily: 'Work Sans,Serif',
            fontSize: '15px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.23px',
            textAlign: 'left',
            textTransform: 'capitalize',
            color: '#282D36',
          }}
        >
          {auth?.username ? auth?.username : 'Username'}
        </Button>
      </Grid>
      <Menu
        id='fade-menu'
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        sx={{ top: '12px' }}
      >
        <MenuItem
          onClick={logoutHandle}
          sx={{ fontSize: '14px !important', fontFamily: 'Work Sans, Serif' }}
        >
          Logout
        </MenuItem>
        <MenuItem
          sx={{ fontSize: '14px !important', fontFamily: 'Work Sans, Serif' }}
          onClick={() => {
            trackMixPanel(TRACK_RESET_PASSWORD_SCREEN);
            navigate('/forget-password');
          }}
        >
          Reset Password
        </MenuItem>
      </Menu>
    </Grid>
  );
}
