import React, { useEffect, useState , useReducer } from 'react';
import { Grid , Typography , MenuItem, Menu } from '@mui/material';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import CreateIcon from '@mui/icons-material/Create';
import Edit from 'components/templates/encountersUi/Edit';
import Button from 'components/atoms/Button';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { useNavigate , useLocation , useParams } from 'react-router-dom';

import 'components/templates/encountersUi/index.scss';
import RichEditor from 'components/organisms/patientChartUi/Editor/RichEditor';

import Box from '@mui/material/Box';
import Select from 'components/atoms/Select';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useSelector , useDispatch } from 'react-redux';



import moment from 'moment';
import { DEFAULT_TIMES } from 'redux/constants/timeFrameConstants';
import './Encounterform.css';

import {
  saveEncounters,
  showSingleEncounters,
} from 'redux/actions/encountersNotesActions';
import { getPatientData , updatePatientData } from 'redux/actions/patientChartActions';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


import {
  encountersSelectStyle,
  encountersTextStyle,
  encountersTimeStyle,
  encountersDateStyle,
} from 'styles/components/encounter';
import { DefaultNote } from '../../defaultNote';

export default function Encounterform(props) {
  const location = useLocation();
  const [timePiker, setTimepiker] = React.useState('');
  const [mode, setMode] = useState(false);
  const [user, setUser] = useState({});
  const [userName, setUsername] = useState('username');
  const [patient, setPatient] = useState({});
  const [value, setValue] = React.useState(moment().format('MM/DD/YYYY'));
  const [diagnosis, setDiagnosis] = useState([]);
  const authState = useSelector((state) => state?.authDetails);
  const patientState = useSelector((state) => state?.patientData);
  const [dataFetch, setdataFetch] = React.useState([]);
  const [editorNote, setEditorNote] = useState();
  const [cptCodes, setCptCodes] = useState([]);

  const timePikerr = timePiker?.split(' ').join('');
  const selectClasses = encountersSelectStyle();
  const textClasses = encountersTextStyle();
  const timeClasses = encountersTimeStyle();
  const dateClasses = encountersDateStyle();

  const date1 = moment(value).format('YYYY-MM-DD');
  const [timeCheck, setTimeCheck] = useState(false);
  const timeee = moment(
    `${date1}T${timePikerr}:00.364Z`,
    'DD MM YYYY hh:mm:ss',
    true
  );

  // Set current tab to encounters
  React.useEffect(() => {
    dispatchh(updatePatientData({ type: 'tab', value: 5 }));
  }, []);

  const handleChangedate = (newValue) => {
    setValue(newValue);
  };
  const [display, setDisplay] = useState('block');
  const [values, setvalues] = useState({
    date: value,
    provider: '',
    reason: 0,
    cptcode: 0,
    editor: '',
  });

  const encountersSingle = useSelector((state) => state?.encountersSingle);
  const { loading, note } = encountersSingle;
  React.useEffect(() => {
    if (props.mode === 'VIEW' || props.mode === 'EDIT') {
      dispatchh(showSingleEncounters(id));
    }
  }, []);

  React.useEffect(() => {
    if (patient.cptCodesApi) {
      setCptCodes(patient.cptCodesApi);
    }
  }, [patient]);
  React.useEffect(() => {
    // setvalues()
    if (note?.id) {
      setdataFetch(note);

      // setValue(note.Value);
      setTimepiker(note.timePiker);

      setEditorNote(note?.text);
      setvalues({
        ...values,
        date: note?.encounter_time,
        reason: note?.diagnosis,

        cptcode: note?.cpt_codes,
        editor: note?.text,
      });
    }
  }, [note]);

  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  React.useEffect(() => {
    if (props.mode === 'VIEW' || props.mode === 'EDIT') {
      if (!note) {
        setOpenBackdrop(true);
      } else {
        setOpenBackdrop(false);
      }
    }
  }, [note]);

  useEffect(() => {
    if (!authState?.loading) setUser(authState.auth);
    if (!patientState?.loading) {
      setPatient(patientState.patient);
      setDiagnosis(patientState.patient.diagnosisData);
    }
    switch (props.mode) {
      case 'CREATE':
        var today = new Date();
        let hours =
          today.getHours() < 10 ? `0${  today.getHours()}` : today.getHours();
        let minutes =
          today.getMinutes() < 10
            ? `0${  today.getMinutes()}`
            : today.getMinutes();
        if (Number(minutes) < 15 || Number(minutes) > 45) {
          if (Number(minutes) > 45 && Number(hours) < 10) {
            hours = `0${Number(hours) + 1}`;
          } else if (Number(minutes) > 45 && Number(hours) > 10) {
            hours = Number(hours) + 1;
          }
          minutes = '00';
        } else {
          minutes = '30';
        }
        var time = `${hours  } : ${  minutes}`;

        setTimepiker(time);
        setTimeSelect(time);
        return setMode(false);
      case 'VIEW':
        return setMode(true);
      case 'EDIT':
        setDisplay('none');
        return setMode(false);

      default:
        setDisplay('none');
        return setMode(false);
    }
  }, []);

  const saveEncounterForm = (data) => {
    setvalues({ ...values, editor: data });
    handleSubmit(data);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setvalues({ ...values, [name]: value });

  };

  const saveEncountersNotes = useSelector(
    (state) => state?.saveEncountersNotes
  );
  const {
    loading: saveLoading,
    savedNote,
    error: saveError,
  } = saveEncountersNotes;
  const submitUpdate = async (noteData) => {
    dispatchh(
      saveEncounters(
        {
          text: noteData,
          encounter_time: timeee._i,
          patient: patient.pk,
          portal_user: user.id,
          diagnosis:
            values.reason !== 0
              ? Array.isArray(values.reason)
                ? values.reason
                : [values.reason]
              : [],
          cpt_codes:
            values.cptcode !== 0
              ? Array.isArray(values.cptcode)
                ? values.cptcode
                : [values.cptcode]
              : [],
        },
        'update',
        id
      )
    );
  };

  const [success, setSuccess] = useState(false);
  const [saveEncounterRichText, setSaveEncounterRichText] = useState(false);
  React.useEffect(() => {
    if (savedNote?.id && patient.pk) {
      setSuccess(true);
      dispatchh(updatePatientData({ type: 'tab', value: 5 }));

      dispatchh({ type: 'ENCOUNTERS_NOTES_CREATE_RESET' });
      setTimeout(() => {
        if (props.mode === 'CREATE')
          navigate(-1);
      }, 1000);
    }
  }, [savedNote, saveError]);
  const handleSubmit = async (noteData) => {
    setTimeout(() => {
      try {
        if (props.mode === 'EDIT') {
          return submitUpdate(noteData);
        }
        dispatchh(
          saveEncounters(
            {
              text: noteData,
              encounter_time: timeee._i,
              patient: patient.pk,
              portal_user: user.id,
              diagnosis:
                values.reason !== 0
                  ? Array.isArray(values.reason)
                    ? values.reason
                    : [values.reason]
                  : [],
              cpt_codes:
                values.cptcode !== 0
                  ? Array.isArray(values.cptcode)
                    ? values.cptcode
                    : [values.cptcode]
                  : [],
            },
            'create'
          )
        );
      } catch (err) {
      }
    }, 500);
  };

  // Redux
  const dispatchh = useDispatch();
  // Set default values

  // For time select
  const [timeSelect, setTimeSelect] = React.useState('06 : 00');

  // Detect wake value change
  const onChangerTimeSelect = (event) => {
    setTimeSelect(event.target.value);
    setTimepiker(event.target.value);
  };

  /// //////////////////
  const navigate = useNavigate();
  const update = () => {
    dispatchh(updatePatientData({ type: 'tab', value: 5 }));
    navigate(-1);
  };
  const encounter = () => {
    navigate(`/encounter-update/${patientState?.patient?.pk}/${id}`, {
      id,
      lastname: location?.state?.lastname,
      firstname: location?.state?.firstname,
    });
  };
  const [currency, setCurrency] = React.useState('EUR');
  const [currency2, setCurrency2] = React.useState('$');

  const reducer = (state, action) => {
    if (action.type === 'save') {
      if (value === null && currency === null && currency2 === null) {
        // setDisableBtn(true)
        return (state = true);
      }
      // setDisableBtn(false)
      return (state = false);

    }
  };
  const [state, dispatch] = useReducer(reducer, true);

  // Get Patient data if not present
  const { pk, id } = useParams();
  React.useEffect(() => {
    if (!patient?.pk) {
      dispatchh(getPatientData(pk));
    }
  }, []);

  React.useEffect(() => {
    setPatient(patientState.patient);
  }, [patientState]);

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || !patient?.pk}
        // onClick={handleClose}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <Grid container style={{ backgroundColor: '#fafbfe' }}>
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: '#7E8186',
            fontSize: '15px',
            marginLeft: '30px',
            fontWeight: '500',
            letterSpacing: '-0.23999999463558197px',
            marginTop: '15px',
            cursor: 'pointer',
            fontFamily: '\'Work Sans\', sans-serif',
          }}
          onClick={update}
        >
          <ArrowBackIosIcon
            sx={{
              fontSize: '15px',
              padding: '15px',
            }}
          />
          Back to Encounter Notes
        </Typography>
        <TableContainer component={Paper} />
        <div
          style={{
            boxSizing: 'border-box',
            padding: '24px',
            width: '1500px',
            margin: 'auto',
            background: 'white',
            maxWidth: '100%',
            borderRadius: '16px',
          }}
        >
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: '#7E8186',
              fontSize: '15px',
            }}
          >
            <h1
              style={{
                display: 'flex',
                letterSpacing: '0.1407px',
                fontSize: '20px',
              }}
            >
              {`${patient?.first_name  } ${  patient?.last_name}`} -{' '}
              {moment().format('MMMM Do YYYY, HH:mm')}
            </h1>
            {props.mode === 'VIEW' ? (
              <CreateIcon
                sx={{
                  cursor: 'pointer',
                }}
                onClick={encounter}
                style={{ marginLeft: '15px', display }}
              />
            ) : (
              <></>
            )}
          </Typography>
          {/* main div */}
          <div
            style={{
              display: 'flex',
              margin: '1.1% 1%',
            }}
          >
            {/* p tag */}
            <Typography
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-end',
                margin: '0% 1%;',
              }}
              sx={{
                color: '#7E8186',
                fontSize: '17px',
                width: '15%',
              }}
            >
              <p style={{ fontFamily: '\'Work Sans\', sans-serif' }}>
                Date and Time
              </p>
            </Typography>
            {/* p tag over */}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              {/* 2nd div */}
              <Stack
                spacing={3}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  flex: '',
                  fontSize: '17px',
                }}
                className='textFieldProvider'
              >
                <div>
                  <DesktopDatePicker
                    name='date'
                    onChange={handleChangedate}
                    onBlur={() => dispatch({ type: 'save' })}
                    disabled={mode}
                    inputFormat='MM/dd/yyyy'
                    // classes={classes}
                    value={value}
                    renderInput={(params) => (
                      <TextField {...params} classes={dateClasses} />
                    )}
                    style={{ marginRight: '10px', fontSize: '17px' }}
                  />
                </div>
              </Stack>
              {/* 2nd div over */}
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              {/* 2nd div */}
              <Stack
                spacing={3}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  flex: '1',
                  marginLeft: '19px',
                }}
              >
                <div>
                  <Select
                    disabled={mode}
                    disableSelect
                    classes={timeClasses}
                    onChange={(e) => {
                      onChangerTimeSelect(e);
                    }}
                    value={timePiker || timeSelect}
                  >
                    <MenuItem value={0}>
                      <Grid container item justifyContent='space-between'>
                        {timePiker ?? '__ : __'}
                        <AccessTimeIcon sx={{ color: '#A9ABAF' }} />
                      </Grid>
                    </MenuItem>
                    {DEFAULT_TIMES.map((item, index) => (
                      <MenuItem
                        onBlur={() => dispatch({ type: 'save' })}
                        key={index}
                        value={item}
                        sx={{
                          fontSize: '15px',
                          color: '#7E8186',
                          fontWeight: 'bold',
                          marginRight: '24px',
                        }}
                      >
                        <Grid
                          name='date'
                          container
                          item
                          justifyContent='space-between'
                        >
                          {item}
                          <AccessTimeIcon sx={{ color: '#A9ABAF' }} />
                        </Grid>
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </Stack>
              {/* 2nd div over */}
            </LocalizationProvider>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              margin: '1% 1%',
              fontFamily: '\'Work Sans\', sans-serif',
              color: '#282D36',
              fontWeight: '400',
            }}
          >
            <Typography
              sx={{
                color: '#7E8186',
                fontSize: '17px',
                width: '15%',
              }}
              style={{ margin: '0% 1%;' }}
            >
              <p style={{ fontFamily: '\'Work Sans\', sans-serif' }}>Provider</p>
            </Typography>
            <TextField
              name='provider'
              onChange={handleChange}
              onBlur={() => dispatch({ type: 'save' })}
              disabled={mode}
              value={authState.auth.username}
              // className='textFieldProvider'
              classes={textClasses}
              id='outlined-basic'
              // label="Dr. McCartney"
              variant='outlined'
            />
          </div>
          {/* main div1 over */}

          {/* <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              margin: '1% 1%',
            }}
          >
            <Typography
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-end',
                margin: '0% 1%;',
                width: '15%',
              }}
              sx={{
                color: '#7E8186',
                fontSize: '17px',
              }}
            >
              <p style={{ fontFamily: "'Work Sans', sans-serif" }}>Reason</p>
            </Typography>
            <div style={{ width: '80%' }}>
              <Box
                component='form'
                style={{ width: '100%' }}
                noValidate
                autoComplete='off'
              >
                <Select
                  name='reason'
                  label={`Select`}
                  onChange={handleChange}
                  onBlur={() => dispatch({ type: 'save' })}
                  disabled={mode}
                  select
                  value={values?.reason}
                  classes={selectClasses}
                >
                  {diagnosis?.map((option) => (
                    <MenuItem
                      key={option.code}
                      value={option.id}
                      defaultValue={20}
                      selected='hello'
                    >
                      {option.code}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </div>
          </div> */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              margin: '1% 1%',
            }}
          >
            <Typography
              sx={{
                color: '#7E8186',
                fontSize: '17px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '15%',
              }}
            >
              <p style={{ fontFamily: '\'Work Sans\', sans-serif' }}>CPT Code</p>
            </Typography>
            <div style={{ width: '80%' }}>
              <Box
                component='form'
                style={{ width: '100%' }}
                noValidate
                autoComplete='off'
              >
                <Select
                  name='cptcode'
                  label="Select"
                  onChange={handleChange}
                  onBlur={() => dispatch({ type: 'save' })}
                  disabled={mode}
                  select
                  value={values?.cptcode}
                  classes={selectClasses}
                >
                  {cptCodes?.map((code) => (
                    <MenuItem
                      key={code.id}
                      value={code.id}
                      defaultValue={20}
                      selected='hello'
                    >
                      {code.code}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </div>
          </div>
        </div>
        <hr style={{ color: 'grey', opacity: '0.5', width: '1440px' }} />
        <div
          style={{
            boxSizing: 'border-box',
            padding: '24px',
            width: '1500px',
            margin: 'auto',
            background: 'white',
            maxWidth: '100%',
            margin: 'auto',
            marginTop: '-38px',
            borderRadius: '16px',
          }}
        >
          <div style={{ width: '100%' }}>
            <Grid
              container
              p={3}
              sx={{
                width: '100%',
                minHeight: '411px',
                background: 'white',
                paddingLeft: '0px',
                paddingRight: '0px',
              }}
              className='encountersNotes'
            >
              <Grid
                container
                item
                xs={12}
                pb={1}
                mb={3}
                sx={{
                  borderBottom: '1px solid #D4D5D7',
                }}
              >
                {/* HEadings here */}
                <div style={{ width: '100%' }}>
                  <RichEditor
                    name='editor'
                    type='encounter'
                    event={saveEncounterForm}
                    saveEncounterRichText={saveEncounterRichText}
                    defaultValue={
                      props.mode === 'CREATE' ? DefaultNote() : editorNote
                    }
                    readOnly={props.mode === 'VIEW'}
                    sx={{
                      fontFamily: 'Work Sans, sans-serif !important',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '24px',
                      letterSpacing: '-0.4099999964237213px',
                      color: '#282D36',
                    }}
                    setWarning={false}
                    focusEditor
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              height: '150px',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <div
              style={{
                width: '240px ',
                height: '54px ',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: saveError ? 'red' : 'green',
                fontFamily: 'Work Sans, sans-serif',
                fontSize: '18px',
                fontWeight: '500',
              }}
            >
              {saveError ? 'Note Saving error' : ''}
              {success ? 'Note Saved' : ''}
            </div>
            {saveLoading ? (
              <div
                style={{
                  width: '240px ',
                  height: '54px ',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <Button
                onClick={() => setSaveEncounterRichText(!saveEncounterRichText)}
                title='Save and Sign'
                sx={{
                  borderRadius: '8px !important',
                  width: '240px !important',
                  height: '54px !important',
                  marginBottom: '20px',
                  fontFamily: 'Work Sans, sans-serif',
                  fontSize: '18px',
                  fontWeight: '500',
                  color: '#A9ABAF',
                  background: '#1A598D',
                  float: 'right',
                  display: props.mode === 'VIEW' ? 'none' : 'block',
                }}
              />
            )}
          </div>
          <Edit />
        </div>
      </Grid>
    </div>
  );
}
