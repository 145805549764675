import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchBar from 'components/atoms/SearchBar';
import './index.css';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useSelector , useDispatch } from 'react-redux';

import { updatePatientData } from 'redux/actions/patientChartActions';
import { usePatientList } from 'redux/reusables/patients';
import BellNotification from 'components/atoms/BellNotification';
import OpenModal from 'components/molecules/dashboardUi/OpenModal';
import { trackMixPanel } from 'redux/proxies/mixpanel';
import { PATIENT_SEARCHED } from 'redux/constants/mixPanel';

const useStyles = makeStyles({
  toolbar: {
    background: 'none',
    color: '#A9ABAF',
  },
  selectLabel: {
    display: 'none',
  },
});

const useStylesSearch = makeStyles({
  root: {
    '& .MuiInputBase-root': {
      borderRadius: '8px',
      height: '32px',
    },
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'dob',
    numeric: true,
    disablePadding: false,
    label: 'DOB',
  },
  {
    id: 'phone',
    numeric: true,
    disablePadding: false,
    label: 'Phone',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy } = props;

  return (
    <TableHead>
      <TableRow
        sx={{
          ' td,th': {
            borderRadius: '8px',
            paddingBottom: 2,
            borderBottom: '1px solid #E6E6E6',
          },
        }}
      >
        <TableCell padding='checkbox' />
        {headCells.map((headCell) => (
          <TableCell
            sx={{ color: '#7E8186' }}
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              sx={{
                fontFamily: 'Work Sans, sans-serif',
                fontWeight: '400',
                fontSize: '16px !important',
                letterSpacing: '-0.31px',
              }}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable(props) {
  const { patients } = usePatientList();
  const dispatch = useDispatch();

  const [rows, setRows] = React.useState(patients || []);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  React.useEffect(() => {
    setRows(patients);
  }, [patients]);

  const [searcherPhrase, setSearcherPhrase] = React.useState('');
  const [notFound, setNotFound] = React.useState(false);

  const phraseFilter = (event) => {
    setSearcherPhrase(event.target.value);
    const currentSearch = event.target.value;
    setPage(0);
    setRows(
      patients
        ? patients.filter(
            (x) =>
              x.first_name
                ?.toLowerCase()
                .includes(currentSearch?.toLowerCase()) ||
              x.last_name
                ?.toLowerCase()
                .includes(currentSearch?.toLowerCase()) ||
              `${x.first_name}${x.last_name}`
                .toLowerCase()
                .includes(currentSearch?.replace(' ', '').toLowerCase()) ||
              x.mobile_phone.includes(
                currentSearch?.replace(/\s/g, '').toLowerCase()
              )
          )
        : []
    );
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    if (rows.length === 0 && searcherPhrase !== '') {
      setNotFound(true);
    } else {
      setNotFound(false);
    }
  }, [rows]);

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const classes = useStyles();
  const classesSearch = useStylesSearch();

  // Patient modal open
  const [patientModalOpen, setPatientModalOpen] = React.useState([]);
  const handleOpenPatientModal = (index) => {
    const temp = [...patientModalOpen];
    temp[index] = true;
    setPatientModalOpen(temp);
  };

  const handleClosePatientModal = (index) => {
    const temp = [...patientModalOpen];
    temp[index] = false;
    setPatientModalOpen(temp);
  };

  return (
    <Box
      sx={{
        width: '100%',
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Grid container xs={12}>
        <Grid item xs={4} container alignItems='center' pl={5}>
          <div
            className='iconStyles'
            style={{ marginRight: '-12px', cursor: 'pointer' }}
            onClick={phraseFilter}
          >
            <FilterAltOutlinedIcon sx={{ color: 'gray' }} />
          </div>

          <SearchBar
            classes={classesSearch}
            sx={{
              width: '343px !important',
              height: '32px !important',
            }}
            placeholder='Search by patient'
            iconSx={{ color: 'gray' }}
            onChange={phraseFilter}
            value={searcherPhrase}
            name='searcher'
            onBlur={() => {
              trackMixPanel(PATIENT_SEARCHED);
            }}
          />
        </Grid>

        <Grid item xs={6} my={4} />
      </Grid>
      <Paper
        sx={{
          width: '95%',
          p: 2,
          borderRadius: '16px',
          boxShadow: 'none',
        }}
      >
        {!notFound ? (
          rows.length ? (
            <TableContainer>
              <Table aria-labelledby='tableTitle'>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <br />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}

                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          // hover
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                          sx={
                            index % 2 == 0
                              ? {
                                  ' td,th': {
                                    border: 0,
                                    borderRadius: '8px',
                                  },
                                  background: '#FAFBFE',
                                }
                              : {
                                  ' td,th': {
                                    border: 0,
                                    borderRadius: '8px',
                                  },
                                }
                          }
                        >
                          <TableCell>
                            {row?.chatData?.unread_count ? (
                              <BellNotification
                                unread_count={row?.chatData?.unread_count}
                                small
                              />
                            ) : (
                              <></>
                            )}
                          </TableCell>
                          <TableCell
                            component='th'
                            id={labelId}
                            scope='row'
                            padding='none'
                            sx={{ fontWeight: 'bold' }}
                          >
                            <Typography
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '17px',
                                fontWeight: '500',
                                color: '#282D36',
                                letterSpacing: '-0.43px',
                                fontFamily: 'Work Sans, sans-serif',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                handleOpenPatientModal(index);
                              }}
                            >
                              <AccountCircle sx={{ marginRight: '10px' }} />

                              {`${row.first_name} ${row.last_name}`}
                            </Typography>
                            {/* Patient click open modal */}
                            <OpenModal
                              patientModalOpen={patientModalOpen[index]}
                              handleClosePatientModal={handleClosePatientModal}
                              index={index}
                              row={row}
                            />
                            {/* Patient click open modal end */}
                          </TableCell>

                          <TableCell
                            align='left'
                            sx={{
                              fontFamily: 'Work Sans, sans-serif',
                              fontSize: '16px',
                              letterSpacing: '-0.31px',
                              color: '#282D36',
                            }}
                          >{`${row.birthdate.slice(5, 7)}-${row.birthdate.slice(
                            8,
                            10
                          )}-${row.birthdate.slice(0, 4)}`}</TableCell>
                          <TableCell
                            align='left'
                            sx={{
                              fontFamily: 'Work Sans, sans-serif',
                              fontSize: '16px',
                              letterSpacing: '-0.31px',
                              color: '#282D36',
                            }}
                          >{`${row.mobile_phone.slice(
                            0,
                            2
                          )} (${row.mobile_phone.slice(
                            2,
                            5
                          )}) ${row.mobile_phone.slice(
                            5,
                            8
                          )} - ${row.mobile_phone.slice(8, 13)}`}</TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <Grid container justifyContent='flex-end' alignItems='center'>
                <Typography
                  sx={{
                    fontSize: '13px',
                    fontWeight: '400',
                    letterSpacing: '-0.08px',
                    color: '#7E8186',
                    fontFamily: 'Work Sans, sans-serif',
                    marginRight: '-70px',
                  }}
                >
                  Number of lines
                </Typography>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20, 25, 50]}
                  variant='regular'
                  classes={classes}
                  component='div'
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </TableContainer>
          ) : (
            <Box
              sx={{
                width: '100%',
                height: '350px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          )
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '350px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <h1>No results found</h1>
          </Box>
        )}
      </Paper>
    </Box>
  );
}
