import * as React from 'react';
import { useEffect } from 'react';

// Material UI imports
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Input from 'components/atoms/Input';
import Switch from 'components/atoms/Switch';
import Select from 'components/atoms/Select';
import Typography from '@mui/material/Typography';
import { MenuItem } from '@mui/material';

// Redux imports
import { useDispatch } from 'react-redux';
import { updateDiabetes } from 'redux/actions/diabetesActions';
import useDidMountEffect from 'hooks/useDidMountEffect';

import mealTableStyles from 'styles/components/organisms/diabetesUI/MealTableStyles';
import { listStylesSelect } from 'styles/components/organisms/diabetesUI/ListStyles';
import { useDiabetes } from 'redux/reusables/diabetes';

export default function MealTableRow(props) {
    const dispatch = useDispatch();
    const { diabetes } = useDiabetes();
    const classes = mealTableStyles();
    const classesSelect = listStylesSelect();

    // Event handlers for select field
    const [selectValue, setSelectValue] = React.useState('');

    const onChangerSelect = (event) => {
        setSelectValue(event.target.value);
        // Set selected insulin
        const selected_insulin =
            event.target.value !== 0 ? event.target.value.replace('_insulin', '') : '';
        dispatch(
            updateDiabetes({
                type: `${props.dose_name}_selected`,
                value: diabetes[`selected_insulin_${selected_insulin}`],
            }),
        );

        dispatch(
            updateDiabetes({
                type: `${props.dose_name}_selected_code`,
                value: diabetes[`selected_insulin_${selected_insulin}_code`],
            }),
        );

        // Resets the remaining row values i.e switches and units
        dispatch(
            updateDiabetes({
                type: props.dose_name,
                value: '',
            }),
        );
        setGlucSwitchValue(false);
        dispatch(updateDiabetes({ type: props.gluc_name, value: false }));

        setCorrSwitchValue(false);
        dispatch(updateDiabetes({ type: props.corr_name, value: false }));

        dispatch(
            updateDiabetes({
                type: props.dose_name,
                value: '',
            }),
        );
        dispatch(
            updateDiabetes({
                type: `insulin_type_unit_${props.name}`,
                value: event.target.value,
            }),
        );
    };

    // Sets defaults if any

    const [corrSwitchValue, setCorrSwitchValue] = React.useState(false);

    // Event value for switch values
    const onChangerSwitchCorr = (e, c) => {
        if (glucSwitchValue === false && e.target.checked === true) {
            setGlucSwitchValue(!glucSwitchValue);
            dispatch(updateDiabetes({ type: props.gluc_name, value: true }));
        }
        setCorrSwitchValue(!corrSwitchValue);
        dispatch(updateDiabetes({ type: props.corr_name, value: e.target.checked }));
    };

    // Event handler for glucose sitch for each row
    const [glucSwitchValue, setGlucSwitchValue] = React.useState(false);

    const onChangerSwitchGluc = (e, c) => {
        if (corrSwitchValue === true && e.target.checked === false) {
            setCorrSwitchValue(!corrSwitchValue);
            dispatch(updateDiabetes({ type: props.corr_name, value: false }));
        }
        setGlucSwitchValue(!glucSwitchValue);
        dispatch(updateDiabetes({ type: props.gluc_name, value: e.target.checked }));
    };

    // Event handler for main switch for row
    const selected_insulin1 = props.dose_name.replace('dose_', '');
    const [switchValue, setSwitchValue] = React.useState(false);

    // Sets default insulin if any

    const dose_selected = diabetes ? diabetes[`dose_${selected_insulin1}_selected`] : '';
    React.useEffect(() => {
        setTimeout(() => {
            ['basal', 'bolus', 'mixed'].map((insulin) => {
                if (dose_selected === diabetes[`selected_insulin_${insulin}`]) {
                    setSelectValue(`${insulin}_insulin`);
                    dispatch(
                        updateDiabetes({
                            type: `insulin_type_unit_${props.name}`,
                            value: `${insulin}_insulin`,
                        }),
                    );
                }
                return false;
            });
        }, 10);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dose_selected, diabetes, props.name]);

    // Enables row if any enabled by default
    const timeframe_switch = diabetes ? diabetes[`switch_${selected_insulin1}`] : '';
    React.useEffect(() => {
        setSwitchValue(timeframe_switch);
    }, [timeframe_switch]);

    const onChangerSwitch = (e, c) => {
        setSwitchValue(!switchValue);
        setSelectValue('');
        dispatch(
            updateDiabetes({
                type: `insulin_type_unit_${props.name}`,
                value: '',
            }),
        );
        if (!diabetes[props.switch_name] === false) {
            // Disables all switches and revert back status to false
            setCorrSwitchValue(false);
            setGlucSwitchValue(false);

            dispatch(updateDiabetes({ type: props.gluc_name, value: false }));
            dispatch(updateDiabetes({ type: props.corr_name, value: false }));
            dispatch(updateDiabetes({ type: props.dose_name, value: '' }));
        }
        dispatch(updateDiabetes({ type: props.switch_name, value: e.target.checked }));
    };

    const onChangerInput = (event) => {
        // Set limit for dose units
        if (event.keyCode === 8 && diabetes[props.dose_name] === '') {
            return false;
        }

        if (event.keyCode === 8 && diabetes[props.dose_name] === 0) {
            dispatch(
                updateDiabetes({
                    type: event.target.name,
                    value: '',
                }),
            );
        } else if (event.target.value < 1000) {
            dispatch(
                updateDiabetes({
                    type: event.target.name,
                    value: Number(event.target.value),
                }),
            );
        }
    };

    // Re renders on change of following
    useEffect(() => {
        if (corrSwitchValue === true) {
            setGlucSwitchValue(true);
        }
    }, [glucSwitchValue, corrSwitchValue]);

    useEffect(() => {}, [diabetes]);
    const selected_insulins = [
        {
            type: 'basal_insulin',
            name: diabetes ? diabetes.selected_insulin_basal : '',
        },
        {
            type: 'bolus_insulin',
            name: diabetes ? diabetes.selected_insulin_bolus : '',
        },
        {
            type: 'mixed_insulin',
            name: diabetes ? diabetes.selected_insulin_mixed : '',
        },
    ]?.sort((a, b) => {
        if (a?.name < b?.name) {
            return -1;
        }
        if (a?.name > b?.name) {
            return 1;
        }
        return 0;
    });

    // Checks if dose value is equal to 0
    // replaces it by empty value
    const temp = diabetes ? diabetes[props.dose_name] : '';
    useEffect(() => {
        // if (temp === 0) {
        //   dispatch(
        //     updateDiabetes({
        //       type: props.dose_name,
        //       value: '',
        //     })
        //   );
        // }
    }, [temp, props.dose_name]);

    // Checks if value of any insulin is changed then resets the current row
    const current_row = diabetes ? diabetes[`insulin_type_unit_${props.name}`] : '';

    // Custom useEffect to run only if key changes not on first mount
    useDidMountEffect(() => {
        // Only resets if select is emptied, which is done by changing the bolus,basal...
        if (current_row === '' || current_row === 0) {
            setSelectValue('');
            dispatch(
                updateDiabetes({
                    type: `insulin_type_unit_${props.name}`,
                    value: '',
                }),
            );

            dispatch(
                updateDiabetes({
                    type: props.dose_name,
                    value: '',
                }),
            );

            setGlucSwitchValue(false);
            dispatch(updateDiabetes({ type: props.gluc_name, value: false }));

            setCorrSwitchValue(false);
            dispatch(updateDiabetes({ type: props.corr_name, value: false }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current_row, props.corr_name, props.gluc_name, props.name, props.dose_name]);

    React.useEffect(() => {
        setGlucSwitchValue(diabetes[props.gluc_name]);
        setCorrSwitchValue(diabetes[props.corr_name]);
    }, [diabetes]); // eslint-disable-line react-hooks/exhaustive-deps

    if (props.name.replace('_', ' ') === 'Dinner') {
        console.warn(
            `\n\n\n ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====\n\n\n`,
        );

        console.log(`red box bug debug`, {
            propsSwitchName: props.switch_name,
            propsDoseName: props.dose_name,
            propsCorrName: props.corr_name,
            selectValue,
            diabetes,
        });

        console.warn(
            `\n\n\n ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====\n\n\n`,
        );
    }

    return (
        <Box p={2}>
            <Grid container spacing={2} alignItems='center'>
                <Grid container item xs={2} alignItems='center'>
                    <Grid item xs={6}>
                        {/* Main switch for meal row */}
                        <Switch
                            onChange={onChangerSwitch}
                            checked={diabetes ? diabetes[props.switch_name] : false}
                            name={props.switch_name}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {/* Name of meal time here */}
                        <Typography
                            variant='subtitle1'
                            component='p'
                            className={classes.timeNames}
                            sx={{ color: !switchValue ? 'gray !important' : '' }}
                        >
                            {props.name.replace('_', ' ')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    {/* Timeframe of meal time here */}

                    <Typography
                        variant='subtitle1'
                        component='span'
                        className={classes.timeValues}
                        sx={{ color: !switchValue ? 'gray !important' : '' }}
                    >
                        {`${props.start?.slice(0, 5)}-${props.stop?.slice(0, 5)}`}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={3}
                    pb={1}
                    style={{ display: 'flex' }}
                    sx={{
                        border:
                            (diabetes[props.switch_name] &&
                                selectValue === '' &&
                                !diabetes[props.gluc_name]) ||
                            (diabetes[props.switch_name] &&
                                selectValue === '' &&
                                diabetes[props.corr_name])
                                ? '1px solid rgb(244,67,54)'
                                : '',
                        borderRadius: '8px',
                    }}
                >
                    {/* Maps selected insulins to select values here */}

                    <Select
                        onChange={onChangerSelect}
                        value={selectValue}
                        disabled={!switchValue}
                        classes={classesSelect}
                    >
                        {selected_insulins
                            ? selected_insulins.map((insulin, index) => {
                                  if (insulin.name !== '' && insulin.name !== 0) {
                                      return (
                                          <MenuItem
                                              key={index}
                                              value={insulin.type}
                                              name={insulin.type}
                                          >
                                              {insulin.name}
                                          </MenuItem>
                                      );
                                  }
                                  return false;
                              })
                            : ''}
                    </Select>
                </Grid>
                <Grid
                    item
                    xs={2}
                    // space={2}
                    pb={1}
                    style={{ display: 'flex' }}
                    alignItems='center'
                    justifyContent='space-evenly'
                    sx={{
                        border:
                            (diabetes[props.switch_name] &&
                                !diabetes[props.dose_name] &&
                                selectValue !== '' &&
                                !diabetes[props.gluc_name]) ||
                            (diabetes[props.switch_name] &&
                                !diabetes[props.dose_name] &&
                                selectValue !== '' &&
                                diabetes[props.corr_name])
                                ? '1px solid rgb(244,67,54)'
                                : '',
                        borderRadius: '8px',
                    }}
                >
                    <Grid item xs={4} container justifyContent='left'>
                        {/* dose amount here, used to populate final table */}

                        <Input
                            value={diabetes ? diabetes[props.dose_name] : ''}
                            name={props.dose_name}
                            onChange={onChangerInput}
                            type='number'
                            disabled={
                                !switchValue || diabetes[`insulin_type_unit_${props.name}`] === ''
                            }
                            error={diabetes ? diabetes[props.dose_name] > 80 : false}
                            onKeyUp={onChangerInput}
                            classes={classes}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography
                            variant='subtitle1'
                            component='span'
                            sx={{ color: !switchValue ? 'gray' : '' }}
                        >
                            Units
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={2} justifyContent='center' sx={{ display: 'flex' }}>
                    {/* Switch for glucose on and off */}

                    <Switch
                        onChange={onChangerSwitchGluc}
                        checked={glucSwitchValue}
                        name={props.gluc_name}
                        disabled={!switchValue}
                    />
                </Grid>
                <Grid item xs={1} sx={{ marginLeft: '-30px' }}>
                    {/* Switch for correction on and off */}

                    <Switch
                        onChange={onChangerSwitchCorr}
                        checked={corrSwitchValue}
                        name={props.corr_name}
                        disabled={!switchValue}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
