import React from 'react';
import LogsTable from 'components/atoms/PatientChart/LogsTable';

export default function MedicationLogs() {
  return (
    <div
      style={{
        maxWidth: '1376px',
        minWidth: '1376px',
        minHeight: '548px',
        borderRadius: '16px',
        background: 'white',
        overflowY: 'hidden',
        padding: '20px',
      }}
    >
      <LogsTable />
    </div>
  );
}
