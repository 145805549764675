import axiosFetch from 'redux/config/axios';

export const getRequiredLogs = async (id) => {
  let res;
  if (id) res = await axiosFetch.get(`/required-logs/?patient_id=${id}`);
  else res = await axiosFetch.get(`/required-logs/`);

  return res;
};

export const getSingleRequiredLogs = async (id) => {
  const res = await axiosFetch.get(`/required-logs/${id}/`);
  return res;
};

export const postRequiredLogs = async (data) => {
  const res = await axiosFetch.post('/required-logs/', data);
  return res;
};

export const putRequiredLogs = async (id, data) => {
  const res = await axiosFetch.put(`/required-logs/${id}/`, data);
  return res;
};

export const patchRequiredLogs = async (id, data) => {
  const res = await axiosFetch.patch(`/required-logs/${id}/`, data);
  return res;
};

export const deleteRequiredLogs = async (id) => {
  const res = await axiosFetch.delete(`/required-logs/${id}/`);
  return res;
};
