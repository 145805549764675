import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Grid, Typography , Paper } from '@mui/material';
import TextField from '@mui/material/TextField';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Chip from '@mui/material/Chip';
import {
  useStyles,
  useStylesMulti,
} from 'styles/components/atoms/Autocomplete';

// Here onChange we set the options to data retrieved from api

export default function Autocompletes(props, { setShowAll }) {
  // Initializing styles for both single valued and multivalued
  const classes = useStyles();
  const classesMulti = useStylesMulti();

  // initializng options for autocomplete from props
  const [options, setOptions] = React.useState(props.options || []);

  React.useEffect(() => {
    // async function getOptions() {
    //   const res = await axios.get(
    //     `https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&authenticity_token='&maxList=&terms=${props.inputValue}`
    //   );
    //   let temp = [...props.options];
    //   temp = [];
    //   res.data[3].map((item) => {
    //     temp.push({
    //       labelOrg: item[1],
    //       label: `${item[0]} | ${item[1]}`,
    //       code: item[0],
    //     });
    //     return false;
    //   });

    //   setOptions(temp);
    // }

    if (!props.disableResults) setOptions(props.options);
  }, [props.options, props.inputValue, props.showAll]);

  // Here we check if the user typed value is available in options or not
  // if available it will be automatically selected on pressing tab
  // else it will clear on blur
  const [autoSelect, setAutoSelect] = React.useState(false);
  React.useEffect(() => {
    if (props.borderLess && !props.disableStyles)
      if (
        props.inputValue !== '' &&
        props.options.filter((e) =>
          e?.label?.toLowerCase().includes(props?.inputValue?.toLowerCase())
        ).length > 0
      ) {
        setAutoSelect(true);
      } else {
        setAutoSelect(false);
      }
  }, [props.inputValue]);

  // Custom paper used for diagnosis search api, to show search results at bottom
  function CustomPaper(props1) {
    return (
      <Paper elevation={8} {...props1}>
          {props1.children}
          {props.disableResults ? (
            ''
          ) : (
            <Grid item container p={2}>
              <Typography
                sx={{
                  fontSize: '14px',
                  color: '#282D36',
                  fontFamily: 'Work Sans, sans-serif',
                  marginLeft: '2px',
                }}
              >
                {props.current ? props.current : 0} of{' '}
                {props.total ? props.total : 0} total
              </Typography>
            </Grid>
          )}
        </Paper>
    );
  }

  // custom paper used for multivalued autocomplete
  function CustomPaperMulti(props1) {
    return (
      <Paper
        elevation={8}
        {...props1}
        sx={{ width: '630px', marginLeft: '-400px' }}
      >
        {props1.children}
      </Paper>
    );
  }

  // custom paper used for large autocomplete
  function CustomPaperLarge(props1) {
    return (
      <Paper elevation={8} {...props1} sx={{ width: '150px' }}>
        {props1.children}
      </Paper>
    );
  }

  // custom tag or chip for multivalued autocomplete
  function MyChip(props, { label }) {
    return (
      <Chip
        {...props}
        sx={{
          fontSize: '11px !important',
          marginRight: '0px !important',
          marginLeft: '1px !important',
        }}
      >
        {label}
      </Chip>
    );
  }

  return (
    <Grid container className='autoCompleteCustom'>
      {props.multiple ? (
        <Autocomplete
          sx={props.sx}
          multiple
          value={props.value}
          onInputChange={props.onInputChange}
          inputValue={props.inputValue}
          id='tags-outlined'
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          onChange={props.onChange}
          PaperComponent={CustomPaperMulti}
          options={props.options}
          getOptionLabel={(options) => options?.label}
          filterSelectedOptions
          // options={props.options}
          className={`${classesMulti.root} ${props.classes?.root}`}
          renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
              <MyChip {...getTagProps({ index })} label={option.code} />
            ))}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select"
              variant="standard"
              InputProps={{
                ...params.InputProps,

                disableUnderline: true,
                endAdornment: (
                  <KeyboardArrowDownIcon sx={{ color: '#7E8186' }} />
                ),
              }}
            />
          )}
        />
      ) : props.borderLess ? (
        <Autocomplete
          value={props.value}
          onChange={props.onChange}
          inputValue={props.inputValue}
          onInputChange={props.onInputChange}
          clearOnBlur={!props.disableOnBlur}
          autoHighlight
          onFocus={props.onFocus}
          autoSelect={autoSelect}
          onBlur={props.onBlur}
          options={props.options}
          disablePortal
          id='combo-box-demo'
          className={`${classes.root} ${props.classes?.root}`}
          sx={props.sx}
          freeSolo
          PaperComponent={props.large ? CustomPaperLarge : ''}
          filterOptions={props.filterOptions}
          renderInput={(params) =>
            props.disableStyles ? (
              <TextField {...params} placeholder={props.placeholder} />
            ) : (
              <TextField
                {...params}
                placeholder="Select"
                variant="standard"
                InputProps={{
                  ...params.InputProps,

                  disableUnderline: true,
                  endAdornment: (
                    <KeyboardArrowDownIcon sx={{ color: '#7E8186' }} />
                  ),
                }}
              />
            )
          }
        />
      ) : (
        <Autocomplete
          autoSelect
          value={props.value}
          className={`${classes.root} ${props.classes?.root}`}
          onChange={props.onChange}
          inputValue={props.inputValue}
          onFocus={props.onFocus}
          onInputChange={props.onInputChange}
          clearOnBlur={false}
          onBlur={props.onBlur}
          options={props.inputValue ? options : []}
          isOptionEqualToValue={(option, value) => option?.code === value?.code}
          PaperComponent={CustomPaper}
          filterOptions={(options, state) => options}
          renderInput={(params) => (
            <TextField {...params} placeholder={props.placeholder} />
          )}
          sx={props.sx}
        />
      )}
    </Grid>
  );
}
