import * as React from 'react';
import { Grid , IconButton } from '@mui/material';

import Button from 'components/atoms/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import {
  savePatientData,
  updatePatientData,
} from 'redux/actions/patientChartActions';

// Modal components
import Modal from '@mui/material/Modal';
import { usePatient } from 'redux/reusables/patients';
import { trackMixPanel } from 'redux/proxies/mixpanel';
import { TRACK_MEDICATION_DELETED } from 'redux/constants/mixPanel';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '16px',
  boxShadow: 24,
  p: 4,
};

export default function MedicationBuilderGridItem({
  MainComponent,
  index,
  noMedNameError,
  setNoMedNameError,
  order
}) {
  // GEt current patient data from redux
  const { patient } = usePatient();

  const dispatch = useDispatch();

  // Here we store the main data
  const [dataTemp, setDataTemp] = React.useState(false);

  React.useEffect(() => {
    setDataTemp(patient.medData
      ? patient.medData[index]?.medName !== ''
      : {
        medName: '',
        nickname: '',
        dose: '',
        units: '',
        route: '',
        frequency: '',
        prn: 'off',
        code: [],
      })
  }, [])

  const edit_mode = patient ? patient.editMode : false;

  React.useEffect(() => {
    if ((dataTemp.medName === '' || !dataTemp.medName) && edit_mode) {
      dispatch(
        updatePatientData({
          type: 'noMedName',
          value: true,
        })
      );
    }
    if (dataTemp.medName) {
      if (dataTemp.medName.length <= 1 || !edit_mode) {
        dispatch(
          updatePatientData({
            type: 'noMedName',
            value: false,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTemp.medName, edit_mode]);

  React.useEffect(() => {
    if (patient.medFields !== 0) {
      if (patient.medData) {
        if (patient.medData[index]) setDataTemp(patient.medData[index]);
      }
    }
  }, [patient.medData]);

  // Event handlers

  const onChanger = (event) => {
    setDataTemp((preVal) => ({
      ...preVal,
      [event.target.name]: event.target.value,
      edited: true,
    }));
  };

  const onChangerSelect = (event) => {
    setValueSelect(event.target.value);
    setDataTemp((preVal) => ({
      ...preVal,
      [event.target.name]: event.target.value,
      edited: true,
    }));
  };

  const onChangerAutoComplete = (e, name) => {
    if (name == 'code') {
      setDataTemp((preVal) => ({
        ...preVal,
        [name]: e ? e.current : [],
        api: e ? e.api : [],
        edited: true,
      }));
      return false;
    }
    setDataTemp((preVal) => ({
      ...preVal,
      [name]: e || '',
      edited: true,
    }));
  };

  const saveMeds = () => {
    // setData((preVal) => ([...preVal, dataTemp]);
    const data = {...dataTemp, order}

    if (patient.medData) {
      const temp = patient.medData.filter((item, i) => i === index);
      if (temp.length !== 0) {
        const newData = patient.medData.map((item, i) => {
          if (i === index) {
            return data;
          }

          return item;
        });

        dispatch(
          updatePatientData({
            type: 'medData',
            value: newData,
          })
        );
      } else {
        dispatch(
          updatePatientData({
            type: 'medData',
            value: patient.medData
              ? [...patient.medData, data]
              : [data],
          })
        );
      }
    } else {
      dispatch(
        updatePatientData({
          type: 'medData',
          value: patient.medData ? [...patient.medData, data] : [data],
        })
      );
    }
  };

  React.useEffect(() => {
    if (dataTemp.medName) {
      saveMeds();
      setEdit(false);
    }
  }, [patient.save]);

  // Temporary menu items
  const menuItems = [
    { name: 'Once a day', color: '#E1F3E5 !important' },
    { name: 'Twice a day', color: '#FDEDDC !important' },
    { name: 'Three times a day', color: '#CCDAE6 !important' },
  ];

  const doseCheck = (e) => {
    if (e.target.value) {
      if (e.target.value >= 9999.999) {
        setDataTemp((preVal) => ({
          ...preVal,
          [e.target.name]: parseFloat(9999.999).toFixed(3),
        }));
        return false;
      }
      if (e.target.value <= 0) {
        setDataTemp((preVal) => ({
          ...preVal,
          [e.target.name]: parseFloat(0.001).toFixed(3),
        }));
      } else {
        setDataTemp((preVal) => ({
          ...preVal,
          [e.target.name]: parseFloat(e.target.value)
            .toFixed(3)
            .replace(/\.?0+$/, ''),
        }));
      }
    }
  };

  const [valueSelect, setValueSelect] = React.useState(0);

  const [edit, setEdit] = React.useState(true);

  React.useEffect(() => {
    if (patient.medData[index]) setEdit(false);
  }, [patient.medData]);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const editOnTap = () => {
    if (!patient.editModeLimit) {
      dispatch(updatePatientData({ type: 'editModeLimit', value: true }));
      dispatch(updatePatientData({ type: 'editMode', value: true }));

      if (!edit) {
        setEdit(true);
      }
    }
  };

  const [currentExpanded, setCurrentExpanded] = React.useState(false);
  React.useEffect(() => {
    if (!patient.editModeLimit) {
      if (currentExpanded) {
        setCurrentExpanded(false);
        return true;
      }
    }
  }, [patient.closeMedExpand, patient.medData]);

  return (
    <ClickAwayListener
      onClickAway={(e) => {
        if (e.target.localName === 'body') {
          e.preventDefault();
          return false;
        }
        if (edit) {
          dispatch(
            updatePatientData({
              type: 'updateMedRow',
              value: patient.updateMedRow ? !patient.updateMedRow : true,
            })
          );
        }
      }}
    >
      <div className='clickAwayDiv'>
        <Grid container xs={12}>
          <MainComponent
            setEdit={setEdit}
            edit={edit}
            editOnTap={editOnTap}
            onChanger={onChanger}
            onChangerSelect={onChangerSelect}
            menuItems={menuItems}
            valueSelect={valueSelect}
            onChangerAutoComplete={onChangerAutoComplete}
            index={index}
            dataTemp={dataTemp}
            doseCheck={doseCheck}
            saveMeds={saveMeds}
            noMedNameError={noMedNameError}
            setNoMedNameError={setNoMedNameError}
          />
          <Grid xs={1} container alignItems='center'>
            <IconButton
              onClick={(e) => {
                editOnTap()
              }}
            >

              <img src='/images/Icons/tabler_pencil.svg' alt='Edit Icon' />

            </IconButton>

            <IconButton
              onClick={(e) => {
                if (!patient.editModeLimit || edit) {
                  handleOpenModal();
                }
              }}
            >
              <img src='/images/Icons/tabler_trash.svg' alt='Trash Icon' />
            </IconButton>


          </Grid>
        </Grid>
        {/* Delete confirmation modal */}
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <Typography
              id='modal-modal-title'
              variant='h6'
              component='h2'
              sx={{ fontWeight: '700', fontSize: '28px' }}
            >
              Delete the medication
            </Typography>
            <Typography
              id='modal-modal-description'
              sx={{
                mt: 2,
                color: '#53575E',
                fontSize: '17px',
                fontFamily: 'Work Sans, serif',
              }}
            >
              Do you really want to delete{' '}
              <span
                style={{
                  mt: 2,
                  color: '#53575E',
                  fontSize: '15px',
                  fontWeight: 'bold',
                  fontFamily: 'Work Sans, serif',
                }}
              >
                {' '}
                {dataTemp.medName ? dataTemp.medName : 'the new med'}
              </span>
              ? You won't be able to return back after deleting.
            </Typography>
            <Grid container xs={12} direction='row'>
              <Grid item xs={6} mt={2}>
                <Button
                  title='Cancel'
                  sx={{
                    width: '180px',
                    height: '40px',
                    background: 'white !important',
                    color: '#004680 !important',
                    border: '1px solid #004680',
                    textTransform: 'capitalize',
                    fontSize: '18px',
                  }}
                  onClick={() => {
                    handleCloseModal();
                  }}
                />
              </Grid>
              <Grid item xs={6} mt={2}>
                <Button
                  title='Delete'
                  sx={{
                    width: '180px',
                    height: '40px',
                    background: '#EC5D57 !important',
                    border: '1px solid #EC5D57',
                    textTransform: 'capitalize',
                    fontSize: '18px',
                  }}
                  onClick={() => {
                    const deletedMeds = patient.medData.filter(
                      (x, i) => i === index
                    )[0];

                    dispatch(
                      updatePatientData({
                        type: 'deletedMeds',
                        value: patient.deletedMeds
                          ? [...patient.deletedMeds, deletedMeds]
                          : [deletedMeds],
                      })
                    );
                    dispatch(
                      updatePatientData({
                        type: 'medData',
                        value: patient.medData.filter((x, i) => i !== index),
                      })
                    );
                    dispatch(
                      updatePatientData({
                        type: 'medFields',
                        value: patient?.medFields - 1,
                      })
                    );

                    // remove current row error if any
                    const tempErrorNoMeds = noMedNameError;
                    tempErrorNoMeds.splice(index, 1);
                    setNoMedNameError(tempErrorNoMeds);

                    // Checks if any meds with no name then dont save
                    if (!noMedNameError.includes(true)) {
                      dispatch(savePatientData('medBuilder'));
                      trackMixPanel(TRACK_MEDICATION_DELETED);
                    }

                    // Close modal and turn off edit mode
                    if (!dataTemp.medName) {
                      dispatch(
                        updatePatientData({
                          type: 'editMode',
                          value: false,
                        })
                      );
                      dispatch(
                        updatePatientData({
                          type: 'editModeLimit',
                          value: false,
                        })
                      );
                    }
                    handleCloseModal();
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </div>
    </ClickAwayListener>
  );
}
