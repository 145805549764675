import * as React from 'react';
import MobileStepper from '@mui/material/MobileStepper';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useSelector } from 'react-redux';
import { useOnboarding } from 'redux/reusables/onboarding';

const useStyles = makeStyles({
  root: {
    '& .MuiMobileStepper-progress': {
      width: '472px',
      borderRadius: '16px',
      height: '8px',
      color: '#0084D5',
    },
  },
});

export default function ProgressMobileStepper(props) {
  const classes = useStyles();

  const { onboarding } = useOnboarding();

  React.useEffect(() => {}, [onboarding]);
  return (
    <MobileStepper
      variant='progress'
      steps={props.steps.length}
      position='static'
      activeStep={props.activeStep}
      sx={{ maxWidth: 560, flexGrow: 1, marginLeft: '-8px', maxHeight: '40px' }}
      className={classes.root}
      nextButton={
        <>
          <IconButton
            size='small'
            onClick={props.handleNext}
            disabled={
              onboarding.dob === '' ||
              onboarding.gender === '' ||
              props.activeStep >= 4
            }
            sx={{
              background: '#E0E0E1 !important',
              marginLeft: '10px',
              marginRight: '10px',
            }}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
          <IconButton
            size='small'
            onClick={props.handleBack}
            disabled={props.activeStep === 1}
            sx={{ background: '#E0E0E1 !important' }}
          >
            <KeyboardArrowUpIcon />
          </IconButton>
        </>
      }
    />
  );
}
