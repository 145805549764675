import {
  TIMEFRAME_DETAILS_FAIL,
  TIMEFRAME_DETAILS_SUCCESS,
  TIMEFRAME_DETAILS_REQUEST,
  TIMEFRAME_UPDATE_FAIL,
  TIMEFRAME_UPDATE_SUCCESS,
  TIMEFRAME_UPDATE_REQUEST,
  TIMEFRAME_SAVE_FAIL,
  TIMEFRAME_SAVE_SUCCESS,
  TIMEFRAME_SAVE_REQUEST,
} from '../constants/timeFrameConstants';

export const timeFrameDetailsReducer = (state = { timeframes: [] }, action) => {
  switch (action.type) {
    case TIMEFRAME_DETAILS_REQUEST:
      return { loading: true, timeframes: [] };
    case TIMEFRAME_DETAILS_SUCCESS:
      return { loading: false, timeframes: action.payload };
    case TIMEFRAME_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const timeFrameUpdateReducer = (state = { timeframes: {} }, action) => {
  switch (action.type) {
    case TIMEFRAME_UPDATE_REQUEST:
      return { loading: true, timeframes: state };
    case TIMEFRAME_UPDATE_SUCCESS:
      return { loading: false, timeframes: action.payload };
    case TIMEFRAME_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const timeFrameSaveReducer = (state = { timeframes: {} }, action) => {
  switch (action.type) {
    case TIMEFRAME_SAVE_REQUEST:
      return { loading: true, timeframes: state };
    case TIMEFRAME_SAVE_SUCCESS:
      return { loading: false, timeframes: action.payload };
    case TIMEFRAME_SAVE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
