import axios from 'axios';
import { API_URL } from 'constant';
import { Tokens } from 'redux/config/getToken';
import { getAccessToken } from './utils';

const accessToken = Tokens.getAccess();

const axiosFetch = axios.create({
  baseURL: `${API_URL}/api`,
  headers:{ Authorization: `Bearer ${accessToken}` }
});

axiosFetch.interceptors.request.use(async req => {
  const token = await getAccessToken();

  if(!token) return req;

  req.headers.Authorization = `Bearer ${token}`
  return req
})

axiosFetch.interceptors.response.use(response =>  response, error => {
  if (error.response.status === 401) {
    Tokens.removeAccess();
    Tokens.removeRefresh();
  }
  return error;
});

export default axiosFetch;
