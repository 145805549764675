import React from 'react';
import { useNavigate , useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  saveEncounters,
  showEncounters,
} from 'redux/actions/encountersNotesActions';
import { Menu, MenuItem, Modal, Box, Typography, Grid } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Button from 'components/atoms/Button';
import axiosFetch from 'redux/config/axios';
import moment from 'moment';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '16px',
  boxShadow: 24,
  p: 4,
};

function ThreeDotsMenu({ id, patientState, encounterData }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(moment().format('MM/DD/YYYY'));
  const { pk, trackerType } = useParams();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const update = (id) => {
    navigate(`/encounter-update/${patientState?.patient?.pk}/${id}`, {
      state: {
        trackerType,
        pk,
      }
    });
  };

  const copy = () => {
    const today = new Date();
    let hours =
      today.getHours() < 10 ? `0${  today.getHours()}` : today.getHours();
    let minutes =
      today.getMinutes() < 10 ? `0${  today.getMinutes()}` : today.getMinutes();
    if (Number(minutes) < 15 || Number(minutes) > 45) {
      if (Number(minutes) > 45 && Number(hours) < 10) {
        hours = `0${Number(hours) + 1}`;
      } else if (Number(minutes) > 45 && Number(hours) > 10) {
        hours = Number(hours) + 1;
      }
      minutes = '00';
    } else {
      minutes = '30';
    }
    const time = `${hours  } : ${  minutes}`;

    const date1 = moment(value).format('YYYY-MM-DD');
    const timePikerr = time?.split(' ').join('');

    const timeee = moment(
      `${date1}T${timePikerr}:00.364Z`,
      'DD MM YYYY hh:mm:ss',
      true
    );
    const copyEncounter = { ...encounterData, encounter_time: timeee._i };
    delete copyEncounter.id;
    dispatch(saveEncounters(copyEncounter, 'create'));
    dispatch(showEncounters(copyEncounter.patient));
  };

  const handleClose = async (id) => {
    try {
      const res = await axiosFetch.delete(`/signed-encounters-notes/${id}/`);
      dispatch(showEncounters(patientState.patient.pk));
      setAnchorEl(false);
      return res.data;
    } catch (err) {
    }
  };
  // Modal data
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(false);
        }}
        keepMounted
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => update(id)}>Edit</MenuItem>
        <MenuItem onClick={() => handleOpenModal()}>Delete</MenuItem>
        <MenuItem onClick={() => copy()}>Copy</MenuItem>
      </Menu>
      {/* Delete confirmation modal */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography
            id='modal-modal-title'
            variant='h6'
            component='h2'
            sx={{ fontWeight: '700', fontSize: '28px' }}
          >
            Delete the encounter
          </Typography>
          <Typography
            id='modal-modal-description'
            sx={{ mt: 2, color: '#53575E', fontSize: '17px' }}
          >
            Do you really want to delete the encounter? You won't be able to
            return back after deleting.
          </Typography>
          <Grid container xs={12} direction='row'>
            <Grid item xs={6} mt={2}>
              <Button
                title='Cancel'
                sx={{
                  width: '180px',
                  height: '40px',
                  background: 'white !important',
                  color: '#004680 !important',
                  border: '1px solid #004680',
                  textTransform: 'capitalize',
                  fontSize: '18px',
                }}
                onClick={() => {
                  handleCloseModal();
                }}
              />
            </Grid>
            <Grid item xs={6} mt={2}>
              <Button
                title='Delete'
                sx={{
                  width: '180px',
                  height: '40px',
                  background: '#EC5D57 !important',
                  border: '1px solid #EC5D57',
                  textTransform: 'capitalize',
                  fontSize: '18px',
                }}
                onClick={() => {
                  handleClose(id);
                  handleCloseModal();
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default ThreeDotsMenu;
