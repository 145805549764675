import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '8px, 0px, 8px, 0px',
    background: '#FFFFFF',
    boxShadow: '-1px 2px 4px rgba(0,0,0,0.04),1px 3px 4px rgba(0,0,0,0.08)',
    borderRadius: '8px',
    minHeight: '100px',
    width: '340px',
  },
});

export default function TooltipCustom({ children, text }) {
  return (
    <div>
      <CustomWidthTooltip title={text}>{children}</CustomWidthTooltip>
    </div>
  );
}
