import axiosFetch from 'redux/config/axios';

export const getTimeframes = async (id) => {
  let res;
  if (id) res = await axiosFetch.get(`/timeframes/?patient_id=${id}`);
  else res = await axiosFetch.get('/timeframes/');

  return res;
};

export const postTimeframes = async (data) => {
  const res = await axiosFetch.post('/timeframes/', data);
  return res;
};

export const putTimeframes = async (data) => {
  const res = await axiosFetch.put('/timeframes/', data);
  return res;
};
