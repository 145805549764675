import React from 'react';
import { Grid, Typography , IconButton } from '@mui/material';

import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import CreateIcon from '@mui/icons-material/Create';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';


import ModalCustom from 'components/atoms/Modal';
import Button from 'components/atoms/Button';

// Redux
import {
  savePatientData,
  updatePatientData,
} from 'redux/actions/patientChartActions';
import { useDispatch, useSelector } from 'react-redux';
import { usePatient } from 'redux/reusables/patients';
import { trackMixPanel } from 'redux/proxies/mixpanel';
import { TRACK_EDIT_CLICKED } from 'redux/constants/mixPanel';
import { capitalize } from 'redux/actions/helperFunctions';
import RichEditor from './RichEditor';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Editor({ editButton, name, editable, defaultValue, tab, label }) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const { patient } = usePatient();

  const defaultNote =
    '{"blocks":[{"key":"1k8j6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}';

  // For not saved warning:
  const [warning, setWarning] = React.useState(true);
  const [warningModal, setWarningModal] = React.useState(false);

  const handleOpen = () => {
    trackMixPanel(TRACK_EDIT_CLICKED(capitalize(name?.replace('_', ' '))));
    setOpen(true);
  };
  const handleClose = () => {
    if (
      patient[`${name}`] !== defaultNote &&
      patient[`${name}Org`] !== patient[`${name}`]
    )
      if (!warning || !editButton) {
        setWarning(true);
        setOpen(false);
      } else {
        setWarningModal(true);
      }
    else {
      setWarning(true);
      setOpen(false);
    }
  };

  const acceptWarning = () => {
    dispatch(savePatientData(name));
    setWarningModal(false);
    setOpen(false);
  };

  const [left, setLeft] = React.useState('');
  const [top, setTop] = React.useState('');
  const [right, setRight] = React.useState('');
  const [bottom, setBottom] = React.useState('');

  React.useEffect(() => {
    if (name)
      switch (name) {
        case 'ALLERGIES':
          setRight('20px');
          setTop('60px');
          break;
        case 'SOCIAL':
          setRight('20px');
          setBottom('30px');
          break;
        case 'FAMILY':
          setRight('20px');
          setBottom('30px');
          break;
        case 'SURGICAL':
          setRight('32vw');
          setBottom('30px');
          break;
        case 'TREATMENT_COORDINATION':
          setRight('32vw');
          setBottom('30px');
          break;
        case 'STICKY':
          setLeft('20px');
          setBottom('60px');
          break;
        case 'SCRATCH':
          setLeft('20px');
          setBottom('60px');
          break;
        case 'OTHER_PROVIDERS':
          setRight('20px');
          setBottom('30px');
          break;
        case 'PHARMACIES':
          setRight('20px');
          setBottom('30px');
          break;
        default:
          setLeft('10px');
          setBottom('10px');
      }
  }, [name]);

  const [focusEditor, setFocusEditor] = React.useState(false);

  const discardChanges = (type) => {
    if (patient[`${type}Org`] !== patient[`${type}`]) {
      dispatch(
        updatePatientData({
          type,
          value: patient[`${type}Org`],
        })
      );
    }
    if (!patient[`${type}Org`]) {
      dispatch(
        updatePatientData({
          type,
          value: defaultNote,
        })
      );
      setWarning(true);
      setOpen(false);
    }
    setWarningModal(false);
  };

  return (
    <div>
      {editButton ? (
        <Typography
          variant='p'
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={
            editable
              ? handleOpen
              : tab
              ? () => {
                  dispatch(updatePatientData({ type: 'tab', value: tab }));
                }
              : () => {}
          }
        >
          <CreateIcon sx={{ fontSize: '15px', marginRight: '10px' }} />
          Edit
        </Typography>
      ) : (
        <IconButton onClick={handleOpen}>
          <Grid
            container
            className='edit-button-home'
            justifyContent='center'
            alignItems='center'
          >
            {/* <RateReviewOutlinedIcon sx={{ color: 'white' }} /> */}
            <img src='/images/Icons/tabler_edit.svg' />
          </Grid>
        </IconButton>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            width: '656px',
            minHeight: '287px',
            background: 'white',
            position: 'absolute',
            left,
            right,
            bottom,
            top,
            style,
          }}
          p={1}
          m={2}
          border={1}
          borderColor='grey.500'
          borderRadius={4}
          onClick={() => {
            setFocusEditor(true);
          }}
          onBlur={() => {
            setFocusEditor(false);
          }}
        >
          <ModalCustom open={warningModal}>
            <Grid container alignItems="center" justifyContent='center'>
              <Grid item xs='12' mb={2} container justifyContent='center'>
                <Typography sx={{ fontSize: '20px' }}>
                  There are unsaved changes.
                </Typography>
              </Grid>
              <Grid xs='6' item container justifyContent='center'>
                <Button
                  onClick={acceptWarning}
                  title='Save Changes'
                  sx={{ width: '170px', height: '55px !important' }}
                />
              </Grid>
              <Grid xs='6' item container justifyContent='center'>
                <Button
                  onClick={() => discardChanges(name)}
                  title='Discard Changes'
                  secondary
                  sx={{ width: '170px', height: '55px !important' }}
                />
              </Grid>
            </Grid>
          </ModalCustom>
          <RichEditor
            name={name}
            defaultValue={defaultValue}
            label={label}
            setWarning={setWarning}
            focusEditor={focusEditor}
          />
        </Box>
      </Modal>
    </div>
  );
}

export default Editor;
