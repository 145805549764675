import { TRACK_TRACKER_STARTED, TRACK_TRACKER_STOPPED, TRACK_TRACKER_FAILED } from 'redux/constants/mixPanel';
import { trackMixPanel } from 'redux/proxies/mixpanel';
import { startTimer as startTimerUtil, stopTimer as stopTimerUtil } from 'utils/Timers';
import { socketCommitTime, closeTimeSocket, openTimeSocket } from 'webSockets/timer';
import {
  TIMER_START,
  TIMER_COMMIT,
  TIMER_STOP,
  ITimersType
} from '../constants/trakerConstants';

export const stopTimer = (type: ITimersType) => (dispatch: any) => {
  const totalTime = stopTimerUtil(type);
  closeTimeSocket(type, totalTime)
  dispatch({ type: TIMER_STOP, payload: { type } });
  trackMixPanel(TRACK_TRACKER_STOPPED);
}

export const startTimer = (type: ITimersType, patientId: string) => async (dispatch: any) => {
  try{
    dispatch({ type: TIMER_START, payload: { type } });
    await openTimeSocket(type, patientId)

    startTimerUtil(type, {cb: (time) => {
      socketCommitTime(type, time);
    }, uiCb: (time) => {
      dispatch({ type: TIMER_COMMIT, payload: { type, time } });
    }})
    trackMixPanel(TRACK_TRACKER_STARTED);
  }catch(error){
    console.error(error);
    trackMixPanel(TRACK_TRACKER_FAILED);
    dispatch(stopTimer(type));
  }
}
