import TimeMe from 'timeme.js';

interface ICommitOptions {
    commitDuration?: number;
    cb?: (total: number) => void;
    uiCb?: (total: number) => void;
}

const commitIntervals: Partial<
    Record<
        string,
        { uiCount: number; commitCount: number; interval: ReturnType<typeof setInterval> }
    >
> = {};

const getTimerTime = (type: string) => TimeMe.getTimeOnPageInSeconds(type);

const INTERVAL_DURATION = 300;
const DEFAULT_COMMIT_DURATION = 5000;

export const stopTimer = (type: string): number => {
    const time = getTimerTime(type);

    TimeMe.stopTimer(type);
    TimeMe.resetRecordedPageTime(type);

    if (commitIntervals[type]) {
        clearInterval(commitIntervals[type]!.interval);
        delete commitIntervals[type];
    }

    return time;
};

export const startTimer = (type: string, commitOptions: ICommitOptions = {}) => {
    stopTimer(type);
    TimeMe.initialize({
        currentPageName: type,
        idleTimeoutInSeconds: -0,
    });

    const commitDuration = commitOptions.commitDuration || DEFAULT_COMMIT_DURATION;
    const commitCallback = commitOptions.cb;
    const uiCallback = commitOptions.uiCb;

    if (commitCallback || uiCallback) {
        const interval = setInterval(() => {
            const currentIntervalItem = commitIntervals[type];
            const uiCount = currentIntervalItem?.uiCount || 0;
            const commitCount = currentIntervalItem?.commitCount || 0;
            const isNeedCommitTime =
                commitCount * commitDuration + commitDuration < uiCount * INTERVAL_DURATION;

            if (commitCallback && isNeedCommitTime) {
                commitCallback(getTimerTime(type));
                if (currentIntervalItem) {
                    currentIntervalItem.commitCount++;
                }
            }
            if (uiCallback) {
                uiCallback(getTimerTime(type));
            }
            if (currentIntervalItem) {
                currentIntervalItem.uiCount++;
            }
        }, INTERVAL_DURATION);

        commitIntervals[type] = {
            interval,
            uiCount: 0,
            commitCount: 0,
        };
    }
};
