import React from 'react';
import CheckBox from 'components/atoms/Checkbox';
import Autocompletes from 'components/atoms/Autocomplete';
import Input from 'components/atoms/Input';
import {  Grid, Typography } from '@mui/material';

import { useDispatch } from 'react-redux';
import axios from 'axios';
import { updatePatientData } from 'redux/actions/patientChartActions';
import {
  useStylesAuto,
  useStylesInput,
} from 'styles/components/atoms/PatientCharts/MedicationBuilder';
// Here we style the Autocomplete field
import { capitalize } from 'redux/actions/helperFunctions';
import { usePatient } from 'redux/reusables/patients';
import {
  getSimilarInputsUnits,
  getSimilarInputsRoutes,
  getSimilarInputsFrequency,
} from './similarInputs';


export function MedicationBuilderGridItemInner({
  edit,
  onChanger,
  setEdit,
  onChangerAutoComplete,
  index,
  dataTemp,
  setNoMedNameError,
  noMedNameError,
  editOnTap,
  doseCheck,
}) {
  const { patient } = usePatient();
  const [codeOptions, setCodeOptions] = React.useState([]);

  const inputElement = React.useRef();
  const focusInput = () => {
    inputElement.current.focus();
  };

  const dispatch = useDispatch();
  // Here we get values from diagnosis sectionand assign it to code autocomplete options
  React.useEffect(() => {
    if (patient.diagnosisData) {
      let temp = [];
      patient.diagnosisData.map((d) => {
        temp.push({
          label: `${d.label} (${d.code})`,
          code: d.code,
          id: d.id,
          nickname: d.nickname,
        });
        return true;
      });
      if (temp.length !== 0)
        temp = [...new Map(temp.map((v) => [v.label, v])).values()];

      setCodeOptions(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient.diagnosisData]);

  const classes = useStylesInput();
  const classesAuto = useStylesAuto();

  const [inputValueUnits, setInputValueUnits] = React.useState('');
  const [inputValueRoute, setInputValueRoute] = React.useState('');
  const [inputValueFrequency, setInputValueFrequency] = React.useState('');

  const [inputValueCode, setInputValueCode] = React.useState('');
  const [inputValueMedName, setValueInputMedName] = React.useState('');

  const [medOptions, setMedOptions] = React.useState([]);

  // Checks if any other row sets edit mode to false and current is still in edit mode
  React.useEffect(() => {
    if (!patient.editMode) {
      if (!dataTemp.medName) {
        dispatch(updatePatientData({ type: 'editMode', value: true }));
      }
    }
    if (inputElement.current && patient.editMode) focusInput();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient.editMode]);

  const onChangeMeds = async (newVal) => {
    // Api calls to get results
    const res = await axios.get(
      `https://clinicaltables.nlm.nih.gov/api/rxterms/v3/search?sf=DISPLAY_NAME&authenticity_token=&maxList=&terms=${newVal}`
    );
    setMedOptions(res.data[1]);
  };
  const [codes, setCodes] = React.useState([]);
  React.useEffect(() => {
    if (patient.medData)
      setTimeout(() => {
        setCodes(patient.medData[index]?.code);
      }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient.medData]);

  // Save on blur
  const [onblurData, setOnblurData] = React.useState({
    firstCheck: false,
    divBlur: false,
    dose: false,
  });
  const checkBlurredRow = (event) => {
    setTimeout(() => {
      setOnblurData((preVal) => ({
        ...preVal,
        [event]: false,
      }));
    }, 300);
  };
  const checkFocusedRow = (event) => {
    setOnblurData((preVal) => ({
      ...preVal,
      [event]: true,
      firstCheck: true,
    }));
  };

  React.useEffect(() => {
    // First case : Everything is okay and safe to save

    if (!dataTemp.medName && patient?.medFields === 1) {
      return true;
    }

    if (
      Object.values(onblurData).filter((item) => item === false).length === 2
    ) {
      if (edit && dataTemp === true) {
        dispatch(
          updatePatientData({
            type: 'medFields',
            value: patient?.medFields - 1,
          })
        );
        dispatch(updatePatientData({ type: 'editMode', value: false }));
        dispatch(updatePatientData({ type: 'editModeLimit', value: false }));
        setEdit(false);
      } else if (edit && !dataTemp.medName) {
        // Checks if med name is empty or not

        const tempErrorNoMeds = noMedNameError;
        tempErrorNoMeds[index] = true;
        setNoMedNameError(tempErrorNoMeds);

        onChanger({ target: { name: 'medName', value: ' ' } });
        dispatch(updatePatientData({ type: 'save', value: !patient.save }));
        dispatch(updatePatientData({ type: 'editMode', value: false }));
        dispatch(updatePatientData({ type: 'editModeLimit', value: false }));
        setEdit(false);
      } else if (edit && dataTemp.medName) {
        // If med name is filled then set error array to false
        if (removeSpace(dataTemp.medName) !== '') {
          const tempErrorNoMeds = noMedNameError;
          tempErrorNoMeds[index] = false;
          setNoMedNameError(tempErrorNoMeds);
        }

        dispatch(updatePatientData({ type: 'save', value: !patient.save }));
        dispatch(updatePatientData({ type: 'editMode', value: false }));
        dispatch(updatePatientData({ type: 'editModeLimit', value: false }));
        setEdit(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onblurData]);

  React.useEffect(() => {
    checkBlurredRow('divBlur');
  }, [patient.updateMedRow]);

  // remove space from start in string
  const removeSpace = (string) => {
    if (string) return string.replace(/^\s+/, '');
  };

  return (
    <Grid
      container
      key={index}
      item
      xs={11}
      alignItems='center'
      py={1}
      onClick={() => {
        checkFocusedRow('divBlur');
        editOnTap();
        checkBlurredRow('dose');
      }}
    >
      {/* HEadings here */}

      <Grid item xs={2.75} sx={{ display: 'flex' }}>
        {edit ? (
          <Autocompletes
            name="medName"
            disableResults
            disableStyles
            value={dataTemp.medName?.trim()}
            onChange={(event, newValue) => {
              const e = {
                target: {
                  name: 'medName',
                  value: newValue,
                },
              };
              onChanger(e);
            }}
            borderLess
            inputValue={inputValueMedName}
            onInputChange={(event, newInputValue) => {
              const e = {
                target: {
                  name: 'medName',
                  value: newInputValue,
                },
              };
              onChanger(e);
              onChangeMeds(newInputValue);
              setValueInputMedName(newInputValue);
            }}
            sx={{
              width: '100% !important',
              background: 'white',
              padding: '0 5px',
              color: '#282D36',
              fontSize: '17px',
              fontWeight: '400',
              fontFamily: 'Work Sans, sans-serif',
              letterSpacing: '-0.31px',
            }}
            options={medOptions}
            placeholder="Select medication"
            filterOptions={(x) => x}
            disableOnBlur
          />
        ) : (
          <Grid item xs zeroMinWidth>
            <Typography
              sx={{
                color: '#282D36',
                paddingRight: '5px',
                fontSize: '17px',
                fontWeight: '400',
                fontFamily: 'Work Sans, sans-serif',
                letterSpacing: '-0.31px',
                overflowWrap: 'break-word',
              }}
            >
              {patient.medData[index]
                ? capitalize(patient.medData[index].medName)
                : ''}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item xs={2.25} sx={{ display: 'flex' }}>
        {edit ? (
          <Input
            sx={{
              height: '40px',
              background: 'white',
            }}
            placeholder='Nickname'
            name='nickname'
            type='text'
            onChange={onChanger}
            value={dataTemp.nickname}
            classes={classes}
          />
        ) : (
          <Typography
            sx={{
              color: '#282D36',
              fontSize: '17px',
              paddingRight: '5px',
              fontWeight: '400',
              fontFamily: 'Work Sans, sans-serif',
              letterSpacing: '-0.31px',
            }}
          >
            {patient.medData[index] ? patient.medData[index].nickname : ''}
          </Typography>
        )}
      </Grid>

      <Grid item xs={1} sx={{ display: 'flex' }}>
        {edit ? (
          <Input
            ref={inputElement}
            sx={{
              height: '40px',
              background: 'white',
            }}
            placeholder='0'
            name='dose'
            onBlur={(e) => {
              doseCheck(e);
              checkBlurredRow('dose');
            }}
            onFocus={() => {
              checkFocusedRow('dose');
            }}
            type='number'
            onChange={onChanger}
            value={dataTemp.dose}
            classes={classes}
            min={0}
          />
        ) : (
          <Typography
            sx={{
              color: '#282D36',
              fontSize: '17px',
              fontWeight: '400',
              fontFamily: 'Work Sans, sans-serif',
              letterSpacing: '-0.31px',
            }}
          >
            {patient.medData[index] ? patient.medData[index].dose : ''}
          </Typography>
        )}
      </Grid>
      <Grid item xs={1}>
        {edit ? (
          <Autocompletes
            value={dataTemp.units}
            // classes={classesUnits}
            onChange={(event, newValue) => {
              if (newValue) {
                onChangerAutoComplete(
                  newValue.label
                    ? getSimilarInputsUnits(newValue.label)
                    : getSimilarInputsUnits(newValue),
                  'units'
                );
              } else {
                onChangerAutoComplete('', 'units');
              }
            }}
            inputValue={inputValueUnits}
            onInputChange={(event, newInputValue) => {
              setInputValueUnits(newInputValue);
            }}
            id='controllable-states-demo'
            disableResults
            options={dropDownDose}
            borderLess
            name='units'
            large
            sx={{ width: '180px !important' }}
          />
        ) : (
          <Typography
            sx={{
              color: '#282D36',
              fontSize: '17px',
              fontWeight: '600',
              fontFamily: 'Work Sans, sans-serif',
              letterSpacing: '-0.31px',
              marginLeft: '10px',
            }}
          >
            {patient.medData[index] ? patient.medData[index].units : ''}
          </Typography>
        )}
      </Grid>
      <Grid item xs={1.5} justifyContent="center">
        {edit ? (
          <Autocompletes
            value={dataTemp.route}
            onChange={(event, newValue) => {
              if (newValue)
                onChangerAutoComplete(
                  newValue.label
                    ? getSimilarInputsRoutes(newValue.label)
                    : getSimilarInputsRoutes(newValue),
                  'route'
                );
              else onChangerAutoComplete('', 'route');
            }}
            inputValue={inputValueRoute}
            onInputChange={(event, newInputValue) => {
              setInputValueRoute(newInputValue);
            }}
            options={dropDownRoute}
            disableResults
            sx={{ width: '100% !important' }}
            borderLess
            name='route'
          />
        ) : (
          <Typography
            sx={{
              color: '#282D36',
              fontSize: '17px',
              fontWeight: '600',
              fontFamily: 'Work Sans, sans-serif',
              letterSpacing: '-0.31px',
              textAlign: 'center',
              textTransform: 'inherit !important',
              marginLeft: '-50px',
            }}
          >
            {patient.medData[index]
              ? patient.medData[index]?.route?.label
                ? patient.medData[index]?.route?.label
                : patient.medData[index]?.route
              : ''}
          </Typography>
        )}
      </Grid>
      <Grid
        item
        xs={1.5}
        sx={{
          display: 'flex',
          marginLeft: edit ? '-20px' : '',
          marginRight: edit ? '20px' : '',
        }}
      >
        {edit ? (
          <Autocompletes
            value={dataTemp.frequency}
            onChange={(event, newValue) => {
              if (newValue)
                onChangerAutoComplete(
                  newValue.label
                    ? getSimilarInputsFrequency(newValue.label)
                    : getSimilarInputsFrequency(newValue),
                  'frequency'
                );
              else onChangerAutoComplete('', 'frequency');
            }}
            inputValue={inputValueFrequency}
            onInputChange={(event, newInputValue) => {
              setInputValueFrequency(newInputValue);
            }}
            options={dropDownFrequency}
            disableResults
            sx={{ width: '170px !important', marginLeft: '20px' }}
            borderLess
            name='frequency'
          />
        ) : (
          <Typography
            sx={{
              fontSize: '15px',
              fontWeight: '600',
              fontFamily: 'Work Sans, sans-serif',
              letterSpacing: '-0.31px',
              height: '28px',
              width: '154px',
              background: patient.medData[index]
                ? patient.medData[index].frequency === 'Once a day'
                  ? '#E1F3E5'
                  : patient.medData[index].frequency === 'Twice a day'
                    ? '#FDEDDC'
                    : patient.medData[index].frequency === 'Three times a day'
                      ? '#CCDAE6'
                      : ''
                : '',
              paddingLeft: '10px',
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {patient.medData[index]
              ? patient.medData[index].frequency !== 0
                ? patient.medData[index].frequency
                : ''
              : ''}
          </Typography>
        )}
      </Grid>
      <Grid item xs={0.5}>
        <Typography
          sx={{
            color: '#282D36',
            fontSize: '17px',
            fontWeight: '400',
            fontFamily: 'Work Sans, sans-serif',
            letterSpacing: '-0.31px',
          }}
        >
          <CheckBox
            name='prn'
            onChange={(e) => {
              if (dataTemp.prn) {
                if (dataTemp?.prn === 'on') {
                  onChanger({ target: { name: 'prn', value: 'off' } });
                } else {
                  onChanger(e);
                }
              } else {
                onChanger(e);
              }
            }}
            checked={dataTemp ? (dataTemp.prn === 'on') : false}
            disabled={!edit}
          />
        </Typography>
      </Grid>
      <Grid item xs={1.5} container>
        <Typography
          sx={{
            color: '#282D36',
            fontSize: '17px',
            fontWeight: '400',
            fontFamily: 'Work Sans, sans-serif',
            letterSpacing: '-0.31px',
          }}
        >
          {edit ? (
            <Autocompletes
              value={dataTemp.code}
              onChange={(event, newValue) => {
                let temp = newValue;
                temp = temp.map((x) => x.id);
                const data = { current: newValue, api: temp };
                onChangerAutoComplete(data, 'code');
              }}
              inputValue={inputValueCode}
              onInputChange={(event, newInputValue) => {
                setInputValueCode(newInputValue);
              }}
              classes={classesAuto}
              options={codeOptions.filter(
                ({ id }) => !dataTemp?.api?.includes(id)
              )}
              disableResults
              borderLess
              multiple
              name='code'
              sx={{
                color: '#282D36',
                fontSize: '15px',
                fontWeight: '400',
                fontFamily: 'Work Sans, sans-serif',
                letterSpacing: '-0.41px',
                width: '230px !important',
              }}
            />
          ) : (
            <Typography
              sx={{
                color: '#282D36',
                fontSize: '17px',
                fontWeight: '400',
                fontFamily: 'Work Sans, sans-serif',
                letterSpacing: '-0.41px',
              }}
            >
              {codes
                ? codes.map((code, indexInner) => codes.length - 1 !== indexInner
                  ? `${code?.code}, `
                  : code?.code)
                : ''}
            </Typography>
          )}
        </Typography>
      </Grid>
    </Grid>
  );
}

const dropDownRoute = [
  { label: 'By mouth' },
  { label: 'PO' },
  { label: 'Per Os' },
  { label: 'Oral' },

  { label: 'Subcutaneus' },
  { label: 'SubQ' },
  { label: 'SQ' },

  { label: 'Intramuscular' },
  { label: 'IM' },

  { label: 'Intravenous' },
  { label: 'IV' },

  { label: 'Rectally' },
  { label: 'PR' },
  { label: 'Per Rectum' },

  { label: 'Vaginally' },
  { label: 'PV' },
  { label: 'Per Vagina' },

  { label: "Sublingually" },
  { label: "Sublingual" },
  { label: "SL" },

  { label: "Intranasal" },
  { label: "IN" },

  { label: "OD" },
  { label: "RIGHT EYE" },

  { label: "OS" },
  { label: "LEFT EYE" },

  { label: "OU" },
  { label: "BOTH EYES" },

  { label: "Inhaled" },
  { label: "INH" },

  { label: "Transdermal" },
  { label: "TD" },

  { label: "Topical" },
];

const dropDownDose = [
  { label: 'g' },
  { label: 'mg' },
  { label: 'mcg' },
  { label: 'U' },
  { label: 'mL' },
  { label: 'L' },
  { label: 'mEq' },
  { label: 'IU' },
  { label: 'tabs' },
  { label: 'capsules' },
  { label: 'doses' },
  { label: 'puffs' },
  { label: 'patches' },
  { label: 'applications' },
  { label: 'grams' },
  { label: 'milligrams' },
  { label: 'micogrtams' },
  { label: 'Units' },
  { label: 'milliliter' },
  { label: 'Liter' },
  { label: 'Milliequivalents' },
  { label: 'International Units' },
  { label: 'tab(s)' },
  { label: 'tablet(s)' },
  { label: 'capsule(s)' },
  { label: 'dose(s)' },
  { label: 'puff(s)' },
  { label: 'patch(s)' },
  { label: 'application(s)' },
];

const dropDownFrequency = [
  { label: 'q24h' },
  { label: 'qDay' },
  { label: 'qD' },
  { label: 'Daily' },
  { label: '1x/day' },

  { label: 'q12h' },
  { label: 'BiD' },
  { label: 'Twice a day' },
  { label: '2x/day' },

  { label: 'q8h' },
  { label: 'TiD' },
  { label: 'Three times a day' },
  { label: '3x/d' },

  { label: 'q6h' },
  { label: 'QiD' },
  { label: 'Four times a day' },
  { label: '4x/d' },

  { label: 'qPM' },
  { label: 'qHs' },
  { label: 'At night' },
  { label: 'Bedtime' },

  { label: 'qAM' },
  { label: 'Wake Up' },

  { label: 'qAC' },
  { label: 'Breakfast' },
  { label: 'Dinner' },
  { label: 'Lunch' },
  { label: 'With Meals' },

  { label: 'qwk' },
  { label: 'Weekly' },
  { label: 'Every Week' },

  { label: 'qMo' },
  { label: 'Monthly' },



];
