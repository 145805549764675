import * as React from 'react';

// Material UI imports
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Input from 'components/atoms/Input';
import listStyles from 'styles/components/organisms/diabetesUI/ListStyles';
import { useEffect } from 'react';
// Redux imports
import { updateDiabetes } from 'redux/actions/diabetesActions';
import { useDispatch } from 'react-redux';
import { useDiabetes } from 'redux/reusables/diabetes';

export default function BasicGrid(props) {
  const dispatch = useDispatch();
  const { diabetes } = useDiabetes();
  const classes = listStyles();

  const [inputValue, setInputValue] = React.useState(
    diabetes ? diabetes[props.name] : ''
  );

  const onChangerInput = (event) => {
    if (event.target.value >= 0 && event.target.value <= 999) {
      setInputValue(event.target.value);
    }
  };

  // performs necessary actions when input is out of focus
  const temp = diabetes ? diabetes[props.name] : '';
  const onBlurDetect = (event) => {
    setInputValue(temp);
    dispatch(
      updateDiabetes({
        type: event.target.name,
        value: Number(event.target.value),
      })
    );
    if (temp === 0) {
      setInputValue('');
    }
  };

  // Replaces 0 with empty input
  useEffect(() => {
    setInputValue(temp);
    if (temp === 0) {
      setInputValue('');
    }
  }, [temp]);

  return (
    <Box my={1}>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={3}>
          <Typography variant='h6' component='p' className={classes.listText}>
            {props.listItem}
          </Typography>
        </Grid>
        <Grid item xs={8} style={{ display: 'flex' }}>
          <Input
            value={inputValue}
            name={props.name}
            onChange={onChangerInput}
            type="number"
            onBlur={onBlurDetect}
            classes={classes}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
