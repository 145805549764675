import React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import MedicationBuilder from 'components/organisms/patientChartUi/MedicationBuilder';
import Tabs from 'components/atoms/Tabs';
import { Tab , Grid, Typography } from '@mui/material';

import { useDispatch } from 'react-redux';
import ModalCustom from 'components/atoms/Modal';
import Button from 'components/atoms/Button';
import {
  savePatientData,
  updatePatientData,
} from 'redux/actions/patientChartActions';
import { usePatient } from 'redux/reusables/patients.js';
import SaveDiscardModal from 'components/atoms/SaveDiscardModal/index.js';
import { capitalize } from 'redux/actions/helperFunctions';
import { trackMixPanel } from 'redux/proxies/mixpanel.js';
import {
  TRACK_MEDICATION_DOSE_CARD_OPENED,
  TRACK_MEDICATION_LOGS_OPENED,
} from 'redux/constants/mixPanel.js';
import MedicationLogs from '../MedicationLogs/index.js';
import MedicationDoseCard from '../MedicationDoseCard.js/index.js';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function Tab5() {
  const [valueTabs, setValueTabs] = React.useState(1);
  const { patient } = usePatient();
  const dispatch = useDispatch();

  // Warning not saved data
  const [warningModal, setWarningModal] = React.useState(false);
  const [tempTab, setTempTab] = React.useState(1);
  const acceptWarning = () => {
    setWarningModal(false);

    if (valueTabs === 1) {
      dispatch(savePatientData('medBuilder'));
    }
    if (valueTabs === 2) {
      dispatch(savePatientData('dose_card'));
    }
    // setValueTabs(tempTab);
  };

  const discardChanges = () => {
    setWarningModal(false);
    setTempTab(valueTabs);
    if (valueTabs === 1) {
      dispatch(
        updatePatientData({
          type: 'medData',
          value: patient.medDataOrg ? patient.medDataOrg : [],
        })
      );

      dispatch(
        updatePatientData({
          type: 'medFields',
          value: patient.medFieldsOrg ? patient.medFieldsOrg : 1,
        })
      );
    }
    if (valueTabs === 2) {
      dispatch(
        updatePatientData({
          type: 'timeframesMeds',
          value: JSON.parse(patient.timeframesMedsOrg),
        })
      );
    }
  };

  // Handling change of tabs
  const handleChangeTabs = (event, newValue) => {
    // If data not saved show warning
    setTempTab(newValue);
    setTimeout(
      () => {
        // compares if the medication builder is changed
        if (
          JSON.stringify(patient.medData) !==
            JSON.stringify(patient.medDataOrg) &&
          valueTabs === 1 &&
          patient.medDataOrg?.length > 0
        ) {
          setWarningModal(true);
          return false;
        }

        // Compares if the dose card is changed
        if (
          JSON.stringify(patient.timeframesMeds) !==
            patient.timeframesMedsOrg &&
          valueTabs === 2 &&
          patient.timeframesMedsOrg !== ''
        ) {
          setWarningModal(true);
          return false;
        }
        if (
          patient.medDataOrg?.length > 0 &&
          !(patient.timeframesMeds?.length === 0 && newValue == 0)
        ) {
          setValueTabs(newValue);
          if (newValue === 0) {
            trackMixPanel(TRACK_MEDICATION_LOGS_OPENED);
          } else if (newValue === 2) {
            trackMixPanel(TRACK_MEDICATION_DOSE_CARD_OPENED);
          }
        }
      },
      valueTabs === 1 && patient.editModeLimit
        ? valueTabs === 2
          ? 10
          : 1000
        : 10
    );
  };

  return (
    <div>
      <Grid xs={12} container mb={2}>
        <SaveDiscardModal
          openModal={warningModal}
          closeModal={() => setWarningModal(false)}
          modalHeading="Unsaved Data!"
          saveDisabled={patient.noMedNameError}
          saveSecondary={patient.noMedNameError}
          saveOnClick={acceptWarning}
          saveTitle='Save Changes'
          discardOnClick={() => discardChanges()}
          discardTitle='Discard Changes'
          discardSecondary
          modalParagraph={
            <>
              Do you really want to leave
              {patient?.medData?.filter((med) => med.edited)?.length > 0
                ? ' '
                : ''}
              <span>
                {patient?.medData
                  ?.filter((med) => med.edited)
                  ?.map((med) => capitalize(med.medName))
                  .join(', ')}
                {patient?.medData?.filter((med) => med.edited)?.length > 0
                  ? ' row?'
                  : '?'}
              </span>{' '}
              Any changes since the last saved will be lost.
            </>
          }
        />
        <Tabs value={valueTabs} onChange={handleChangeTabs}>
          <Tab
            label='Logs'
            sx={{
              textTransform: 'initial !important',
              fontSize: '16px',
              color: '#7E8186',
            }}
          />
          <Tab
            label='Medication Builder'
            sx={{
              textTransform: 'initial !important',
              fontSize: '16px',
              color: '#7E8186',
            }}
          />
          <Tab
            label='Medication dose card'
            sx={{
              textTransform: 'initial !important',
              fontSize: '16px',
              color: '#7E8186',
            }}
          />
        </Tabs>
      </Grid>
      <div
        style={{
          maxWidth: '1490px',
          width: '1490px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <TabPanel value={valueTabs} index={0} disabled>
          <MedicationLogs />
        </TabPanel>
        <TabPanel value={valueTabs} index={1}>
          <MedicationBuilder />
        </TabPanel>
        <TabPanel value={valueTabs} index={2}>
          <MedicationDoseCard />
        </TabPanel>
      </div>
    </div>
  );
}
