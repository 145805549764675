import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { capitalize } from 'redux/actions/helperFunctions';
import { usePatient } from 'redux/reusables/patients';
import { useDiabetes } from 'redux/reusables/diabetes';
import { useAuth } from 'redux/reusables/auth';

export const DefaultNote = () => {
  const [datax, setDatax] = useState();
  const [surgicalData, setSurgialData] = useState({
    key: 'd1k1n',
    text: '',
    type: 'unstyled',
    depth: 0,
  });
  const [socialData, setSocialData] = useState({});
  const [medicationDataShow, setMedicationDataShow] = useState([]);
  const [medicationDataNoCodeShow, setMedicationDataNoCodeShow] = useState([]);
  const [familyData, setFamilyData] = useState({});
  const [allergiesArray, setAllergiesArray] = useState([]);
  const [editorData, setEditorData] = useState([]);
  const [providerName, setProviderName] = useState('');

  const patientState = usePatient();
  const [diabetesx, setDiabetesx] = useState();
  const diabetesState = useDiabetes();
  const encountersEditorData = useSelector(
    (state) => state.encountersEditorData
  );
  const { editor,noMeds } = encountersEditorData;
  const { auth } = useAuth();

  useEffect(() => {
    if (!patientState?.loading) {
      setDatax(patientState?.patient);
      SurgicalHistory(patientState?.patient);
      SocialHistory(patientState?.patient);
      familyHistory(patientState?.patient);
      allergiesText(patientState?.patient);
      setMedicationDataShow(patientState?.patient?.medData);
    }

    if (!diabetesState?.loading) {
      setDiabetesx(diabetesState?.diabetes);
    }
  }, [patientState,patientState?.patient?.medData]);


  useEffect(() => {
    if (editor?.length > 0) {
      setEditorData(editor);
    }
    if(noMeds?.length>0){
      setMedicationDataNoCodeShow(noMeds);
    }
  }, [editor]);

  useEffect(() => {
    if (auth?.username) {
      setProviderName(auth?.username);
    }
  }, [auth]);

  const diagnoseData = datax?.diagnosisData?.map((d) => ` ${  d.label}`) ?? '';
  const medicationData = datax?.medData?.map((m) => ` ${  m.medName}`) ?? '';

  function SurgicalHistory(patientData) {
    let str = '[';
    if (patientData?.SURGICAL) {
      for (
        let i = 0;
        i < JSON.parse(patientData?.SURGICAL).blocks.length;
        i++
      ) {
        const d = JSON.parse(patientData?.SURGICAL).blocks[i];

        if (JSON.parse(patientData?.SURGICAL).blocks.length - 1 === i) {
          str = str.concat(JSON.stringify(d));
        } else {
          str = str.concat(`${JSON.stringify(d)  },`);
        }
      }
    }
    str += ']';
    setSurgialData(str);
  }

  function SocialHistory(patientData) {
    let str = '[';
    if (patientData?.SOCIAL) {
      for (let i = 0; i < JSON.parse(patientData?.SOCIAL).blocks.length; i++) {
        const d = JSON.parse(patientData?.SOCIAL).blocks[i];

        if (JSON.parse(patientData?.SOCIAL).blocks.length - 1 === i) {
          str = str.concat(JSON.stringify(d));
        } else {
          str = str.concat(`${JSON.stringify(d)  },`);
        }
      }
    }
    str += ']';
    setSocialData(str);
  }

  function familyHistory(patientData) {
    let str = '[';
    if (patientData?.FAMILY) {
      for (let i = 0; i < JSON.parse(patientData?.FAMILY).blocks.length; i++) {
        const d = JSON.parse(patientData?.FAMILY).blocks[i];

        if (JSON.parse(patientData?.FAMILY).blocks.length - 1 === i) {
          str = str.concat(JSON.stringify(d));
        } else {
          str = str.concat(`${JSON.stringify(d)  },`);
        }
      }
    }
    str += ']';
    setFamilyData(str);
  }

  function allergiesText(patientData) {
    const allergiesObj = patientData?.ALLERGIES ? JSON.parse(patientData?.ALLERGIES) : {};
    const allergiesBlocks = allergiesObj.blocks || [];

    setAllergiesArray(allergiesBlocks);
  }

  const getHeading = (id, name) => ({
    key: id,
    text: name,
    type: 'unstyled',
    depth: 0,
    inlineStyleRanges: [
      {
        offset: 0,
        length: 20,
        style: 'BOLD',
      },
    ],
    entityRanges: [],
    data: {},
  });

  const getLineBreak = (id) => ({
    key: id,
    text: '',
    type: 'unstyled',
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: [],
    data: {},
  });

  const getParagraph = (id, text) => ({
    key: id,
    text,
    type: 'unstyled',
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: [],
    data: {},
  });

  let surgicalDatax = null;
  let socialDatax = null;
  let familyDatax = null;

  try {
    surgicalDatax = JSON.parse(surgicalData);
    socialDatax = JSON.parse(socialData);
    familyDatax = JSON.parse(familyData);
  } catch (e) {
    surgicalDatax = surgicalData;
    socialDatax = socialData;
    familyDatax = familyData;
  }

  const data = {
    blocks: [
      {
        key: '3vmhm',
        text: `Date of Service: ${  moment().format('MMMM Do YYYY, HH:mm')}`,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          {
            offset: 0,
            length: 17,
            style: 'BOLD',
          },
        ],
      },

      getLineBreak('aktqp'),

      {
        key: 'fkosf',
        text: `Chief Complaint: ${  diagnoseData}`,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          {
            offset: 0,
            length: 16,
            style: 'BOLD',
          },
        ],
      },

      getLineBreak('d1k1n'),

      {
        key: 'a26tq',
        text: `History of presenting illness: ${datax?.first_name} ${
          datax?.last_name
        } whose date of birth is ${datax?.birthdate} is a ${moment().diff(
          datax?.birthdate,
          'years'
        )} year old ${
          datax?.sex === 'M' ? 'Male' : 'Female'
        } patient with insulin dependent diabetes. They started using Precina Health on ${moment(
          diabetesx?.created_at
        ).format(
          'MMMM DD, YYYY'
        )}. We provide dosing assistance, titration, monitoring, and pharmacy refills.`,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          {
            offset: 0,
            length: 30,
            style: 'BOLD',
          },
        ],
      },

      getLineBreak('45af6'),

      {
        key: 'b25eo',
        text: 'Review of systems:',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          {
            offset: 0,
            length: 19,
            style: 'BOLD',
          },
        ],
      },

      getLineBreak('me5q'),

      {
        key: 'bl02c',
        text: 'A complete 13 systems were reviewed and are negative except listed as above.',
        type: 'unstyled',
        depth: 0,
      },

      getLineBreak('1f1im'),

      {
        key: 'cb8t7',
        text: 'Past Medical History:',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          {
            offset: 0,
            length: 21,
            style: 'BOLD',
          },
        ],
      },

      getLineBreak('fh44u'),

      {
        key: 'esspo',
        text: 'Past Surgical History:',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          {
            offset: 0,
            length: 22,
            style: 'BOLD',
          },
        ],
      },

      getLineBreak('dt864'),


      {
        key: 'allergies',
        text: 'Allergies:',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          {
            offset: 0,
            length: 22,
            style: 'BOLD',
          },
        ],
      },

      getLineBreak('dt865'),

      {
        key: 'dn2f7',
        text: 'Medications: ',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          {
            offset: 0,
            length: 11,
            style: 'BOLD',
          },
        ],
      },

      getLineBreak('u2b8'),

      {
        key: 'agaf5',
        text: 'Family History: ',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          {
            offset: 0,
            length: 14,
            style: 'BOLD',
          },
        ],
      },

      getLineBreak('e0bk8'),
      {
        key: '5bjek',
        text: 'Social history: ',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          {
            offset: 0,
            length: 14,
            style: 'BOLD',
          },
        ],
      },

      getLineBreak('8a1pv'),

      {
        key: '46rp9',
        text: 'Physical Exam:',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          {
            offset: 0,
            length: 16,
            style: 'BOLD',
          },
        ],
        entityRanges: [],
        data: {},
      },
      getLineBreak('lb11pv'),
      {
        key: 'a99t1',
        text: 'General: Awake, alert and oriented. No acute distress. Well developed, hydrated and nourished. Appears stated age.',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          {
            offset: 0,
            length: 9,
            style: 'BOLD',
          },
        ],
        entityRanges: [],
        data: {},
      },
      {
        key: 'vr1a',
        text: 'Skin: Skin is intact without rashes or lesions. Appropriate color for ethnicity. Nailbeds pink with no cyanosis or clubbing. There is no jaundice',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          {
            offset: 0,
            length: 5,
            style: 'BOLD',
          },
        ],
        entityRanges: [],
        data: {},
      },
      {
        key: '66b7j',
        text: 'Head: The head is normocephalic and atraumatic without visible masses, depressions, or scarring.',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          {
            offset: 0,
            length: 6,
            style: 'BOLD',
          },
        ],
        entityRanges: [],
        data: {},
      },
      {
        key: 'h0sk',
        text: 'Eyes: Conjunctivae are clear without exudates or hemorrhage. Sclera is non-icteric. Extraocular muscles are intact, Eyelids are normal in appearance without swelling or lesions.',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          {
            offset: 0,
            length: 6,
            style: 'BOLD',
          },
        ],
        entityRanges: [],
        data: {},
      },
      {
        key: '86t4l',
        text: 'Ears: The pinnae are normal, otoscopy is not possible in this telemedicine encounter.',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          {
            offset: 0,
            length: 6,
            style: 'BOLD',
          },
        ],
        entityRanges: [],
        data: {},
      },
      {
        key: '86usq',
        text: 'Nose: The external nose is free of lesions or rash.',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          {
            offset: 0,
            length: 6,
            style: 'BOLD',
          },
        ],
        entityRanges: [],
        data: {},
      },
      {
        key: '6n4as',
        text: 'Throat: Oral mucosa is pink and moist with good dentition. Tongue normal in appearance without lesions and with good symmetrical movement. No buccal nodules or lesions are noted. The pharynx is normal in appearance without tonsillar swelling or exudates.',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          {
            offset: 0,
            length: 8,
            style: 'BOLD',
          },
        ],
        entityRanges: [],
        data: {},
      },
      {
        key: 'b763u',
        text: 'Neck: Auscultation, palpation, and percussion cannot be performed visually.',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          {
            offset: 0,
            length: 6,
            style: 'BOLD',
          },
        ],
        entityRanges: [],
        data: {},
      },
      {
        key: 'b6s4c',
        text: 'Cardiac: Auscultation, palpation, and percussion cannot be performed visually.',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          {
            offset: 0,
            length: 9,
            style: 'BOLD',
          },
        ],
        entityRanges: [],
        data: {},
      },
      {
        key: '8fc93',
        text: 'Respiratory: Auscultation and percussion cannot be performed visually.',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          {
            offset: 0,
            length: 13,
            style: 'BOLD',
          },
        ],
        entityRanges: [],
        data: {},
      },
      {
        key: '35s14',
        text: 'Abdominal: Auscultation, palpation, and percussion cannot be performed visually.',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          {
            offset: 0,
            length: 11,
            style: 'BOLD',
          },
        ],
        entityRanges: [],
        data: {},
      },
      {
        key: 'am9pi',
        text: 'Genital/Rectal: Not assessed',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          {
            offset: 0,
            length: 16,
            style: 'BOLD',
          },
        ],
        entityRanges: [],
        data: {},
      },
      {
        key: 'fg6lu',
        text: 'Extremities: Upper and lower extremities are atraumatic in appearance without visible deformity.',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          {
            offset: 0,
            length: 13,
            style: 'BOLD',
          },
        ],
        entityRanges: [],
        data: {},
      },
      {
        key: '3123e',
        text: 'Neurological: The patient is awake, alert, and oriented to person, place, and time with normal speech. Cranial nerves are intact. Cerebellar function is intact. Memory is normal and thought process is intact. No gait abnormalities are appreciated. Cranial nerves 2, 3, 4, 6, 7, 8, 9, 10, and 12 appear normal. Cranial nerve 1 and 11 are not accessible from a telemedicine encounter. An H-test revealed normal movement and rhythm, no eye beating movements. Pupillary constriction and dilation normal. Facial muscles of the forehead, zygomatic region, and mandibular region are intact.',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          {
            offset: 0,
            length: 13,
            style: 'BOLD',
          },
        ],
        entityRanges: [],
        data: {},
      },
      {
        key: 'ptfj',
        text: 'Psychiatric: Appropriate mood and affect. Good judgement and insight. No visual or auditory hallucinations. No suicidal or homicidal ideation.',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [{ offset: 0, length: 12, style: 'BOLD' }],
        entityRanges: [],
        data: {},
      },

      // Medication data here
      getLineBreak('asmev'),
      getHeading('16asme', 'Medications: '),
      getLineBreak('1kasme'),


      // Diagnoses data here
      getLineBreak('1ah47'),
      getHeading('12na5a', 'Past Medical History (Diagnoses): '),
      getLineBreak('aml305'),

      // All data here:
      getLineBreak('degvv'),
      getHeading('16ji1', 'Assessment and Plan'),
      getLineBreak('d5agvv'),
      getParagraph(
        '1k5i1',
        'The physical exam is normal as listed above except as listed below:'
      ),

      {
        key: '2rcrv',
        text: `Other Conditions. We facilitate appropriate medication adherence according to the medications they are chronically managing. This includes: ${medicationDataNoCodeShow
          ?.map((item) => (item?.medName ? capitalize(item?.medName) : ' '))
          .join(', ')}`,
        type: 'unstyled',
        depth: 0,

        entityRanges: [],
        data: {},
      },

      getLineBreak('drljv'),

      {
        key: '1cj8i',
        text: 'Signed,',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: '7rhcf',
        text: `${providerName  } ${  moment().format('MMMM Do YYYY, HH:mm')}`,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          { offset: 0, length: providerName?.length || 0, style: 'BOLD' },
        ],
        entityRanges: [],
        data: {},
      },
      {
        key: '51h0d',
        text: '',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: 'b7arl',
        text: 'The pre-encounter chart check, on-camera video encounter, medication review and updates, counseling, and the documenting of the encounter took more than __ minutes.',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [{ offset: 154, length: 33, style: 'BOLD' }],
        entityRanges: [],
        data: {},
      },
    ],
    entityMap: {},
  };

  // push object after a certain object in array
  const index = data.blocks.findIndex((x) => x.key === 'esspo');
  if (surgicalDatax.length > 0) {
    surgicalDatax.map((item, i) => {
      data.blocks.splice(index + i + 1, 0, {
        ...item,
        key: `${item.key  }surgical`,
      });
    });
  }

  const allegiesIndex = data.blocks.findIndex((x) => x.key === 'allergies');
  if (allergiesArray.length > 0) {
    allergiesArray.map((item, i) => {
      data.blocks.splice(allegiesIndex + i + 1, 0, {
        ...item,
        key: `${item.key  }allergies`,
      });
    });
  }


  // push object after a certain object in array
  const socialIndex = data.blocks.findIndex((x) => x.key === '5bjek');
  if (socialDatax?.length > 0) {
    socialDatax.map((item, i) => {
      data.blocks.splice(socialIndex + i + 1, 0, {
        ...item,
        key: `${item.key  }social`,
      });
    });
  }

  // push object after a certain object in array
  const familyIndex = data.blocks.findIndex((x) => x.key === 'agaf5');
  if (familyDatax?.length > 0) {
    familyDatax.map((item, i) => {
      data.blocks.splice(familyIndex + i + 1, 0, {
        ...item,
        key: `${item.key  }family`,
      });
    });
  }

  // for editor data
  const editorIndex = data.blocks.findIndex((x) => x.key === '1k5i1');
  if (editorData?.length > 0) {
    editorData.map((item, i) => {
      data.blocks.splice(editorIndex + i + 1, 0, {
        key: item?.id,
        text: `${item?.label} (${item.code}): ${item.medications
          ?.map((item) => item?.name)
          .join(', ')}  ${item?.code}`,
        type: 'ordered-list-item',
        depth: 0,
        entityRanges: [],
        data: {},
      });
    });
  }

  // for medication data
  const setMedicationData = (editorIndexMedications, location) => {
    if (medicationDataShow?.length > 0) {
      medicationDataShow.map((med, i) => {
        data.blocks.splice(editorIndexMedications + i + 1, 0, {
          key: `${med?.id}${location}`,
          text: med.medName
            ? `${capitalize(med.medName)}${med.medName ? ' | ' : ''}${
              med?.dose == null || !med?.dose ? '' : med?.dose
            }${med.units === null || !med?.units ? '' : med.units}${
              med.dose || med.units ? ' | ' : ''
            }${med?.route === null || !med?.route ? '' : med?.route}${
              med.route ? ' | ' : ''
            }${
              med?.frequencyOrg === null || !med?.frequencyOrg
                ? ''
                : med?.frequencyOrg
            }${med.frequencyOrg ? ' | ' : ''} ${
              med.prn === null || !med.prn || med.prn === 'off'
                ? ''
                : 'PRN: ' + 'On'
            } `
            : '',
          type: 'ordered-list-item',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        });
      });
    }
  };
  // For physical report at end section, below pschatric
  const editorIndexMedications = data.blocks.findIndex((x) => x.key === '1kasme');
  setMedicationData(editorIndexMedications, 'endMeds');

  // For medications at start
  const editorIndexMedicationsStart = data.blocks.findIndex(
    (x) => x.key === 'dn2f7'
  );
  setMedicationData(editorIndexMedicationsStart, 'startMeds');

  // for diagnoses data aml305
  // cb8t7

  const setDiagnosisData = (editorIndexDiagnoses, location) => {
    if (editorData?.length > 0) {
      editorData.map((item, i) => {
        data.blocks.splice(editorIndexDiagnoses + i + 1, 0, {
          key: `${item?.id}${location}`,
          text: `${item?.label === null || !item?.label ? '' : item?.label} (${
            item?.code === null || !item?.code ? '' : item?.code
          }): ${
            item?.nickname === null || !item?.nickname ? '' : item?.nickname
          }`,
          type: 'ordered-list-item',
          depth: 0,

          entityRanges: [],
          data: {},
        });
      });
    }
  };
  const editorIndexDiagnoses = data.blocks.findIndex((x) => x.key === 'aml305');
  setDiagnosisData(editorIndexDiagnoses, 'diagnosesEnd');
  const editorIndexDiagnosesStart = data.blocks.findIndex(
    (x) => x.key === 'cb8t7'
  );
  setDiagnosisData(editorIndexDiagnosesStart, 'diagnosesStart');

  // Will return default data if no saved is present in redux else return saved data
  if (patientState?.patient?.editor) return patientState?.patient?.editor;
  return JSON.stringify(data);
};
