import { makeStyles } from '@mui/styles';

export const encountersSelectStyle = makeStyles({
  root: {
    height: '40px',
    fontFamily: '\'Work Sans\', sans-serif',

    width: '400px',
    borderRadius: '8px !important',
    padding: '8px,72px,8px,16px',
    background: '#FCFDFE',
    border: '1px solid #D8D8DC !important',
  },
  dropdownStyle: {
    fontSize: '15px',
    color: '#7E8186',
    width: '400px',
    maxHeight: '450px',
    fontWeight: 'bold !important',
    '& .MuiSvgIcon-root': {
      display: 'none',
    },
    '& .Mui-selected': {
      width: '400px',
    },
  },
});

export const encountersTextStyle = makeStyles({
  root: {
    '& .MuiInputBase-root': {
      height: '40px',
      width: '400px',
      fontFamily: '\'Work Sans\', sans-serif !important',
      //   left: '119px',
      //   top: '0px',
      background: '#FCFDFE',
      borderRadius: '8px',
    },

    '& .MuiInputBase-input-MuiOutlinedInput-input': {
      borderRadius: '8px',
    },

    '& .MuiFormHelperText-root': {
      marginTop: '8px',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      background: '#F1F3F7',
    },
  },
});

export const encountersTimeStyle = makeStyles({
  root: {
    height: '40px',
    fontFamily: '\'Work Sans\', sans-serif',

    width: '192px',
    borderRadius: '8px !important',
    padding: '8px,72px,8px,16px',
    background: '#FCFDFE',
    border: '1px solid #D8D8DC !important',

    '& .MuiSelect-icon': {
      display: 'none',
    },
  },
  dropdownStyle: {
    fontSize: '15px',
    color: '#7E8186',
    width: '192px',
    maxHeight: '450px',
    fontWeight: 'bold !important',
    '& .MuiSvgIcon-root': {
      display: 'none',
    },
    '& .Mui-selected': {
      width: '192px',
    },
  },
});

export const encountersDateStyle = makeStyles({
  root: {
    '& .MuiInputBase-root': {
      height: '40px',
      width: '192px',
      fontFamily: '\'Work Sans\', sans-serif !important',
      //   left: '119px',
      //   top: '0px',
      background: '#FCFDFE',
      borderRadius: '8px',
    },

    '& .MuiInputBase-input-MuiOutlinedInput-input': {
      borderRadius: '8px',
    },

    '& .MuiFormHelperText-root': {
      marginTop: '8px',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      background: '#F1F3F7',
    },
  },
});
