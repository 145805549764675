import { makeStyles } from '@mui/styles';

const gluoseTableStyles = makeStyles({
  tableHeadingName: {
    // styleName: Bold/Callout;
    fontFamily: 'Work Sans, serif',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '21px',
    letterSpacing: '-0.3100000023841858px',
    textAlign: 'center',
    color: '#53575E',
  },
  tableHeadingTime: {
    // styleName: Regular/Subheadline;
    fontFamily: 'Work Sans, serif',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '-0.23px',
    textAlign: 'center',
    color: '#7E8186',
  },
  firstColumn: {
    // styleName: Bold/Headline;
    fontFamily: 'Comfortaa',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '22px',
    letterSpacing: '-0.43px',
    textAlign: 'left',
    color: '#004680',
  },
  calculatedDose: {
    // styleName: regular / body for web app ;
    fontFamily: 'Work Sans, serif',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.40px',
    textAlign: 'left',
    color: '#282D36',
  },
});

export default gluoseTableStyles;
