import * as React from 'react';
import { Grid } from '@mui/material';
import HorizontalStepper from 'components/atoms/HorizontalStepper';
import VerticleStepper from 'components/atoms/VerticalStepper';
import ClearIcon from '@mui/icons-material/Clear';
import 'styles/components/templates/OnboardingUi.module.scss';
import Step1 from 'components/molecules/onboardingSectionUi/OnboardingSteps/Step1';
import Step2 from 'components/molecules/onboardingSectionUi/OnboardingSteps/Step2';
import Step3 from 'components/molecules/onboardingSectionUi/OnboardingSteps/Step3';
import Step4 from 'components/molecules/onboardingSectionUi/OnboardingSteps/Step4';
import { showDiabetes } from 'redux/actions/diabetesActions';
import '../onBoardingTemplate.css';
import { useDispatch } from 'react-redux';
import { getOnboardingDetails } from 'redux/actions/onboardingActions';
import { useNavigate , Link } from 'react-router-dom';
import { fetchAvailableStates } from 'redux/actions/settingActions';
import { useOnboarding } from 'redux/reusables/onboarding';


function getStepContent(step, handlers) {
  switch (step) {
    case 1:
      return <Step1 onClick={handlers.handleNext} />;

    case 2:
      return <Step2 onClick={handlers.handleNext} />;
    case 3:
      return <Step3 onClick={handlers.handleNext} />;
    case 4:
      return <Step4 onClick={handlers.handleNext} />;
    case 5:
      return <Step4 onClick={handlers.handleNext} />;
    default:
      return 'unknown step';
  }
}

export default function Onboarding() {
  const navigate = useNavigate();
  // Set defaults
  const [activeStep, setActiveStep] = React.useState(1);

  // Handles next and previous steps
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(1);
  };

  const dispatch = useDispatch();
  const { onboarding } = useOnboarding();

  const closeOnboarding = () => {
    dispatch({ type: 'fname', value: '' });
    dispatch({ type: 'lname', value: '' });
    dispatch({ type: 'dob', value: '' });
    dispatch({ type: 'email', value: '' });
    dispatch({ type: 'phone', value: '+1' });

    navigate('/dashboard');
  };

  // Dispatches the two main actions required to populate fields
  React.useEffect(() => {
    dispatch(getOnboardingDetails());
    dispatch(fetchAvailableStates());
    dispatch(showDiabetes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {}, []);

  // Step details mapped on steps
  const steps = [
    {
      label: 'Patient Info',
      info: ' ',
    },
    {
      label: 'Contact Info',
      info: 'Tell about the patient',
    },
    {
      label: 'Sex',
      info: `What is ${
        onboarding.fname !== '' ? `${onboarding.fname  }'s` : 'Patient\'s'
      } Sex `,
    },
    {
      label: 'Medical Provider',
      info: `Select ${
        onboarding.fname !== '' ? `${onboarding.fname  }'s` : 'Patient\'s'
      } Medical provider`,
    },
    {
      label: 'Timewindows',
      info: `What time does ${
        onboarding.fname !== '' ? onboarding.fname : 'Patient\'s'
      }: `,
    },
  ];

  return (
    <div>
      <Grid container>
        <Grid item container justifyContent='space-between' px={11} pt={3}>
          <Link to='/dashboard'>
            <img
              src='/images/PrecinaLogo.png'
              style={{ width: '105px', height: '32px' }}
              alt='logo here'
            />
          </Link>
          <div
            style={{ width: '40px', height: '40px', cursor: 'pointer' }}
            onClick={closeOnboarding}
          >
            <ClearIcon />
          </div>
        </Grid>
        <Grid
          item
          container
          xs={6}
          sm={6}
          md={6}
          lg={7}
          alignItems='flex-start'
          px={12}
          pt={2}
          mb={4}
        >
          <Grid
            item
            container
            xs={12}
            justifyContent='left'
            sx={{ fontSize: '28px' }}
          >
            <h1 style={{ fontSize: '28px' }}>
              {steps[activeStep]
                ? steps[activeStep].label !== 'Timewindows'
                  ? 'Patient Information'
                  : steps[activeStep].label
                : 'Timewindows'}
            </h1>
            <Grid item container xs={12}>
              <p
                style={{
                  fontSize: '16px',
                  marginBottom: '0px',
                  marginTop: '0px',
                  color: '#7E8186',
                  fontFamily: 'Work Sans, Sans Serif',
                }}
              >
                Questions{' '}
                {`${
                  activeStep < steps.length ? activeStep : activeStep - 1
                }/${4}`}
              </p>{' '}
            </Grid>

            <HorizontalStepper
              handleBack={handleBack}
              handleNext={handleNext}
              steps={steps}
              activeStep={activeStep}
              handleReset={handleReset}
            />
          </Grid>

          <Grid item container xs={12} justifyContent='left'>
            <h2 style={{ color: '#53575E', fontSize: '22px' }}>
              {steps[activeStep]
                ? steps[activeStep].info
                : activeStep > 4
                ? 'What time does Naya: '
                : ''}
            </h2>
          </Grid>
          <Grid item container xs={12}>
            {getStepContent(activeStep, { handleNext })}
          </Grid>
        </Grid>
        <Grid
          item
          container
          sm={6}
          xs={6}
          md={6}
          lg={5}
          alignItems='flex-start'
          pt={2}
          sx={{ maxHeight: '40vh', fontSize: '28px' }}
          className='progressVertical'
        >
          <h1 style={{ fontSize: '28px' }}>Summary</h1>
          <Grid xs={12}>
            <VerticleStepper
              handleBack={handleBack}
              handleNext={handleNext}
              steps={steps}
              activeStep={activeStep}
              handleReset={handleReset}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
