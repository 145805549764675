import * as React from 'react';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import MobileStepper from '@mui/material/MobileStepper';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';

import CreateIcon from '@mui/icons-material/Create';

const useStyles = makeStyles({
  root: {
    '& .MuiMobileStepper-progress': {
      width: '240px',
      height: '2px',
      borderRadius: '16px',
    },
  },
});

export default function VerticalLinearStepper(props) {
  const classes = useStyles();

  return (
    <Box sx={{ maxWidth: 400, transform: 'translate(-7%,0%)' }} mt={4}>
      <Grid container justifyContent='flex-start'>
        <Grid item container xs={3}>
          <MobileStepper
            variant='progress'
            steps={
              props.activeStep > 3 && props.activeStep < 5
                ? props.steps.length
                : props.steps.length + 1
            }
            position='static'
            activeStep={props.activeStep}
            className={classes.root}
            sx={{
              width: 240,
              flexGrow: 1,

              maxHeight: '40px',
              transformOrigin: '20% 40%',
              transform: ' rotate(90deg)',
              zIndex: '-1',
            }}
            backButton={
              props.activeStep === 5 ? (
                <div
                  style={{
                    width: '36px',
                    height: '28px',
                    border: '1px solid black',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderColor: '#0084D5',
                    background: '#0084D5',
                  }}
                >
                  <CheckIcon
                    sx={{ color: 'white', transform: 'rotate(-90deg)' }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    width: '36px',
                    height: '28px',
                    border: '1px solid black',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderColor: '#0084D5',
                  }}
                >
                  <span
                    style={{
                      width: '4px',
                      height: '4px',
                      background: '#0084D5',
                      borderRadius: '50%',
                      borderColor: '#0084D5',
                    }}
                   />
                </div>
              )
            }
            nextButton={
              <div
                style={{
                  width: '36px',
                  height: '28px',
                  border: '1px solid black',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderColor: props.activeStep >= 4 ? '#0084D5' : '#A9ABAF',
                }}
              >
                <span
                  style={{
                    width: '4px',
                    height: '4px',
                    background: props.activeStep >= 4 ? '#0084D5' : '#A9ABAF',
                    borderRadius: '50%',
                    borderColor: props.activeStep >= 4 ? '#0084D5' : '#A9ABAF',
                  }}
                 />
              </div>
            }
          />
        </Grid>
        <Grid
          container
          item
          xs={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '210px',
            fontSize: '17px',
          }}
          variant="p"
        >
          <Typography
            sx={{
              transform: 'translate(0%,-75%)',
              color: props.activeStep < 4 ? '#0084D5' : '#A9ABAF',
              fontSize: '17px',
              fontWeight: 'bold',
            }}
            variant="p"
          >
            Patient Information
            <CreateIcon
              fontSize='small'
              sx={{
                transform: 'translate(60%,-75%)',
              }}
            />
          </Typography>

          <Typography
            sx={{
              transform: 'translate(0%,-60%)',
              color: props.activeStep === 1 ? '#282D36' : '#A9ABAF',
              fontSize: '17px',
              fontWeight: '500',
            }}
            variant="p"
          >
            Contact Info
          </Typography>
          <Typography
            sx={{
              transform: 'translate(0%,-110%)',
              color: props.activeStep === 2 ? '#282D36' : '#A9ABAF',
              fontSize: '17px',
              fontWeight: '500',
            }}
          >
            Sex
          </Typography>
          <Typography
            variant="p"
            sx={{
              transform: 'translate(0%,-220%)',
              color: props.activeStep === 3 ? '#282D36' : '#A9ABAF',
              fontSize: '17px',
              fontWeight: '500',
            }}
          >
            Medical Provider
          </Typography>
          <Typography
            sx={{
              color: props.activeStep >= 4 ? '#0084D5' : '#A9ABAF',
              fontSize: '17px',
              fontWeight: 'bold',
            }}
            variant="p"
          >
            Timewindows
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
