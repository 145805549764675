import {
  ONBOARDING_DETAILS_FAIL,
  ONBOARDING_DETAILS_SUCCESS,
  ONBOARDING_DETAILS_REQUEST,
  ONBOARDING_UPDATE_FAIL,
  ONBOARDING_UPDATE_SUCCESS,
  ONBOARDING_UPDATE_REQUEST,
  ONBOARDING_SAVE_FAIL,
  ONBOARDING_SAVE_SUCCESS,
  ONBOARDING_SAVE_REQUEST,
} from '../constants/onboardingConstants';

export const getOnboardingDetailsReducer = (
  state = { onboarding: {} },
  action
) => {
  switch (action.type) {
    case ONBOARDING_DETAILS_REQUEST:
      return { loading: true, onboarding: {} };
    case ONBOARDING_DETAILS_SUCCESS:
      return { loading: false, onboarding: action.payload };
    case ONBOARDING_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const setOnboardingDetailsReducer = (
  state = { onboarding: {} },
  action
) => {
  switch (action.type) {
    case ONBOARDING_UPDATE_REQUEST:
      return { loading: true, onboarding: state };
    case ONBOARDING_UPDATE_SUCCESS:
      return { loading: false, onboarding: action.payload };
    case ONBOARDING_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const saveOnboardingDetailsReducer = (
  state = { loading: false },
  action
) => {
  switch (action.type) {
    case ONBOARDING_SAVE_REQUEST:
      return { loading: true };
    case ONBOARDING_SAVE_SUCCESS:
      return { loading: false };
    case ONBOARDING_SAVE_FAIL:
      return { loading: false };
    default:
      return state;
  }
};
