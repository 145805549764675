import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Tokens } from 'redux/config/getToken';
import {
    diabetesDetailsReducer,
    diabetesOrignalReducer,
    diabetesSaveReducer,
} from './reducers/diabetesReducers';
import {
    timeFrameDetailsReducer,
    timeFrameUpdateReducer,
    timeFrameSaveReducer,
} from './reducers/timeFrameReducers';
import {
    getOnboardingDetailsReducer,
    saveOnboardingDetailsReducer,
} from './reducers/onboardingReducers';
import { patientListReducer } from './reducers/dashboardReducers';
import { patientDataReducer, savePatientDetailsReducer } from './reducers/patientChartReducers';
import { timersReducer } from './reducers/trackerReducers';
import { authDetailsReducers } from './reducers/authReducers';
import {
    getEncountersNotesReducer,
    getEncountersSingleReducer,
    saveEncountersNotesReducer,
    getEncountersEditorData,
} from './reducers/encountersNotesReducers';
import {
    hypertensionDetailsReducer,
    hypertensionSaveReducer,
} from './reducers/hypertensionReducers';
import { settingsReducer } from './reducers/settingsReducers';

// All reducers to add to store here, for future reference
const reducer = combineReducers({
    diabetesDetails: diabetesDetailsReducer,
    diabetesOrignal: diabetesOrignalReducer,
    diabetesSave: diabetesSaveReducer,
    timeFrameDetails: timeFrameDetailsReducer,
    timeFrameUpdate: timeFrameUpdateReducer,
    timeFrameSave: timeFrameSaveReducer,
    onboardingDetails: getOnboardingDetailsReducer,
    patientList: patientListReducer,
    patientData: patientDataReducer,
    authDetails: authDetailsReducers,
    savePatient: savePatientDetailsReducer,
    saveOnboarding: saveOnboardingDetailsReducer,
    encountersNotes: getEncountersNotesReducer,
    encountersSingle: getEncountersSingleReducer,
    saveEncountersNotes: saveEncountersNotesReducer,
    encountersEditorData: getEncountersEditorData,
    hypertensionDetails: hypertensionDetailsReducer,
    hypertensionSave: hypertensionSaveReducer,
    settings: settingsReducer,
    trackers: timersReducer,
});

const initialState = {};

const checkToken = (store) => (next) => (action) => {
    const check = Tokens.getAccess();
    const { authDetails } = store.getState();

    if (!check && authDetails?.auth?.isAuthenticated) {
        next({ type: 'AUTH_CHECK', payload: false });
    }
    next(action);
};

const middleware = [thunk, checkToken];

const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware)),
);

export default store;
