import * as React from 'react';
import { useState , useReducer } from 'react';
import { useNavigate , NavLink, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Tokens } from 'redux/config/getToken';
import 'styles/components/templates/loginUi/index.scss';
import axios from 'axios';
import { API_URL } from 'constant';

import BackdropCustom from 'components/atoms/Backdrop';
import { trackMixPanel } from 'redux/proxies/mixpanel';
import {
  TRACK_USER_SIGNED_IN,
  TRACK_USER_SIGNIN_FAILED,
} from 'redux/constants/mixPanel';

export default function Loginotp() {
  const theme = createTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [smscode, setsmscode] = useState('');
  const [islogin, setIsLogin] = useState(false);
  const [verify, setVerify] = useState('');
  const [loading, setLoading] = useState(false);
  // const [disableBtn, setDisableBtn] = useState(true);
  // validation area start
  const reducer = (state, action) => {
    if (action.type === 'smscode') {
      if (smscode.length < 6) {
        // setDisableBtn(true)
        return (state = 'Invalid OTP');
      }
        // setDisableBtn(false)
        return (state = '');

    }
  };
  const [state, dispatch] = useReducer(reducer, '');

  // validation area over

  React.useEffect(() => {
    const check = Tokens.getAccess();
    if (check) {
      trackMixPanel(TRACK_USER_SIGNED_IN);
      window.location.replace('/dashboard');
    }
  }, [islogin]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const verifyNumber = location.state.detail.slice(2).split('', 6).join('');
      if (verifyNumber === '999999') {
        if (smscode === '123456') {
          const res = await axios.post(`${API_URL}/api/auth-by-sms/`, {
            mobile_phone: `${location.state.detail}`,
            sms_code: smscode,
          });

          if (res.status === 200) {
            Tokens.setAccess(res.data.access);
            Tokens.setRefresh(res.data.refresh);
            setIsLogin(true);
            // history.go(0);
          }
          setLoading(false);
        } else {
          setLoading(false);
          trackMixPanel(TRACK_USER_SIGNIN_FAILED);
          return setVerify('Invalid OTP code');
        }
      } else {
        const res = await axios.post(`${API_URL}/api/auth-by-sms/`, {
          mobile_phone: `${location.state.detail}`,
          sms_code: smscode,
        });
        if (res.status === 200) {
          Tokens.setAccess(res.data.access);
          Tokens.setRefresh(res.data.refresh);
          setIsLogin(true);
          // history.go(0);
        }
        setLoading(false);
      }
    } catch (err) {
      trackMixPanel(TRACK_USER_SIGNIN_FAILED);
      setLoading(false);
    }
  };

  function handleChange(event) {
    const smscode = event.target.value;
    setsmscode(
      smscode.replace(/[A-Za-z!\-?=@`~;:._'"\[\]\ \+\{}|#$%^&*()\\//]/g, '')
    );
  }
  return (
    <div className="loginSection">
      <ThemeProvider theme={theme} style={{ backgroundColor: 'red' }}>
        <Container component='main'>
          <BackdropCustom open={loading} />
          <CssBaseline />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            className="loginBox"
          >
            <img
              src='/images/PrecinaLogo.png'
              style={{ width: '130px', height: '40px', marginBottom: '30px' }}
              alt='logo here'
            />
            <Box
              component='form'
              onSubmit={(e) => handleSubmit(e)}
              noValidate
              sx={{ mt: 1 }}
              style={{ display: 'block', width: '100%' }}
            >
              <Typography
                variant='h5'
                component='h5'
                style={{
                  fontFamily: 'Comfortaa',
                  fontWeight: '700!important',
                  fontSize: '22px!important',
                  lineHeight: ' 48px',
                  letterSpacing: ' 0.35px',
                  color: '#282D36',
                  marginBottom: '25px',
                }}
              >
                Authenticate your account
              </Typography>
              <Typography
                variant='p'
                component='p'
                style={{
                  fontFamily: 'Comfortaa',
                  fontWeight: '700!important',
                  fontSize: '2px!important',
                  lineHeight: '',
                  letterSpacing: ' 0.35px',
                  color: '#282D36',
                  marginBottom: '25px',
                }}
              >
                Please confirm your account by entering the authorization code
                sent to {location?.state?.detail}
              </Typography>
              <Typography className="labeltxt">OTP Number*</Typography>
              <TextField
                // error={error}
                fullWidth
                name='smscode'
                placeholder='Enter Otp Number'
                value={smscode?.replace(/^\d{7}$/, '')}
                className="inputfield"
                inputProps={{ maxLength: 6 }}
                onChange={handleChange}
                onBlur={() => dispatch({ type: 'smscode' })}
                // helperText={error ? "Enter a valid otp number" : ""}
              />
              <div style={{ textAlign: '-webkit-right', color: 'red' }}>
                {verify}
              </div>
              <div style={{ textAlign: '-webkit-right' }}>
                <p style={{ color: 'red' }}>
                  <b>{state}</b>
                </p>
              </div>
              <Grid container>
                <Grid item xs>
                  <Link
                    style={{
                      textAlign: 'center',
                      color: '#53575E',
                      textDecoration: 'none',
                      fontFamily: ' Work Sans',
                      fontStyle: 'normal',
                      fontWeight: 'normal',
                      fontSize: '13px',
                      lineHeight: '18px',
                    }}
                    href='#'
                    variant='body2'
                  >
                    <span style={{ marginLeft: '90px' }}>
                      it may take a minute to recieve your code.
                    </span>
                    <br />{' '}
                    <span style={{ marginLeft: '90px' }}>
                      Haven't Recieved it?
                    </span>
                    <NavLink
                      style={{
                        color: '#42b3f5',
                        textDecoration: 'none',
                        fontSize: '13px',
                      }}
                      to='/OTP'
                    >
                      Resend a new code
                    </NavLink>
                  </Link>
                </Grid>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  sx={{ mt: 3, mb: 2, fontFamily: 'Work Sans, Serif' }}
                  className="Button"
                  // disabled={disableBtn}
                >
                  Submit
                </Button>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
}
