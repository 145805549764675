import {
  HYPERTENSION_DETAILS_FAIL,
  HYPERTENSION_DETAILS_SUCCESS,
  HYPERTENSION_DETAILS_REQUEST,
  HYPERTENSION_DETAILS_RESET,
  HYPERTENSION_UPDATE,
  HYPERTENSION_SAVE_REQUEST,
  HYPERTENSION_SAVE_SUCCESS,
  HYPERTENSION_SAVE_FAIL,
  HYPERTENSION_UPDATE_FULL,
} from '../constants/hypertensionConstants';

export const hypertensionDetailsReducer = (
  state = { hypertension: {} },
  action
) => {
  switch (action.type) {
    case HYPERTENSION_DETAILS_REQUEST:
      return { loading: true, hypertension: {} };
    case HYPERTENSION_DETAILS_SUCCESS:
      return { loading: false, hypertension: action.payload };
    case HYPERTENSION_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case HYPERTENSION_DETAILS_RESET:
      return { loading: false, hypertension: {} };
    case HYPERTENSION_UPDATE:
      return {
        loading: false,
        hypertension: {
          ...state.hypertension,
          [action.typeUpdate]: action.dataUpdate,
        },
      };
    case HYPERTENSION_UPDATE_FULL:
      return {
        loading: false,
        hypertension: {
          ...state.hypertension,
          ...action.dataUpdate,
        },
      };
    default:
      return state;
  }
};

export const hypertensionSaveReducer = (
  state = { hypertension: {} },
  action
) => {
  switch (action.type) {
    case HYPERTENSION_SAVE_REQUEST:
      return { loading: true, hypertension: {} };
    case HYPERTENSION_SAVE_SUCCESS:
      return { loading: false, hypertension: action.payload };
    case HYPERTENSION_SAVE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
