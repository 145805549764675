import axiosFetch from 'redux/config/axios';

export const getRequiredMedications = async (id) => {
  let res;
  if (id) res = await axiosFetch.get(`/required-medications/?patient_id=${id}`);
  else res = await axiosFetch.get(`/required-medications/`);

  return res;
};

export const getSingleRequiredMedications = async (id) => {
  const res = await axiosFetch.get(`/required-medications/${id}/`);
  return res;
};

export const postRequiredMedications = async (data) => {
  const res = await axiosFetch.post('/required-medications/', data);
  return res;
};

export const putRequiredMedications = async (id, data) => {
  const res = await axiosFetch.put(`/required-medications/${id}/`, data);
  return res;
};

export const patchRequiredMedications = async (id, data) => {
  const res = await axiosFetch.patch(`/required-medications/${id}/`, data);
  return res;
};

export const deleteRequiredMedications = async (id) => {
  const res = await axiosFetch.delete(`/required-medications/${id}/`);
  return res;
};
