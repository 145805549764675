import * as React from 'react';
import Button from 'components/atoms/Button';
import Navbar from 'components/molecules/dashboardUi/Navbar';
import Tabs from 'components/atoms/Tabs';
import { Grid, Tab, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AccountCircle from '@mui/icons-material/AccountCircle';
import './index.scss';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Tab1 from 'components/organisms/patientChartUi/Tabs/Tab1';
import Tab2 from 'components/organisms/patientChartUi/Tabs/Tab2';
import Tab3 from 'components/organisms/patientChartUi/Tabs/Tab3';
import Tab4 from 'components/organisms/patientChartUi/Tabs/Tab4';
import Tab5 from 'components/organisms/patientChartUi/Tabs/Tab5';
import Tab6 from 'components/organisms/patientChartUi/Tabs/Tab6';
import Tab7 from 'components/organisms/patientChartUi/Tabs/Tab7';

import LinearProgress from '@mui/material/LinearProgress';
// Redux
import {
    getPatientData,
    savePatientData,
    updatePatientData,
} from 'redux/actions/patientChartActions';
import { saveDiabetes } from 'redux/actions/diabetesActions';
import { showTimeFrames } from 'redux/actions/timeFrameActions';
import { startTimer, stopTimer } from 'redux/actions/timersActions';
import { fetchAvailableStates } from 'redux/actions/settingActions';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Editors from 'components/organisms/patientChartUi/Editor';
import { useNavigate, useLocation } from 'react-router';

import Chats from 'components/organisms/chat/Chats';
import { DIABETES_DETAILS_SUCCESS } from 'redux/constants/diabetesConstants';
import BackdropCustom from 'components/atoms/Backdrop';
import { usePatient } from 'redux/reusables/patients';
import { useDiabetes, useDiabetesOrg } from 'redux/reusables/diabetes';
import AddTitration from 'components/molecules/patientChartUi/AddTitration';
import SaveDiscardModal from 'components/atoms/SaveDiscardModal';
import { capitalize } from 'redux/actions/helperFunctions';
import { trackMixPanel } from 'redux/proxies/mixpanel';
import { TRACK_TAB_SELECTION } from 'redux/constants/mixPanel';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function PatientChart() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [valueTabs, setValueTabs] = React.useState(0);
    const { pk, trackerType } = useParams();
    // Warning not saved data
    const [warningModal, setWarningModal] = React.useState(false);
    // Disable tab if not selected in titration
    const [disabledTab, setDisabedTab] = React.useState(false);

    // const [tempTab, setTempTab] = React.useState(1);
    const acceptWarning = () => {
        setWarningModal(false);
        // setValueTabs(tempTab);
        if (valueTabs === 3) {
            dispatch(savePatientData('diagnosisData'));
        }
        if (valueTabs === 4) {
            // Medication builder save from warning
            if (
                JSON.stringify(patient.medData) !== JSON.stringify(patient.medDataOrg) &&
                patient.medDataOrg?.length > 0
            ) {
                dispatch(savePatientData('medBuilder'));
            }
            // Dose card save from warning
            else if (
                JSON.stringify(patient.timeframesMeds) !== patient.timeframesMedsOrg &&
                patient.timeframesMedsOrg !== ''
            )
                dispatch(savePatientData('dose_card'));
        }

        if (valueTabs === 1) {
            dispatch(saveDiabetes());
        }
    };

    const discardChanges = () => {
        setWarningModal(false);
        // Discards changes to diagnoses builder
        if (valueTabs === 3) {
            dispatch(
                updatePatientData({
                    type: 'diagnosisData',
                    value: patient.diagnosisDataOrg,
                }),
            );
            dispatch(
                updatePatientData({
                    type: 'diagnsosisFields',
                    value: patient.diagnsosisFieldsOrg,
                }),
            );
        }

        // Discard changes from medciation builder on global tabs change
        if (
            valueTabs === 4 &&
            JSON.stringify(patient.medData) !== JSON.stringify(patient.medDataOrg)
            // && patient.medDataOrg?.length > 0
        ) {
            dispatch(
                updatePatientData({
                    type: 'medData',
                    value: patient.medDataOrg ? patient.medDataOrg : [],
                }),
            );

            dispatch(
                updatePatientData({
                    type: 'medFields',
                    value: patient.medFieldsOrg ? patient.medFieldsOrg : 0,
                }),
            );
        }

        // Discard changes from dose card  on global tabs change
        if (
            valueTabs === 4 &&
            JSON.stringify(patient.timeframesMeds) !== patient.timeframesMedsOrg &&
            patient.timeframesMedsOrg !== ''
        ) {
            dispatch(
                updatePatientData({
                    type: 'timeframesMeds',
                    value: JSON.parse(patient.timeframesMedsOrg),
                }),
            );
        }
        // setValueTabs(tempTab);

        /// For diabetes screen:
        if (valueTabs === 1) {
            dispatch({
                type: DIABETES_DETAILS_SUCCESS,
                payload: {
                    ...diabetes,
                    ...diabetesOrg,
                },
            });
        }
    };

    React.useEffect(() => {
        dispatch(startTimer(trackerType, pk));
        dispatch(fetchAvailableStates());

        if (!patient.pk) {
            dispatch(getPatientData(pk));
            dispatch(showTimeFrames(pk));
        }
        return () => {
            dispatch(stopTimer(trackerType, pk));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { patient } = usePatient();

    const { diabetes } = useDiabetes();

    const { diabetes: diabetesOrg } = useDiabetesOrg();

    const tab = patient ? patient.tab : 0;
    React.useEffect(() => {
        if (tab) setValueTabs(Number(tab));
        trackMixPanel(TRACK_TAB_SELECTION(tab));
    }, [tab]);

    const handleChangeTabs = (event, newValue) => {
        // Checks for not saved data in medication builder
        // setTempTab(newValue);
        setTimeout(
            () => {
                // Handle warning for medication builder
                if (
                    JSON.stringify(patient.medData) !== JSON.stringify(patient.medDataOrg) ||
                    (JSON.stringify(patient.timeframesMeds) !== patient.timeframesMedsOrg &&
                        valueTabs === 4)
                ) {
                    setWarningModal(true);
                    return false;
                }

                if (
                    JSON.stringify(diabetes.diabetesOrgValues) !== JSON.stringify(diabetesOrg) &&
                    valueTabs === 1
                ) {
                    setWarningModal(true);
                    return false;
                }

                // Handle warning for diagnoses builder

                if (
                    JSON.stringify(patient.diagnosisData) !==
                        JSON.stringify(patient.diagnosisDataOrg) &&
                    valueTabs === 3
                ) {
                    setWarningModal(true);
                    return false;
                }

                if (patient.medData) {
                    dispatch(updatePatientData({ type: 'tab', value: newValue }));
                    setValueTabs(newValue);
                }
            },
            valueTabs === 4 && patient.editModeLimit ? 1000 : 10,
        );
    };
    const location = useLocation();

    React.useEffect(() => {
        dispatch(updatePatientData({ type: 'tab', value: 0 }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    // Default content
    const [content, setContent] = React.useState(
        '{"blocks":[{"key":"1k8j6","text":"Scratch ...","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
    );

    // Change content on state change
    const patientCheck = patient.SCRATCH;
    React.useEffect(() => {
        if (patientCheck) {
            setContent(patientCheck);
        }
    }, [patientCheck]);

    const tabDisabledCheck = patient?.titrationEnabled;
    React.useEffect(() => {
        if (!tabDisabledCheck) {
            setDisabedTab(true);
        } else if (tabDisabledCheck) {
            setDisabedTab(false);
        }
    }, [tabDisabledCheck]);

    return (
        <div className='patient-chart-root'>
            <Grid container justifyContent='center'>
                <BackdropCustom open={!patient.first_name} />
                <Navbar hiddenSearch borderDown />

                <SaveDiscardModal
                    openModal={warningModal}
                    closeModal={() => setWarningModal(false)}
                    modalHeading='Unsaved Data!'
                    saveDisabled={
                        (valueTabs === 3 && patient.duplicateErrorDiagnoses) ||
                        (valueTabs === 4 && patient.noMedNameError) ||
                        (valueTabs === 1 && diabetes?.diabetesErrorSave)
                    }
                    saveSecondary={
                        (valueTabs === 3 && patient.duplicateErrorDiagnoses) ||
                        (valueTabs === 4 && patient.noMedNameError) ||
                        (valueTabs === 1 && diabetes?.diabetesErrorSave)
                    }
                    saveOnClick={acceptWarning}
                    saveTitle='Save Changes'
                    discardOnClick={() => discardChanges()}
                    discardTitle='Discard Changes'
                    discardSecondary
                    modalParagraph={
                        <>
                            Do you really want to leave
                            {patient?.medData?.filter((med) => med.edited)?.length > 0 ? ' ' : ''}
                            <span>
                                {patient?.medData
                                    ?.filter((med) => med.edited)
                                    ?.map((med) => capitalize(med.medName))
                                    .join(', ')}
                            </span>
                            {'? '}
                            Any changes since the last saved will be lost.
                        </>
                    }
                />
                <Grid container px={7} pt={3} sx={{ marginLeft: '60px' }}>
                    <Grid
                        xs={2}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                            navigate('/dashboard');
                        }}
                    >
                        <Typography
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                color: '#7E8186',
                                fontSize: '15px',
                            }}
                        >
                            <ArrowBackIosIcon
                                sx={{
                                    fontSize: '15px',
                                }}
                            />
                            Back
                        </Typography>
                    </Grid>
                    <Grid item container xs={12} justifyContent='flex-start' pt={1}>
                        <Typography
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '20px',
                                fontWeight: '700',
                            }}
                            variant='p'
                        >
                            <AccountCircle
                                sx={{
                                    width: '32px',
                                    height: '32px',
                                    marginRight: '10px',
                                    color: '#C4C4C4',
                                }}
                            />
                            {patient.first_name
                                ? `${patient.first_name} ${patient.last_name}`
                                : 'Patient Name'}
                        </Typography>
                    </Grid>
                </Grid>
                <hr style={{ color: 'gray', opacity: '0.5' }} />

                <Tabs value={valueTabs} onChange={handleChangeTabs}>
                    <Tab
                        label='Overview'
                        sx={{
                            textTransform: 'initial !important',
                            // styleName: Regular/Callout;
                            fontFamily: 'Work Sans, Serif',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '21px',
                            letterSpacing: '-0.31px',
                            textAlign: 'left',
                            color: ' #7E8186',
                        }}
                    />
                    <Tab
                        label='Diabetes'
                        sx={{
                            textTransform: 'initial !important',
                            // styleName: Regular/Callout;
                            fontFamily: 'Work Sans, Serif',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '21px',
                            letterSpacing: '-0.31px',
                            textAlign: 'left',
                            color: ' #7E8186',
                            display: !patient?.diabetesEnabled ? 'none' : 'flex',
                        }}
                        disabled={!patient?.diabetesEnabled}
                    />
                    <Tab
                        label='Hypertension'
                        sx={{
                            textTransform: 'initial !important',
                            // styleName: Regular/Callout;
                            fontFamily: 'Work Sans, Serif',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '21px',
                            letterSpacing: '-0.31px',
                            textAlign: 'left',
                            color: ' #7E8186',
                            display: disabledTab ? 'none' : 'flex',
                        }}
                        disabled={disabledTab}
                    />
                    <Tab
                        label='Diagnoses'
                        sx={{
                            textTransform: 'initial !important',
                            // styleName: Regular/Callout;
                            fontFamily: 'Work Sans, Serif',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '21px',
                            letterSpacing: '-0.31px',
                            textAlign: 'left',
                            color: ' #7E8186',
                        }}
                    />
                    <Tab
                        label='Medications'
                        sx={{
                            textTransform: 'initial !important',
                            // styleName: Regular/Callout;
                            fontFamily: 'Work Sans, Serif',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '21px',
                            letterSpacing: '-0.31px',
                            textAlign: 'left',
                            color: ' #7E8186',
                        }}
                    />
                    <Tab
                        label='Encounter Notes'
                        sx={{
                            textTransform: 'initial !important',
                            // styleName: Regular/Callout;
                            fontFamily: 'Work Sans, Serif',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '21px',
                            letterSpacing: '-0.31px',
                            textAlign: 'left',
                            color: ' #7E8186',
                        }}
                    />
                    <Tab
                        label='Time Frame'
                        sx={{
                            textTransform: 'initial !important',
                            // styleName: Regular/Callout;
                            fontFamily: 'Work Sans, Serif',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '21px',
                            letterSpacing: '-0.31px',
                            textAlign: 'left',
                            color: ' #7E8186',
                        }}
                    />
                    <Tab
                        label='Settings'
                        sx={{
                            textTransform: 'initial !important',
                            // styleName: Regular/Callout;
                            fontFamily: 'Work Sans, Serif',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '21px',
                            letterSpacing: '-0.31px',
                            textAlign: 'left',
                            color: ' #7E8186',
                            display: 'none',
                        }}
                        disabled
                    />
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            width: '100%',
                        }}
                    >
                        <AddTitration />
                    </div>
                </Tabs>

                {!patient.first_name ? (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                ) : (
                    <div style={{ maxWidth: '1490px' }}>
                        <TabPanel value={valueTabs} index={0}>
                            <Tab1 />
                        </TabPanel>
                        <TabPanel value={valueTabs} index={1}>
                            <Tab2 />
                        </TabPanel>
                        <TabPanel value={valueTabs} index={2}>
                            <Tab3 />
                        </TabPanel>
                        <TabPanel value={valueTabs} index={3}>
                            <Tab4 />
                        </TabPanel>
                        <TabPanel value={valueTabs} index={4}>
                            <Tab5 />
                        </TabPanel>
                        <TabPanel value={valueTabs} index={5}>
                            <Tab6 />
                        </TabPanel>
                        <TabPanel value={valueTabs} index={6}>
                            <Tab7 />
                        </TabPanel>
                    </div>
                )}

                <Editors
                    defaultValue={content}
                    setValue={(e) => setContent(e)}
                    name='SCRATCH'
                    label='Scratch'
                />

                {patient?.first_name && <Chats />}
            </Grid>
        </div>
    );
}
