export const formatDate = (dateString) => {
  const b = dateString.split(/\D+/);
  const offsetMult = dateString.indexOf('+') !== -1 ? -1 : 1;
  const hrOffset = offsetMult * (+b[7] || 0);
  const minOffset = offsetMult * (+b[8] || 0);
  const temp_date = new Date(
    Date.UTC(
      +b[0],
      +b[1] - 1,
      +b[2],
      +b[3] + hrOffset,
      +b[4] + minOffset,
      +b[5],
      +b[6] || 0
    )
  );
  const weekday = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  var today = new Date();
  let day = weekday[today.getDay()];
  today = temp_date;
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  return { date: mm + '/' + dd + '/' + yyyy, day: day };
};

export const getTime = () => {
  var today = new Date();

  var minutes =
    today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes();
  var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours();
  var seconds =
    today.getSeconds() < 10 ? '0' + today.getSeconds() : today.getSeconds();
  return hours + ':' + minutes + ':' + seconds;
};

//Function to getValues for original data in diabetes actions:
export const getOrgValues = (diabetes) => {
  return {
    hypo: diabetes.hypo,
    goal: diabetes.goal,
    far: diabetes.far,
    danger: diabetes.danger,
    impossible: diabetes.impossible,
    corr_for_every: diabetes.corr_for_every,
    corr_above: diabetes.corr_above,
    corr_take: diabetes.corr_take,
    selected_insulin_basal: diabetes.selected_insulin_basal,
    selected_insulin_bolus: diabetes.selected_insulin_bolus,
    selected_insulin_mixed: diabetes.selected_insulin_mixed,
    switch_wake: diabetes.switch_wake,
    switch_breakfast: diabetes.switch_breakfast,
    switch_lunch: diabetes.switch_lunch,
    switch_dinner: diabetes.switch_dinner,
    switch_sleep: diabetes.switch_sleep,
    dose_wake_selected: diabetes.dose_wake_selected,
    dose_breakfast_selected: diabetes.dose_breakfast_selected,
    dose_lunch_selected: diabetes.dose_lunch_selected,
    dose_dinner_selected: diabetes.dose_dinner_selected,
    dose_sleep_selected: diabetes.dose_sleep_selected,
    dose_wake: diabetes.dose_wake,
    dose_breakfast: diabetes.dose_breakfast,
    dose_lunch: diabetes.dose_lunch,
    dose_dinner: diabetes.dose_dinner,
    dose_sleep: diabetes.dose_sleep,
    gluc_wake: diabetes.gluc_wake,
    gluc_breakfast: diabetes.gluc_breakfast,
    gluc_lunch: diabetes.gluc_lunch,
    gluc_dinner: diabetes.gluc_dinner,
    gluc_sleep: diabetes.gluc_sleep,
    corr_wake: diabetes.corr_wake,
    corr_breakfast: diabetes.corr_breakfast,
    corr_lunch: diabetes.corr_lunch,
    corr_dinner: diabetes.corr_dinner,
    corr_sleep: diabetes.corr_sleep,
  };
};

export const capitalize = (string) => {
  if (string)
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
