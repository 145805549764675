import React, { useEffect } from 'react';
import Button from 'components/atoms/Button';
import RangeTable from 'components/molecules/diabetesUi/RangeTable';
// Material UI imports
import CheckIconDefault from '@mui/icons-material/Check';
import AddIconDefault from '@mui/icons-material/Add';
import { Grid, Typography, Box, CircularProgress } from '@mui/material';

// Redux imports
import { useSelector } from 'react-redux';

import { useDispatch } from 'react-redux';
import { saveDiabetes, updateDiabetes } from 'redux/actions/diabetesActions';
import { TIME_ARRAY } from 'redux/constants/timeFrameConstants';
import GlucoseTableStyles from 'styles/components/organisms/diabetesUI/GlucoseTableStyles';
import { useDiabetes , useSaveDiabetes } from 'redux/reusables/diabetes';
import { useTimeframes } from 'redux/reusables/timeframes';

// import Input from 'components/atoms/Input';

function AddIcon() {
  return <AddIconDefault style={{ color: ' #67C57E' }} />;
}
function CheckIcon() {
  return <CheckIconDefault style={{ color: '#004680' }} />;
}

export default function GlucoseTable() {
  const dispatch = useDispatch();
  const { diabetes } = useDiabetes();

  const { loading } = useSaveDiabetes();

  const { timeframes } = useTimeframes();
  // const [loading, setLoading] = React.useState(false);
  const classes = GlucoseTableStyles();

  const saveDiabetesHandler = (event) => {
    event.preventDefault();
    // setLoading(true);
    dispatch(saveDiabetes());
  };

  const [overloaded, setOverloaded] = React.useState(false);

  // Set temp variables to reduce complexity in useEffect;
  const tempDoseWake = diabetes ? diabetes.dose_wake : '';
  const tempDoseBreak = diabetes ? diabetes.dose_breakfast : '';
  const tempDoseLunch = diabetes ? diabetes.dose_lunch : '';
  const tempDoseDinner = diabetes ? diabetes.dose_dinner : '';
  const tempDoseSleep = diabetes ? diabetes.dose_sleep : '';
  const tempSwitchWake = diabetes ? diabetes.switch_wake : '';
  const tempSwitchBreak = diabetes ? diabetes.switch_breakfast : '';
  const tempSwitchLunch = diabetes ? diabetes.switch_lunch : '';
  const tempSwitchDinner = diabetes ? diabetes.switch_dinner : '';
  const tempSwitchSleep = diabetes ? diabetes.switch_sleep : '';
  const tempCorrWake = diabetes ? diabetes.corr_wake : '';
  const tempCorrBreak = diabetes ? diabetes.corr_breakfast : '';
  const tempCorrLunch = diabetes ? diabetes.corr_lunch : '';
  const tempCorrDinner = diabetes ? diabetes.corr_dinner : '';
  const tempCorrSleep = diabetes ? diabetes.corr_sleep : '';
  const tempGlucWake = diabetes ? diabetes.gluc_wake : '';
  const tempGlucBreak = diabetes ? diabetes.gluc_breakfast : '';
  const tempGlucLunch = diabetes ? diabetes.gluc_lunch : '';
  const tempGlucDinner = diabetes ? diabetes.gluc_dinner : '';
  const tempGlucSleep = diabetes ? diabetes.gluc_sleep : '';

  useEffect(() => {
    ['', '', '', '', '', '', ''].map((item, index) => {
      setOverloaded(false);
      TIME_ARRAY.some((time) => {
        if (diabetes[`corr_${time}`] && diabetes[`switch_${time}`]) {
          if (
            diabetes[`dose_${time}`] + (index - 1) * diabetes.corr_take >
            80
          ) {
            setOverloaded(true);
            return true;
          }
            setOverloaded(false);

        }
        return false;
      });
      return false;
    });
  }, [
    tempDoseWake,
    tempDoseBreak,
    tempDoseLunch,
    tempDoseDinner,
    tempDoseSleep,
    tempCorrWake,
    tempCorrBreak,
    tempCorrLunch,
    tempCorrDinner,
    tempCorrSleep,
    diabetes,
  ]);

  // Checks if any errors exists then disables save button
  React.useEffect(() => {
    if (
      tempDoseWake > disableMaxLimit ||
      (tempSwitchWake && !tempDoseWake && !tempGlucWake) ||
      (tempSwitchWake && !tempDoseWake && tempGlucWake && tempCorrWake) ||
      tempDoseBreak > disableMaxLimit ||
      (tempSwitchBreak && !tempDoseBreak && !tempGlucBreak) ||
      (tempSwitchBreak && !tempDoseBreak && tempGlucBreak && tempCorrBreak) ||
      tempDoseLunch > disableMaxLimit ||
      (tempSwitchLunch && !tempDoseLunch && !tempGlucLunch) ||
      (tempSwitchLunch && !tempDoseLunch && tempGlucLunch && tempCorrLunch) ||
      tempDoseDinner > disableMaxLimit ||
      (tempSwitchDinner && !tempDoseDinner && !tempGlucDinner) ||
      (tempSwitchDinner &&
        !tempDoseDinner &&
        tempGlucDinner &&
        tempCorrDinner) ||
      tempDoseSleep > disableMaxLimit ||
      (tempSwitchSleep && !tempDoseSleep && !tempGlucSleep) ||
      (tempSwitchSleep && !tempDoseSleep && tempGlucSleep && tempCorrSleep) ||
      overloaded
    ) {
      dispatch(
        updateDiabetes({
          type: 'diabetesErrorSave',
          value: true,
        })
      );
    } else {
      dispatch(
        updateDiabetes({
          type: 'diabetesErrorSave',
          value: false,
        })
      );
    }
  }, [
    tempDoseWake,
    tempDoseBreak,
    tempDoseLunch,
    tempDoseDinner,
    tempDoseSleep,
    tempSwitchWake,
    tempSwitchBreak,
    tempSwitchLunch,
    tempSwitchDinner,
    tempSwitchSleep,
    tempGlucWake,
    tempGlucBreak,
    tempGlucLunch,
    tempGlucDinner,
    tempGlucSleep,
    tempCorrWake,
    tempCorrBreak,
    tempCorrLunch,
    tempCorrDinner,
    tempCorrSleep,
  ]);

  const disableMaxLimit = 80;

  const getInsulinText = ({isCorr, insulinTypeUnit, dose, corrTake, index} ) => {
    if(isCorr) {
      const isNotBolus = insulinTypeUnit !== 'bolus_insulin';
      const corrValue = corrTake * (index - 1);
      if(isNotBolus){
        if(corrValue) return `${dose} + ${corrValue}`
        return dose;
      }
      return dose + corrValue;
    }
    return dose;
  }

  const getInsulinUpdateData =  ({isCorr, insulinTypeUnit, dose, corrTake, index} ) => {
    if(isCorr) {
      const isNotBolus = insulinTypeUnit !== 'bolus_insulin';
      const corrValue = corrTake * (index - 1);
      if(isNotBolus) return corrValue;

      return dose + corrValue;
    }
    return dose;
  }

  return diabetes ? (
    <Box
      sx={{
        width: '100%',
        height: 'auto',
        position: 'relative',
        // boxShadow: '1px 2px 6px #886c6c85;',
        overflow: 'hidden !important',
        marginTop: '50px',
        padding: '20px',
      }}
    >
      <Grid
        container
        spacing={2}
        alignItems='center'
        p={2}
        sx={{
          // background: '#F6F9FC',
          minHeight: '120px',
        }}
      >
        <Grid container item xs={2} justifyContent='center' />
        <Grid container item xs={2} alignItems='center' justifyContent='center'>
          {diabetes.switch_wake ? (
            <>
              <Grid container item xs={12} justifyContent='center'>
                <Typography
                  variant='h5'
                  component='p'
                  className={classes.tableHeadingName}
                >
                  Wake Up
                </Typography>
              </Grid>
              <Typography
                variant='h6'
                component='p'
                className={classes.tableHeadingTime}
              >
                {`${timeframes[0].start?.slice(
                  0,
                  5
                )}-${timeframes[0].stop?.slice(0, 5)}`}
              </Typography>
            </>
          ) : (
            ''
          )}
        </Grid>
        <Grid container item xs={2} alignItems='center' justifyContent='center'>
          {diabetes.switch_breakfast ? (
            <>
              <Grid container item xs={12} justifyContent='center'>
                <Typography
                  variant='h5'
                  component='p'
                  className={classes.tableHeadingName}
                >
                  Breakfast
                </Typography>
              </Grid>
              <Typography
                variant='h6'
                component='p'
                className={classes.tableHeadingTime}
              >
                {`${timeframes[1].start?.slice(
                  0,
                  5
                )}-${timeframes[1].stop?.slice(0, 5)}`}
              </Typography>
            </>
          ) : (
            ''
          )}
        </Grid>

        <Grid container item xs={2} alignItems='center' justifyContent='center'>
          {diabetes.switch_lunch ? (
            <>
              <Grid container item xs={12} justifyContent='center'>
                <Typography
                  variant='h5'
                  component='p'
                  className={classes.tableHeadingName}
                >
                  Lunch
                </Typography>
              </Grid>
              <Typography
                variant='h6'
                component='p'
                className={classes.tableHeadingTime}
              >
                {`${timeframes[2].start?.slice(
                  0,
                  5
                )}-${timeframes[2].stop?.slice(0, 5)}`}
              </Typography>
            </>
          ) : (
            ''
          )}
        </Grid>
        <Grid container item xs={2} alignItems='center' justifyContent='center'>
          {diabetes.switch_dinner ? (
            <>
              <Grid container item xs={12} justifyContent='center'>
                <Typography
                  variant='h5'
                  component='p'
                  className={classes.tableHeadingName}
                >
                  Dinner
                </Typography>
              </Grid>
              <Typography
                variant='h6'
                component='p'
                className={classes.tableHeadingTime}
              >
                {`${timeframes[3].start?.slice(
                  0,
                  5
                )}-${timeframes[3].stop?.slice(0, 5)}`}
              </Typography>
            </>
          ) : (
            ''
          )}
        </Grid>
        <Grid container item xs={2} alignItems='center' justifyContent='center'>
          {diabetes.switch_sleep ? (
            <>
              <Grid container item xs={12} justifyContent='center'>
                <Typography
                  variant='h5'
                  component='p'
                  className={classes.tableHeadingName}
                >
                  Sleep
                </Typography>
              </Grid>
              <Typography
                variant='h6'
                component='p'
                className={classes.tableHeadingTime}
              >
                {`${timeframes[4].start?.slice(
                  0,
                  5
                )}-${timeframes[4].stop?.slice(0, 5)}`}
              </Typography>
            </>
          ) : (
            ''
          )}
        </Grid>
      </Grid>

      {/* Glucose Row */}
      <Grid
        container
        // spacing={2}
        alignItems='center'
        p={2}
        sx={{
          background: '#FAFBFE',
          borderRadius: '8px',
        }}
      >
        <Grid container item xs={2} justifyContent='start'>
          <Typography
            variant='h6'
            component='p'
            className={classes.firstColumn}
          >
            Glucose Check
          </Typography>
        </Grid>
        {/* Will only show icons if a certain feature is turned on */}
        <Grid container item xs={2} alignItems='center' justifyContent='center'>
          {diabetes.gluc_wake ? <CheckIcon /> : ''}
        </Grid>
        <Grid container item xs={2} alignItems='center' justifyContent='center'>
          {diabetes.gluc_breakfast ? <CheckIcon /> : ''}
        </Grid>
        <Grid container item xs={2} alignItems='center' justifyContent='center'>
          {diabetes.gluc_lunch ? <CheckIcon /> : ''}
        </Grid>
        <Grid container item xs={2} alignItems='center' justifyContent='center'>
          {diabetes.gluc_dinner ? <CheckIcon /> : ''}
        </Grid>
        <Grid container item xs={2} alignItems='center' justifyContent='center'>
          {diabetes.gluc_sleep ? <CheckIcon /> : ''}
        </Grid>
      </Grid>
      {/* <hr style={{ color: 'gray' }} /> */}

      {/* Correctional Row */}
      <Grid container spacing={2} alignItems='center' p={2}>
        <Grid container item xs={2} justifyContent='start'>
          <Typography
            variant='h6'
            component='p'
            className={classes.firstColumn}
          >
            Correctional
          </Typography>
        </Grid>
        <Grid container item xs={2} alignItems='center' justifyContent='center'>
          {diabetes.corr_wake ? <AddIcon /> : ''}
        </Grid>
        <Grid container item xs={2} alignItems='center' justifyContent='center'>
          {diabetes.corr_breakfast ? <AddIcon /> : ''}
        </Grid>
        <Grid container item xs={2} alignItems='center' justifyContent='center'>
          {diabetes.corr_lunch ? <AddIcon /> : ''}
        </Grid>
        <Grid container item xs={2} alignItems='center' justifyContent='center'>
          {diabetes.corr_dinner ? <AddIcon /> : ''}
        </Grid>
        <Grid container item xs={2} alignItems='center' justifyContent='center'>
          {diabetes.corr_sleep ? <AddIcon /> : ''}
        </Grid>
      </Grid>
      {/* <hr style={{ color: 'gray' }} /> */}

      {/* Here we map through the timeframes array to map the range table, the empty array limits the rows to 6 */}

      {['', '', '', '', '', '', ''].map((item, index) =>
        diabetes.corr_above + (index - 1) * diabetes.corr_for_every <=
        diabetes.danger ? (
          index === 0 ? (
            <RangeTable
              column2={diabetes.switch_wake ? 'hypo' : ''}
              column3={diabetes.switch_breakfast ? 'hypo' : ''}
              column4={diabetes.switch_lunch ? 'hypo' : ''}
              column5={diabetes.switch_dinner ? 'hypo' : ''}
              column6={diabetes.switch_sleep ? 'hypo' : ''}
              key={index}
              range={`Less than ${diabetes.hypo}`}
              checkIndex={index}
            />
          ) : index === 1 ? (
            <RangeTable
              column2={getInsulinText({isCorr: diabetes.corr_wake, insulinTypeUnit: diabetes.insulin_type_unit_WAKE_UP, dose: diabetes.dose_wake, corrTake: diabetes.corr_take, index})}
              column3={getInsulinText({isCorr: diabetes.corr_breakfast, insulinTypeUnit: diabetes.insulin_type_unit_BREAKFAST, dose: diabetes.dose_breakfast, corrTake: diabetes.corr_take, index})}
              column4={getInsulinText({isCorr: diabetes.corr_lunch, insulinTypeUnit: diabetes.insulin_type_unit_LUNCH, dose: diabetes.dose_lunch, corrTake: diabetes.corr_take, index})}
              column5={getInsulinText({isCorr: diabetes.corr_dinner, insulinTypeUnit: diabetes.insulin_type_unit_DINNER, dose: diabetes.dose_dinner, corrTake: diabetes.corr_take, index})}
              column6={getInsulinText({isCorr: diabetes.corr_sleep, insulinTypeUnit: diabetes.insulin_type_unit_SLEEP, dose: diabetes.dose_sleep, corrTake: diabetes.corr_take, index})}
              doseData={{
                column2: getInsulinUpdateData({isCorr: diabetes.corr_wake, insulinTypeUnit: diabetes.insulin_type_unit_WAKE_UP, dose: diabetes.dose_wake, corrTake: diabetes.corr_take, index}),
                column3: getInsulinUpdateData({isCorr: diabetes.corr_breakfast, insulinTypeUnit: diabetes.insulin_type_unit_BREAKFAST, dose: diabetes.dose_breakfast, corrTake: diabetes.corr_take, index}),
                column4: getInsulinUpdateData({isCorr: diabetes.corr_lunch, insulinTypeUnit: diabetes.insulin_type_unit_LUNCH, dose: diabetes.dose_lunch, corrTake: diabetes.corr_take, index}),
                column5: getInsulinUpdateData({isCorr: diabetes.corr_dinner, insulinTypeUnit: diabetes.insulin_type_unit_DINNER, dose: diabetes.dose_dinner, corrTake: diabetes.corr_take, index}),
                column6: getInsulinUpdateData({isCorr: diabetes.corr_sleep, insulinTypeUnit: diabetes.insulin_type_unit_SLEEP, dose: diabetes.dose_sleep, corrTake: diabetes.corr_take, index}),
              }}
              key={index}
              range={`${diabetes.hypo}-${
                diabetes.corr_above + index * diabetes.corr_for_every
              }`}
              checkIndex={index}
            />
          ) : (
            <RangeTable
              column2={
                getInsulinText({isCorr: diabetes.corr_wake, insulinTypeUnit: diabetes.insulin_type_unit_WAKE_UP, dose: diabetes.dose_wake, corrTake: diabetes.corr_take, index})
              }
              column3={
                getInsulinText({isCorr: diabetes.corr_breakfast, insulinTypeUnit: diabetes.insulin_type_unit_BREAKFAST, dose: diabetes.dose_breakfast, corrTake: diabetes.corr_take, index})
              }
              column4={
                getInsulinText({isCorr: diabetes.corr_lunch, insulinTypeUnit: diabetes.insulin_type_unit_LUNCH, dose: diabetes.dose_lunch, corrTake: diabetes.corr_take, index})
              }
              column5={
                getInsulinText({isCorr: diabetes.corr_dinner, insulinTypeUnit: diabetes.insulin_type_unit_DINNER, dose: diabetes.dose_dinner, corrTake: diabetes.corr_take, index})
              }
              column6={
                getInsulinText({isCorr: diabetes.corr_sleep, insulinTypeUnit: diabetes.insulin_type_unit_SLEEP, dose: diabetes.dose_sleep, corrTake: diabetes.corr_take, index})
              }
              doseData={{
                column2: getInsulinUpdateData({isCorr: diabetes.corr_wake, insulinTypeUnit: diabetes.insulin_type_unit_WAKE_UP, dose: diabetes.dose_wake, corrTake: diabetes.corr_take, index}),
                column3: getInsulinUpdateData({isCorr: diabetes.corr_breakfast, insulinTypeUnit: diabetes.insulin_type_unit_BREAKFAST, dose: diabetes.dose_breakfast, corrTake: diabetes.corr_take, index}),
                column4: getInsulinUpdateData({isCorr: diabetes.corr_lunch, insulinTypeUnit: diabetes.insulin_type_unit_LUNCH, dose: diabetes.dose_lunch, corrTake: diabetes.corr_take, index}),
                column5: getInsulinUpdateData({isCorr: diabetes.corr_dinner, insulinTypeUnit: diabetes.insulin_type_unit_DINNER, dose: diabetes.dose_dinner, corrTake: diabetes.corr_take, index}),
                column6: getInsulinUpdateData({isCorr: diabetes.corr_sleep, insulinTypeUnit: diabetes.insulin_type_unit_SLEEP, dose: diabetes.dose_sleep, corrTake: diabetes.corr_take, index}),
              }}
              key={index}
              range={`${
                diabetes.corr_above + (index - 1) * diabetes.corr_for_every + 1
              }-${
                index === 6 ||
                diabetes.corr_above + index * diabetes.corr_for_every >
                  diabetes.danger
                  ? diabetes.danger
                  : diabetes.corr_above + index * diabetes.corr_for_every
              }`}
              checkIndex={index}
            />
          )
        ) : (
          false
        )
      )}
      <RangeTable
        column2={diabetes.switch_wake ? 'hyper' : ''}
        column3={diabetes.switch_breakfast ? 'hyper' : ''}
        column4={diabetes.switch_lunch ? 'hyper' : ''}
        column5={diabetes.switch_dinner ? 'hyper' : ''}
        column6={diabetes.switch_sleep ? 'hyper' : ''}
        range={` > ${diabetes.danger}`}
      />

      <Grid
        container
        spacing={2}
        alignItems='center'
        justifyContent='flex-end'
        p={2}
        mt={3}
      >
        {/* <Grid item container justifyContent='center' align xs={4}>
          <Input
            placeholder={'Enter notes'}
            label={'Notes'}
            sx={{ width: '330px !important' }}
          />
        </Grid> */}
        <Grid item container justifyContent='end' align xs={4}>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <Grid xs={11} container justifyContent='center'>
                <Button
                  // primary
                  title='Save'
                  disabled={diabetes?.diabetesErrorSave}
                  secondary={diabetes?.diabetesErrorSave}
                  onClick={saveDiabetesHandler}
                />
              </Grid>

              <Grid
                xs={11}
                sx={{
                  maxWidth: '80%',
                  minHeight: '18px',
                  fontSize: '12px',
                  textAlign: 'center',
                  marginTop: '15px',
                }}
              >
                By clicking save I confirm that I have reviewed the Dose Card
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  ) : (
    ''
  );
}
