import {
  STATE_LIST_REQUEST,
  STATE_LIST_SUCCESS,
  STATE_LIST_FAIL,
} from '../constants/settingsConstants';
import { getAvailableStates } from '../proxies/availableStates';

export const fetchAvailableStates = () => async (dispatch) => {
  try {
    dispatch({ type: STATE_LIST_REQUEST });
    const { data } = await getAvailableStates();
    const states = data.map(state => ({id: state.code, name: state.full_name}));

    dispatch({ type: STATE_LIST_SUCCESS, payload: states });
  } catch (error) {
    dispatch({ type: STATE_LIST_FAIL, payload: {} });
  }
};
