import * as React from 'react';
import Box from '@mui/material/Box';
import ListItems from 'components/molecules/diabetesUi/ListItems';
// Material UI imports
import { CircularProgress } from '@mui/material';
import listStyles from 'styles/components/organisms/diabetesUI/ListStyles';
import { useDiabetes } from 'redux/reusables/diabetes';

export default function BoxSx(props) {
  const { loading, diabetes } = useDiabetes();
  const classes = listStyles();

  return loading ? (
    <CircularProgress />
  ) : (
    <Box
      className={classes.boxContainer}
      sx={{
        borderLeft: '0px !important',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <ListItems
          listItem='Hypo'
          value={diabetes ? diabetes.hypo : ''}
          name='hypo'
        />
        <ListItems
          listItem='Goal'
          value={diabetes ? diabetes.goal : ''}
          name='goal'
        />
        <ListItems
          listItem='Far'
          value={diabetes ? diabetes.far : ''}
          name='far'
        />
        <ListItems
          listItem='Danger'
          value={diabetes ? diabetes.danger : ''}
          name='danger'
        />
        <ListItems
          listItem='Impossible'
          value={diabetes ? diabetes.impossible : ''}
          name='impossible'
        />
      </div>
    </Box>
  );
}
