import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'components/atoms/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { capitalize } from 'redux/actions/helperFunctions';
import { savePatientData } from 'redux/actions/patientChartActions';
import { usePatient } from 'redux/reusables/patients';
import { useTimeframes } from 'redux/reusables/timeframes';
import Timeframes from './Timeframes';
import {
  getFrequencyNumbers,
  getSimilarInputsFrequency,
} from '../MedicationBuilder/similarInputs';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '16px',
  boxShadow: 24,
  p: 4,
};

export default function MedicationDoseCard() {
  const dispatch = useDispatch();
  const { patient } = usePatient();
  const savePatient = useSelector((state) => state.savePatient);
  const { loading } = savePatient;

  const { timeframes } = useTimeframes();
  const [medDataDose, setMedData] = React.useState([]);
  const [showPopup, setShowPopup] = React.useState(true);
  const [warning, setWarning] = React.useState(false);
  const [showWarning, setShowWarning] = React.useState(false);
  const [noDoseAddedWarning, setNoDoseAddedWarning] = React.useState([]);

  React.useEffect(() => {
    if (patient.totalTimeframeLeft === -1) {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  }, [patient.totalTimeframeLeft]);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const saveDoseCard = () => {
    if (showPopup || showWarning) {
      handleOpenModal();
      return false;
    }

    dispatch(savePatientData('dose_card'));
  };
  React.useEffect(() => {
    if (patient.medDataDose) setMedData(patient.medDataDose);
  }, [patient.medDataDose]);

  const [noSavedMeds, setNoSavedMeds] = React.useState(false);
  React.useEffect(() => {
    if (patient.medDataDose) {
      if (patient.medDataDose.length > 0) {
        setNoSavedMeds(false);
      } else {
        setNoSavedMeds(true);
      }
    }
  }, [patient.medDataDose]);

  React.useEffect(() => {
    if (warning) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  }, [warning]);

  return (
    <div
      style={{
        maxWidth: '1376px',
        minWidth: '1376px',
        minHeight: '548px',
        borderRadius: '16px',
        background: 'white',
      }}
    >
      <Grid container p={3}>
        <Grid
          container
          item
          pt={3}
          mb={1}
          sx={{ borderBottom: '1px solid #D4D5D7', paddingBottom: '15px' }}
        >
          {/* HEadings here */}
          <Grid item xs={3}>
            <Typography
              sx={{ color: '#7E8186', fontSize: '16px', fontWeight: '400' }}
            >
              Med Name
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              sx={{ color: '#7E8186', fontSize: '16px', fontWeight: '400' }}
            >
              Dose/Units
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              sx={{ color: '#7E8186', fontSize: '16px', fontWeight: '400' }}
            >
              Frequency
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              sx={{
                color: '#53575E',
                fontSize: '16px',
                fontWeight: '600',
                textAlign: 'center',
                letterSpacing: '-0.31 px',
              }}
            >
              Wake up
            </Typography>
            <Typography
              sx={{
                color: '#7E8186',
                fontSize: '15px',
                fontWeight: '400',
                textAlign: 'center',
              }}
            >
              {timeframes
                ? `${timeframes[0]?.start.slice(
                    0,
                    5
                  )}-${timeframes[0]?.stop.slice(0, 5)}`
                : '-'}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              sx={{
                color: '#53575E',
                fontSize: '16px',
                fontWeight: '600',
                textAlign: 'center',
                letterSpacing: '-0.31 px',
              }}
            >
              Breakfast
            </Typography>
            <Typography
              sx={{
                color: '#7E8186',
                fontSize: '15px',
                fontWeight: '400',
                textAlign: 'center',
              }}
            >
              {timeframes
                ? `${timeframes[1]?.start.slice(
                    0,
                    5
                  )}-${timeframes[1]?.stop.slice(0, 5)}`
                : '-'}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              sx={{
                color: '#53575E',
                fontSize: '16px',
                fontWeight: '600',
                textAlign: 'center',
                letterSpacing: '-0.31 px',
              }}
            >
              Lunch
            </Typography>
            <Typography
              sx={{
                color: '#7E8186',
                fontSize: '15px',
                fontWeight: '400',
                textAlign: 'center',
              }}
            >
              {timeframes
                ? `${timeframes[2]?.start.slice(
                    0,
                    5
                  )}-${timeframes[2]?.stop.slice(0, 5)}`
                : '-'}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              sx={{
                color: '#53575E',
                fontSize: '16px',
                fontWeight: '600',
                textAlign: 'center',
                letterSpacing: '-0.31 px',
              }}
            >
              Dinner
            </Typography>
            <Typography
              sx={{
                color: '#7E8186',
                fontSize: '15px',
                fontWeight: '400',
                textAlign: 'center',
              }}
            >
              {timeframes
                ? `${timeframes[3]?.start.slice(
                    0,
                    5
                  )}-${timeframes[3]?.stop.slice(0, 5)}`
                : '-'}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              sx={{
                color: '#53575E',
                fontSize: '16px',
                fontWeight: '600',
                textAlign: 'center',
                letterSpacing: '-0.31 px',
              }}
            >
              Sleep
            </Typography>
            <Typography
              sx={{
                color: '#7E8186',
                fontSize: '15px',
                fontWeight: '400',
                textAlign: 'center',
              }}
            >
              {timeframes
                ? `${timeframes[4]?.start.slice(
                    0,
                    5
                  )}-${timeframes[4]?.stop.slice(0, 5)}`
                : '-'}
            </Typography>
          </Grid>
        </Grid>

        {/* Data here */}
        {medDataDose
          ? medDataDose.map((data, index) => (
              <Grid
                container
                xs={12}
                item
                pt={3}
                alignItems='center'
                key={index}
              >
                <Grid item xs={3} zeroMinWidth pr={1}>
                  <Typography
                    sx={{
                      color: '#004680',
                      fontSize: '17px',
                      fontWeight: '500',
                      overflowWrap: 'break-word',
                    }}
                    variant='p'
                  >
                    {data.medName ? capitalize(data.medName) : data.medName}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    sx={{
                      color: '#282D36',
                      fontSize: '17px',
                      fontWeight: '400',
                    }}
                  >
                    {data.dose} {data.units}
                  </Typography>
                </Grid>
                <Grid item container xs={2} justifyContent='flex-start'>
                  {noDoseAddedWarning[index] ? (
                    <Typography
                      sx={{
                        color: 'red',
                        fontSize: '12px',
                        fontFamily: 'Work Sans, Serif',
                      }}
                    >
                      Dose added without any frequency
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        fontSize: '15px',
                        fontWeight: '600',
                        fontFamily: 'Work Sans, sans-serif',
                        letterSpacing: '-0.31px',
                        padding: '4px 8px',
                        background:
                          getSimilarInputsFrequency(data?.frequency) === 'QD'
                            ? '#E1F3E5'
                            : getSimilarInputsFrequency(data?.frequency) ===
                              'BiD'
                            ? '#FDEDDC'
                            : getSimilarInputsFrequency(data?.frequency) ===
                              'TiD'
                            ? '#CCDAE6'
                            : '',
                        borderRadius: '4px',
                      }}
                    >
                      {getSimilarInputsFrequency(data?.frequency)}
                    </Typography>
                  )}
                </Grid>
                {data.dose ? (
                  <Timeframes
                    index={index}
                    setNoDoseAddedWarning={setNoDoseAddedWarning}
                    noDoseAddedWarning={noDoseAddedWarning}
                    setWarning={setWarning}
                    frequency={data.frequency}
                    dose={data.dose}
                    unit={data.units}
                    medId={data.id}
                    medName={data.medName}
                  />
                ) : (
                  ''
                )}
              </Grid>
            ))
          : ''}
      </Grid>

      <Grid
        container
        item
        xs={12}
        justifyContent='space-between'
        alignItems="center"
        px={2}
        mt={6}
      >
        <Typography
          ml={6}
          mb={2}
          sx={{
            color: '#004680',
            fontFamily: 'Work Sans, sans-serif',
            fontSize: '18px',
            fontWeight: '500',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
         />

        {showWarning ? (
          <Typography
            style={{ color: 'red', fontSize: '12px', marginRight: '-50%' }}
          >
            Frequency is different from dose selected
          </Typography>
        ) : (
          ''
        )}

        {loading ? (
          <Grid
            sx={{ width: '240px', height: '54px', marginBottom: '20px' }}
            item
            container
            justifyContent='center'
            alignItems='center'
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Button
            title='Save'
            sx={{
              borderRadius: '8px !important',
              width: '240px !important',
              height: '54px !important',
              marginBottom: '20px',
              fontFamily: 'Work Sans, sans-serif',
              fontSize: '18px',
              fontWeight: '500',
              color: '#A9ABAF',
            }}
            disabled={noSavedMeds}
            secondary={noSavedMeds}
            onClick={() => {
              saveDoseCard();
            }}
          />
        )}
      </Grid>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography
            id='modal-modal-title'
            variant='h6'
            component='h2'
            sx={{ fontWeight: '700', fontSize: '28px' }}
          >
            Please review:
          </Typography>
          <Typography
            id='modal-modal-description'
            sx={{
              mt: 2,
              color: '#53575E',
              fontSize: '17px',
              fontFamily: 'Work Sans, serif',
            }}
          >
            <ul
              style={{
                listStyle: 'none',
                paddingLeft: '0px',
                fontSize: '15px',
              }}
            >
              {patient.timeframesMeds?.length > 0
                ? patient.timeframesMeds?.map((data, index) =>
                    data.times?.length > 0 || !data.frequency ? (
                      false
                    ) : (
                      <li>
                        <span style={{ fontWeight: 'bold' }}>
                          {capitalize(data.medName)}:
                        </span>{' '}
                        No timeframes assigned
                      </li>
                    )
                  )
                : ''}

              {patient.timeframesMeds
                ? patient.timeframesMeds?.map((data, index) =>
                    getFrequencyNumbers(data?.frequency) ===
                      data.times?.length || !data.frequency ? (
                      false
                    ) : (
                      <li>
                        <span style={{ fontWeight: 'bold' }}>
                          {capitalize(data.medName)}:
                        </span>{' '}
                        Mismatched frequency
                      </li>
                    )
                  )
                : ''}
            </ul>
            Do you really want to save?
          </Typography>
          <Grid container xs={12} direction='row'>
            <Grid item xs={6} mt={2}>
              <Button
                title='Cancel'
                sx={{
                  width: '180px',
                  height: '40px',
                  background: 'white !important',
                  color: '#004680 !important',
                  border: '1px solid #004680',
                  textTransform: 'capitalize',
                  fontSize: '18px',
                }}
                onClick={() => {
                  handleCloseModal();
                }}
              />
            </Grid>
            <Grid item container xs={6} mt={2}>
              {loading ? (
                <Grid
                  sx={{
                    width: '180px',
                    height: '40px',
                    border: '1px solid #004680',
                    textTransform: 'capitalize',
                    fontSize: '18px',
                  }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress />
                </Grid>
              ) : (
                <Button
                  title='Save'
                  sx={{
                    width: '180px',
                    height: '40px',
                    background: '#004680 !important',
                    border: '1px solid #004680',
                    textTransform: 'capitalize',
                    fontSize: '18px',
                  }}
                  onClick={() => {
                    handleCloseModal();
                    dispatch(savePatientData('dose_card'));
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
