import { Grid, Typography } from '@mui/material';
import React from 'react';
import Button from '../Button';
import ModalCustom from '../Modal';

function SaveDiscardModal(props) {
  return (
    <ModalCustom open={props.openModal} handleClose={props.closeModal}>
      <Grid container alignItems="center" justifyContent='center'>
        <Grid item xs={12} mb={2}>
          <Typography
            sx={{
              // styleName: Bold/Title 1;
              fontFamily: 'Comfortaa',
              fontSize: '28px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '34px',
              letterSpacing: '0.37px',
              textAlign: 'left',
            }}
          >
            {props.modalHeading}
          </Typography>
        </Grid>
        <Grid xs={12} mb={4}>
          <Typography
            sx={{
              // styleName: regular / body for web app ;
              fontFamily: 'Work Sans, serif',
              fontSize: '17px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              letterSpacing: '-0.40px',
              textAlign: 'left',
              '& span': {
                fontFamily: 'Work Sans, serif',
                fontSize: '17px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '-0.40px',
                textAlign: 'left',
              },
            }}
          >
            {props.modalParagraph}
          </Typography>
        </Grid>

        <Grid xs='6' item container justifyContent='left'>
          <Button
            onClick={props.discardOnClick}
            title={props.discardTitle}
            secondary={props.discardSecondary}
            primary={props.discardPrimary}
            sx={{
              width: '173px',
              height: '55px !important',
              color: '#EC5D57 !important',
              background: 'none !important',
              border: '1px solid #EC5D57',
            }}
          />
        </Grid>
        <Grid xs='6' item container justifyContent='center'>
          <Button
            disabled={props.saveDisabled}
            secondary={props.saveSecondary}
            onClick={props.saveOnClick}
            title={props.saveTitle}
            primary={props.savePrimary}
            sx={{
              width: '173px',
              height: '55px !important',
              border: '1px solid #004680',
            }}
          />
        </Grid>
      </Grid>
    </ModalCustom>
  );
}

export default SaveDiscardModal;
