import React from 'react';
import Button from 'components/atoms/Button';
import Select from 'components/atoms/Select';
import { MenuItem , Grid } from '@mui/material';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import {
  setOnboardingDetails,
  saveOnboardingDetails,
} from 'redux/actions/onboardingActions';

import { useNavigate } from 'react-router-dom';
import { useOnboarding, useSaveOnboarding } from 'redux/reusables/onboarding';
import { trackMixPanel } from 'redux/proxies/mixpanel';
import {
  TRACK_PATIENT_CREATION_FAILURE,
  TRACK_PATIENT_CREATION_SUCCESS,
} from 'redux/constants/mixPanel';

// Modal for loading animations
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

// To modify select fields
const useStyles = makeStyles({
  root: {
    fontSize: '15px',
    color: '#7E8186',

    '& .MuiSelect-icon': {
      display: 'none',
    },
  },
  dropdownStyle: {
    fontSize: '15px',
    color: '#7E8186',
    maxHeight: '450px',
    fontWeight: 'bold !important',
    '& .MuiSvgIcon-root': {
      display: 'none',
    },
  },
});

export default function Step4(props) {
  const classes = useStyles();

  // Redux
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { onboarding } = useOnboarding();

  const saveOnboarding = useSaveOnboarding();
  const { loading } = saveOnboarding;

  // Set default values
  // For selects
  const [wakeSelect, setWakeSelect] = React.useState('06 : 00');
  const [breakfastSelect, setBreakfastSelect] = React.useState('09 : 00');
  const [lunchSelect, setLunchSelect] = React.useState('13 : 00');
  const [dinnerSelect, setDinnerSelect] = React.useState('17 : 00');
  const [sleepSelect, setSleepSelect] = React.useState('22 : 30');
  // For modal
  const [open, setOpen] = React.useState(false);
  const defaultTimes = [
    '00 : 00',
    '00 : 30',
    '01 : 00',
    '01 : 30',
    '02 : 00',
    '02 : 30',
    '03 : 00',
    '03 : 30',
    '04 : 00',
    '04 : 30',
    '05 : 00',
    '05 : 30',
    '06 : 00',
    '06 : 30',
    '07 : 00',
    '07 : 30',
    '08 : 00',
    '08 : 30',
    '09 : 00',
    '09 : 30',
    '10 : 00',
    '10 : 30',
    '11 : 00',
    '11 : 30',
    '12 : 00',
    '12 : 30',
    '13 : 00',
    '13 : 30',
    '14 : 00',
    '14 : 30',
    '15 : 00',
    '15 : 30',
    '16 : 00',
    '16 : 30',
    '17 : 00',
    '17 : 30',
    '18 : 00',
    '18 : 30',
    '19 : 00',
    '19 : 30',
    '20 : 00',
    '20 : 30',
    '21 : 00',
    '21 : 30',
    '22 : 00',
    '22 : 30',
    '23 : 00',
    '23 : 30',
  ];

  // Detect wake value change
  const onChangerwakeSelect = (event) => {
    dispatch(
      setOnboardingDetails({
        type: 'timeWAKE_UP',
        value: event.target.value,
      })
    );
    setWakeSelect(event.target.value);
  };

  // Detect breakfast value change
  const onChangerbreakfastSelect = (event) => {
    dispatch(
      setOnboardingDetails({
        type: 'timeBREAKFAST',
        value: event.target.value,
      })
    );
    setBreakfastSelect(event.target.value);
  };

  // Detect lunch value change
  const onChangerlunchSelect = (event) => {
    dispatch(
      setOnboardingDetails({
        type: 'timeLUNCH',
        value: event.target.value,
      })
    );
    setLunchSelect(event.target.value);
  };

  // Detect dinner value change
  const onChangerdinnerSelect = (event) => {
    dispatch(
      setOnboardingDetails({
        type: 'timeDINNER',
        value: event.target.value,
      })
    );
    setDinnerSelect(event.target.value);
  };

  // Detect sleep value change
  const onChangersleepSelect = (event) => {
    dispatch(
      setOnboardingDetails({
        type: 'timeSLEEP',
        value: event.target.value,
      })
    );
    setSleepSelect(event.target.value);
  };

  // Handle submit click and modal open
  const [success, setSuccess] = React.useState(false);
  const handleClickOpen = () => {
    dispatch(saveOnboardingDetails(navigate));

    setTimeout(() => {
      if (!onboarding.userError) {
        setSuccess(true);
      }
    }, 2000);
  };

  React.useEffect(() => {
    setOpen(saveOnboarding.loading);
  }, [saveOnboarding.loading]);

  const [error, setError] = React.useState(false);
  React.useEffect(() => {
    if (onboarding.userError) {
      dispatch(setOnboardingDetails({ type: 'userError', value: false }));
    }
  }, []);
  React.useEffect(() => {
    if (onboarding.userError) {
      // dispatch(setOnboardingDetails({ type: 'userError', value: false }));
      setError(onboarding.userError);
    }
  }, [onboarding.userError]);

  React.useEffect(() => {
    if (success) {
      trackMixPanel(TRACK_PATIENT_CREATION_SUCCESS);
    }
    if (error) {
      trackMixPanel(TRACK_PATIENT_CREATION_FAILURE);
    }
  }, [success, error]);

  return (
    <div>
      <Grid
        xs={12}
        container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: '400px',
          minWidth: '540px',
        }}
      >
        {['Wake Up', 'Has Breakfast', 'Has Lunch', 'Has Dinner', 'Sleeps'].map(
          (item, index) => (
            <>
              <InputLabel
                id='demo-simple-select-helper-label'
                sx={{
                  marginLeft: '8px',
                  marginTop: '10px',
                  fontSize: '15px',
                  color: '#7E8186',
                }}
              >
                {item}
              </InputLabel>

              <Select
                disableSelect
                classes={classes}
                onChange={
                  index === 0
                    ? onChangerwakeSelect
                    : index === 1
                    ? onChangerbreakfastSelect
                    : index === 2
                    ? onChangerlunchSelect
                    : index === 3
                    ? onChangerdinnerSelect
                    : onChangersleepSelect
                }
                value={
                  index === 0
                    ? wakeSelect
                    : index === 1
                    ? breakfastSelect
                    : index === 2
                    ? lunchSelect
                    : index === 3
                    ? dinnerSelect
                    : sleepSelect
                }
                onOpen={() => {
                  const event = new KeyboardEvent('keypress', {
                    key: 'enter',
                  });
                }}
              >
                <MenuItem key={index} value={0}>
                  <Grid container item justifyContent='space-between'>
                    __ : __
                    <AccessTimeIcon />
                  </Grid>
                </MenuItem>
                {defaultTimes.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item}
                      sx={{
                        fontSize: '15px',
                        color: '#7E8186',
                        fontWeight: 'bold',
                      }}
                      onClick={() => {
                      }}
                    >
                      {item}
                    </MenuItem>
                  ))}
              </Select>
            </>
          )
        )}

        <Grid xs={12} item container mt={5}>
          <Grid item container xs={6} sm={6} md={6} lg={7}>
            <Button
              title="Submit"
              onClick={() => {
                handleClickOpen();
                // props.onClick();
              }}
              disabled={
                onboarding.timeWAKE === 0 ||
                onboarding.timeBREAKFAST === 0 ||
                onboarding.timeLUNCH === 0 ||
                onboarding.timeDINNER === 0 ||
                onboarding.timeSLEEP === 0 ||
                onboarding.phone === '' ||
                onboarding.fname === '' ||
                onboarding.lname === '' ||
                onboarding.dob === '' ||
                onboarding.email === ''
              }
              secondary={
                onboarding.timeWAKE === 0 ||
                onboarding.timeBREAKFAST === 0 ||
                onboarding.timeLUNCH === 0 ||
                onboarding.timeDINNER === 0 ||
                onboarding.timeSLEEP === 0 ||
                onboarding.phone === '' ||
                onboarding.fname === '' ||
                onboarding.lname === '' ||
                onboarding.dob === '' ||
                onboarding.email === ''
              }
            />
          </Grid>

          <Grid
            xs={6}
            sm={6}
            md={6}
            lg={5}
            item
            container
            alignItems='center'
            sx={{
              display: 'flex',

              color: success ? 'green' : 'red',
              textAlign: 'center',
            }}
          >
            <Typography
              gutterBottom
              ml={2}
              sx={{ fontSize: success ? '14px' : '12px' }}
            >
              {error ? 'Username with phone or email already exists' : ''}
              {success ? (
                <span
                  style={{
                    display: 'flex',
                    alignItem: 'center',
                  }}
                >
                  <span
                    style={{
                      marginRight: '12px',
                    }}
                  >
                    Onboarding form submitted!
                  </span>
                  <CircularProgress size={13} />
                </span>
              ) : (
                ''
              )}
            </Typography>
          </Grid>
        </Grid>
        <BootstrapDialog aria-labelledby='customized-dialog-title' open={open}>
          <DialogContent dividers>
            <Grid container alignItems='center'>
              <CircularProgress size={30} />
              <Typography gutterBottom ml={2}>
                Loading
              </Typography>
            </Grid>
          </DialogContent>
        </BootstrapDialog>
      </Grid>
    </div>
  );
}
