export const ENCOUNTERS_NOTES_CREATE_FAIL = 'ENCOUNTERS_NOTES_CREATE_FAIL';
export const ENCOUNTERS_NOTES_CREATE_REQUEST =
  'ENCOUNTERS_NOTES_CREATE_REQUEST';
export const ENCOUNTERS_NOTES_CREATE_SUCCESS =
  'ENCOUNTERS_NOTES_CREATE_SUCCESS';
export const ENCOUNTERS_NOTES_CREATE_RESET = 'ENCOUNTERS_NOTES_CREATE_RESET';

export const ENCOUNTERS_NOTES_DELETE_FAIL = 'ENCOUNTERS_NOTES_DELETE_FAIL';
export const ENCOUNTERS_NOTES_DELETE_REQUEST =
  'ENCOUNTERS_NOTES_DELETE_REQUEST';
export const ENCOUNTERS_NOTES_DELETE_SUCCESS =
  'ENCOUNTERS_NOTES_DELETE_SUCCESS';

export const ENCOUNTERS_NOTES_FETCH_FAIL = 'ENCOUNTERS_NOTES_FETCH_FAIL';
export const ENCOUNTERS_NOTES_FETCH_REQUEST = 'ENCOUNTERS_NOTES_FETCH_REQUEST';
export const ENCOUNTERS_NOTES_FETCH_SUCCESS = 'ENCOUNTERS_NOTES_FETCH_SUCCESS';

export const ENCOUNTERS_SINGLE_FETCH_FAIL = 'ENCOUNTERS_SINGLE_FETCH_FAIL';
export const ENCOUNTERS_SINGLE_FETCH_REQUEST =
  'ENCOUNTERS_SINGLE_FETCH_REQUEST';
export const ENCOUNTERS_SINGLE_FETCH_SUCCESS =
  'ENCOUNTERS_SINGLE_FETCH_SUCCESS';
export const ENCOUNTERS_SINGLE_FETCH_RESET = 'ENCOUNTERS_SINGLE_FETCH_RESET';

export const ENCOUNTERS_NOTES_UPDATE_FAIL = 'ENCOUNTERS_NOTES_UPDATE_FAIL';
export const ENCOUNTERS_NOTES_UPDATE_REQUEST =
  'ENCOUNTERS_NOTES_UPDATE_REQUEST';
export const ENCOUNTERS_NOTES_UPDATE_SUCCESS =
  'ENCOUNTERS_NOTES_UPDATE_SUCCESS';

export const ENCOUNTERS_NOTES_EDITOR_FAIL = 'ENCOUNTERS_NOTES_EDITOR_FAIL';
export const ENCOUNTERS_NOTES_EDITOR_REQUEST =
  'ENCOUNTERS_NOTES_EDITOR_REQUEST';
export const ENCOUNTERS_NOTES_EDITOR_SUCCESS =
  'ENCOUNTERS_NOTES_EDITOR_SUCCESS';
