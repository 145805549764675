import Cookies from 'universal-cookie';

class getToken {
  cookies;
  constructor() {
    this.cookies = new Cookies();
  }

  getAccess = () => {
    return this.cookies.get('access_token');
  };

  getRefresh = () => {
    return this.cookies.get('refresh_token');
  };

  setAccess = (data) => {
    this.cookies.set('access_token', data, {
      path: '/'
    });
  };

  setRefresh = (data) => {
    this.cookies.set('refresh_token', data, {
      path: '/'
    });
  };

  removeRefresh = () => {
    this.cookies.remove('refresh_token');
  };
  removeAccess = () => {
    this.cookies.remove('access_token');
  };
}

export const Tokens = new getToken();
