import React, { useCallback, useMemo, useRef } from 'react';

import { Grid, Typography } from '@mui/material';

import Button from 'components/atoms/Button';
import AddIcon from '@mui/icons-material/Add';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import MedicationBuilderGridItem from 'components/atoms/PatientChart/MedicationBuilderGridItem';

import CircularProgress from '@mui/material/CircularProgress';

import { useSelector, useDispatch } from 'react-redux';
import {
  savePatientData,
  updatePatientData,
} from 'redux/actions/patientChartActions';
import {
  MedicationBuilderGridItemInner,
} from 'components/organisms/patientChartUi/MedicationBuilder/MedicationBuilderGridItemInner';
import { usePatient } from 'redux/reusables/patients';
import Input from 'components/atoms/Input';
import {
  useStylesInputSearch,
} from 'styles/components/atoms/PatientCharts/MedicationBuilder';

export default function MedicationBuilder() {
  const [sorting, setSorting] = React.useState(null);
  const dispatch = useDispatch();
  const [noMedNameError, setNoMedNameError] = React.useState([]);

  const classes=useStylesInputSearch()

  const handleSortingChange = (field) => {
    const isSameField = sorting?.field === field;
    const isASC = sorting?.orderType === 'ASC';

    const isChangeToDESC = isSameField && isASC;
    const isCreateNew = !isSameField;
    if(isChangeToDESC) return setSorting({...sorting, orderType: 'DESC'});
    if(isCreateNew) return setSorting({field, orderType: 'ASC'});
    return setSorting(null);
  }

  const { patient } = usePatient();
  const savePatient = useSelector((state) => state.savePatient);
  const { loading } = savePatient;

  const [editMode, setEditMode] = React.useState(true);

  React.useEffect(() => {
    if (patient.medFields === 0) {
      setEditMode(false);
      return false;
    }
    setEditMode(patient.editMode);
  }, [patient.editMode, patient.medFields]);

  const [fields, setFields] = React.useState(patient.medFields || 1);
  React.useEffect(() => {
    setFields(patient.medFields);
  }, [patient.medFields]);

  React.useEffect(() => {
    if (patient.medData?.length === 0) {
      setTimeout(() => {
        dispatch(updatePatientData({ type: 'editMode', value: true }));
        dispatch(updatePatientData({ type: 'editModeLimit', value: true }));
      }, 300);
    }

    // Disable save if no medication name
    if (noMedNameError.includes(true)) {
      dispatch(updatePatientData({ type: 'noMedNameError', value: true }));
    } else if (!noMedNameError.includes(true)) {
      dispatch(updatePatientData({ type: 'noMedNameError', value: false }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient.medData]);

  React.useEffect(() => {
    if (loading) {
      dispatch(updatePatientData({ type: 'editModeLimit', value: true }));
    } else {
      dispatch(updatePatientData({ type: 'editModeLimit', value: false }));
    }
  }, [loading]);

  // Medication fields data:
  // const [medications,setMedications] = React.useState([{}])

  // Searching medications using code:
  const [searchMedication, setSearchMedication] = React.useState('');
  const inputRef=useRef();

  function debounce(func, timeout = 400){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }


  const onChangerSearchMedication = useCallback(debounce((e) => {
    setSearchMedication(e.target.value);
    inputRef.current.focus()
  }), [searchMedication]);

  const [list, setList] = React.useState([]);

  React.useEffect(() => {
    const orders = patient.medData.map(({order}) => order);
    const maxOrder = Math.max(...orders);
    const fieldsArray = [...Array(fields)];

    const validList =  fieldsArray.map((_, index) => {
      const item = patient.medData[index] || null;
      if(!item){
        const newOrder = maxOrder + fieldsArray.length - index;
        return {
          index,
          order: newOrder,
          code: [],
          medName: '',
          nickname: '',
          route: '',
        }
      }
      return {
        index,
        order: item.order,
        code: item.code,
        medName: item.medName,
        route: item.route,
        nickname: item.nickname,
      }
    })
    setList(validList);
  }, [fields, patient.medData]);

  const sortedList = useMemo(() => {
    const searchString = searchMedication.toLowerCase() || null;
    const sortingField = sorting?.field || null;
    const sortingOrderType = sorting?.orderType || null;


    return [...list].sort((a, b) => {
      const aCodes = a.code || [];
      const bCodes = b.code || [];
      const isAMatched = aCodes.some( ({ code }) => code?.toLowerCase().includes(searchString));
      const isBMatched = bCodes.some( ({ code }) => code?.toLowerCase().includes(searchString));
      const isEquaCodeslMatch = (isAMatched && isBMatched) || (!isAMatched && !isBMatched);

      if(isEquaCodeslMatch) {
        if(sortingField && sortingOrderType){
          const aFieldValue = a[sortingField] || '';
          const bFieldValue = b[sortingField] || '';
          if(aFieldValue !== bFieldValue){
            if(aFieldValue > bFieldValue) return sortingOrderType === 'ASC' ? 1 : -1;
            if(aFieldValue < bFieldValue) return sortingOrderType === 'ASC' ? -1 : 1;
          }
        }
        if(a.order > b.order) return 1;
        if(a.order < b.order) return -1;
      }

      if(isAMatched) return -1;
      if(isBMatched) return 1;

      return 0;
    })
  }, [list, searchMedication, sorting]);

  const getSortingArrowStyle = (field) => {
    const arrowStyles = {
      transform: 'rotate(0deg)',
      opacity: 0,
      color: '#7E8186',
      transition: 'all 0.3s ease-in-out',
      marginLeft: 7
    }

    if(sorting?.field === field) {
      arrowStyles.opacity = 1;
      if(sorting.orderType === 'ASC'){
        arrowStyles.transform = 'rotate(180deg)';
      }
    }

    return arrowStyles
  }

  return (
    <div style={{ width: '1490px' }}>
      <Grid
        container
        p={3}
        xs={12}
        sx={{
          width: '1490px',
          minHeight: '411px',
          background: 'white',
        }}
      >
        <Grid
          container
          item
          xs={12}
          pt={3}
          mb={1}
          sx={{
            borderBottom: '1px solid #D4D5D7',
            paddingBottom: '15px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {/* HEadings here */}
          <Grid xs={11} container>
            <Grid item xs={2.75} onClick={() => handleSortingChange('medName')} style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
              <Typography
                sx={{
                  color: '#7E8186',
                  fontSize: '16px',
                  fontWeight: '400',
                  fontFamily: 'Work Sans, sans-serif',
                  letterSpacing: '-0.31px',
                }}
              >
              Med name
              </Typography>
              <ArrowDownwardIcon style={getSortingArrowStyle('medName')}/>
            </Grid>

            <Grid item xs={2.25} onClick={() => handleSortingChange('nickname')} style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
              <Typography
                sx={{
                  color: '#7E8186',
                  fontSize: '16px',
                  fontWeight: '400',
                  fontFamily: 'Work Sans, sans-serif',
                  letterSpacing: '-0.31px',
                }}
              >
              Nickname
              </Typography>
              <ArrowDownwardIcon style={getSortingArrowStyle('nickname')}/>
            </Grid>

            <Grid item xs={1} style={{display: 'flex', alignItems: 'center'}}>
              <Typography
                sx={{
                  marginLeft: '-5px',
                  color: '#7E8186',
                  fontSize: '16px',
                  fontWeight: '400',
                  fontFamily: 'Work Sans, sans-serif',
                  letterSpacing: '-0.31px',
                }}
              >
              Dose
              </Typography>
            </Grid>
            <Grid item xs={1} style={{display: 'flex', alignItems: 'center'}}>
              <Typography
                sx={{
                  color: '#7E8186',
                  fontSize: '16px',

                  fontWeight: '400',
                  fontFamily: 'Work Sans, sans-serif',
                  letterSpacing: '-0.31px',
                }}
              >
              Units
              </Typography>
            </Grid>
            <Grid item xs={1.5} onClick={() => handleSortingChange('route')} style={{display: 'flex', alignItems: 'center', cursor: 'pointer', justifyContent: 'center'}}>
              <Typography
                sx={{
                  marginLeft: '-45px',
                  color: '#7E8186',
                  fontSize: '16px',
                  fontWeight: '400',
                  fontFamily: 'Work Sans, sans-serif',
                  letterSpacing: '-0.31px'
                }}
              >
              Route
              </Typography>
              <ArrowDownwardIcon style={getSortingArrowStyle('route')}/>
            </Grid>
            <Grid item xs={1.5} style={{display: 'flex', alignItems: 'center'}}>
              <Typography
                sx={{
                  color: '#7E8186',
                  fontSize: '16px',
                  fontWeight: '400',
                  fontFamily: 'Work Sans, sans-serif',
                  letterSpacing: '-0.31px',
                }}
              >
              Frequency
              </Typography>
            </Grid>

            <Grid item xs={0.5} style={{display: 'flex', alignItems: 'center'}}>
              <Typography
                sx={{
                  color: '#7E8186',
                  fontSize: '16px',
                  fontWeight: '400',
                  fontFamily: 'Work Sans, sans-serif',
                  letterSpacing: '-0.31px',
                }}
              >
              PRN
              </Typography>
            </Grid>
            <Grid item xs={1.5} style={{display: 'flex', alignItems: 'center'}}>
              <Typography
                sx={{
                  color: '#7E8186',
                  fontSize: '16px',
                  fontWeight: '400',
                  fontFamily: 'Work Sans, sans-serif',
                  letterSpacing: '-0.31px',
                }}
              >
                <Input classes={classes} placeholder="Code" onChange={(e)=>onChangerSearchMedication(e)} key="editor1" ref={inputRef}/>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={1}/>
        </Grid>
        <Grid xs={12}>
          {sortedList.map((item) => (
            <MedicationBuilderGridItem
              MainComponent={MedicationBuilderGridItemInner}
              index={item.index}
              order={item.order}
              key={item.index}
              noMedNameError={noMedNameError}
              setNoMedNameError={setNoMedNameError}
            />
          ))}
        </Grid>

        <Grid container item xs={12} justifyContent='flex-start'>
          <Typography
            sx={{
              color:
                patient.medFields >= 50 ||
                (patient.editModeLimit && patient.medFields !== 0)
                  ? '#a0c7e5'
                  : '#004680',
              fontFamily: 'Work Sans, sans-serif',
              fontSize: '16px',
              fontWeight: '600',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              letterSpacing: '-0.31px',
            }}
            mt={5}
            mb={7}
            onClick={() => {
              if (
                (patient.medFields < 50 && !patient.editModeLimit) ||
                patient.medFields === 0
              ) {
                dispatch(
                  updatePatientData({ type: 'editModeLimit', value: true })
                );
                setFields((preVal) => preVal + 1);
                dispatch(updatePatientData({ type: 'editMode', value: true }));
                dispatch(
                  updatePatientData({ type: 'medFields', value: fields + 1 })
                );
              }
            }}
          >
            <AddIcon />
            Add new medication
          </Typography>
        </Grid>

        <Grid
          container
          item
          xs={12}
          justifyContent='space-between'
          alignItems="center"
        >
          {loading ? (
            <Grid
              sx={{ width: '240px', height: '54px', marginBottom: '20px' }}
              item
              container
              justifyContent='center'
              alignItems='center'
            >
              <CircularProgress />
            </Grid>
          ) : (
            <Button
              title='Save'
              sx={{
                borderRadius: '8px !important',
                width: '240px !important',
                height: '54px !important',
                marginBottom: '20px',
                fontFamily: 'Work Sans, sans-serif',
                fontSize: '18px',
                fontWeight: '500',
                color: '#A9ABAF',
              }}
              onClick={() => {
                dispatch(
                  updatePatientData({ type: 'editModeLimit', value: false })
                );
                dispatch(
                  updatePatientData({ type: 'save', value: !patient.save })
                );

                setTimeout(() => {
                  dispatch(
                    updatePatientData({ type: 'editMode', value: false })
                  );
                  dispatch(savePatientData('medBuilder'));
                }, 300);
              }}
              disabled={
                patient.noMedName ||
                noMedNameError.includes(true) ||
                patient.medFields === 0
              }
              secondary={
                patient.noMedName ||
                noMedNameError.includes(true) ||
                patient.medFields === 0
              }
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
}
