import React from 'react';
import { Grid } from '@mui/material';

import List from 'components/organisms/diabetesUi/List';
import ListSelect from 'components/organisms/diabetesUi/ListSelect';
import ListMix from 'components/organisms/diabetesUi/ListMix';
import MealTable from 'components/organisms/diabetesUi/MealTable';
import GlucoseTable from 'components/organisms/diabetesUi/GlucoseTable';

export default function Settings({ hideNav }) {
    return (
        <div>
            <Grid
                container
                // spacing={2}
                alignItems='center'
                justifyContent='center'
                sx={{
                    width: '1376px',
                    marginTop: '-25px',
                }}
            >
                <Grid container item xs={4} alignItems='center' justifyContent='center'>
                    <List />
                </Grid>
                <Grid container item xs={4} alignItems='center' justifyContent='center'>
                    <ListSelect />
                </Grid>
                <Grid container item xs={4} alignItems='center' justifyContent='center'>
                    <ListMix />
                </Grid>
            </Grid>
            <Grid
                container
                // spacing={2}
                alignItems='center'
                justifyContent='center'
                mt={5}
                sx={{
                    width: '1376px',
                }}
            >
                <MealTable />
                <GlucoseTable />
            </Grid>
        </div>
    );
}
