import axiosFetch from 'redux/config/axios';
import {
  AUTH_DETAILS_REQUEST,
  AUTH_DETAILS_SUCCESS,
  AUTH_DETAILS_FAIL,
} from 'redux/constants/authConstants';
import { Tokens } from 'redux/config/getToken';
// Function to show diabetes api content
export const authCheck = () => async (dispatch) => {
  try {
    dispatch({ type: AUTH_DETAILS_REQUEST });
    // const history = useHistory();

    // Custom token class
    const check = Tokens.getAccess();
    // Checks if access token is not present fetches
    // fresh one from api/refresh/ and stores in cookie
    if (!check) {
      const refresh = Tokens.getRefresh();
      if (!refresh) {
        // const { data } = await axios.post(`${API_URL}/api/token/`, {
        //   mobile_phone: USERNAME,
        //   password: PASSWORD,
        // });
        // Tokens.setAccess(data.access);
        // Tokens.setRefresh(data.refresh);
        // window.location.reload();
        // history.push('/signin');
      } else {
        // const { data } = await axios.post(`${API_URL}/api/token/refresh/`, {
        //   refresh: refresh,
        // });
        // Tokens.setAccess(data.access);
        // Tokens.setRefresh(data.refresh);
        // window.location.href = '/signin';
      }
    }

    if (check) {
      const ownData = await axiosFetch('/get-own-data/');
      dispatch({
        type: AUTH_DETAILS_SUCCESS,
        payload: {
          username: `${ownData.data.first_name} ${ownData.data.last_name}`,
          id: ownData.data.pk,
          isAuthenticated: true,
        },
      });
    } else {
      throw new Error('No access token');
    }
  } catch (error) {
    dispatch({ type: AUTH_DETAILS_FAIL, payload: error });
  }
};
