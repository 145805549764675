import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    width: '343px',
    borderRadius: '16px',
    '& .MuiInputBase-root': {
      borderRadius: '16px',
      padding: '0px',
      height: '60px',
    },

    '& .MuiInputBase-input-MuiOutlinedInput-input': {
      borderRadius: '16px',
      padding: '0px',
    },

    '& .MuiFormHelperText-root': {
      marginTop: '8px',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      background: '#F1F3F7',
    },
  },
});
