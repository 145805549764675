import * as React from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import Button from 'components/atoms/Button';
import Navbar from 'components/molecules/dashboardUi/Navbar';
import { Grid, Typography } from '@mui/material';
import Table from 'components/atoms/Table';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch } from 'react-redux';
import { listPatients } from 'redux/actions/dashboardActions';
import { useLocation, useNavigate } from 'react-router';
import { updatePatientData } from 'redux/actions/patientChartActions';
import { PATIENT_DATA_RESET } from 'redux/constants/patientChartConstants';
import { DIABETES_DETAILS_RESET } from 'redux/constants/diabetesConstants';
import { trackMixPanel } from 'redux/proxies/mixpanel';
import { TRACK_PATIENT_CREATION_OPENED } from 'redux/constants/mixPanel';

export default function Dashboard() {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(listPatients());
    dispatch({ type: PATIENT_DATA_RESET });
    dispatch({ type: DIABETES_DETAILS_RESET });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(updatePatientData({ type: 'tab', value: 0 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className='dashboard-ui-container'>
      <Grid container>
        <Navbar />
        <hr style={{ color: 'gray', opacity: '0.5' }} />

        <Grid
          container
          xs={12}
          justifyContent='center'
          sx={{ background: '#F7F9FE', paddingBottom: '50px' }}
        >
          <Grid
            item
            container
            xs={12}
            justifyContent='space-between'
            pl={2}
            pr={2}
          >
            <Grid item container xs={5} alignItems='center'>
              <Typography
                variant='h3'
                sx={{
                  fontSize: '28px',
                  fontWeight: '700',
                  letterSpacing: '0.38px',
                }}
              >
                Patient Panel
              </Typography>
            </Grid>
            <Grid
              item
              container
              alignItems='center'
              xs={7}
              justifyContent='flex-end'
              py={2}
            >
              {/* <Link to={'/onboarding'} style={{ textDecoration: 'none' }}> */}

              <Button
                title='New patient'
                sx={{
                  width: '178px',
                  height: '40px !important',
                  textTransform: 'capitalize',
                  borderRadius: '8px !important',
                  fontFamily: 'Work Sans, sans-serif',
                  fontSize: '16px',
                  fontWeight: '600',
                  letterSpacing: '-0.31px',
                }}
                startIcon={<AddIcon />}
                onClick={() => {
                  trackMixPanel(TRACK_PATIENT_CREATION_OPENED);
                  navigate('/onboarding');
                }}
              />
              {/* </Link> */}
            </Grid>
          </Grid>
          <Grid container xs={12} justifyContent='center'>
            <Table />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
