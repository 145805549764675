import * as React from 'react';
import { useState , useEffect , useReducer } from 'react';
import { useNavigate , Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import 'styles/components/templates/loginUi/index.scss';
import { API_URL } from 'constant';

import { Tokens } from 'redux/config/getToken';

import BackdropCustom from 'components/atoms/Backdrop';
import { trackMixPanel } from 'redux/proxies/mixpanel';
import { TRACK_USER_SIGNIN_FAILED } from 'redux/constants/mixPanel';

export default function SignIn() {
  useEffect(() => {
    console.clear();
    const validate = Tokens.getAccess();
    if (validate) {
      navigate('/dashboard');
    }

  }, []);
  const navigate = useNavigate();
  const theme = createTheme();
  const [islogin, setIsLogin] = useState(false);
  const [loading, setLoading] = useState(false);

  const [LoginError, setLoginError] = useState(false);
  const [Mobile, setMobile] = useState('');
  const [Password, setPassword] = useState('');
  // const [disableBtn, setDisableBtn] = useState(true);
  const [password, setPasswordErr] = useState('');

  //   validation area
  const reducer = (state, action) => {
    if (action.type === 'Mobile') {
      if (Mobile.length < 10) {
        //  setDisableBtn(true)
        return (state = 'Invalid Number');
      }
    } else if (Password.length < 5) {
      // setDisableBtn(true)
      return setPasswordErr('Invalid Password');
    } else if (Mobile.length === 10 && Password.length >= 5) {
      // setDisableBtn(false)
      setPasswordErr('');
      return (state = '');
    }
  };
  const [state, dispatch] = useReducer(reducer, '');
  // valdation area over
  const handleSubmit = async (event) => {
    event.preventDefault();
    axios.defaults.withCredentials = true;
    setLoading(true);
    // Validate Api
    try {
      const res = await axios.post(`${API_URL}/api/validate-password/`, {
        mobile_phone: `+1${  Mobile}`,
        password: Password,
      });

      event.preventDefault();
      if (res.status === 200) {
        navigate('/OTP', { state: { detail: `+1${  Mobile}` } });
      }
      setLoading(false);

    } catch (err) {
      setLoginError(true);
      setLoading(false);
      trackMixPanel(TRACK_USER_SIGNIN_FAILED);
    }
  };
  // Regex for numeric value
  function handleChange1(event) {
    const Mobile = event.target.value;
    setMobile(
      Mobile.replace(/[A-Za-z!\-?=@`~;:._'"\[\]\ \+\{}|#$%^&*()\\//]/g, '')
    );
  }

  function handleChange2(event) {
    const Password = event.target.value;
    setPassword(Password.replace(/^\s+|\s+$|\s+(?=\s)/g, ''));
  }
  return (
    <div className="loginSection">
      <ThemeProvider theme={theme} style={{ backgroundColor: 'red' }}>
        <Container component='main'>
          <BackdropCustom open={loading} />
          <CssBaseline />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            className="loginBox"
          >
            <img
              src='/images/PrecinaLogo.png'
              style={{ width: '130px', height: '40px', marginBottom: '30px' }}
              alt='logo here'
            />

            <Box
              component='form'
              onSubmit={(e) => handleSubmit(e)}
              noValidate
              sx={{ mt: 1 }}
              style={{ display: 'block', width: '100%' }}
            >
              <Typography
                variant='h5'
                component='h5'
                style={{
                  fontFamily: 'Comfortaa',
                  fontWeight: '700!important',
                  fontSize: '22px!important',
                  lineHeight: ' 48px',
                  letterSpacing: ' 0.35px',
                  color: '#282D36',
                  marginBottom: '25px',
                }}
              >
                Login To Precina Health
              </Typography>
              <Typography className="labeltxt">phone number*</Typography>
              <TextField
                margin='normal'
                required
                fullWidth
                name='mobile'
                placeholder='Your Mobile Number'
                type='mobile'
                id='mobile'
                autoComplete='mobile'
                value={Mobile}
                onChange={handleChange1}
                InputProps={{ startAdornment: '+1' }}
                className="inputfield"
                inputProps={{ maxLength: 10 }}
                onBlur={() => dispatch({ type: 'Mobile' })}
              />
              <div style={{ textAlign: '-webkit-right' }}>
                <p style={{ color: 'red' }}>
                  <b>{state}</b>
                </p>
              </div>
              <Typography className="labeltxt" style={{ marginTop: '9px' }}>
                password*
              </Typography>
              <TextField
                margin='normal'
                fullWidth
                name='password'
                placeholder='Enter Password'
                type='password'
                id='password'
                autoComplete='current-password'
                value={Password}
                onChange={handleChange2}
                className="inputfield"
                onBlur={() => dispatch({ type: 'Password' })}
              />
              <div style={{ textAlign: '-webkit-right' }}>
                <p style={{ color: 'red' }}>
                  <b>{password}</b>
                </p>
              </div>
              <Grid container>
                <Grid item xs>
                  {/* <Link
                    style={{
                      color: '#53575E',
                      textDecoration: 'none',
                      fontFamily: ' Work Sans',
                      fontStyle: 'normal',
                      fontWeight: 'normal',
                      fontSize: '13px',
                      lineHeight: '18px',
                    }}
                    to='/forget-password'
                    variant='body2'
                  >
                    Forgot your password?
                  </Link> */}
                </Grid>
                {islogin ? (
                  ''
                ) : (
                  <div>
                    {LoginError ? (
                      <p style={{ color: 'red' }}>
                        <b>
                          No active account found with the given credentials{' '}
                        </b>
                      </p>
                    ) : (
                      <div />
                    )}
                  </div>
                )}
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  sx={{ mt: 3, mb: 2, fontFamily: 'Work Sans, Serif' }}
                  className="Button"
                  // disabled={disableBtn}
                >
                  Login
                </Button>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
}
