import React from 'react';
import { Grid, ToggleButton, Typography } from '@mui/material';
import Editors from 'components/organisms/patientChartUi/Editor';
import CreateIcon from '@mui/icons-material/Create';
import ModalCustom from 'components/atoms/Modal';
import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';
import InputLabel from '@mui/material/InputLabel';
import { useDispatch } from 'react-redux';
import {
  getFormattedPhoneNum,
  validateEmail,
  getFormattedDate,
} from 'components/molecules/onboardingSectionUi/OnboardingSteps/functions';
import {
  inputLabelStyle,
  inputStyle,
} from 'styles/components/atoms/PatientCharts/SectionTitle';
import { usePatient } from 'redux/reusables/patients';
import moment from 'moment';
import { patchPatient } from 'redux/proxies/patients';

export default function SectionTitle({
  title,
  pt,
  name,
  editable,
  defaultValue,
  tab,
  link,
  label,
}) {
  // Redux and router setup
  const [openModal, setOpenModal] = React.useState(false);

  // For input
  const classes = inputStyle();
  // For Labels
  const inputLabelClasses = inputLabelStyle();
  // This is to update contact info (coming from patient info or medical provider from patient chart page)
  const updateOnbaording = () => {
    // if (link === 'contact') {
    setOpenModal(!openModal);
    // }
  };

  const dispatch = useDispatch();
  const { patient } = usePatient();

  // Initial values set on load
  const [data, setData] = React.useState({
    first_name: patient.first_name ? patient.first_name : '',
    last_name: patient.last_name ? patient.last_name : '',
    email: patient.email ? patient.email : '',
    mobile_phone: patient.mobile_phone
      ? getFormattedPhoneNum(patient.mobile_phone)
      : '+1',
    birthdate: patient.birthdate
      ? moment(patient.birthdate).format('MM-DD-YYYY')
      : '',
    sex: patient.sex ? patient.sex : '',
  });
  const [phoneError, setPhoneError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [dobError, setDobError] = React.useState(false);

  const setError = (errorType) => {
    errorType === 'email' ? setEmailError(true) : setEmailError(emailError);
    errorType === 'mobile_phone'
      ? setPhoneError(true)
      : setPhoneError(phoneError);
    errorType === 'birthdate' ? setDobError(true) : setDobError(dobError);
  };

  const removeError = (errorType) => {
    errorType === 'email' ? setEmailError(false) : setEmailError(emailError);
    errorType === 'mobile_phone'
      ? setPhoneError(false)
      : setPhoneError(phoneError);
    errorType === 'birthdate' ? setDobError(false) : setDobError(dobError);
  };

  // Onchange handler for all inputs
  const onChanger = (event) => {
    // Clear validation error
    removeError(event.target.name);
    // Don't accept special characters for specific inputs except these
    if (
      event.target.name === 'first_name' ||
      event.target.name === 'last_name'
    ) {
      if (
        event.nativeEvent.data
          ? !event.nativeEvent.data.search(/[$&+,:;=?@#|'<>.^*()%!-]/)
          : false
      ) {
        return false;
      }
    }

    // Dont accept special characters for email except @
    if (event.target.name === 'email') {
      if (
        event.nativeEvent.data
          ? !event.nativeEvent.data.search(/[$&,:;=?#|'<>^*()%!-]/)
          : false
      ) {
        return false;
      }
    }

    // Does not allow to remove +1 from number input
    if (
      (event.keyCode === 8 && data.mobile_phone.length === 1) ||
      data.mobile_phone.length === 0
    ) {
      setData((preValue) => ({
          ...preValue,
          [event.target.name]: '+1',
        }));
      return false;
    }

    // Set data if no exceptions
    setData((preValue) => ({
        ...preValue,
        [event.target.name]: event.target.value,
      }));

    // Checks if input provided is mobile_phone then format as required and save
    if (event.target.name === 'mobile_phone') {
      if (
        event.nativeEvent.inputType === 'deleteContentBackward' &&
        data.mobile_phone.length > 2
      ) {
        return false;
      }

      setData((preValue) => ({
          ...preValue,
          [event.target.name]: getFormattedPhoneNum(event.target.value),
        }));
    }

    // Format date
    if (event.target.name === 'birthdate') {
      if (event.nativeEvent.inputType === 'deleteContentBackward') {
        return false;
      }
      setData((preValue) => ({
          ...preValue,
          [event.target.name]: getFormattedDate(event.target.value),
        }));
    }
  };

  const updateUser = async () => {
    try {
      const res = await patchPatient(patient.pk, {
        ...data,
        mobile_phone: data.mobile_phone.replace(/[^0-9]/g, ''),
      });
    } catch (err) {
    }
  };

  return (
    <Grid
      container
      item
      justifyContent='space-between'
      sx={{ fontSize: '17px', color: '#004680', fontWeight: '700' }}
      pt={pt || 1}
    >
      <Grid xs={9}>
      <Typography variant='p'>{title}</Typography>
      </Grid>

      <Typography
        variant='p'
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {link ? (
          <Typography
            variant='p'
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => updateOnbaording(link)}
          >
            <CreateIcon sx={{ fontSize: '15px', marginRight: '10px' }} />
            Edit
          </Typography>
        ) : (
          <Editors
            editButton
            name={name}
            editable={editable}
            defaultValue={defaultValue}
            tab={tab}
            label={label}
          />
        )}
      </Typography>
      <ModalCustom
        open={openModal}
        handleClose={() => setOpenModal(false)}
        width={650}
      >
        <div className='step1-onboarding'>
          <Grid xs={12} container direction='row'>
            <Grid xs={12} item mb={2}>
              <Typography
                sx={{
                  // styleName: Bold/Title 1;
                  fontFamily: 'Comfortaa',
                  fontSize: '28px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '34px',
                  letterSpacing: '0.37px',
                  textAlign: 'left',
                  color: '#282D36',
                }}
              >
                Update Patient Info
              </Typography>
            </Grid>
            <Grid xs={6} item mb={2}>
              <InputLabel classes={inputLabelClasses}>
                First Name
              </InputLabel>
              <Input
                classes={classes}
                name='first_name'
                onChange={onChanger}
                value={data.first_name}
                onKeyUp={onChanger}
              />
            </Grid>
            <Grid xs={6} item container direction='column' mb={2}>
              <InputLabel classes={inputLabelClasses}>Last Name</InputLabel>
              <Input
                classes={classes}
                name='last_name'
                onChange={onChanger}
                value={data.last_name}
                onKeyUp={onChanger}
              />
            </Grid>
            <Grid xs={6} item container direction='column' mb={2}>
              <InputLabel classes={inputLabelClasses}>
                Date of Birth
              </InputLabel>
              <Input
                classes={classes}
                placeholder="MM/DD/YYYY"
                format="MM/DD/YY"
                name='birthdate'
                error={dobError}
                helperText={dobError ? 'Enter a valid date' : ''}
                type='text'
                onChange={(e) => {
                  if (e.target.value.length < 11) {
                    onChanger(e);
                  }
                }}
                value={data.birthdate}
                onBlur={(e) => {
                  const checkYear = e.target.value.slice(6, 10);
                  const currentYear = new Date().getFullYear();
                  if (checkYear > currentYear || checkYear < 1900)
                    setError('birthdate');
                }}
              />
            </Grid>
            <Grid xs={6} item container direction='column' mb={2}>
              <InputLabel classes={inputLabelClasses}>Email</InputLabel>
              <Input
                classes={classes}
                error={emailError}
                helperText={emailError ? 'Enter a valid email address' : ''}
                name='email'
                onChange={onChanger}
                value={data.email}
                onBlur={(e) => {
                  if (!validateEmail(e.target.value)) setError('email');
                }}
              />
            </Grid>
            <Grid xs={6} item container direction='column ' mb={2}>
              <InputLabel classes={inputLabelClasses}>Phone</InputLabel>
              <Input
                placeholder="(__) __ __"
                classes={classes}
                name='mobile_phone'
                error={phoneError}
                helperText={phoneError ? 'Enter a valid phone number' : ''}
                onChange={(e) => {
                  // Does not allow mobile_phone number to be greater then 11
                  if (e.target.value.length < 20)
                    if (isFinite(e.nativeEvent.data)) onChanger(e);
                }}
                value={data.mobile_phone}
                onKeyUp={(e) => {
                  // Does not allow mobile_phone number to be greater then 11
                  // On key up to detect backspace on last value +1
                  if (e.target.value.length < 20)
                    if (isNaN(isFinite(e.key)) && e.keyCode === 8) onChanger(e);
                }}
                onBlur={(e) => {
                  if (data.mobile_phone.length !== 19) setError('mobile_phone');
                }}
              />
            </Grid>

            <Grid xs={6} item container direction='column ' mb={2}>
              <InputLabel classes={inputLabelClasses}>Gender</InputLabel>
              <Grid container item direction='row'>
                <Grid xs={6} item>
                  <ToggleButton
                    sx={{
                      width: '80%',
                      fontFamily: 'Work Sans, Serif',
                      textTransform: 'capitalize',
                    }}
                    value="M"
                    selected={data.sex === 'M'}
                    onChange={() => {
                      setData((preVal) => ({ ...preVal, sex: 'M' }));
                    }}
                  >
                    Male
                  </ToggleButton>
                </Grid>
                <Grid xs={6} item>
                  <ToggleButton
                    sx={{
                      width: '80%',
                      fontFamily: 'Work Sans, Serif',
                      textTransform: 'capitalize',
                    }}
                    value="F"
                    selected={data.sex === 'F'}
                    onChange={() => {
                      setData((preVal) => ({ ...preVal, sex: 'F' }));
                    }}
                  >
                    Female
                  </ToggleButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid xs={12} item container mt={3}>
              <Button
                title="Update"
                onClick={() => {
                  updateUser();
                }}
                disabled={
                  // Button will be disabled if any of following not satisfied
                  emailError ||
                  phoneError ||
                  dobError ||
                  data.mobile_phone.replace(/\s/g, '').length < 15 ||
                  !data.first_name.replace(/\s/g, '').length ||
                  !data.email.replace(/\s/g, '').length ||
                  !data.last_name.replace(/\s/g, '').length ||
                  !data.birthdate.replace(/\s/g, '').length
                }
                secondary={
                  emailError ||
                  phoneError ||
                  dobError ||
                  data.mobile_phone.replace(/\s/g, '').length < 15 ||
                  !data.first_name.replace(/\s/g, '').length ||
                  !data.email.replace(/\s/g, '').length ||
                  !data.last_name.replace(/\s/g, '').length ||
                  !data.birthdate.replace(/\s/g, '').length
                }
              />
            </Grid>
          </Grid>
        </div>
      </ModalCustom>
    </Grid>
  );
}
