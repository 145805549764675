import { WS_URL } from 'constant';
import { Tokens } from 'utils/Tokens';

const INCCORECT_WS_STATES = [2, 3];

const getCommitData = (time: number) =>
    JSON.stringify({
        action: 'commit_time',
        tracked_time: time,
        request_id: new Date().getTime(),
    });

const timerWebSockets: Partial<
    Record<string, { patient: string; lastTimeValue: number; socket: WebSocket }>
> = {};

export const openTimeSocket = (type: string, patient: string) =>
    new Promise<void>((resolve, reject) => {
        let targetSocketItem = timerWebSockets[type];
        if (!targetSocketItem) {
            const accessToken = Tokens.getAccess();
            const socketUrl = `${WS_URL}/ws/tracker/${patient}/?bearer=${accessToken}&type=${type}`;
            targetSocketItem = {
                patient,
                lastTimeValue: 0,
                socket: new WebSocket(socketUrl),
            };
            timerWebSockets[type] = targetSocketItem;
        }
        targetSocketItem.socket.addEventListener('open', () => {
            resolve();
        });
        targetSocketItem.socket.addEventListener('error', () => {
            reject();
        });
    });

export const socketCommitTime = (type: string, time: number) => {
    const targetSocketItem = timerWebSockets[type];
    if (!targetSocketItem) throw new Error('WEB_SOCKET_IS_NOT_OPENED');
    if (INCCORECT_WS_STATES.includes(targetSocketItem.socket.readyState))
        throw new Error('WEB_SOCKET_IS_NOT_VALID');

    if (targetSocketItem.lastTimeValue !== time) {
        targetSocketItem.socket.send(getCommitData(time));
    }

    targetSocketItem.lastTimeValue = time;
};

export const closeTimeSocket = (type: string, time?: number) => {
    const targetSocketItem = timerWebSockets[type];
    if (time && targetSocketItem) {
        targetSocketItem.socket.send(getCommitData(time));
    }
    if (targetSocketItem) {
        targetSocketItem.socket.close();
        delete timerWebSockets[type];
    }
};
