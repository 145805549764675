import axiosFetch from 'redux/config/axios';

export const getMedicationEntries = async (id) => {
  let res;
  if (id) res = await axiosFetch.get(`/medication-entries/?patient_id=${id}`);
  else res = await axiosFetch.get('/medication-entries/');
  return res;
};

export const getSingleMedicationEntries = async (id) => {
  const res = await axiosFetch.get(`/medication-entries/${id}/`);
  return res;
};

export const postMedicationEntries = async (data) => {
  const res = await axiosFetch.post('/medication-entries/', data);
  return res;
};

export const putMedicationEntries = async (id, data) => {
  const res = await axiosFetch.put(`/medication-entries/${id}/`, data);
  return res;
};

export const patchMedicationEntries = async (id, data) => {
  const res = await axiosFetch.patch(`/medication-entries/${id}/`, data);
  return res;
};

export const deleteMedicationEntries = async (id) => {
  const res = await axiosFetch.delete(`/medication-entries/${id}/`);
  return res;
};
