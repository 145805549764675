import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { updateDiabetes } from 'redux/actions/diabetesActions';
import { TIME_ARRAY } from 'redux/constants/timeFrameConstants';
import gluoseTableStyles from 'styles/components/organisms/diabetesUI/GlucoseTableStyles';
import { useDiabetes } from 'redux/reusables/diabetes';

export default function RangeTable(props) {
  const classes = gluoseTableStyles();
  const dispatch = useDispatch();
  const { diabetes } = useDiabetes();
  React.useEffect(() => {}, [diabetes]);

  const warningLimitMax = 80;
  const warningLimitMin = 60;

  const [refresh, setRefresh] = React.useState(true);

  React.useEffect(() => {
    // Here we map the timeframes into redux for saving it later on
    const doseData = props.doseData || {};

    if (props.range) {
      TIME_ARRAY.map((time, index) => {
        let rangeMin = '';
        let rangeMax = '';
        let dose = 0;
        if (props.range.includes('Less than ')) {
          rangeMin = 0;
          rangeMax = Number(props.range.split('Less than ')[1] - 1);
        } else if (props.range.includes('>')) {
          return false;
        } else {
          rangeMin = Number(props.range.split('-')[0]);
          rangeMax = Number(props.range.split('-')[1]);

          dose = doseData[`column${index + 2}`] || 0;
        }
        dispatch(
          updateDiabetes({
            type: `${time}Ranges`,
            value: diabetes[`${time}Ranges`]
              ? [
                  ...diabetes[`${time}Ranges`],
                  {
                    rangeMin,
                    rangeMax,
                    dose,
                  },
                ]
              : [],
          })
        );
        return true;
      });
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    TIME_ARRAY.map((time, index) => {
      dispatch(
        updateDiabetes({
          type: `${time}Ranges`,
          value: [],
        })
      );
      return true;
    });
    setTimeout(() => {
      setRefresh(!refresh);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.range,
    diabetes.dose_wake,
    diabetes.dose_breakfast,
    diabetes.dose_lunch,
    diabetes.dose_dinner,
    diabetes.dose_sleep,
    diabetes.corr_wake,
    diabetes.corr_breakfast,
    diabetes.corr_lunch,
    diabetes.corr_dinner,
    diabetes.corr_sleep,
    diabetes.hypo,
    diabetes.goal,
    diabetes.far,
    diabetes.danger,
    diabetes.corr_for_every,
    diabetes.corr_above,
    diabetes.corr_take,
  ]);

  const color = props.checkIndex % 2 == 0 ? '#FAFBFE' : '';
  return (
    <Grid
      container
      // spacing={2}
      alignItems='center'
      p={2}
      style={{ borderRadius: '8px', background: color }}
    >
      <Grid container item xs={2} justifyContent='start'>
        <Typography variant='h6' component='p' className={classes.firstColumn}>
          {props.range} mg/dl
        </Typography>
      </Grid>

      <Grid container item xs={2} alignItems='center' justifyContent='center'>
        <Typography
          variant='h6'
          component='p'
          className={classes.calculatedDose}
          sx={
            props.column2
              ? diabetes.dose_wake >= warningLimitMin
                ? Number(props.column2.toString().split('+')[0]) +
                    Number(props.column2.toString().split('+')[1]) >
                    warningLimitMax || Number(props.column2) > warningLimitMax
                  ? { color: 'red !important' }
                  : {}
                : {}
              : {}
          }
        >
          {diabetes.switch_wake
            ? !diabetes.dose_wake || !diabetes.dose_wake_selected
              ? '-'
              : props.column2
            : ''}
        </Typography>
      </Grid>
      <Grid container item xs={2} alignItems='center' justifyContent='center'>
        <Typography
          variant='h6'
          component='p'
          className={classes.calculatedDose}
          sx={
            props.column3
              ? diabetes.dose_breakfast >= warningLimitMin
                ? Number(props.column3.toString().split('+')[0]) +
                    Number(props.column3.toString().split('+')[1]) >
                    warningLimitMax || Number(props.column3) > warningLimitMax
                  ? { color: 'red !important' }
                  : {}
                : {}
              : {}
          }
        >
          {diabetes.switch_breakfast
            ? !diabetes.dose_breakfast && !diabetes.dose_breakfast_selected
              ? '-'
              : props.column3
            : ''}
        </Typography>
      </Grid>
      <Grid container item xs={2} alignItems='center' justifyContent='center'>
        <Typography
          variant='h6'
          component='p'
          className={classes.calculatedDose}
          sx={
            props.column4
              ? diabetes.dose_lunch >= warningLimitMin
                ? Number(props.column4.toString().split('+')[0]) +
                    Number(props.column4.toString().split('+')[1]) >
                    warningLimitMax || Number(props.column4) > warningLimitMax
                  ? { color: 'red !important' }
                  : {}
                : {}
              : {}
          }
        >
          {diabetes.switch_lunch
            ? !diabetes.dose_lunch || !diabetes.dose_lunch_selected
              ? '-'
              : props.column4
            : ''}
        </Typography>
      </Grid>
      <Grid container item xs={2} alignItems='center' justifyContent='center'>
        {' '}
        <Typography
          variant='h6'
          component='p'
          className={classes.calculatedDose}
          sx={
            props.column5
              ? diabetes.dose_dinner >= warningLimitMin
                ? Number(props.column5.toString().split('+')[0]) +
                    Number(props.column5.toString().split('+')[1]) >
                    warningLimitMax || Number(props.column5) > warningLimitMax
                  ? { color: 'red !important' }
                  : {}
                : {}
              : {}
          }
        >
          {diabetes.switch_dinner
            ? !diabetes.dose_dinner || !diabetes.dose_dinner_selected
              ? '-'
              : props.column5
            : ''}
        </Typography>
      </Grid>
      <Grid container item xs={2} alignItems='center' justifyContent='center'>
        <Typography
          variant='h6'
          component='p'
          className={classes.calculatedDose}
          sx={
            props.column6
              ? diabetes.dose_sleep >= warningLimitMin
                ? Number(props.column6.toString().split('+')[0]) +
                    Number(props.column6.toString().split('+')[1]) >
                    warningLimitMax || Number(props.column6) > warningLimitMax
                  ? { color: 'red !important' }
                  : {}
                : {}
              : {}
          }
        >
          {diabetes.switch_sleep
            ? !diabetes.dose_sleep || !diabetes.dose_sleep_selected
              ? '-'
              : props.column6
            : ''}
        </Typography>
      </Grid>
    </Grid>
  );
}
