import React from 'react';
import Button from 'components/atoms/Button';
import Select from 'components/atoms/Select';
import { MenuItem , Grid } from '@mui/material';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  saveTimeframes,
  showTimeFrames,
  updateTimeframes,
} from 'redux/actions/timeFrameActions';
import { DEFAULT_TIMES } from 'redux/constants/timeFrameConstants';

// To modify select fields
const useStyles = makeStyles({
  root: {
    height: '40px',
    width: '318px',
    borderRadius: '8px !important',
    padding: '8px,72px,8px,16px',
    background: '#FCFDFE',
    border: '1px solid #D8D8DC !important',
    '& .MuiSelect-icon': {
      display: 'none',
    },
  },
  dropdownStyle: {
    fontSize: '15px',
    color: '#7E8186',
    width: '318px',
    maxHeight: '450px',
    fontWeight: 'bold !important',
    '& .MuiSvgIcon-root': {
      display: 'none',
    },
    '& .Mui-selected': {
      width: '318px',
    },
  },
});

export default function MedicationBuilder() {
  const classes = useStyles();

  // Redux
  const dispatch = useDispatch();
  const { pk } = useParams();

  // Set default values
  const timeFrameUpdate = useSelector((state) => state.timeFrameUpdate);
  const { timeframes, loading } = timeFrameUpdate;
  // For selects

  // For start
  const [wakeSelectStart, setWakeSelectStart] = React.useState('06 : 00');
  const [breakfastSelectStart, setBreakfastSelectStart] =
    React.useState('09 : 00');
  const [lunchSelectStart, setLunchSelectStart] = React.useState('13 : 00');
  const [dinnerSelectStart, setDinnerSelectStart] = React.useState('17 : 00');
  const [sleepSelectStart, setSleepSelectStart] = React.useState('22 : 30');

  // For Stop
  const [wakeSelectStop, setWakeSelectStop] = React.useState('06 : 00');
  const [breakfastSelectStop, setBreakfastSelectStop] =
    React.useState('09 : 00');
  const [lunchSelectStop, setLunchSelectStop] = React.useState('13 : 00');
  const [dinnerSelectStop, setDinnerSelectStop] = React.useState('17 : 00');
  const [sleepSelectStop, setSleepSelectStop] = React.useState('22 : 30');
  // For modal

  React.useEffect(() => {
    dispatch(showTimeFrames(pk));
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (timeframes) {
      // Start
      setWakeSelectStart(timeframes[0]?.start || 0);
      setBreakfastSelectStart(timeframes[1]?.start || 0);
      setLunchSelectStart(timeframes[2]?.start || 0);
      setDinnerSelectStart(timeframes[3]?.start || 0);
      setSleepSelectStart(timeframes[4]?.start || 0);
      // Stop
      setWakeSelectStop(timeframes[0]?.stop || 0);
      setBreakfastSelectStop(timeframes[1]?.stop || 0);
      setLunchSelectStop(timeframes[2]?.stop || 0);
      setDinnerSelectStop(timeframes[3]?.stop || 0);
      setSleepSelectStop(timeframes[4]?.stop || 0);
    }

    // eslint-disable-next-line
  }, [timeframes]);

  // Detect wake value change
  const onChangerwakeSelect = (event, type) => {
    if (type === 'start') {
      dispatch(
        updateTimeframes({
          type: 'start',
          index: '0',
          value: event.target.value,
        })
      );
      setWakeSelectStart(event.target.value);
    } else {
      dispatch(
        updateTimeframes({
          type: 'stop',
          index: '0',
          value: event.target.value,
        })
      );
      setWakeSelectStop(event.target.value);
    }
  };

  // Detect breakfast value change
  const onChangerbreakfastSelect = (event, type) => {
    if (type === 'start') {
      dispatch(
        updateTimeframes({
          type: 'start',
          index: '1',
          value: event.target.value,
        })
      );
      setBreakfastSelectStart(event.target.value);
    } else {
      dispatch(
        updateTimeframes({
          type: 'stop',
          index: '1',
          value: event.target.value,
        })
      );
      setBreakfastSelectStop(event.target.value);
    }
  };

  // Detect lunch value change
  const onChangerlunchSelect = (event, type) => {
    if (type === 'start') {
      dispatch(
        updateTimeframes({
          type: 'start',
          index: '2',
          value: event.target.value,
        })
      );
      setLunchSelectStart(event.target.value);
    } else {
      dispatch(
        updateTimeframes({
          type: 'stop',
          index: '2',
          value: event.target.value,
        })
      );
      setLunchSelectStop(event.target.value);
    }
  };

  // Detect dinner value change
  const onChangerdinnerSelect = (event, type) => {
    if (type === 'start') {
      dispatch(
        updateTimeframes({
          type: 'start',
          index: '3',
          value: event.target.value,
        })
      );
      setDinnerSelectStart(event.target.value);
    } else {
      dispatch(
        updateTimeframes({
          type: 'stop',
          index: '3',
          value: event.target.value,
        })
      );
      setDinnerSelectStop(event.target.value);
    }
  };

  // Detect sleep value change
  const onChangersleepSelect = (event, type) => {
    if (type === 'start') {
      dispatch(
        updateTimeframes({
          type: 'start',
          index: '4',
          value: event.target.value,
        })
      );
      setSleepSelectStart(event.target.value);
    } else {
      dispatch(
        updateTimeframes({
          type: 'stop',
          index: '4',
          value: event.target.value,
        })
      );
      setSleepSelectStop(event.target.value);
    }
  };

  return (
    <div style={{ width: '1490px' }}>
      <Grid
        container
        p={3}
        sx={{
          width: '1490px',
          minHeight: '411px',
          background: 'white',
        }}
      >
        <Grid
          container
          item
          xs={12}
          pt={3}
          pb={1}
          mb={3}
          sx={{
            borderBottom: '1px solid #D4D5D7',
          }}
        >
          {/* HEadings here */}

          <Grid item xs={6}>
            <Typography
              sx={{
                color: '#7E8186',
                fontSize: '16px',
                fontWeight: '400',
                fontFamily: 'Work Sans, sans-serif',
                letterSpacing: '-0.31px',
              }}
              pl={4}
            >
              Timeframe name
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography
              sx={{
                color: '#7E8186',
                fontSize: '16px',
                fontWeight: '400',
                fontFamily: 'Work Sans, sans-serif',
                letterSpacing: '-0.31px',
              }}
            >
              From
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              sx={{
                color: '#7E8186',
                fontSize: '16px',
                fontWeight: '400',
                fontFamily: 'Work Sans, sans-serif',
                letterSpacing: '-0.31px',
              }}
            >
              To
            </Typography>
          </Grid>
        </Grid>

        {/* Data here */}

        <Grid container item xs={12}>
          <Grid
            xs={6}
            item
            container
            pl={4}
            sx={{ minHeight: '350px' }}
            direction='column'
            justifyContent='space-around'
          >
            {['Wake Up', 'Breakfast', 'Lunch', 'Dinner', 'Sleep'].map(
              (time) => (
                  <Typography
                    sx={{
                      // styleName: bold / body for web app;
                      fontFamily: 'Work Sans, Serif',
                      fontSize: '17px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '24px',
                      letterSpacing: '-0.43px',
                      textAlign: 'left',
                      color: ' #004680',
                    }}
                  >
                    {time}
                  </Typography>
                )
            )}
          </Grid>
          <Grid xs={6} item container>
            <Grid
              container
              item
              xs={6}
              sx={{ minHeight: '350px' }}
              direction='column'
              justifyContent='space-around'
            >
              {[
                'Wake Up',
                'Has Breakfast',
                'Has Lunch',
                'Has Dinner',
                'Sleeps',
              ].map((item, index) => (
                <Select
                  disableSelect
                  classes={classes}
                  onChange={(e) => {
                    index === 0
                      ? onChangerwakeSelect(e, 'start')
                      : index === 1
                      ? onChangerbreakfastSelect(e, 'start')
                      : index === 2
                      ? onChangerlunchSelect(e, 'start')
                      : index === 3
                      ? onChangerdinnerSelect(e, 'start')
                      : onChangersleepSelect(e, 'start');
                  }}
                  value={
                    index === 0
                      ? wakeSelectStart
                      : index === 1
                      ? breakfastSelectStart
                      : index === 2
                      ? lunchSelectStart
                      : index === 3
                      ? dinnerSelectStart
                      : sleepSelectStart
                  }
                  onOpen={() => {
                    const event = new KeyboardEvent('keypress', {
                      key: 'enter',
                    });
                  }}
                >
                  <MenuItem key={index} value={0}>
                    <Grid container item justifyContent='space-between'>
                      __ : __
                      <AccessTimeIcon sx={{ color: '#A9ABAF' }} />
                    </Grid>
                  </MenuItem>
                  {DEFAULT_TIMES.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item}
                        sx={{
                          fontSize: '15px',
                          color: '#7E8186',
                          fontWeight: 'bold',
                        }}
                        onClick={() => {
                        }}
                      >
                        <Grid container item justifyContent='space-between'>
                          {item}
                          <AccessTimeIcon sx={{ color: '#A9ABAF' }} />
                        </Grid>
                      </MenuItem>
                    ))}
                </Select>
              ))}
            </Grid>
            <Grid
              container
              item
              xs={6}
              sx={{ minHeight: '350px' }}
              direction='column'
              justifyContent='space-around'
            >
              {[
                'Wake Up',
                'Has Breakfast',
                'Has Lunch',
                'Has Dinner',
                'Sleeps',
              ].map((item, index) => (
                <Select
                  disableSelect
                  classes={classes}
                  onChange={(e) => {
                    index === 0
                      ? onChangerwakeSelect(e, 'stop')
                      : index === 1
                      ? onChangerbreakfastSelect(e, 'stop')
                      : index === 2
                      ? onChangerlunchSelect(e, 'stop')
                      : index === 3
                      ? onChangerdinnerSelect(e, 'stop')
                      : onChangersleepSelect(e, 'stop');
                  }}
                  value={
                    index === 0
                      ? wakeSelectStop
                      : index === 1
                      ? breakfastSelectStop
                      : index === 2
                      ? lunchSelectStop
                      : index === 3
                      ? dinnerSelectStop
                      : sleepSelectStop
                  }
                  onOpen={() => {
                    const event = new KeyboardEvent('keypress', {
                      key: 'enter',
                    });
                  }}
                >
                  <MenuItem key={index} value={0}>
                    <Grid container item justifyContent='space-between'>
                      __ : __
                      <AccessTimeIcon sx={{ color: '#A9ABAF' }} />
                    </Grid>
                  </MenuItem>
                  {DEFAULT_TIMES.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item}
                        sx={{
                          fontSize: '15px',
                          color: '#7E8186',
                          fontWeight: 'bold',
                        }}
                        onClick={() => {
                        }}
                      >
                        <Grid container item justifyContent='space-between'>
                          {item}
                          <AccessTimeIcon sx={{ color: '#A9ABAF' }} />
                        </Grid>
                      </MenuItem>
                    ))}
                </Select>
              ))}
            </Grid>
          </Grid>
        </Grid>

        {/* <Typography
            ml={6}
            mb={2}
            sx={{
              color: '#004680',
              fontFamily: 'Work Sans, sans-serif',
              fontSize: '18px',
              fontWeight: '500',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            Cancel
          </Typography> */}
        <Grid
          container
          item
          xs={12}
          justifyContent='end'
          alignItems="center"
          mt={5}
          sx={{ marginLeft: '-20px' }}
        >
          {loading ? (
            <Grid
              sx={{ width: '240px', height: '54px', marginBottom: '20px' }}
              item
              container
              justifyContent='center'
              alignItems='center'
            >
              <CircularProgress />
            </Grid>
          ) : (
            <Button
              title='Save'
              sx={{
                borderRadius: '8px !important',
                width: '240px !important',
                height: '54px !important',
                marginBottom: '20px',
                fontFamily: 'Work Sans, sans-serif',
                fontSize: '18px',
                fontWeight: '500',
                color: '#A9ABAF',
              }}
              onClick={() => dispatch(saveTimeframes())}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
}
