import { makeStyles } from '@mui/styles';

export const textStyle = makeStyles({
  root: {
    '& .MuiInputBase-root': {
      height: '40px',
      width: '89px',
      fontFamily: '\'Work Sans\', sans-serif !important',
      //   left: '119px',
      //   top: '0px',
      background: '#FCFDFE',
      borderRadius: '8px',
      fontSize: '17px',
    },

    '& .MuiInputBase-input-MuiOutlinedInput-input': {
      borderRadius: '8px',
      fontSize: '17px',
    },

    '& .MuiFormHelperText-root': {
      marginTop: '8px',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      background: '#F1F3F7',
    },
  },
});
