import * as React from 'react';
import { useState , useEffect , useReducer } from 'react';
import { useNavigate , Link } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import 'styles/components/templates/loginUi/index.scss';
import { API_URL } from 'constant';

import { Tokens } from 'redux/config/getToken';

import BackdropCustom from 'components/atoms/Backdrop';
import Button from 'components/atoms/Button';
import axiosFetch from 'redux/config/axios';

export default function SignIn() {
  useEffect(() => {
    console.clear();
    const validate = Tokens.getAccess();
    // if (validate) {
    //   history.push('/dashboard');
    // }
  }, []);
  const navigate = useNavigate();
  const theme = createTheme();
  const [islogin, setIsLogin] = useState(false);
  const [loading, setLoading] = useState(false);

  const [LoginError, setLoginError] = useState(false);
  const [resetData, setResetData] = useState({
    old_password: '',
    password: '',
    password2: '',
  });
  // const [disableBtn, setDisableBtn] = useState(true);
  const [password, setPasswordErr] = useState('');

  //   validation area
  const reducer = (state, action) => {
    if (action.type === 'password') {
      if (resetData.password !== resetData.password2) {
        return setPasswordErr('Passwords not matched');
      }
        return setPasswordErr('');

    }
  };
  const [state, dispatch] = useReducer(reducer, '');
  // valdation area over
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    // Validate Api
    try {
      const res = await axiosFetch.put(`${API_URL}/api/change-password`, {
        old_password: resetData.old_password,
        password: resetData.password,
        password2: resetData.password2,
      });

      event.preventDefault();
      if (res.status === 200) {
        Tokens.removeRefresh();
        Tokens.removeAccess();
        navigate('/signin');
      }
      setLoading(false);
    } catch (err) {
      setLoginError(true);
      setLoading(false);
    }
  };

  function handleChange2(event) {
    const data = event.target.value;

    setLoginError(false);
    dispatch({ type: 'password' });
    setResetData((preVal) => ({ ...preVal, [event.target.name]: data }));
  }
  return (
    <div className="loginSection">
      <ThemeProvider theme={theme} style={{ backgroundColor: 'red' }}>
        <Container component='main'>
          <BackdropCustom open={loading} />
          <CssBaseline />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            className="loginBox"
          >
            <img
              src='/images/PrecinaLogo.png'
              style={{ width: '130px', height: '40px', marginBottom: '30px' }}
              alt='logo here'
            />

            <Box
              component='form'
              onSubmit={(e) => handleSubmit(e)}
              noValidate
              sx={{ mt: 1 }}
              style={{ display: 'block', width: '100%' }}
            >
              <Typography
                variant='h5'
                component='h5'
                style={{
                  fontFamily: 'Comfortaa',
                  fontWeight: '700!important',
                  fontSize: '22px!important',
                  lineHeight: ' 48px',
                  letterSpacing: ' 0.35px',
                  color: '#282D36',
                  marginBottom: '25px',
                }}
              >
                Reset Password
              </Typography>
              <Typography className="labeltxt">Old Password</Typography>
              <TextField
                margin='normal'
                required
                fullWidth
                type='password'
                name='old_password'
                placeholder='Enter your old password'
                value={resetData.old_password}
                onChange={(e) => handleChange2(e)}
                className="inputfield"
              />
              <div style={{ textAlign: '-webkit-right' }}>
                <p style={{ color: 'red' }}>
                  <b>{state}</b>
                </p>
              </div>
              <Typography className="labeltxt" style={{ marginTop: '9px' }}>
                New Password
              </Typography>
              <TextField
                margin='normal'
                fullWidth
                name='password'
                placeholder='Enter New Password'
                type='password'
                id='password'
                autoComplete='current-password'
                value={resetData.password}
                onChange={(e) => handleChange2(e)}
                className="inputfield"
                onBlur={() => dispatch({ type: 'password' })}
              />
              <Typography className="labeltxt" style={{ marginTop: '9px' }}>
                Re-Enter New Password
              </Typography>
              <TextField
                margin='normal'
                fullWidth
                name='password2'
                placeholder='Re-Enter New Password'
                type='password'
                id='password'
                autoComplete='current-password'
                value={resetData.password2}
                onChange={(e) => handleChange2(e)}
                className="inputfield"
                onBlur={() => dispatch({ type: 'password' })}
              />
              <div style={{ textAlign: '-webkit-right' }}>
                <p style={{ color: 'red' }}>
                  <b>{password}</b>
                </p>
              </div>
              <Grid container>
                {islogin ? (
                  ''
                ) : (
                  <div>
                    {LoginError ? (
                      <p style={{ color: 'red' }}>
                        <b>Old password invalid</b>
                      </p>
                    ) : (
                      <div />
                    )}
                  </div>
                )}
                <Button
                  type='submit'
                  //   fullWidth
                  //   variant='contained'
                  sx={{ mt: 3, mb: 2, width: '100%' }}
                  className="Button"
                  disabled={password}
                  secondary={password}
                  title='Submit'
                />
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
}
