import React from 'react';
import { Grid, Typography } from '@mui/material';
import SectionTitle from 'components/atoms/PatientChart/SectionTitle';
import { capitalize } from 'redux/actions/helperFunctions';
import { usePatient } from 'redux/reusables/patients';

export default function CurrentMedications() {
  const { patient } = usePatient();
  const [medData, setMedData] = React.useState([]);
  React.useEffect(() => {
    if (patient.medData) setMedData(patient.medData);
  }, [patient.medData]);


  const getMedName = (medDataItem) => {
    if(medDataItem.nickname) return medDataItem.nickname;
    if(medDataItem.medName) return capitalize(medDataItem.medName);

    return medDataItem.medName;
  }

  return (
    <div
      style={{
        maxWidth: '676px',
        minHeight: '352px',
        borderRadius: '16px',
        background: 'white',
      }}
      className='overview-sections'
    >
      <Grid container  p={3}>
        <SectionTitle title='Current medications' tab={4} />
        <Grid
          container
          item
          pt={3}
          mb={1}
          sx={{ borderBottom: '1px solid #D4D5D7', paddingBottom: '15px' }}
          className='overview-headings'
        >
          {/* HEadings here */}
          <Grid item xs={7}>
            <Typography
              sx={{ color: '#7E8186', fontSize: '16px', fontWeight: '400' }}
            >
              Name
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              sx={{ color: '#7E8186', fontSize: '16px', fontWeight: '400' }}
            >
              Dose
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              sx={{
                color: '#7E8186',
                fontSize: '16px',
                fontWeight: '400',
                textAlign: 'center',
              }}
            >
              Freq
            </Typography>
          </Grid>
        </Grid>

        {/* Data here */}
        {medData
          ? medData.map((data, index) => (
            <Grid
              container
              item
              pt={3}
              alignItems='center'
              key={index}
              className='populated'
            >
              <Grid item xs={7} zeroMinWidth pr={1}>
                <Typography
                  sx={{
                    overflowWrap: 'break-word',
                  }}
                  variant='p'
                >
                  {getMedName(data)}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className='dose'>
                  {data.dose} {data.units}
                </Typography>
              </Grid>
              <Grid item container xs={3} justifyContent='center'>
                <Typography
                  sx={{
                    padding: '4px 8px',
                    background:
                        data.frequency === 'Once a day'
                          ? '#E1F3E5'
                          : data.frequency == 'Twice a day'
                            ? '#FDEDDC'
                            : data.frequency == 'Three times a day'
                              ? '#CCDAE6'
                              : '',
                    borderRadius: '4px',
                  }}
                >
                  {data.frequency}
                </Typography>
              </Grid>
            </Grid>
          ))
          : ''}
      </Grid>
    </div>
  );
}
