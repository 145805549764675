export const HYPERTENSION_DETAILS_REQUEST = 'HYPERTENSION_DETAILS_REQUEST';
export const HYPERTENSION_DETAILS_SUCCESS = 'HYPERTENSION_DETAILS_SUCCESS';
export const HYPERTENSION_DETAILS_FAIL = 'HYPERTENSION_DETAILS_FAIL';

export const HYPERTENSION_ORIGINAL_REQUEST = 'HYPERTENSION_ORIGINAL_REQUEST';
export const HYPERTENSION_ORIGINAL_SUCCESS = 'HYPERTENSION_ORIGINAL_SUCCESS';
export const HYPERTENSION_ORIGINAL_FAIL = 'HYPERTENSION_ORIGINAL_FAIL';

export const HYPERTENSION_UPDATE_REQUEST = 'HYPERTENSION_UPDATE_REQUEST';
export const HYPERTENSION_UPDATE_SUCCESS = 'HYPERTENSION_UPDATE_SUCCESS';
export const HYPERTENSION_UPDATE_FAIL = 'HYPERTENSION_UPDATE_FAIL';

export const HYPERTENSION_SAVE_REQUEST = 'HYPERTENSION_SAVE_REQUEST';
export const HYPERTENSION_SAVE_SUCCESS = 'HYPERTENSION_SAVE_SUCCESS';
export const HYPERTENSION_SAVE_FAIL = 'HYPERTENSION_SAVE_FAIL';

export const HYPERTENSION_DETAILS_RESET = 'HYPERTENSION_DETAILS_RESET';

export const HYPERTENSION_UPDATE = 'HYPERTENSION_UPDATE';
export const HYPERTENSION_UPDATE_FULL = 'HYPERTENSION_UPDATE_FULL';
