import { getPatients } from 'redux/proxies/patients';
import axiosFetch from 'redux/config/axios';
import {
  PATIENT_LIST_REQUEST,
  PATIENT_LIST_SUCCESS,
  PATIENT_LIST_FAIL,
} from '../constants/dashboardConstants';
// Function to show diabetes api content

export const listPatients = () => async (dispatch) => {
  try {
    dispatch({ type: PATIENT_LIST_REQUEST });

    const res = await getPatients();

    // For chat data
    const chatRes = await axiosFetch.get('/chat/');
    let check = false;
    const newPatients = [];
    res.data.map((patient) => {
      check = false;
      chatRes?.data.map((chat) => {
        chat?.participants.map((user) => {
          if (user.pk === patient.pk) {
            newPatients.push({ ...patient, chatData: chat });
            check = true;
          }

          return true;
        });

        return true;
      });

      if (!check) {
        newPatients.push({ ...patient, chatData: null });
      }

      return true;
    });

    // move objects with chatData to the top of the list
    newPatients.sort((a, b) => {
      if (a.chatData && b.chatData) {
        return a.chatData?.unread_count > b.chatData?.unread_count ? -1 : 1;
      } if (a.chatData) {
        return -1;
      } if (b.chatData) {
        return 1;
      }
    });

    dispatch({ type: PATIENT_LIST_SUCCESS, payload: newPatients });
  } catch (error) {
    dispatch({ type: PATIENT_LIST_FAIL, payload: error });
    window.location.reload();
  }
};
