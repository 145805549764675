import * as React from 'react'
import { useTrackers } from 'redux/reusables/trackers'
import moment from 'moment'
import styles from './Tracker.module.scss'

export default function Tracker() {
  const trakers: any = useTrackers()
  const timeItems = Object.values(trakers)
    .filter((item: any) => !!item.time)
    .map(({ time }: any) => moment.utc(time, 'X').format('HH:mm:ss'))

  return (
    <div className={styles.root}>
      {timeItems.map((item) => (
        <span key={item} className={styles.item}>
          {item}
        </span>
      ))}
    </div>
  )
}
