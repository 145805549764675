import axiosFetch from 'redux/config/axios';

export const getEncountersTexts = async (id) => {
  let res;
  if (id) res = await axiosFetch.get(`/encounters-texts/?patient_id=${id}`);
  else res = await axiosFetch.get('/encounters-texts/');

  return res;
};
export const getSingleEncountersTexts = async (id) => {
  const res = await axiosFetch.get(`/encounters-texts/${id}/`);
  return res;
};
export const postEncountersTexts = async (data) => {
  const res = await axiosFetch.post('/encounters-texts/', data);
  return res;
};

export const putEncountersTexts = async (id, data) => {
  const res = await axiosFetch.put(`/encounters-texts/${id}/`, data);
  return res;
};

export const patchEncountersTexts = async (id, data) => {
  const res = await axiosFetch.patch(`/encounters-texts/${id}/`, data);
  return res;
};

export const deleteEncountersTexts = async (id) => {
  const res = await axiosFetch.delete(`/encounters-texts/${id}/`);
  return res;
};
