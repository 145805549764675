import Cookies from 'universal-cookie';

class TokensController {
    cookies;

    constructor() {
        this.cookies = new Cookies();
    }

    getAccess = () => this.cookies.get('access_token');

    getRefresh = () => this.cookies.get('refresh_token');

    setAccess = (token: string) => {
        this.cookies.set('access_token', token, {
            path: '/',
        });
    };

    setRefresh = (token: string) => {
        this.cookies.set('refresh_token', token, {
            path: '/',
        });
    };

    removeRefresh = () => {
        this.cookies.remove('refresh_token');
    };

    removeAccess = () => {
        this.cookies.remove('access_token');
    };
}

export const Tokens = new TokensController();
