import { makeStyles } from '@mui/styles';

export const useStylesChat = makeStyles({
  table: {
    minWidth: 650,
  },
  chatSection: {
    position: 'absolute',
    width: '760',
    height: '837',
    left: '680',
    top: '0',
  },
  headBG: {
    backgroundColor: '#e0e0e0',
  },
  borderRight500: {
    borderRight: '1px solid #e0e0e0',
  },
  messageArea: {
    height: '70vh',
    overflowY: 'auto',
  },
  fonticon: {
    marginLeft: '1000px',
  },
  nameColor: {
    color: '#171c73',
    fontSize: '5px',
  },
  Color: {
    fontSize: '5px',
    backgroundColor: '',
  },
  logo: {
    fontSize: '5px',
    color: 'black',
  },
  chatName: {
    fontSize: '0.7em',
    color: '#004680',
  },
  messageColor: {
    whiteSpace: 'pre-line',
    fontSize: '1rem',
    background: '#fff',
    borderRadius: '0px',
    right: '0',
    top: '4px',
    padding: '0px 0px 0px',
    maxWidth: '100%',
    color: '#282D36',
    textAlign: 'left',
    '& span': {
      fontSize: '17px',
      fontFamily: 'Work Sans',
      fontWeight: '400',
      lineHeight: '24px',
      letterSpacing: '-0.43px',
      borderRadius: '16px',
      right: '0',
      top: '0px',
      padding: '0px 4px',
      maxWidth: '100%',
      color: '#282D36',
      textAlign: 'left',
    },
  },

  receiveMsg: {
    background: '#CCE6F7',
    borderRadius: '16px',
    right: '0',
    top: '4px',
    padding: '2px 8px',
    maxWidth: '100%',
    color: '#282D36',
    textAlign: 'right',
    '& span': {
      fontFamily: ' Work Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '24px',
      letterSpacing: '-0.4300000071525574px',
      fontSize: '17px',
      background: '#CCE6F7',
      borderRadius: '16px',
      right: '0',
      top: '4px',
      padding: '2px 8px',
      maxWidth: '100%',
      color: '#282D36',
    },
  },

  chatPanel: {
    width: '100%',
    bottom: '0',
    right: '0',
  },
  Avatarname: {
    '& span': {
      fontSize: '12px',
      color: '#004680',
      fontFamily: 'Work Sans',
      fontWeight: '600',
      lineHeight: '16px',
      letterSpacing: '0px',
    },
  },
  chatList: {
    overflowX: 'hidden',
    overflowY: 'scroll',
    maxHeight: '250px',
  },
  time: {
    fontSize: '12px',
    textAlign: 'right',

    '& span': {
      fontSize: '12px',
      //  backgroundColor: "green",
      color: '#A9ABAF',
      fontFamily: 'Work Sans',
      fontWeight: '400',
      fontStyle: 'normal',
      lineHeight: '16px',
      letterSpacing: '0px',
    },
  },
  chatInput: {
    borderRadius: '16px',
    background: '#FCFDFE',
    border: '1px solid #D8D8DC',
    padding: '4px',
    margin: '15px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  chat: {
    '& div:before': {
      display: 'none',
    },
  },
  sendButton: {
    backgroundColor: '#004680!important',
    borderRadius: '16px!important',
    height: '46px',
  },
  chatBox: {
    position: 'fixed',
    bottom: '0',
    right: '0',
    width: '380px',
  },
  avtarHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
