import {
  PATIENT_DATA_FAIL,
  PATIENT_DATA_SUCCESS,
  PATIENT_DATA_REQUEST,
  PATIENT_UPDATE_FAIL,
  PATIENT_UPDATE_SUCCESS,
  PATIENT_UPDATE_REQUEST,
  PATIENT_SAVE_FAIL,
  PATIENT_SAVE_SUCCESS,
  PATIENT_SAVE_REQUEST,
  PATIENT_DATA_RESET,
} from '../constants/patientChartConstants';

export const patientDataReducer = (state = { patient: {} }, action) => {
  switch (action.type) {
    case PATIENT_DATA_REQUEST:
      return { loading: true, patient: {} };
    case PATIENT_DATA_SUCCESS:
      return { loading: false, patient: action.payload };
    case PATIENT_DATA_FAIL:
      return { loading: false, error: action.payload };
    case PATIENT_DATA_RESET:
      return { loading: false, patient: {} };
    default:
      return state;
  }
};

export const patientUpdateReducer = (state = { patient: {} }, action) => {
  switch (action.type) {
    case PATIENT_UPDATE_REQUEST:
      return { loading: true, patient: state };
    case PATIENT_UPDATE_SUCCESS:
      return { loading: false, patient: action.payload };
    case PATIENT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const savePatientDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case PATIENT_SAVE_REQUEST:
      return { loading: true, patient: state };
    case PATIENT_SAVE_SUCCESS:
      return { loading: false, patient: action.payload };
    case PATIENT_SAVE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
