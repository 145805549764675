export const TIMER_START = 'TIMER_START';
export const TIMER_COMMIT = 'TIMER_COMMIT';
export const TIMER_STOP = 'TIMER_STOP';

export type ITimersActionType = typeof TIMER_START | typeof TIMER_COMMIT | typeof TIMER_STOP;

export const F2F_TIMER_TYPE = 'F2F';
export const RPM_TIMER_TYPE = 'RPM';

export type ITimersType = typeof F2F_TIMER_TYPE | typeof RPM_TIMER_TYPE;
