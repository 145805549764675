import React from 'react';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import './index.scss';

function BellNotification({ small, unread_count }) {
  return (
    <div
      className='bell-icon'
      style={
        small
          ? {
              width: '25px',
              height: '25px',
              marginTop: '0px',
            }
          : {}
      }
    >
      <NotificationsNoneOutlinedIcon
        sx={
          small
            ? {
                color: '#0084D5',
                width: '15px',
                height: '15px',
              }
            : {
                color: '#0084D5',
              }
        }
      />
      {unread_count > 0 ? (
        <div className='notification-badge'>{unread_count}</div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default BellNotification;
