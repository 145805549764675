import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import 'styles/components/atoms/Select/Select.scss';
import { useStyles } from 'styles/components/atoms/Select/SelectStyles.js';

export default function Hook(props) {
  const classes = useStyles(props);

  return (
    <Select
      labelId='demo-simple-select-helper-label'
      onOpen={props.onOpen}
      name={props.name}
      onFocus={props.onFocus}
      id='demo-simple-select-helper'
      value={props.value ? props.value : 0}
      onBlur={props.onBlur}
      onChange={props.onChange}
      className={
        props.classes ? `${props.classes.root} ${classes.root}` : classes.root
      }
      MenuProps={{
        classes: {
          paper: props.classes
            ? `${props.classes.dropdownStyle} ${classes.dropdownStyle}`
            : classes.dropdownStyle,
        },
      }}
      disabled={props.disabled}
    >
      {!props.disableSelect ? (
        <MenuItem value={0} className={classes.selectClass}>
          Select
        </MenuItem>
      ) : (
        false
      )}

      {props.children ? (
        props.children
      ) : (
        <MenuItem
          value={props.value}
          name={props.name}
          className={classes.selectClass}
        >
          {props.label}
        </MenuItem>
      )}
    </Select>
  );
}
