import 'styles/globals.scss';
import { Routes, Route, Navigate } from 'react-router-dom';

import Dashboard from 'components/templates/dashboardUi/Dashboard';
import Onboarding from 'components/templates/onboardingSectionUi/OnBoarding';
import PatientChart from 'components/templates/patientChartUi/PatientChart';
import { useDispatch } from 'react-redux';
import { authCheck } from 'redux/actions/authAction';
import React from 'react';
import SignIn from 'components/templates/loginUi/SignIn';
import { PrivateRoute } from 'components/PrivateRoute/PrivateRoute';
import Loginotp from 'components/templates/loginUi/Loginotp';
import ForgetPassword from 'components/templates/forgetPasswordUi/ForgetPassword';
import EncounterUpdate from 'components/templates/encountersUi/EncounterUpdate';
import Encounterread from 'components/templates/encountersUi/Encounterread';
import EncounterCreate from 'components/templates/encountersUi/EncounterCreate';
import ModalCustom from 'components/atoms/Modal';
import Button from 'components/atoms/Button';
import { Box, Grid, Typography } from '@mui/material';
import { useAuth } from 'redux/reusables/auth';
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_TOKEN } from 'constant';
import * as Sentry from '@sentry/react';

import Tracker from 'components/organisms/Tracker/Tracker';

mixpanel.init(MIXPANEL_TOKEN, {
    debug: true,
});

function App() {
    const dispatch = useDispatch();
    const { auth } = useAuth();
    const [authModalOpen, setAuthModalOpen] = React.useState(true);
    const [offline, setOffline] = React.useState(false);

    React.useEffect(() => {
        dispatch(authCheck());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (auth?.id) setAuthModalOpen(auth?.isAuthenticated);
    }, [auth]);

    const handleGoToSignin = () => {
        if (window.location.pathname === '/signin') {
            setAuthModalOpen(true);
        } else {
            window.location.href = '/signin';
        }
    };

    React.useEffect(() => {
        window.addEventListener('offline', () => setOffline(true));
        window.addEventListener('online', () => {
            setOffline(false);
        });
    }, []);

    return (
        <div>
            <ModalCustom open={!authModalOpen || offline}>
                <Box>
                    <Typography
                        id='modal-modal-title'
                        variant='h6'
                        component='h2'
                        sx={{ fontWeight: '700', fontSize: '28px' }}
                    >
                        {offline ? 'Connection Lost' : 'Session expired'}
                    </Typography>
                    <Typography
                        id='modal-modal-description'
                        sx={{ mt: 2, color: '#53575E', fontSize: '17px' }}
                    >
                        {offline
                            ? 'Your connection has been lost, kindly connect and retry.'
                            : `Your session has expired due to inactivity or security reasons.
            Please login into your account again.`}
                    </Typography>
                    <Grid container item xs={12} mt={2} justifyContent='center'>
                        {!offline && (
                            <Button
                                title='Login'
                                sx={{
                                    width: '180px',
                                    height: '40px',
                                    textTransform: 'capitalize',
                                    fontSize: '18px',
                                }}
                                onClick={() => {
                                    handleGoToSignin();
                                }}
                            />
                        )}
                    </Grid>
                </Box>
            </ModalCustom>
            <Routes>
                <Route path='/' exact element={<Navigate to='/dashboard' repalce />} />
                <Route path='/signin' exact element={<SignIn />} />
                <Route path='/forget-password' exact element={<ForgetPassword />} />

                <Route
                    path='/dashboard'
                    element={
                        <PrivateRoute>
                            <Dashboard />
                        </PrivateRoute>
                    }
                />
                <Route
                    path='/onboarding'
                    element={
                        <PrivateRoute>
                            <Onboarding />
                        </PrivateRoute>
                    }
                />

                <Route path='/patient-chart' exact element={<Navigate to='/dashboard' repalce />} />
                <Route
                    path='/patient-chart/:pk'
                    exact
                    element={<Navigate to='/dashboard' repalce />}
                />
                <Route
                    path='/patient-chart/:pk/:trackerType'
                    element={
                        <PrivateRoute>
                            <PatientChart />
                        </PrivateRoute>
                    }
                />
                <Route
                    path='/encounter-create/:pk'
                    element={
                        <PrivateRoute>
                            <EncounterCreate />
                        </PrivateRoute>
                    }
                />
                <Route
                    path='/encounter-update/:pk/:id'
                    element={
                        <PrivateRoute>
                            <EncounterUpdate />
                        </PrivateRoute>
                    }
                />
                <Route
                    path='/encounter-read/:pk/:id'
                    exact
                    element={
                        <PrivateRoute>
                            <Encounterread />
                        </PrivateRoute>
                    }
                />

                <Route path='/OTP' element={<Loginotp />} />
            </Routes>
            <Tracker />
        </div>
    );
}

export default Sentry.withProfiler(App);
