import * as React from 'react';
import ListItemsSelects from 'components/molecules/diabetesUi/ListItemsSelects';
import ListItems from 'components/molecules/diabetesUi/ListItems';
// Material UI imports
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import listStyles from 'styles/components/organisms/diabetesUI/ListStyles';
import { useDiabetes } from 'redux/reusables/diabetes';

export default function BoxSx(props) {
  const { loading, diabetes } = useDiabetes();
  const classes = listStyles();

  return loading ? (
    <CircularProgress />
  ) : (
    <Box
      className={classes.boxContainer}
      sx={{
        borderRight: '0px !important',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <ListItems
          listItem='For every'
          value={diabetes ? diabetes.corr_for_every : 1}
          name='corr_for_every'
        />
        <ListItems
          listItem='Above'
          value={
            diabetes
              ? diabetes.corr_above
                ? diabetes.corr_above
                : diabetes.goal
              : ''
          }
          name='corr_above'
        />

        <ListItemsSelects
          listItem='Take'
          value={1}
          data={[
            { value: 1, name: 'corr_take' },
            { value: 2, name: 'corr_take' },
            { value: 3, name: 'corr_take' },
          ]}
          name='corr_take'
        />
      </div>
    </Box>
  );
}
