/// ///////////////////////////////////Auth
export const TRACK_USER_SIGNED_IN = 'User signed in';
export const TRACK_USER_SIGNED_OUT = 'User signed out';
export const TRACK_USER_SIGNIN_FAILED = 'User signin failed';
export const TRACK_RESET_PASSWORD_SCREEN = 'Reset password screen opened';

/// ///////////////////////////////////onboarding
export const TRACK_PATIENT_CREATION_SUCCESS = 'Patient created successfully';
export const TRACK_PATIENT_CREATION_FAILURE = 'Patient creation failed';
export const TRACK_PATIENT_CREATION_OPENED = 'New Patient button clicked';

/// ///////////////////////////////////Dashboard
export const TRACK_PATIENT_OPENED = (openType) => `Patient Chart opened with ${openType}`;
export const PATIENT_SEARCHED = 'Patient Searched';

/// /////////////////////////////////////Patient Chart Overview
export const TRACK_TAB_SELECTION = (tab) => {
  switch (tab) {
    case 0:
      return 'Patient Chart Overview Opened';
    case 1:
      return 'Diabetes Builder Opened';
    case 2:
      return 'Hypertension Builder Opened';
    case 3:
      return 'Diagnoses Builder Opened';
    case 4:
      return 'Medications Builder Opened';
    case 5:
      return 'Encounters Notes Opened';
    case 6:
      return 'Timeframes Editor Opened';
    default:
      return 'Patient Chart Opened';
  }
};

export const TRACK_EDIT_CLICKED = (item) => `Editing ${item}`;

// Medication Builder
export const TRACK_MEDICATION_SAVED = 'Medication Saved';
export const TRACK_MEDICATION_DELETED = 'Medication Deleted';
export const TRACK_MEDICATION_LOGS_OPENED = 'Medication Logs Opened';
export const TRACK_MEDICATION_DOSE_CARD_OPENED = 'Medication Dose Card Opened';

// Diagnoses Builder
export const TRACK_DIAGNOSES_SAVED = 'Diagnoses Saved';
export const TRACK_DIAGNOSES_DELETED = 'Diagnoses Deleted';

// Hypetertension
export const TRACK_HYPERTENSION_SAVED = 'Hypetertension Saved';
export const TRACK_HYPERTENSION_SAVE_FAILURE = 'Hypetertension failed to save';

// Diabetes Editor
export const TRACK_DIABETES_SAVE_SUCCESS = 'Diabetes Saved';
export const TRACK_DIABETES_SAVE_FAILURE = 'Diabetes Save Failed';
export const TRACK_DIABETES_LOGS_OPENED = 'Diabetes Logs Opened';

// Encounters Notes
export const TRACK_ENCOUNTERS_NOTES_FETCHED_SUCCESS =
  'Encounters Notes List Fetched Success';
export const TRACK_ENCOUNTERS_NOTES_FETCHED_FAIL =
  'Encounters Notes List Fetched Fail';
export const TRACK_ENCOUNTERS_SAVE_SUCCESS = 'Encounters Saved';
export const TRACK_ENCOUNTERS_SAVE_FAILURE = 'Encounters Save Failed';
export const TRACK_ENCOUNTERS_NOTE_OPENED = 'Encounters Note Opened';
export const TRACK_ENCOUNTERS_NOTE_OPEN_FAILED =
  'Encounters Note failed to open';
export const TRACK_ENCOUNTERS_NOTE_EDITING = 'Encounters Note Editing';
export const TRACK_ENCOUNTERS_NOTE_EDITING_FAILED =
  'Encounters Note Editing Failed';
export const TRACK_ENCOUNTERS_NOTE_DELETED = 'Encounters Note Deleted';

// Timeframes
export const TRACK_TIMEFRAMES_SAVED = 'Timeframes Saved';
export const TRACK_TIMEFRAMES_SAVE_FAILURE = 'Timeframes Save Failed';


// Trackers
export const TRACK_TRACKER_STARTED = 'Tracker Started';
export const TRACK_TRACKER_STOPPED = 'Tracker Stopped';
export const TRACK_TRACKER_FAILED = 'Tracker Failed';
