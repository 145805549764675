import {
  STATE_LIST_REQUEST,
  STATE_LIST_SUCCESS,
  STATE_LIST_FAIL,
} from '../constants/settingsConstants';


const DEFAULT_SETTING_STATE = {
  states: [],
  isLoadingState: false,
}

export const settingsReducer = (state = DEFAULT_SETTING_STATE, action) => {
  switch (action.type) {
    case STATE_LIST_REQUEST:
      return { ...state, isLoadingState: true, states: [] };
    case STATE_LIST_SUCCESS:
      return { ...state, isLoadingState: false, states: action.payload };
    case STATE_LIST_FAIL:
      return { ...state, isLoadingState: false, states: [] };
    default:
      return state;
  }
};
