import {
  TIMER_START,
  TIMER_COMMIT,
  TIMER_STOP,
  ITimersActionType,
  ITimersType
} from '../constants/trakerConstants';

interface IActionPaylaod {
  type: ITimersType,
  time?: number,
}

interface IAction {
  type: ITimersActionType,
  payload: IActionPaylaod
}

type ITimersState = Partial<Record<ITimersType, number>>;

export const timersReducer = (state: ITimersState = {}, action: IAction) => {
  switch (action.type) {
    case TIMER_START:
      return { ...state, [action.payload.type]: { time: 0 }};
    case TIMER_COMMIT:
      return { ...state, [action.payload.type]: { time: action.payload.time }};
    case TIMER_STOP:
      const filteredTimers = { ...state };

      if (filteredTimers[action.payload.type]) {
        delete filteredTimers[action.payload.type];
      }
      return filteredTimers;
    default:
      return state;
  }
};
