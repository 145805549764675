import React from 'react';
import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';
import InputLabel from '@mui/material/InputLabel';
import { useDispatch, useSelector } from 'react-redux';
import { setOnboardingDetails } from 'redux/actions/onboardingActions';
import { Grid } from '@mui/material';
import { useOnboarding } from 'redux/reusables/onboarding';
import {
  getFormattedPhoneNum,
  validateEmail,
  getFormattedDate,
} from './functions';

export default function Step1(props) {
  // Redux
  const dispatch = useDispatch();
  const { onboarding } = useOnboarding();

  // Initial values set on load
  const [data, setData] = React.useState({
    fname: onboarding.fname ? onboarding.fname : '',
    lname: onboarding.lname ? onboarding.lname : '',
    email: onboarding.email ? onboarding.email : '',
    phone: onboarding.phone ? onboarding.phone : '+1',
    dob: onboarding.dob ? onboarding.dob : '',
  });
  const [phoneError, setPhoneError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [dobError, setDobError] = React.useState(false);

  const setError = (errorType) => {
    errorType === 'email' ? setEmailError(true) : setEmailError(emailError);
    errorType === 'phone' ? setPhoneError(true) : setPhoneError(phoneError);
    errorType === 'dob' ? setDobError(true) : setDobError(dobError);
  };

  const removeError = (errorType) => {
    errorType === 'email' ? setEmailError(false) : setEmailError(emailError);
    errorType === 'phone' ? setPhoneError(false) : setPhoneError(phoneError);
    errorType === 'dob' ? setDobError(false) : setDobError(dobError);
  };

  // Onchange handler for all inputs
  const onChanger = (event) => {
    // Clear validation error
    removeError(event.target.name);
    // Don't accept special characters for specific inputs except these
    if (event.target.name === 'fname' || event.target.name === 'lname') {
      if (
        event.nativeEvent.data
          ? !event.nativeEvent.data.search(/[$&+,:;=?@#|'<>.^*()%!-]/)
          : false
      ) {
        return false;
      }
    }

    // Dont accept special characters for email except @
    if (event.target.name === 'email') {
      if (
        event.nativeEvent.data
          ? !event.nativeEvent.data.search(/[$&,:;=?#|'<>^*()%!-]/)
          : false
      ) {
        return false;
      }
    }

    // Does not allow to remove +1 from number input
    if (
      (event.keyCode === 8 && data.phone.length === 1) ||
      data.phone.length === 0
    ) {
      setData((preValue) => ({
          ...preValue,
          [event.target.name]: '+1',
        }));
      return false;
    }

    // Set data if no exceptions
    setData((preValue) => ({
        ...preValue,
        [event.target.name]: event.target.value,
      }));
    // Set data on redux
    dispatch(
      setOnboardingDetails({
        type: event.target.name,
        value: event.target.value,
      })
    );

    // Checks if input provided is phone then format as required and save
    if (event.target.name === 'phone') {
      if (
        event.nativeEvent.inputType === 'deleteContentBackward' &&
        data.phone.length > 2
      ) {
        return false;
      }

      setData((preValue) => ({
          ...preValue,
          [event.target.name]: getFormattedPhoneNum(event.target.value),
        }));
      dispatch(
        setOnboardingDetails({
          type: event.target.name,
          value: getFormattedPhoneNum(event.target.value),
        })
      );
    }

    // Format date
    if (event.target.name === 'dob') {
      if (event.nativeEvent.inputType === 'deleteContentBackward') {
        return false;
      }
      setData((preValue) => ({
          ...preValue,
          [event.target.name]: getFormattedDate(event.target.value),
        }));
      dispatch(
        setOnboardingDetails({
          type: event.target.name,
          value: getFormattedDate(event.target.value),
        })
      );
    }
  };

  // Re render component on change in data
  // React.useEffect(() => {}, [onboarding]);
  React.useEffect(() => {
    if (!onboarding.userError)
      setData({
        fname: '',
        lname: '',
        email: '',
        phone: '+1',
        dob: '',
      });
  }, [onboarding.userError]);

  return (
    <div className='step1-onboarding'>
      <Grid xs={12} container sx={{ maxWidth: '560px' }} direction='column'>
        <Grid xs={12} item>
          <InputLabel
            sx={{
              marginLeft: '8px',
              marginBottom: '4px',
              fontSize: '15px',
              color: '#7E8186',
            }}
          >
            First Name
          </InputLabel>
          <Input
            sx={{ width: '560px !important', minHeight: '85px' }}
            name='fname'
            onChange={onChanger}
            value={onboarding.fname}
            onKeyUp={onChanger}
          />
        </Grid>
        <Grid xs={12} item container direction='column'>
          <InputLabel
            sx={{
              marginLeft: '8px',
              marginBottom: '4px',
              fontSize: '15px',
              color: '#7E8186',
            }}
          >
            Last Name
          </InputLabel>
          <Input
            sx={{ width: '560px !important', minHeight: '85px' }}
            name='lname'
            onChange={onChanger}
            value={onboarding.lname}
            onKeyUp={onChanger}
          />
        </Grid>
        <Grid xs={12} item container direction='column'>
          <InputLabel
            sx={{
              marginLeft: '8px',
              marginBottom: '4px',
              fontSize: '15px',
              color: '#7E8186',
            }}
          >
            Date of Birth
          </InputLabel>
          <Input
            sx={{
              width: '560px !important',
              minHeight: '85px',
            }}
            placeholder="MM/DD/YYYY"
            format="MM/DD/YY"
            name='dob'
            error={dobError}
            helperText={dobError ? 'Enter a valid date' : ''}
            type='text'
            onChange={(e) => {
              if (e.target.value.length < 11) {
                onChanger(e);
              }
            }}
            value={onboarding.dob}
            onBlur={(e) => {
              const checkYear = e.target.value.slice(6, 10);
              const currentYear = new Date().getFullYear();
              if (checkYear > currentYear || checkYear < 1900) setError('dob');
            }}
          />
        </Grid>
        <Grid xs={12} item container direction='column'>
          <InputLabel
            sx={{
              marginLeft: '8px',
              marginBottom: '4px',
              fontSize: '15px',
              color: '#7E8186',
            }}
          >
            Email
          </InputLabel>
          <Input
            sx={{
              width: '560px !important',
              minHeight: '85px',
            }}
            error={emailError}
            helperText={emailError ? 'Enter a valid email address' : ''}
            name='email'
            onChange={onChanger}
            value={onboarding.email}
            onBlur={(e) => {
              if (!validateEmail(e.target.value)) setError('email');
            }}
          />
        </Grid>
        <Grid xs={12} item container direction='column'>
          <InputLabel
            sx={{
              marginLeft: '8px',
              marginBottom: '4px',
              fontSize: '15px',
              color: '#7E8186',
            }}
          >
            Phone
          </InputLabel>
          <Input
            placeholder="(__) __ __"
            sx={{ width: '560px !important', minHeight: '85px' }}
            name='phone'
            error={phoneError}
            helperText={phoneError ? 'Enter a valid phone number' : ''}
            onChange={(e) => {
              // Does not allow phone number to be greater then 11
              if (e.target.value.length < 20)
                if (isFinite(e.nativeEvent.data)) onChanger(e);
            }}
            value={onboarding.phone}
            onKeyUp={(e) => {
              // Does not allow phone number to be greater then 11
              // On key up to detect backspace on last value +1
              if (e.target.value.length < 20)
                if (isNaN(isFinite(e.key)) && e.keyCode === 8) onChanger(e);
            }}
            onBlur={(e) => {
              if (data.phone.length !== 19) setError('phone');
            }}
          />
        </Grid>
        <Grid xs={12} item container mt={3}>
          <Button
            title="Next"
            onClick={() => {
              validateEmail(data.email)
                ? data.phone.length === 19
                  ? data.dob.length === 10
                    ? props.onClick()
                    : setError('dob')
                  : setError('phone')
                : setError('email');
            }}
            disabled={
              // Button will be disabled if any of following not satisfied
              emailError ||
              phoneError ||
              dobError ||
              data.phone.replace(/\s/g, '').length < 15 ||
              !data.fname.replace(/\s/g, '').length ||
              !data.email.replace(/\s/g, '').length ||
              !data.lname.replace(/\s/g, '').length ||
              !data.dob.replace(/\s/g, '').length
            }
            secondary={
              emailError ||
              phoneError ||
              dobError ||
              data.phone.replace(/\s/g, '').length < 15 ||
              !data.fname.replace(/\s/g, '').length ||
              !data.email.replace(/\s/g, '').length ||
              !data.lname.replace(/\s/g, '').length ||
              !data.dob.replace(/\s/g, '').length
            }
          />
        </Grid>
      </Grid>
    </div>
  );
}
