import {
  AUTH_DETAILS_FAIL,
  AUTH_DETAILS_SUCCESS,
  AUTH_DETAILS_REQUEST,
  AUTH_CHECK,
} from '../constants/authConstants';

export const authDetailsReducers = (state = { auth: {} }, action) => {
  switch (action.type) {
    case AUTH_DETAILS_REQUEST:
      return { loading: true, auth: {} };
    case AUTH_DETAILS_SUCCESS:
      return { loading: false, auth: action.payload };
    case AUTH_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case AUTH_CHECK:
      return {
        loading: false,
        auth: { ...state?.auth, isAuthenticated: action.payload },
      };
    default:
      return state;
  }
};
