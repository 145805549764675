import React from 'react';
import { Grid } from '@mui/material';
import SectionTitle from 'components/atoms/PatientChart/SectionTitle';
import { usePatient } from 'redux/reusables/patients';
import RichEditor from '../Editor/RichEditor';

export default function Allergies() {
  // Redux
  const { patient } = usePatient();

  // Default content
  const [content, setContent] = React.useState(
    '{"blocks":[{"key":"1k8j6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}'
  );

  // Change content on state change
  const patientCheck = patient.OTHER_PROVIDERS;
  React.useEffect(() => {
    if (patientCheck) {
      setContent(patientCheck);
    }
  }, [patientCheck]);
  return (
    <div
      style={{
        maxWidth: '396px',

        width: '100%',
        height: '352px',
        borderRadius: '16px',
        background: 'white',
      }}
    >
      <Grid container p={3} xs={12}>
        <SectionTitle
          label="Other Providers"
          title='Other Providers'
          name='OTHER_PROVIDERS'
          editable
          defaultValue={content}
        />
        <Grid
          container
          mt={1}
          sx={{
            maxHeight: '292px',
            background: 'white',
            overflowX: 'hidden',
          }}
          xs={12}
        >
          <RichEditor readOnly defaultValue={content} />
        </Grid>
      </Grid>
    </div>
  );
}
