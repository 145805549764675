import React from 'react';
import { CircularProgress, Grid, Typography , Tab } from '@mui/material';
// organism imports

import Footer from 'components/atoms/Footer';
// Redux imports
import { useDispatch } from 'react-redux';
import Settings from 'components/organisms/hypertensionUi/Settings';

import Tabs from 'components/atoms/Tabs';

import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import LogsTable from 'components/atoms/Hypertension/LogsTable';
import ModalCustom from 'components/atoms/Modal';
import Button from 'components/atoms/Button';
// import { saveDiabetes, showDiabetes } from 'redux/actions/diabetesActions';
// import { DIABETES_DETAILS_SUCCESS } from 'redux/constants/diabetesConstants';
import { useParams } from 'react-router-dom';
import BackdropCustom from 'components/atoms/Backdrop';
// import { useDiabetes, useDiabetesOrg } from 'redux/reusables/diabetes';
// import { useTimeframes } from 'redux/reusables/timeframes';
import { useSelector } from 'react-redux';
import { showHypertension } from 'redux/actions/hypertensionActions';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ overflowX: 'auto' }}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function Hypertension({ hideNav }) {
  const dispatch = useDispatch();
  const { pk } = useParams();

  const [valueTabs, setValueTabs] = React.useState(1);

  const [warningModal, setWarningModal] = React.useState(false);

  const handleChangeTabs = (event, newValue) => {
    setValueTabs(newValue);
  };

  React.useEffect(() => {
    dispatch(showHypertension(pk));
  }, []);

  const { hypertension, loading } = useSelector(
    (state) => state.hypertensionDetails
  );
  return (
    <div
      style={{
        overflowX: 'hidden',
        overflowY: 'hidden',
      }}
    >
      {loading || !hypertension?.patient ? (
        <div
          style={{
            display: 'flex',
            minHeight: '90vh',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <BackdropCustom open={loading} />
        </div>
      ) : false ? (
        <div
          style={{
            display: 'flex',
            minHeight: '80vh',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <h1>Please Reload</h1>
        </div>
      ) : true ? (
        true ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: hideNav ? '20px' : '100px',
              paddingTop: '15px',
              background: '#ffffff',
              borderRadius: '16px',
            }}
          >
            <Tabs value={valueTabs} onChange={handleChangeTabs}>
              <Tab
                label='Logs'
                sx={{
                  textTransform: 'initial !important',
                  fontSize: '16px',
                  color: '#7E8186',
                }}
              />
              <Tab
                label='Settings'
                sx={{
                  textTransform: 'initial !important',
                  fontSize: '16px',
                  color: '#7E8186',
                }}
              />
            </Tabs>
            <Grid container xs={12}>
              {/* Modal to handle save/discard changes */}
              <ModalCustom
                open={warningModal}
                handleClose={() => setWarningModal(false)}
              >
                <Grid container alignItems="center" justifyContent='center'>
                  <Grid item xs='12' mb={2} container justifyContent='center'>
                    <Typography sx={{ fontSize: '20px' }}>
                      There are unsaved changes.
                    </Typography>
                  </Grid>
                  <Grid xs='6' item container justifyContent='center'>
                    <Button
                      //   disabled={diabetes?.diabetesErrorSave}
                      //   secondary={diabetes?.diabetesErrorSave}
                      //   onClick={acceptWarning}
                      title='Save Changes'
                      sx={{ width: '170px', height: '55px !important' }}
                    />
                  </Grid>
                  <Grid xs='6' item container justifyContent='center'>
                    <Button
                      //   onClick={() => discardChanges()}
                      title='Discard Changes'
                      secondary
                      sx={{ width: '170px', height: '55px !important' }}
                    />
                  </Grid>
                </Grid>
              </ModalCustom>
              {/* Modal ends here */}

              <TabPanel value={valueTabs} index={0}>
                <LogsTable />
              </TabPanel>
              <TabPanel value={valueTabs} index={1}>
                <Settings />
              </TabPanel>
            </Grid>
          </div>
        ) : (
          <CircularProgress />
        )
      ) : (
        <CircularProgress />
      )}
      <Footer />
    </div>
  );
}

export default Hypertension;
