import {
  TIMEFRAME_DETAILS_FAIL,
  TIMEFRAME_DETAILS_SUCCESS,
  TIMEFRAME_DETAILS_REQUEST,
  TIMEFRAME_UPDATE_FAIL,
  TIMEFRAME_UPDATE_SUCCESS,
  TIMEFRAME_UPDATE_REQUEST,
  TIMEFRAME_SAVE_FAIL,
  TIMEFRAME_SAVE_SUCCESS,
  TIMEFRAME_SAVE_REQUEST,
  TIME_ARRAY_CAPITAL,
} from '../constants/timeFrameConstants';
import { getTimeframes, putTimeframes } from 'redux/proxies/timeframes';
import { trackMixPanel } from 'redux/proxies/mixpanel';
import {
  TRACK_TIMEFRAMES_SAVED,
  TRACK_TIMEFRAMES_SAVE_FAILURE,
} from 'redux/constants/mixPanel';

export const showTimeFrames = (patient_id) => async (dispatch) => {
  try {
    dispatch({ type: TIMEFRAME_DETAILS_REQUEST });

    const { data } = await getTimeframes(patient_id);
    const filtered = [...data];

    let newTimeframes = [];
    let updateTimeframes = [];

    TIME_ARRAY_CAPITAL.map((time) => {
      newTimeframes.push(filtered.filter((x) => x.name === time)[0]);
      return true;
    });
    newTimeframes.map((item) => {
      updateTimeframes.push({
        id: item.id,
        dose_card: item.dose_card,
        name: item.name,
        patient: item.patient,
        start: item?.start?.slice(0, 5)?.replace(':', ' : '),
        stop: item?.stop?.slice(0, 5)?.replace(':', ' : '),
      });
      return true;
    });

    dispatch({ type: TIMEFRAME_UPDATE_SUCCESS, payload: updateTimeframes });

    dispatch({ type: TIMEFRAME_DETAILS_SUCCESS, payload: newTimeframes });
  } catch (error) {
    dispatch({ type: TIMEFRAME_DETAILS_FAIL, payload: error });
  }
};

export const updateTimeframes = (details) => async (dispatch, getState) => {
  try {
    dispatch({ type: TIMEFRAME_UPDATE_REQUEST });

    const { timeFrameUpdate } = getState();
    const { timeframes } = timeFrameUpdate;

    let newTimeframes = timeframes.timeframes;
    newTimeframes[Number(details.index)] =
      details.type === 'start'
        ? {
            ...newTimeframes[Number(details.index)],
            start: details.value,
            stop: newTimeframes[Number(details.index)].stop,
          }
        : {
            ...newTimeframes[Number(details.index)],
            start: newTimeframes[Number(details.index)].start,
            stop: details.value,
          };

    dispatch({ type: TIMEFRAME_UPDATE_SUCCESS, payload: newTimeframes });
  } catch (error) {
    dispatch({ type: TIMEFRAME_UPDATE_FAIL, payload: error });
  }
};

export const saveTimeframes = (details) => async (dispatch, getState) => {
  try {
    dispatch({ type: TIMEFRAME_SAVE_REQUEST });

    const { timeFrameUpdate } = getState();
    const { timeframes } = timeFrameUpdate;

    let newTimeframes = [];

    timeframes.map((item) => {
      newTimeframes.push({
        name: item.name,
        patient: item.patient,
        start: item?.start?.replace(' : ', ':'),
        stop: item?.stop?.replace(' : ', ':'),
      });
      return true;
    });

    let currentTimeframes = [];
    timeframes.map((item) => {
      currentTimeframes.push({
        ...item,
        start: `${item?.start?.replace(' : ', ':')}:00`,
        stop: `${item?.stop?.replace(' : ', ':')}:00`,
      });
      return true;
    });

    newTimeframes.map((time) => {
      putTimeframes(time);
      return true;
    });

    trackMixPanel(TRACK_TIMEFRAMES_SAVED);
    dispatch({ type: TIMEFRAME_DETAILS_SUCCESS, payload: currentTimeframes });
    dispatch({ type: TIMEFRAME_SAVE_SUCCESS, payload: {} });
  } catch (error) {
    trackMixPanel(TRACK_TIMEFRAMES_SAVE_FAILURE);
    dispatch({ type: TIMEFRAME_SAVE_FAIL, payload: error });
  }
};
