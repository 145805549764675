import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LogStyles from 'styles/components/atoms/Diabetes/LogsTable';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import { Typography, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { formatDate, getTime } from 'redux/actions/helperFunctions';
import { useDiabetes } from 'redux/reusables/diabetes';
import { useTimeframes } from 'redux/reusables/timeframes';

function createData(name, name_org, key, start, stop) {
  return { name, name_org, key, start, stop };
}

function CloseIconStyled() {
  return <CloseIcon style={{ color: '#F3A551' }} />;
}

function RemoveIconStyled() {
  return <RemoveIcon style={{ color: '#D4D5D7' }} />;
}

export default function LogsTable() {
  const classes = LogStyles();

  const { timeframes } = useTimeframes();

  const { diabetes } = useDiabetes();
  const { hypertension } = useSelector(state=>state.hypertensionDetails);


  const [requiredToTimeframes, setRequiredToTimeframes] = React.useState([]);
  const [entriesToTimeframes, setEntriesToTimeframes] = React.useState([]);
  const [glucoseToTimeframes, setGlucoseToTimeframes] = React.useState([]);
  const [pressureToTimeframes, setPressureToTimeframes] = React.useState([]);


  const rows = [
    createData(
      'Wake up',
      'WAKE_UP',
      timeframes[0]?.id,
      timeframes[0]?.start,
      timeframes[0]?.stop
    ),
    createData(
      'Breakfast',
      'BREAKFAST',

      timeframes[1]?.id,
      timeframes[1]?.start,
      timeframes[1]?.stop
    ),
    createData(
      'Lunch',
      'LUNCH',

      timeframes[2]?.id,
      timeframes[2]?.start,
      timeframes[2]?.stop
    ),
    createData(
      'Dinner',
      'DINNER',

      timeframes[3]?.id,
      timeframes[3]?.start,
      timeframes[3]?.stop
    ),
    createData(
      'Sleep',
      'SLEEP',

      timeframes[4]?.id,
      timeframes[4]?.start,
      timeframes[4]?.stop
    ),
  ];

  React.useEffect(() => {
    if (hypertension.requiredLogs) {
      // Here we get medication data , do some sorting on date, then remove duplicate dates
      let temp = hypertension.requiredLogs;
      temp = temp.filter((x) => x.timeframes?.length > 0);
      temp = temp.sort((a, b) =>
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero. hyperDate.timeframes.includes(time.id) ? hyperDate.id : []
         new Date(b.date) - new Date(a.created_at)
      );

      const dates = [...Array(7)].map((_, i) => {
        const d = new Date();
        d.setDate(d.getDate() - i);
        return formatDate(d.toISOString());
      });

      const mainArray = {};
      temp.map((hyperDate, index) => {
        // mainArray[index] = [];
        const date=formatDate(hyperDate.created_at)
        mainArray[date.date] = mainArray[date.date]
          ? mainArray[date.date]
          : [];
        timeframes.map((time) => {
          mainArray[date.date][time.name] = mainArray[date.date][
            time.name
          ]
            ? mainArray[date.date][time.name]
            : [];
          if (hyperDate.timeframes.includes(time.id)) {
            mainArray[date.date][time.name] = hyperDate.type;
          }
        });
      });

      const keys = Object.keys(mainArray);
      const last = keys[keys.length - 1];

      // Medication Entries start from here:
      const tempEntries = {};
      const tempEntriesGlucose = {};

      dates.map((date) => {
        tempEntries[date.date] = [];
        tempEntriesGlucose[date.date] = [];
      });

      hypertension.pressureEntries.map((hyperDate, index) => {
        // mainArray[index] = [];
        const date = hyperDate.date
          ? `${hyperDate.date.slice(5, 7)}/${hyperDate.date.slice(
              8,
              10
            )}/${hyperDate.date.slice(0, 4)}`
          : '01/01/2000';

        tempEntries[date] = tempEntries[date] ? tempEntries[date] : [];
        timeframes.map((time) => {
          tempEntries[date][time.name] = tempEntries[date][time.name]
            ? tempEntries[date][time.name]
            : [];
          if (hyperDate.timeframe === time.id) {
            if (
              tempEntries[date][time.name]?.timeframe === hyperDate.timeframe &&
              tempEntries[date][time.name].heart_rate

              // tempEntries[date][time.name]?.id === hyperDate.required_medication
            ) {
              const heartRate = tempEntries[date][time.name]?.heart_rate;

              tempEntries[date][time.name] = {
                heart_rate: heartRate,
                timeframe: hyperDate.timeframe,
                systolic: hyperDate.systolic,
                diastolic: hyperDate.diastolic,
              };
            } else {
              tempEntries[date][time.name] = {
                heart_rate: hyperDate.heart_rate,
                timeframe: hyperDate.timeframe,
                systolic: hyperDate.systolic,
                diastolic: hyperDate.diastolic,
              };
            }
          }
        });
      });

      setEntriesToTimeframes(tempEntries);
      setRequiredToTimeframes(mainArray);

      // Medication entries ends here

      hypertension.pressureEntries.map((hyperDate, index) => {
        // mainArray[index] = [];

        const date = hyperDate.date
          ? `${hyperDate.date.slice(5, 7)}/${hyperDate.date.slice(
              8,
              10
            )}/${hyperDate.date.slice(0, 4)}`
          : '01/01/2000';

        tempEntriesGlucose[date] = tempEntriesGlucose[date]
          ? tempEntriesGlucose[date]
          : [];
        timeframes.map((time) => {
          tempEntriesGlucose[date][time.name] = tempEntriesGlucose[date][
            time.name
          ]
            ? tempEntriesGlucose[date][time.name]
            : [];
          if (hyperDate.timeframe === time.id) {
            tempEntriesGlucose[date][time.name] = hyperDate.value;
          }
        });
      });

      setGlucoseToTimeframes(tempEntriesGlucose);

      const newMainArray = {};
      dates.map((date) => {
        if (mainArray[last]) newMainArray[date.date] = mainArray[last];
        else {
          newMainArray[date.date] = [];
        }
      });

      setRequiredToTimeframes(newMainArray);

      // RequiredMeds
    }
  }, [diabetes.requiredMedsDiabetes]);


  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: 'none !important',
        overflowX: 'auto',
        paddingBottom: '30px',
      }}
    >
      <Table
        sx={{ minWidth: 650, tableLayout: 'fixed' }}
        aria-label='simple table'
        // tableLayout='fixed'
      >
        <TableHead>
          <TableRow
            sx={{
              fontFamily: 'Comfortaa, cursive !important',
              fontWeight: '600',
              fontSize: '16px',
              ' th': {
                border: 0,
              },
              marginBottom: '20px',
            }}
          >
            <TableCell style={{ width: '180px' }} />

            {entriesToTimeframes && requiredToTimeframes
              ? Object.keys(requiredToTimeframes).map((key, index) => {
                  let date1 = key.split('/');
                  date1 = new Date(date1[2], date1[0] - 1, date1[1]);
                  const dayNames = [
                    'Sun',
                    'Mon',
                    'Tue',
                    'Wed',
                    'Thu',
                    'Fri',
                    'Sat',
                  ];

                  const dayName = dayNames[date1.getDay()];
                  return (
                    <TableCell align='center' className={classes.tableCellHead}>
                      <Typography className={classes.tableCellHeadDate}>
                        {key.slice(0, 5)}
                      </Typography>
                      <Typography className={classes.tableCellHeadDay}>
                        {dayName}
                      </Typography>
                      <Grid xs={12} container>
                        <Grid item xs={6}>
                          <Typography className={classes.tableCellHeadType}>
                            Blood Pressure
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography className={classes.tableCellHeadType}>
                            Heart Rate
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                  );
                })
              : false}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            const color = index % 2 == 0 ? '#FAFBFE' : '';
            return (
              <TableRow
                key={row.name}
                sx={{
                  ' td,th': {
                    border: 0,
                    borderRadius: '8px',
                  },
                  background: color,
                }}
              >
                <TableCell
                  component='th'
                  scope='row'
                  className={classes.timeframeName}
                >
                  {row.name}
                </TableCell>
                {entriesToTimeframes && requiredToTimeframes
                  ? Object.keys(requiredToTimeframes).map((
                      key,
                      index
                    ) => {
                      let todayDate = formatDate(new Date().toISOString());
                      todayDate = todayDate.date;
                      const time = getTime();
                      let arrived = false;
                      const regex = new RegExp(':', 'g');

                      if (
                        parseInt(time.replace(regex, ''), 10) >
                          parseInt(row.stop.replace(regex, ''), 10) ||
                        new Date(todayDate) > new Date(key)
                      ) {
                        arrived = true;
                      }

                      if (Object.keys(requiredToTimeframes).length !== 0)
                        return (
                          <TableCell align='center'>
                            {
                              arrived ? (
                                Object.keys(entriesToTimeframes).length !==
                                0 ? (
                                  <Grid
                                    xs={12}
                                    container
                                    className={classes.rowText}
                                  >
                                    <Grid
                                      item
                                      xs={6}
                                      container
                                      justifyContent='center'
                                    >
                                      <Typography
                                      sx={{fontFamily:'Work Sans, Serif', fontSize:'17px'}}

                                      >
                                        {entriesToTimeframes[key][row.name_org]
                                          ?.systolic ?`${entriesToTimeframes[key][row.name_org]
                                          ?.systolic}/`:''}{entriesToTimeframes[key][row.name_org]
                                            ?.diastolic}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}
                                      sx={{fontFamily:'Work Sans, Serif', fontSize:'17px'}}
                                    >
                                        {entriesToTimeframes[key][row.name_org]
                                          ?.heart_rate}
                                    </Grid>
                                  </Grid>
                                ) : (
                                  <CloseIconStyled />
                                )
                              ) : (
                                <RemoveIconStyled />
                              )
                            }
                          </TableCell>
                        );
                    })
                  : false}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
