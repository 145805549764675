import React from 'react';
import { Grid, Typography, Box, CircularProgress } from '@mui/material';
import MealTableRow from 'components/molecules/diabetesUi/MealTableRow';
import mealTableStyles from 'styles/components/organisms/diabetesUI/MealTableStyles';
import { useDiabetes } from 'redux/reusables/diabetes';
import { useTimeframes } from 'redux/reusables/timeframes';

export default function MealTable(props) {
    const classes = mealTableStyles();
    const { timeframes } = useTimeframes();
    const { loading } = useDiabetes();

    // This is used to map the correct data to correct row
    // For the working of switches
    const switchData = [
        {
            gluc_name: 'gluc_wake',
            corr_name: 'corr_wake',
            dose_name: 'dose_wake',
            switch_name: 'switch_wake',
        },
        {
            gluc_name: 'gluc_breakfast',
            corr_name: 'corr_breakfast',
            dose_name: 'dose_breakfast',
            switch_name: 'switch_breakfast',
        },
        {
            gluc_name: 'gluc_lunch',
            corr_name: 'corr_lunch',
            dose_name: 'dose_lunch',
            switch_name: 'switch_lunch',
        },
        {
            gluc_name: 'gluc_dinner',
            corr_name: 'corr_dinner',
            dose_name: 'dose_dinner',
            switch_name: 'switch_dinner',
        },
        {
            gluc_name: 'gluc_sleep',
            corr_name: 'corr_sleep',
            dose_name: 'dose_sleep',
            switch_name: 'switch_sleep',
        },
    ];
    return loading ? (
        <CircularProgress />
    ) : (
        <Box
            sx={{
                width: '100%',
                height: 'auto',
                position: 'relative',
                // boxShadow: '1px 2px 6px #886c6c85;',
                borderBottom: '1px solid #E8ECF1',

                overflow: 'hidden !important',
                marginTop: '20px',
            }}
            pb={2}
        >
            <Grid
                container
                spacing={2}
                alignItems='center'
                p={2}
                sx={{
                    // background: '#F6F9FC',
                    paddingRight: '35px',
                    borderBottom: '1px solid #E8ECF1',

                    paddingLeft: '30px',
                }}
            >
                <Grid container item xs={2} alignItems='center' justifyContent='center'>
                    <Typography variant='h5' component='p' className={classes.tableHeadings}>
                        Meal
                    </Typography>
                </Grid>
                <Grid container item xs={2} alignItems='center'>
                    <Typography variant='h5' component='p' className={classes.tableHeadings}>
                        Time
                    </Typography>
                </Grid>
                <Grid container item xs={2} alignItems='center'>
                    <Typography variant='h5' component='p' className={classes.tableHeadings}>
                        Insulin Name
                    </Typography>
                </Grid>
                <Grid
                    container
                    item
                    xs={2}
                    ml={7}
                    // alignItems='center'
                    justifyContent='flex-end'
                >
                    <Typography variant='h5' component='p' className={classes.tableHeadings}>
                        Scheduled Dose
                    </Typography>
                </Grid>
                <Grid container item xs={2} alignItems='center' justifyContent='flex-end'>
                    <Typography variant='h5' component='p' className={classes.tableHeadings}>
                        Glucose Check
                    </Typography>
                </Grid>
                <Grid container item xs={1} alignItems='center'>
                    <Typography variant='h5' component='p' className={classes.tableHeadings}>
                        Correctional
                    </Typography>
                </Grid>
            </Grid>
            {/* Here we map the data for each row for timeframes */}
            {['WAKE_UP', 'BREAKFAST', 'LUNCH', 'DINNER', 'SLEEP'].map((time, index) => {
                const outerIndex = index;
                return timeframes.map((timeframe) => {
                    if (timeframe.name === time) {
                        return (
                            <MealTableRow
                                key={outerIndex}
                                name={timeframe.name}
                                start={timeframe.start}
                                stop={timeframe.stop}
                                corr_name={switchData[outerIndex].corr_name}
                                gluc_name={switchData[outerIndex].gluc_name}
                                dose_name={switchData[outerIndex].dose_name}
                                switch_name={switchData[outerIndex].switch_name}
                            />
                        );
                    }
                    return true;
                });
            })}
        </Box>
    );
}
