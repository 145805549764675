import axiosFetch from 'redux/config/axios';
import { getSinglePatient } from 'redux/proxies/patients';
import {
  deleteRequiredMedications,
  getRequiredMedications,
  patchRequiredMedications,
  postRequiredMedications,
  putRequiredMedications,
} from 'redux/proxies/requiredMedications';
import { getTimeframes } from 'redux/proxies/timeframes';
import {
  deleteDiangoses,
  getDiagnoses,
  postDiagnoses,
  putDiangoses,
} from 'redux/proxies/diagnoses';
import { getMedicationEntries } from 'redux/proxies/medicationEntries';

import { getEncountersTexts } from 'redux/proxies/encountersTexts';
import { getFrequencyNumbers } from 'components/organisms/patientChartUi/MedicationBuilder/similarInputs';
import { trackMixPanel } from 'redux/proxies/mixpanel';
import {
  TRACK_DIAGNOSES_SAVED,
  TRACK_MEDICATION_SAVED,
} from 'redux/constants/mixPanel';
import { formatDate } from './helperFunctions';
import {
  PATIENT_DATA_FAIL,
  PATIENT_DATA_SUCCESS,
  PATIENT_DATA_REQUEST,
  PATIENT_UPDATE_REQUEST,
  PATIENT_SAVE_FAIL,
  PATIENT_SAVE_SUCCESS,
  PATIENT_SAVE_REQUEST,
} from '../constants/patientChartConstants';

const getDiagnosis = async (id) => {
  const diagnosesValues = await axiosFetch.get(`/diagnoses/${id}/`);
  return diagnosesValues.data;
};
export const getPatientData = (patient_id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PATIENT_DATA_REQUEST });

    // Get data for Patient,Encounters Notes, Chat, CPT-Codes
    const [res, encountersTexts, resChat, { data: cptCodes }] =
      await Promise.all([
        getSinglePatient(patient_id),
        getEncountersTexts(patient_id),
        axiosFetch.get(`/chat/patient/${patient_id}/`),
        axiosFetch.get(`/cpt-codes/`),
      ]);

    let medicationEntries = await getMedicationEntries(patient_id);

    medicationEntries = medicationEntries.data;

    const {data} = res;
    data.medicationEntries = medicationEntries;

    // For dose_card
    let timeframes = await getTimeframes(patient_id);
    timeframes = timeframes.data;

    [
      // For notes data

      'CHIEF_COMPLAINT',
      'HPI',
      'ROS',
      'DIAGNOSES',
      'SURGICAL',
      'FAMILY',
      'SOCIAL',
      'ALLERGIES',
      'MEDICAL',
      'PHYSICAL',
      'STICKY',
      'SCRATCH',
      'PHARMACIES',
      'OTHER_PROVIDERS',
      'TREATMENT_COORDINATION',
      'OTHER',
    ].map((itemType) => {
      if (encountersTexts.data.filter((item) => item.type === itemType)[0]) {
        data[itemType] = encountersTexts.data
          .filter((item) => item.type === itemType)
          .reverse()[0].text;
        data[`${itemType}Org`] = encountersTexts.data
          .filter((item) => item.type === itemType)
          .reverse()[0].text;
      }

      return true;
    });

    // For diagnosis data

    let diagnosesValues = await getDiagnoses(patient_id);
    diagnosesValues = diagnosesValues.data;

    if (diagnosesValues.length > 0) {
      const tempDiagnoses = [];
      const tempDiagnosesIds = [];
      diagnosesValues.map((d) => {
        tempDiagnoses.push({
          label: d.icd_text,
          code: d.icd_code,
          nickname: d.nickname,
          order: d.order,
          id: d.id,
        });
        tempDiagnosesIds.push(d.id);
        return true;
      });
      data.diagnosisData = tempDiagnoses;
      data.diagnosisDataOrg = tempDiagnoses;
      data.diagnosisDataIds = tempDiagnosesIds;

      data.diagnsosisFields = diagnosesValues.length;
      data.diagnsosisFieldsOrg = diagnosesValues.length;
    } else {
      data.diagnosisData = [];
      data.diagnosisDataOrg = [];

      data.diagnsosisFields = 1;
      data.diagnsosisFieldsOrg = 1;

      data.diagnosisDataIds = [];
    }

    // For chat data

    try {
      if (resChat.data) {
        data.chatIdData = resChat?.data[0]?.id;

        const resChatReadCheck = await axiosFetch.get(
          `/messages/${data.chatIdData}/`
        );
        data.chatDataFull = { ...resChatReadCheck?.data, results: {} };
      }
    }catch(err){

    }
    // Get required meds

    const requiredMeds = await getRequiredMedications(patient_id);

    // match objects of medication entries with required meds
    const medicationEntriesWithRequiredMeds = [];
    medicationEntries.map((medEntry) => {
      const requiredMed = requiredMeds.data.filter(
        (requiredMed) => requiredMed.id === medEntry.required_medication
      );
      if (requiredMed.length > 0) {
        medicationEntriesWithRequiredMeds.push({
          ...medEntry,
          medName: requiredMed[0].display_name,
          nickname: requiredMed[0].nickname,
        });
      }
      return true;
    });

    data.medicationEntries = medicationEntriesWithRequiredMeds;

    const tempMeds = [];
    const tempMedsIds = [];
    const medTimeframeTemp = [];
    requiredMeds.data.map((rule) => {
      if (rule.patient === patient_id && rule.is_insulin === false) {
        let freq = '';
        freq = rule.frequency;

        const diagnosisTemp = [];

        rule.diagnosis.map((item) => {
          getDiagnosis(item).then((res) => {
            if (res.id)
              diagnosisTemp.push({
                label: `${res.icd_text} (${res.icd_code})`,
                code: res.icd_code,
                id: res.id,
                order: res.order,
              });
          });
          return true;
        });

        // Here we map and set the values for
        // Dose_card

        if (rule.timeframes.length > 0) {
          const tempNames = [];

          rule.timeframes.map((time) => {
            timeframes.map((timeInner) => {
              if (time === timeInner.id) {
                tempNames.push(timeInner.name);
              }
              return true;
            });
            return true;
          });
          medTimeframeTemp.push({
            id: rule.id,
            medName: rule.display_name,
            nickname: rule.nickname,
            times: tempNames,
            timesIds: rule.timeframes,
            frequency: rule.frequency,
          });
        } else {
          medTimeframeTemp.push({
            id: rule.id,
            medName: rule.display_name,
            nickname: rule.nickname,
            times: [],
            timesIds: rule.timeframes,
            frequency: rule.frequency,
          });
        }
        const created_at_custom = formatDate(rule.created_at);
        tempMeds.push({
          id: rule.id,
          frequency: freq,
          frequencyOrg: rule.frequency,
          units: rule.dose_unit,
          route: rule.route,
          medName: rule.display_name,
          dose: rule.dose_prescribed,
          prn: rule.prn ? 'on' : 'off',
          code: diagnosisTemp,
          api: rule.diagnosis,
          date: created_at_custom.date,
          day: created_at_custom.day,
          timeframes: rule.timeframes,
          frequencyNum: getFrequencyNumbers(rule.frequency),
          order: rule.order,
          nickname: rule.nickname
        });
        tempMedsIds.push(rule.id);
      }
      return true;
    });

    // sort tempMeds by frequencyNum in descending order
    tempMeds?.sort((a, b) => b.frequencyNum - a.frequencyNum);

    // For hypertension
    try {
      const { data: hyperTension } = await axiosFetch.get(
        `/hypertension/${patient_id}/`
      );
      if (hyperTension) data.titrationEnabled = true;
    } catch (error) {
      data.titrationEnabled = false;
    }

    // For diabetes
    try {
      const { data: diabetes } = await axiosFetch.get(
        `/diabetes/${patient_id}/`
      );
      if (diabetes) data.diabetesEnabled = true;
    } catch (error) {
      data.diabetesEnabled = false;
    }

    data.medData = tempMeds;
    data.medDataDose = tempMeds;
    // data['medDataDoseOrg'] = tempMeds;

    data.timeframesMeds = medTimeframeTemp;
    data.timeframesMedsOrg = JSON.stringify(medTimeframeTemp);

    data.medDataOrg = tempMeds;
    data.medDataIds = tempMedsIds;
    data.editMode = false;
    data.editModeLimit = false;
    data.medFields = tempMeds.length || 1;
    data.medFieldsOrg = tempMeds.length || 0;
    data.cptCodesApi = cptCodes;
    data.tab = 0;

    dispatch({ type: PATIENT_DATA_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PATIENT_DATA_FAIL, payload: error });
  }
};

export const updatePatientData = (details) => async (dispatch, getState) => {
  try {
    dispatch({ type: PATIENT_UPDATE_REQUEST });

    // Get current state
    const { patientData } = getState();
    const { patient } = patientData;
    patient[details.type] = details.value;

    dispatch({ type: PATIENT_DATA_SUCCESS, payload: patient });
  } catch (error) {
    dispatch({ type: PATIENT_DATA_FAIL, payload: error });
  }
};

const saveDiagnosis = async (d, patient, auth, nickname) => {
  if (d.id)
    await putDiangoses(d.id, {
      icd_text: d.label,
      icd_code: d.code,
      patient: patient.pk,
      portal_user: auth.id,
      nickname: d?.nickname,
      order: d.order,
    });
  else {
    await postDiagnoses({
      icd_text: d.label,
      icd_code: d.code,
      patient: patient.pk,
      portal_user: auth.id,
      nickname: d?.nickname,
      order: d.order,
    });
  }
};


const saveMeds = async (m, patient, dIds, timeframeMeds) => {
  const requiredMeds = await getRequiredMedications(patient.pk);
  let freq = '';
  freq = m.frequency?.toUpperCase();
  const codesLeft = [];
  m.api?.map((code) => {
    if (dIds.includes(code)) {
      codesLeft.push(code);
    }
    return true;
  });
  let timeframesTemp = m.timeframes;
  requiredMeds.data.map((timeframeInner) => {
    if (m.id === timeframeInner.id) {
      timeframesTemp = timeframeInner.timeframes;
    }
    return true;
  });
  if (!m.id)
    await postRequiredMedications({
      display_name: m.medName,
      nickname: m.nickname,
      frequency: freq,
      route: m.route ? m.route.toUpperCase() : '',
      dose_prescribed: m.dose,
      dose_unit: m.units,
      patient: patient.pk,
      is_insulin: false,
      prn: m.prn === 'on',
      diagnosis: codesLeft,
      timeframes: timeframesTemp,
      order: m.order
    });
  else {
    await putRequiredMedications(m.id, {
      display_name: m.medName,
      nickname: m.nickname,
      frequency: freq,
      route: m.route ? m.route.toUpperCase() : '',
      dose_prescribed: m.dose,
      dose_unit: m.units,
      patient: patient.pk,
      is_insulin: false,
      prn: m.prn === 'on',
      diagnosis: codesLeft,
      timeframes: timeframesTemp,
      order: m.order
    });
  }
};
export const savePatientData = (type) => async (dispatch, getState) => {
  try {
    dispatch({ type: PATIENT_SAVE_REQUEST });

    // Get current state
    const { patientData, authDetails, timeFrameDetails } = getState();
    const { patient } = patientData;
    const { auth } = authDetails;
    const { timeframes } = timeFrameDetails;

    const data = patient;

    // For dose_card
    if (type === 'dose_card') {
      patient.timeframesMeds.map((item) => {
        const timeframesTemp = [];
        timeframes.map((time) => {
          if (item.times.includes(time.name)) {
            timeframesTemp.push(time.id);
          }
          return true;
        });

        patchRequiredMedications(item.id, {
          timeframes: timeframesTemp,
        });
        return true;
      });
      patient.timeframesMedsOrg = JSON.stringify(patient.timeframesMeds);
      dispatch({ type: PATIENT_SAVE_SUCCESS, payload: {} });
      return false;
    }

    // For medication builder
    if (type === 'medBuilder') {
      // update fields
      data.medFieldsOrg = patient.medFields || 1;
      // Delete deleted meds
      if (patient.deletedMeds?.length > 0) {
        patient.deletedMeds.map((m) => {
          if (m?.id) {
            deleteRequiredMedications(m.id);
          }
          return true;
        });
      }

      // Add current new ones

      if (patient.medData.length > 0) {
        const promises = patient.medData.map((m, i) =>
          saveMeds(m, patient, patient.diagnosisDataIds)
        );

        await promises.reduce((m, o) => m.then(() => o), Promise.resolve());
      }

      // Gets the new data and refreshes current ones

      const requiredMeds = await getRequiredMedications(patient.pk);

      const tempMeds = [];
      const tempMedsIds = [];
      const medTimeframeTemp = [];
      requiredMeds.data.map((rule) => {
        if (rule.patient === patient.pk && rule.is_insulin === false) {
          let freq = '';
          freq = rule.frequency;

          const diagnosisTemp = [];

          rule.diagnosis.map((item) => {
            getDiagnosis(item).then((res) => {
              if (res.id)
                diagnosisTemp.push({
                  label: `${res.icd_text} (${res.icd_code})`,
                  code: res.icd_code,
                  id: res.id,
                });
            });
            return true;
          });
          // tempMedsIds.push(rule.id);
          // Update dose_card
          if (rule.timeframes.length > 0) {
            const tempNames = [];

            rule.timeframes.map((time) => {
              timeframes.map((timeInner) => {
                if (time === timeInner.id) {
                  tempNames.push(timeInner.name);
                }
                return true;
              });
              return true;
            });
            medTimeframeTemp.push({
              id: rule.id,
              medName: rule.display_name,
              nickname: rule.nickname,
              times: tempNames,
              timesIds: rule.timeframes,
              frequency: rule.frequency,
            });
          } else {
            medTimeframeTemp.push({
              id: rule.id,
              medName: rule.display_name,
              nickname: rule.nickname,
              times: [],
              timesIds: rule.timeframes,
              frequency: rule.frequency,
            });
          }

          const created_at_custom = formatDate(rule.created_at);

          tempMeds.push({
            id: rule.id,
            frequency: freq,
            frequencyOrg: rule.frequency,
            units: rule.dose_unit,
            route: rule.route,
            medName: rule.display_name,
            nickname: rule.nickname,
            dose: rule.dose_prescribed,
            order: rule.order,
            prn: rule.prn ? 'on' : 'off',
            code: diagnosisTemp,
            api: rule.diagnosis,
            timeframes: rule.timeframes,
            date: created_at_custom.date,
            day: created_at_custom.day,
            frequencyNum: getFrequencyNumbers(rule.frequency),
          });
        }

        return true;
      });

      // sort tempMeds by frequencyNum in descending order
      tempMeds?.sort((a, b) => b.frequencyNum - a.frequencyNum);

      if (patient.medFields === 0) {
        data.timeframesMeds = [];
        data.timeframesMedsOrg = '[]';
        data.medDataIds = [];
        data.medDataDose = [];
        data.medData = [];
        data.medDataOrg = [];
        data.deletedMeds = [];
      } else {
        data.timeframesMeds = medTimeframeTemp;
        data.timeframesMedsOrg = JSON.stringify(medTimeframeTemp);
        data.medDataIds = tempMedsIds;
        data.medDataDose = tempMeds;
        data.medData = tempMeds;
        data.medDataOrg = tempMeds;
        data.deletedMeds = [];
      }

      trackMixPanel(TRACK_MEDICATION_SAVED);
      dispatch({ type: PATIENT_SAVE_SUCCESS, payload: {} });
      return true;
    }
    // For diagnosis data
    if (type === 'diagnosisData') {
      // Delete old ones
      if (patient.deletedDiagnoses?.length > 0) {
        patient.deletedDiagnoses.map((d) => {
          if (d?.id) {
            deleteDiangoses(d.id);
          }
          return true;
        });
      }
      // Add current new ones
      const saveDiagnosisPromises = patient.diagnosisData.map((d, i) => saveDiagnosis(
        d,
        patient,
        auth,
        patient.diagnosisNicknames ? patient.diagnosisNicknames[i] : null
      ));
      await Promise.all(saveDiagnosisPromises);

      let diagnosesValues = await axiosFetch.get(`/diagnoses/`);

      diagnosesValues = diagnosesValues.data.filter(
        (x) => x.patient === patient.pk
      );

      if (diagnosesValues.length > 0) {
        const tempDiagnosesIds = [];
        const tempDiagnoses = [];

        diagnosesValues.map((d) => {
          tempDiagnoses.push({
            label: d.icd_text,
            code: d.icd_code,
            nickname: d.nickname,
            order: d.order,
            id: d.id,
          });
          tempDiagnosesIds.push(d.id);
          return true;
        });
        data.diagnosisDataIds = tempDiagnosesIds;
        data.diagnosisData = tempDiagnoses;
        data.diagnosisDataOrg = tempDiagnoses;
        data.diagnsosisFieldsOrg = patient.diagnsosisFields || 1;
      } else {
        data.diagnosisDataIds = [];
      }

      dispatch({ type: PATIENT_SAVE_SUCCESS, payload: {} });

      trackMixPanel(TRACK_DIAGNOSES_SAVED);

      return false;
    }

    try {
      // Posting encounter text if encounter note exist
      await axiosFetch.post(`/encounters-texts/`, {
        text: patient[type],
        type,
        patient: patient.pk,
        portal_user: auth.id,
      });
      patient[`${type}Org`] = patient[type];
    } catch (error) {
      // Posting encounter note if encounter note dose not exist

      await axiosFetch.post(`/encounters-notes/`, {
        patient: patient.pk,
        portal_user: auth.id,
      });
      await axiosFetch.post(`/encounters-texts/`, {
        text: patient[type],
        type,
        patient: patient.pk,
        portal_user: auth.id,
      });
      patient[`${type}Org`] = patient[type];
    }

    dispatch({ type: PATIENT_SAVE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PATIENT_SAVE_FAIL, payload: error });
  }
};
