import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import MUIRichTextEditor from 'mui-rte';
import { convertToRaw } from 'draft-js';

import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import './RichEditor.scss';
// Redux
import { useDispatch } from 'react-redux';
import {
  updatePatientData,
  savePatientData,
} from 'redux/actions/patientChartActions';

const myTheme = createTheme({
  // Set up your custom MUI theme here
});

Object.assign(myTheme, {
  overrides: {
    MUIRichTextEditor: {
      editor: {
        overflow: 'auto',
      },
    },
  },
});

function RichEditor({
  readOnly,
  defaultValue,
  name,
  label,
  event,
  type,
  setWarning,
  focusEditor,
  saveEncounterRichText,
  setSaveEncounterRichText,
}) {
  const dispatch = useDispatch();
  const RichRef = React.useRef();
  const [otherContent, setOtherContent] = React.useState('');

  // Save and dispatch to redux
  const save = (data) => {

    if (!data) {
      return false;
    }
    // if (type === 'encounter') {
    //   return event(data);
    // }
    dispatch(updatePatientData({ type: name, value: otherContent }));
    dispatch(savePatientData(name));
    setWarning(false);
  };

  const checkScratch = (data) => {
    const content = JSON.stringify(convertToRaw(data.getCurrentContent()));
    setOtherContent(content);
  };

  React.useEffect(() => {
    if (type === 'encounter' && otherContent !== '') event(otherContent);
  }, [saveEncounterRichText]);
  // Custom controls here for text-alignment
  function TextRight(props) {
    return (
      <span
        style={{
          width: '100%',
          textAlign: 'right',
        }}
      >
        {props.children}
      </span>
    );
  }
  function TextCenter(props) {
    return (
      <span
        style={{
          width: '100%',
          textAlign: 'center',
        }}
      >
        {props.children}
      </span>
    );
  }
  function TextLeft(props) {
    return (
      <span
        style={{
          width: '100%',
          textAlign: 'left',
        }}
      >
        {props.children}
      </span>
    );
  }

  const focusOnClick = () => {
    RichRef.current?.focus();
  };

  React.useEffect(() => {
    if (focusEditor) focusOnClick();
  }, [focusEditor]);
  return (
    <div
      className={
        readOnly ? 'editor-root-read-only editor-overall' : 'editor-overall'
      }
    >
      <ThemeProvider theme={myTheme}>
        <MUIRichTextEditor
          ref={RichRef}
          label={label}
          onSave={(data) => save(data)}
          onChange={
            name === 'SCRATCH'
              ? (e) => checkScratch(e)
              : (e) => {
                  checkScratch(e);
                }
          }
          onBlur={
            name === 'SCRATCH'
              ? () => save(otherContent)
              : () => {
                  if (otherContent !== '')
                    dispatch(
                      updatePatientData({ type: name, value: otherContent })
                    );
                }
          }
          inlineToolbar
          controls={[
            'bold',
            'italic',
            'underline',
            'text-align-left',
            'text-align-center',
            'text-align-right',
            'bulletList',
            'numberList',
            type === 'encounter' ? 'save' : 'save',
          ]}
          customControls={[
            {
              name: 'text-align-left',
              icon: <FormatAlignLeftIcon />,
              type: 'block',
              blockWrapper: <TextLeft />,
            },
            {
              name: 'text-align-center',
              icon: <FormatAlignCenterIcon />,
              type: 'block',
              blockWrapper: <TextCenter />,
            },
            {
              name: 'text-align-right',
              icon: <FormatAlignRightIcon />,
              type: 'block',
              blockWrapper: <TextRight />,
            },
          ]}
          readOnly={readOnly}
          defaultValue={defaultValue}
        />
      </ThemeProvider>
    </div>
  );
}

export default RichEditor;
