export const PATIENT_DATA_REQUEST = 'PATIENT_DATA_REQUEST';
export const PATIENT_DATA_SUCCESS = 'PATIENT_DATA_SUCCESS';
export const PATIENT_DATA_FAIL = 'PATIENT_DATA_FAIL';
export const PATIENT_DATA_RESET = 'PATIENT_DATA_RESET';

export const PATIENT_UPDATE_REQUEST = 'PATIENT_UPDATE_REQUEST';
export const PATIENT_UPDATE_SUCCESS = 'PATIENT_UPDATE_SUCCESS';
export const PATIENT_UPDATE_FAIL = 'PATIENT_UPDATE_FAIL';

export const PATIENT_SAVE_REQUEST = 'PATIENT_SAVE_REQUEST';
export const PATIENT_SAVE_SUCCESS = 'PATIENT_SAVE_SUCCESS';
export const PATIENT_SAVE_FAIL = 'PATIENT_SAVE_FAIL';
