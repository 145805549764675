import axiosFetch from 'redux/config/axios';

export const getDiagnoses = async (id) => {
  let res;
  if (id) res = await axiosFetch.get(`/diagnoses/?patient_id=${id}`);
  else res = await axiosFetch.get('/diagnoses/');

  return res;
};
export const getSingleDiangoses = async (id) => {
  const res = await axiosFetch.get(`/diagnoses/${id}/`);
  return res;
};
export const postDiagnoses = async (data) => {
  const res = await axiosFetch.post('/diagnoses/', data);
  return res;
};

export const putDiangoses = async (id, data) => {
  const res = await axiosFetch.put(`/diagnoses/${id}/`, data);
  return res;
};

export const patchDiangoses = async (id, data) => {
  const res = await axiosFetch.patch(`/diagnoses/${id}/`, data);
  return res;
};

export const deleteDiangoses = async (id) => {
  const res = await axiosFetch.delete(`/diagnoses/${id}/`);
  return res;
};
