import axiosFetch from 'redux/config/axios';
import { TIME_ARRAY_CAPITAL } from 'redux/constants/timeFrameConstants';
import { DEFAULT_USER_STATE } from 'constant'

import {
  ONBOARDING_DETAILS_FAIL,
  ONBOARDING_DETAILS_SUCCESS,
  ONBOARDING_DETAILS_REQUEST,
  ONBOARDING_UPDATE_FAIL,
  ONBOARDING_UPDATE_SUCCESS,
  ONBOARDING_UPDATE_REQUEST,
  ONBOARDING_SAVE_FAIL,
  ONBOARDING_SAVE_SUCCESS,
  ONBOARDING_SAVE_REQUEST,
} from '../constants/onboardingConstants';

export const getOnboardingDetails = (details) => async (dispatch, getState) => {
  try {
    dispatch({ type: ONBOARDING_DETAILS_REQUEST });

    // Get current state
    const data = {};
    data.fname = '';
    data.lname = '';
    data.dob = '';
    data.email = '';
    data.phone = '+1';
    data.gender = '';
    data.state = DEFAULT_USER_STATE;
    data.userSuccess = false;
    data.medProvider = 'Pod1';
    data.timeWAKE_UP = '06:00';
    data.timeBREAKFAST = '09:00';
    data.timeLUNCH = '13:00';
    data.timeDINNER = '17:00';
    data.timeSLEEP = '22:30';

    dispatch({ type: ONBOARDING_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ONBOARDING_DETAILS_FAIL, payload: error });
  }
};

export const setOnboardingDetails = (details) => async (dispatch, getState) => {
  try {
    dispatch({ type: ONBOARDING_UPDATE_REQUEST });

    // Get current state
    const { onboardingDetails } = getState();
    const { onboarding } = onboardingDetails;
    onboarding[details.type] = details.value;

    dispatch({ type: ONBOARDING_UPDATE_SUCCESS, payload: onboarding });
  } catch (error) {
    dispatch({ type: ONBOARDING_UPDATE_FAIL, payload: error });
  }
};

function reverseString(str) {
  // Step 1. Use the split() method to return a new array
  const splitString = str.split(''); // var splitString = "hello".split("");
  // ["h", "e", "l", "l", "o"]

  // Step 2. Use the reverse() method to reverse the new created array
  const reverseArray = splitString.reverse(); // var reverseArray = ["h", "e", "l", "l", "o"].reverse();
  // ["o", "l", "l", "e", "h"]

  // Step 3. Use the join() method to join all elements of the array into a string
  const joinArray = reverseArray.join(''); // var joinArray = ["o", "l", "l", "e", "h"].join("");
  // "olleh"

  // Step 4. Return the reversed string
  return joinArray; // "olleh"
}

export const saveOnboardingDetails =
  (history) => async (dispatch, getState) => {
    try {
      dispatch({ type: ONBOARDING_SAVE_REQUEST });

      // Get current state
      const { onboardingDetails } = getState();
      const { onboarding } = onboardingDetails;

      const { authDetails } = getState();
      const { auth } = authDetails;

      const phone = onboarding.phone.replace(/[^0-9]/g, '');

      // Format date to support api required format

      let formatedDob = reverseString(onboarding.dob);
      formatedDob = formatedDob.replace('/', '-');
      formatedDob = formatedDob.replace('/', '-');
      formatedDob =
        `${reverseString(formatedDob.slice(0, 4))
        }-${
        reverseString(formatedDob.slice(8, 10))
        }-${
        reverseString(formatedDob.slice(5, 7))}`;
      // Make user data object
      const userData = {
        first_name: onboarding.fname,
        last_name: onboarding.lname,
        birthdate: formatedDob,
        email: onboarding.email,
        mobile_phone: `+${phone}`,
        sex: onboarding.gender,
      };

      // Post user data

      try {
        await axiosFetch.post(`/patients/`, {
          user: userData,
          state: onboarding.state,
        });

        const patients = await axiosFetch.get(`/patients/`);

        // //Returns patients data for timeframes saving
        const currentPatient = patients.data.filter(x=>x.email===userData.email || x.phone===userData.mobile_phone)[0];

        await axiosFetch.post(`/diabetes/`, {
          patient: currentPatient.pk,
        });

        // Here we set timeframes for newly created patient
        const timeFrames = [];
        const times = TIME_ARRAY_CAPITAL;
        times.map((time, index) => {
          let startTime = 0;
          let stopTime = 0;
          if (index === 0) {
            startTime = onboarding[`time${time}`].replace(/\s/g, '');
            let modifyTime = Number(`${startTime[0]}${startTime[1]}`) + 2;
            if (modifyTime > 24) {
              modifyTime -= 24;
              modifyTime = 0 + modifyTime;
            }
            stopTime =
              (modifyTime < 10 ? `0${modifyTime}` : modifyTime) +
              startTime.slice(2, 5);
          }
          if (index > 0 && index < 4) {
            startTime = onboarding[`time${time}`].replace(/\s/g, '');
            let modifyTime = Number(`${startTime[0]}${startTime[1]}`) + 1;
            if (modifyTime >= 24) {
              modifyTime -= 24;
              modifyTime = 0 + modifyTime;
            }
            stopTime =
              (modifyTime < 10 ? `0${modifyTime}` : modifyTime) +
              startTime.slice(2, 5);
            modifyTime = Number(`${startTime[0]}${startTime[1]}`) - 1;
            if (modifyTime < 0) {
              modifyTime = 24 + modifyTime;
            }
            startTime =
              (modifyTime < 10 ? `0${modifyTime}` : modifyTime) +
              startTime.slice(2, 5);
          }
          if (index === 4) {
            startTime = onboarding[`time${time}`].replace(/\s/g, '');
            let modifyTime = Number(`${startTime[0]}${startTime[1]}`) - 2;
            if (modifyTime < 0) {
              modifyTime = 24 + modifyTime;
            }
            stopTime = startTime;
            startTime =
              (modifyTime < 10 ? `0${modifyTime}` : modifyTime) +
              startTime.slice(2, 5);
          }
          timeFrames.push({
            patient: currentPatient.pk,
            start: startTime,
            stop: stopTime,
            name: time,
          });
          return true;
        });

        // here we post the new created timeframes to api
        const timeFramesRes = await axiosFetch.post(`/timeframes/`, timeFrames);

        // Set chat id
        const resultChat = await axiosFetch({
          method: 'POST',
          url: `/chat/`,
          data: {
            participants: [currentPatient.pk, auth.id],
          },
        });

        onboarding.userError = false;
        onboarding.userSuccess = true;
        setTimeout(() => {
          history.push('/dashboard');
        }, 3500);
      } catch (error) {
        onboarding.userError = true;
      }

      dispatch({ type: ONBOARDING_SAVE_SUCCESS });
    } catch (error) {
      dispatch({ type: ONBOARDING_SAVE_FAIL, payload: error });
    }
  };
