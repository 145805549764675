import React from 'react';
import { Grid } from '@mui/material';
// Components boxes imports
import Allergies from 'components/organisms/patientChartUi/Allergies';
import CurrentMedications from 'components/organisms/patientChartUi/CurrentMedications';
import MedicalHistory from 'components/organisms/patientChartUi/MedicalHistory';
import PatientInfo from 'components/organisms/patientChartUi/PatientInfo';
import SocialHistory from 'components/organisms/patientChartUi/SocialHistory';
import SurgicalHistory from 'components/organisms/patientChartUi/SurgicalHistory';
import StickyNote from 'components/organisms/patientChartUi/StickyNote';
import Family from 'components/organisms/patientChartUi/Family';
import OtherProviders from 'components/organisms/patientChartUi/OtherProviders';
import Pharmacies from 'components/organisms/patientChartUi/Pharmacies';
import TreatmentCoordination from 'components/organisms/patientChartUi/TreatmentCoordination';

export default function Tab1() {
  return (
    <Grid
      container
      xs={12}
      justifyContent='space-between'
      pl={3}
      pr={3}
      pt={5}
      sx={{ background: '#FAFBFE' }}
      alignItems='start'
    >
      {/* First column for patient info and Sticky note here */}
      <Grid item container xs={3} lg={3} spacing={2}>
        <Grid item xs={12}>
          <PatientInfo />
        </Grid>
        {/* <Grid item xs={12}>
          <OtherProviders />
        </Grid> */}
        {/* <Grid item xs={12}>
          <Pharmacies />
        </Grid> */}
        <Grid item xs={12} my={2}>
          <StickyNote />
        </Grid>
      </Grid>
      {/* Second Column for Current medication, Medical History and Surgical History here */}
      <Grid
        item
        container
        xs={8}
        lg={5}
        spacing={2}
        sx={{ minHeight: '1000px' }}
      >
        <Grid item xs={12}>
          <CurrentMedications />
        </Grid>
        <Grid item xs={12}>
          <MedicalHistory />
        </Grid>
        <Grid item xs={12}>
          <SurgicalHistory />
        </Grid>
        <Grid item xs={12}>
          <TreatmentCoordination />
        </Grid>
      </Grid>
      {/* 3rd  Column for Allergies and Social Allergies here */}
      <Grid
        item
        container
        xs={12}
        lg={4}
        // style={{ maxWidth: '290px' }}
        spacing={2}
      >
        <Grid item container xs={6} lg={12} justifyContent='center'>
          <Allergies />
        </Grid>
        <Grid item container xs={6} lg={12} justifyContent='center'>
          <SocialHistory />
        </Grid>
        <Grid item container xs={6} lg={12} justifyContent='center'>
          <Family />
        </Grid>
        <Grid item container xs={6} lg={12} justifyContent='center'>
          <OtherProviders />
        </Grid>
        <Grid item container xs={6} lg={12} justifyContent='center'>
          <Pharmacies />
        </Grid>
      </Grid>
      {/* Write button */}
    </Grid>
  );
}
