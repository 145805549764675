import axios from 'axios';
import jwt_decode from "jwt-decode";
import moment from 'moment';
import { Tokens } from 'redux/config/getToken';
import { API_URL } from 'constant';

let refreshing = false;

export const getAccessToken = async () => {
  try{
    await waitRefreshing();
    const accessToken = Tokens.getAccess() || null;
    const refreshToken = Tokens.getRefresh() || null;
    if(!accessToken || !refreshToken) return null;

    const user = jwt_decode(accessToken)
    const isExpired = moment.unix(user.exp).diff(moment()) < 1;

    if(!isExpired) return accessToken;
		refreshing = true;

		const { data } = await axios.post(`${API_URL}/api/token/refresh/`, {
			refresh: refreshToken,
		});
		Tokens.setAccess(data.access);
		Tokens.setRefresh(data.refresh);

		refreshing = false;
    return data.access;
  }
  catch(e) {
    return null;
  }
};



const waitRefreshing = async (duration = 500, iterations= 10 ) => {
	await new Promise((resolve, reject) => {
		setTimeout(() => {
			if(refreshing){
				let i = 0;
				const waitInterval = setInterval(() => {
					if(!refreshing) {
						resolve();
						clearInterval(waitInterval);
					}
					if(i >= iterations) {
						clearInterval(waitInterval);
						reject();
					}
					i++;
				}, duration);
			}else{
				resolve();
			}
		}, 0)
	});
}
