// Email validator
export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// Phone formatter
export function getFormattedPhoneNum(input) {
  let output = '+1 (';
  input.replace(
    /^\+\d{0,1}\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/,
    (match, g1, g2, g3) => {
      if (g1.length) {
        output += g1;
        if (g1.length === 3) {
          output += ')';
          if (g2.length) {
            output += ` ${  g2}`;
            if (g2.length === 3) {
              output += ' - ';
              if (g3.length) {
                output += g3;
              }
            }
          }
        }
      }
    }
  );
  return output;
}

// Get days in current month
const daysInMonth = (month, year) => new Date(year, month, 0).getDate();

export const getFormattedDate = (date) => {
  let output = '';
  date.replace(
    /^\D*(\d{0,2})\D*(\d{0,2})\D*(\d{0,4})/,
    (match, g1, g2, g3) => {
      if (g1.length) {
        output += g1;
        if (g1.length === 2) {
          output += '/';
          if (g2.length) {
            output += g2;
            if (g2.length === 2) {
              output += '/';
              if (g3.length) {
                output += g3;
              }
            }
          }
        }
      }
    }
  );

  if (Number(`${output[0]}${output[1]}`) > 12) {
    output = output.slice(2, 10);
    output = `12${  output}`;
  }

  const monthDays = daysInMonth(
    Number(`${output[0]}${output[1]}`),
    Number(`${output[6]}${output[7]}${output[8]}${output[9]}`)
  );
  if (!isNaN(monthDays)) {
    if (Number(`${output[3]}${output[4]}`) > monthDays) {
      output = output.slice(0, 3) + monthDays + output.slice(5, 10);
    }
  }

  return output;
};
