import {
  DIABETES_DETAILS_FAIL,
  DIABETES_DETAILS_SUCCESS,
  DIABETES_DETAILS_REQUEST,
  DIABETES_UPDATE_FAIL,
  DIABETES_UPDATE_SUCCESS,
  DIABETES_UPDATE_REQUEST,
  DIABETES_SAVE_FAIL,
  DIABETES_SAVE_SUCCESS,
  DIABETES_SAVE_REQUEST,
  DIABETES_ORIGINAL_REQUEST,
  DIABETES_ORIGINAL_SUCCESS,
  DIABETES_ORIGINAL_FAIL,
  DIABETES_DETAILS_RESET,
} from '../constants/diabetesConstants';

export const diabetesDetailsReducer = (state = { diabetes: {} }, action) => {
  switch (action.type) {
    case DIABETES_DETAILS_REQUEST:
      return { loading: true, diabetes: {} };
    case DIABETES_DETAILS_SUCCESS:
      return { loading: false, diabetes: action.payload };
    case DIABETES_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case DIABETES_DETAILS_RESET:
      return { loading: false, diabetes: {} };
    default:
      return state;
  }
};

export const diabetesOrignalReducer = (state = { diabetes: '' }, action) => {
  switch (action.type) {
    case DIABETES_ORIGINAL_REQUEST:
      return { loading: true, diabetes: '' };
    case DIABETES_ORIGINAL_SUCCESS:
      return { loading: false, diabetes: action.payload };
    case DIABETES_ORIGINAL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const diabetesUpdateReducer = (state = { diabetes: {} }, action) => {
  switch (action.type) {
    case DIABETES_UPDATE_REQUEST:
      return { loading: true, diabetes: state };
    case DIABETES_UPDATE_SUCCESS:
      return { loading: false, diabetes: action.payload };
    case DIABETES_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const diabetesSaveReducer = (state = { diabetes: {} }, action) => {
  switch (action.type) {
    case DIABETES_SAVE_REQUEST:
      return { loading: true, diabetes: state };
    case DIABETES_SAVE_SUCCESS:
      return { loading: false, diabetes: { message: 'Success' } };
    case DIABETES_SAVE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
