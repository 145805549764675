import * as React from 'react';
import Input from 'components/atoms/Input';
import axios from 'axios';
import { Grid, Typography } from '@mui/material';
import Autocompletes from 'components/atoms/Autocomplete';
import { makeStyles } from '@mui/styles';
import Button from 'components/atoms/Button';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DehazeIcon from '@mui/icons-material/Dehaze';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import {
  getPatientData,
  savePatientData,
  updatePatientData,
} from 'redux/actions/patientChartActions';
import { usePatient } from 'redux/reusables/patients';
import { trackMixPanel } from 'redux/proxies/mixpanel';
import { TRACK_DIAGNOSES_DELETED } from 'redux/constants/mixPanel';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const useStyles = makeStyles({
  root: {
    width: '240px',
    borderRadius: '8px',
    '& .MuiInputBase-root': {
      borderRadius: '8px',
      height: '40px',
    },

    '& .MuiInputBase-input-MuiOutlinedInput-input': {
      borderRadius: '8px',
    },

    '& .MuiFormHelperText-root': {
      marginTop: '8px',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      background: '#F1F3F7',
    },
  },
});

const getItemStyle = (isDragging, draggableStyle) => ({
  display: 'flex',
  userSelect: "none",
  opacity: isDragging ? 0.7 : 1,
  ...draggableStyle
});

export default function Tab4() {
  const classes = useStyles();

  const { patient } = usePatient();
  const savePatient = useSelector((state) => state.savePatient);
  const { loading } = savePatient;

  const dispatch = useDispatch();
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState([]);
  const [diagnsosisFields, setDiagnosisFields] = React.useState(
    patient.diagnsosisFields ? patient.diagnsosisFields : 1
  );
  const [currentResults, setCurrentResults] = React.useState([0]);
  const [nicknames, setNickNames] = React.useState([]);
  const [totalResults, setTotalResults] = React.useState([0]);
  const [showAll, setShowAll] = React.useState([true]);
  const [diagnosisData, setDiagnosisData] = React.useState(
    patient.diagnosisData ? patient.diagnosisData : []
  );

  const [duplicateError, setDuplicateError] = React.useState([]);
  // const [duplicateErrorSingle, setDuplicateErrorSingle] = React.useState(false);

  React.useEffect(() => {
    if (patient.diagnosisData) {
      setDiagnosisData(patient.diagnosisData);
      setDiagnosisFields(patient.diagnsosisFields);
      const temp = [];
      patient.diagnosisData.map((data) => {
        temp.push(data.nickname);
      });
      setNickNames(temp);

      if (
        JSON.stringify(patient.diagnosisData) ==
        JSON.stringify(patient.diagnosisDataOrg)
      )
        setDuplicateError([]);
    }
  }, [patient.diagnosisData]);

  // React.useEffect(() => {}, [diagnosisData]);

  const saveDiagnosisRedux = (i) => {
    if (i !== undefined) {
      const temp = [...diagnosisData];
      temp[i] = {
        ...temp[i],
        nickname: nicknames[i],
      };
      setDiagnosisData(temp);

      dispatch(updatePatientData({ type: 'diagnosisData', value: temp }));
      dispatch(
        updatePatientData({ type: 'diagnsosisFields', value: diagnsosisFields })
      );
    } else {
      dispatch(
        updatePatientData({ type: 'diagnosisData', value: diagnosisData })
      );
      dispatch(
        updatePatientData({ type: 'diagnsosisFields', value: diagnsosisFields })
      );
    }
  };
  const saveDiagnosis = () => {
    // let temp=diagnosisData;
    dispatch(
      updatePatientData({ type: 'diagnosisData', value: diagnosisData })
    );
    dispatch(
      updatePatientData({ type: 'diagnsosisFields', value: diagnsosisFields })
    );

    dispatch(savePatientData('diagnosisData'));
    dispatch(getPatientData(patient.pk));
  };

  const onChangeDiagnostics = async (indexOuter, newVal) => {
    // Api calls to get results
    const res = await axios.get(
      `https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&authenticity_token='&maxList=&terms=${
        newVal || inputValue[indexOuter]
      }`
    );
    const maxSet = res.data[0];

    if (indexOuter === undefined) {
      return false;
    }

    // Set current and max results
    const tempCurrent = [...currentResults];
    const tempTotal = [...totalResults];
    tempCurrent[indexOuter] = res.data[3].length;
    tempTotal[indexOuter] = maxSet;

    setTotalResults(tempTotal);
    setCurrentResults(tempCurrent);
    const temp = [...options];
    temp[indexOuter] = [];
    res.data[3].map((item) => {
      temp[indexOuter].push({
        labelOrg: item[1],
        label: `${item[0]} | ${item[1]}`,
        code: item[0],
      });
      return false;
    });

    setOptions(temp);
  };

  const addDiagnosisField = (event) => {
    setDiagnosisFields((preVal) => preVal + 1);
    dispatch(
      updatePatientData({
        type: 'diagnsosisFields',
        value: diagnsosisFields + 1,
      })
    );
  };

  const addNickNamesToRedux = (event, i) => {
    const tempDiagnosis = [...diagnosisData];
    tempDiagnosis[i] = {
      ...tempDiagnosis[i],
      nickname: event.target.value,
    };
    dispatch(
      updatePatientData({
        type: 'diagnosisData',
        value: tempDiagnosis,
      })
    );
  };

  React.useEffect(() => {
    setDuplicateError(duplicateError);
    if (duplicateError.includes(true)) {
      dispatch(
        updatePatientData({ type: 'duplicateErrorDiagnoses', value: true })
      );
    }
    if (!duplicateError.includes(true)) {
      dispatch(
        updatePatientData({ type: 'duplicateErrorDiagnoses', value: false })
      );
    }
  }, [nicknames, diagnosisData]);

  const duplicateCheckOnBlur = (i) => {
    // Test duplications

    const tempDuplicate = [...diagnosisData];
    const testDuplicate = tempDuplicate?.some((item, index) => {
      if (index !== i)
        if (
          tempDuplicate[i]?.code === item?.code ||
          (nicknames[i] &&
            nicknames[i]?.trim() === item?.nickname?.trim() &&
            nicknames[i]?.replace(/\s/g, '') !== '')
        ) {
          const tempError = [...duplicateError];
          tempError[i] = true;
          setDuplicateError(tempError);
          return true;
        }
    });
    if (!testDuplicate) {
      const tempError = [...duplicateError];
      tempError[i] = false;
      setDuplicateError(tempError);
    }
  };


  const sortedList = React.useMemo(() => {
    const orders = diagnosisData.map(({order}) => order).filter(order => order);
    const maxOrder = Math.max(...orders);
    const fieldsArray = [...Array(diagnsosisFields)];
    let iOrder = maxOrder;

    const list = fieldsArray.map((_, index) => {
      const item = diagnosisData[index];
      const order = item?.order || item?.order === 0 ? item.order : ++iOrder;

      if(!item){
        return {
          index,
          isAdded: true,
          order
        }
      }
      return {
        isAdded: false,
        index,
        order
      }
    })

    return list.sort((a, b) => {
      if(a.order > b.order) return 1;
      if(a.order < b.order) return -1;
      return 0;
    })
  }, [diagnsosisFields, diagnosisData])

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const validList = [...sortedList];
    const [removed] = validList.splice(result.source.index, 1);
    validList.splice(result.destination.index, 0, removed);
    validList.forEach((item, index) => {
      item.order = index;
    })

    const newDiagnosisData = diagnosisData.map((item, index) => {
      const findedItem = validList.find(item => item.index === index);
      if(findedItem) {
        return {...item, order: findedItem.order};
      }
      return item;
    })

    setDiagnosisData(newDiagnosisData);
  }

  const isDNDDisalbed = sortedList.some(item => item.isAdded);

  return (
    <div>
      <Grid
        container
        p={3}
        sx={{ width: '1376px', minHeight: '411px', background: 'white' }}
      >
        <Grid
          container
          item
          xs={0.5}
        />
        <Grid
          container
          item
          xs={11}
          pt={3}
          mb={1}
          sx={{ borderBottom: '1px solid #D4D5D7', paddingBottom: '15px' }}
        >
          {/* HEadings here */}
          <Grid item xs={7} mr={5}>
            <Typography
              sx={{
                color: '#7E8186',
                fontSize: '16px',
                fontWeight: '400',
                fontFamily: 'Work Sans, sans-serif',
                letterSpacing: '-0.31px',
              }}
            >
              Diagnosis
            </Typography>
          </Grid>

          <Grid item xs={2} mr={5}>
            <Typography
              sx={{
                color: '#7E8186',
                fontSize: '16px',
                fontWeight: '400',
                fontFamily: 'Work Sans, sans-serif',
                letterSpacing: '-0.31px',
              }}
            >
              Code
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              sx={{
                color: '#7E8186',
                fontSize: '16px',
                fontWeight: '400',
                fontFamily: 'Work Sans, sans-serif',
                letterSpacing: '-0.31px',
              }}
            >
              Nickname
            </Typography>
          </Grid>
        </Grid>

        {/* Data here */}
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable" isDropDisabled={isDNDDisalbed}>
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {sortedList.map((item, index) => (
                <Draggable key={item.index} draggableId={String(item.index)} index={index} isDragDisabled={isDNDDisalbed}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <Grid
                        container
                        item
                        xs={0.5}
                        alignItems='center'
                      >
                        <DehazeIcon />
                      </Grid>
                      <Grid
                        key={index}
                        container
                        item
                        xs={11}
                        pt={3}
                        mb={1}
                        sx={{ paddingBottom: '15px' }}
                        alignItems='center'
                      >
                        <Grid
                          item
                          xs={7}
                          mr={5}
                          sx={{
                            border: duplicateError[item.index] ? '1px solid rgb(244,67,54)' : '',
                            borderRadius: '10px',
                          }}
                        >
                          <Autocompletes
                            onBlur={() => {
                              const temp = [...showAll];
                              temp[item.index] = false;
                              setShowAll(temp);
                              onChangeDiagnostics(item.index);
                              saveDiagnosisRedux();

                              duplicateCheckOnBlur(item.index);
                            }}
                            showAll={showAll[item.index]}
                            total={inputValue[item.index] && totalResults[item.index]}
                            current={inputValue[item.index] && currentResults[item.index]}
                            value={diagnosisData[item.index] ?? ''}
                            setShowAll={() => {
                              const temp = [...showAll];
                              temp[item.index] = !showAll[item.index];
                              setShowAll(temp);
                              onChangeDiagnostics(item.index);
                            }}
                            onChange={(event, newValue) => {
                              const temp = [...diagnosisData];
                              temp[item.index] = {
                                ...temp[item.index],
                                label: newValue ? newValue.labelOrg : '',
                                code: newValue ? newValue.code : '',
                              };
                              setDiagnosisData(temp);
                            }}
                            inputValue={inputValue[item.index] ?? ''}
                            onInputChange={(event, newInputValue) => {
                              onChangeDiagnostics(item.index, newInputValue);
                              const temp = [...inputValue];
                              temp[item.index] = newInputValue.split(' | ')[1] ?? newInputValue;

                              setInputValue(temp);
                            }}
                            options={options[item.index] ? options[item.index] : []}
                            placeholder="Select diagnosis"
                            filterOptions={(x) => x}
                          />
                        </Grid>
                        <Grid item container xs={2} sx={{ display: 'flex' }} mr={5}>
                          <Input
                            classes={classes}
                            value={diagnosisData[item.index] ? diagnosisData[item.index].code : ''}
                            sx={{ display: 'flex' }}
                            disabled
                          />
                        </Grid>
                        <Grid item container xs={2} sx={{ display: 'flex' }}>
                          <Input
                            classes={classes}
                            disabled={!diagnosisData[item.index]?.code}
                            value={nicknames[item.index] ? nicknames[item.index] : ''}
                            onChange={(event) => {
                              const temp = [...nicknames];
                              temp[item.index] = event.target.value;

                              setNickNames(temp);
                              dispatch(
                                updatePatientData({
                                  type: 'diagnosisNicknames',
                                  value: temp,
                                })
                              );
                            }}
                            onBlur={(e) => {
                              addNickNamesToRedux(e, item.index);
                              saveDiagnosisRedux(item.index);
                              duplicateCheckOnBlur(item.index);
                            }}
                            sx={{
                              border: duplicateError[item.index] ? '1px solid rgb(244,67,54)' : '',
                              borderRadius: '10px',
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={0.5}
                        alignItems='center'
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          setDiagnosisData((arr) =>
                            arr.filter((item, index) => index !== item.index)
                          );
                          const remaining = diagnosisData.filter(
                            (arr, index) => index !== item.index
                          );
                          dispatch(
                            updatePatientData({
                              type: 'diagnosisData',
                              value: remaining,
                            })
                          );
                          setInputValue((arr) =>
                            arr.filter((_, index) => index !== item.index)
                          );
                          setNickNames((arr) => arr.filter((_, index) => index !== item.index));
                          setDiagnosisFields((preVal) => preVal - 1);
                          const deleted = diagnosisData.filter(
                            (arr, index) => index === item.index
                          )[0];
                          dispatch(
                            updatePatientData({
                              type: 'deletedDiagnoses',
                              value: patient.deletedDiagnoses
                                ? [...patient.deletedDiagnoses, deleted]
                                : [deleted],
                            })
                          );

                          trackMixPanel(TRACK_DIAGNOSES_DELETED);

                          // Remove duplicate field row
                          const tempError = [...duplicateError];
                          tempError.splice(item.index, 1);
                          setDuplicateError(tempError);
                          dispatch(
                            updatePatientData({
                              type: 'diagnsosisFields',
                              value: patient.diagnsosisFields - 1,
                            })
                          );
                        }}
                      >
                        <CloseIcon />
                      </Grid>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

        <Grid container item xs={12} justifyContent='flex-start'>
          {' '}
          <Typography
            sx={{
              color: '#004680',
              fontFamily: 'Work Sans, sans-serif',
              fontSize: '16px',
              fontWeight: '600',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              letterSpacing: '-0.31px',
            }}
            onClick={addDiagnosisField}
            mt={5}
            mb={7}
          >
            <AddIcon />
            Add new diagnosis
          </Typography>
        </Grid>

        <Grid container item xs={12} justifyContent='flex-start'>
          {loading ? (
            <Grid
              sx={{ width: '240px', height: '54px', marginBottom: '20px' }}
              item
              container
              justifyContent='center'
              alignItems='center'
            >
              <CircularProgress />
            </Grid>
          ) : (
            <Button
              title='Save'
              disabled={duplicateError.includes(true)}
              secondary={duplicateError.includes(true)}
              sx={{
                borderRadius: '8px !important',
                width: '240px !important',
                height: '54px !important',
                marginBottom: '20px',
                fontFamily: 'Work Sans, sans-serif',
                fontSize: '17px',
                fontWeight: '500',
                color: '#A9ABAF',
                textTransform: 'capitalize',
              }}
              // disabled={!diagnosisData[0]}
              // secondary={!diagnosisData[0]}
              onClick={saveDiagnosis}
            />
          )}
          <Typography
            ml={6}
            mb={2}
            sx={{
              color: '#004680',
              fontFamily: 'Work Sans, sans-serif',
              fontSize: '17px',
              fontWeight: '500',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
           />
          {duplicateError.includes(true) ? (
            <Typography
              mb={2}
              sx={{
                color: 'rgb(244,67,54)',
                fontFamily: 'Work Sans, sans-serif',
                fontSize: '17px',

                fontWeight: '500',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              Duplicate diagnosis added
            </Typography>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </div>
  );
}
