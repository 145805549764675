import * as React from 'react';
import 'styles/globals.scss';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useState, useEffect } from 'react';
import { Grid , Paper , List , ListItem , ListItemIcon , ListItemText , Avatar , Divider , TextField } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import moment from 'moment';
import Cookies from 'universal-cookie';
import { useSelector } from 'react-redux';
import axiosFetch from 'redux/config/axios';
import { useStylesChat } from 'styles/components/organisms/chatUI';
import { usePatient } from 'redux/reusables/patients';
import { WS_URL } from 'constant';

function Chats() {
  const { patient } = usePatient();
  const isMounted = React.useRef(false);
  const ws = React.useRef(null);
  const [chats, setChats] = useState([]);
  const [connection, setConnection] = useState(false);
  const [isLoading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(1); // eslint-disable-line react-hooks/exhaustive-deps
  const [nextpage, setNextPage] = React.useState(false);
  const [text, setText] = React.useState('');
  const messagesEndRef = React.useRef(null);
  const [rows, setRows] = React.useState(1);
  const [expanded, setExpended] = React.useState(false);
  const [chatRoomId, setChatRoomId] = useState('');

  React.useEffect(() => {
    if (patient.chatIdData) {
      setChatRoomId(patient.chatIdData);
    }
  }, [patient.chatIdData]);

  // conection established
  useEffect(() => {
    setPage(1);
  }, []);
  useEffect(() => {
    async function checkConnection() {
      if (!connection) {
        const cookies = new Cookies();
        const access_token = cookies.get('access_token');
        ws.current = new WebSocket(`${WS_URL}/ws/chat/?bearer=${access_token}`);
        ws.current.onopen = () => {
          setConnection(true);
        };
        ws.current.onclose = () => {};
      }

      return () => {
        ws.current?.close();
      };
    }

    checkConnection();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // connection with socket

  useEffect(() => {
    if (!ws.current) return false;
    ws.current.onmessage = (val) => {
      const res = JSON.parse(val.data);
      if (res && res.errors && res.errors.length > 0) {
      }
      if (res && res.data) {
        if (res.msg_type) {
          setChats((c) => [res.data, ...c]);
        }
      }
      if (res.msg_type === 'message_deleted') {
        const temp = chats.filter(({ id }) => id !== res.message_id);
        setChats(temp);
      }
    };
  }, [chats]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chats]);

  useEffect(() => {
    async function fetchData() {
      axiosFetch
        .get(`/messages/${chatRoomId}/`)
        .then((res) => {
          setChats((c) => [...c, ...res.data.results]);
          setNextPage(!!res.data.next);
        })
        .finally(() => setLoading(false))
        .catch(() => {
        });
    }
    if (isLoading || nextpage) {
      if (chatRoomId) {
        fetchData();
      }
    }
  }, [page, chatRoomId]); // eslint-disable-line react-hooks/exhaustive-deps

  // send message

  const sendMessage = () => {
    ws.current.onopen = () => {
      setConnection(true);
    };
    ws.current?.send(
      JSON.stringify({
        action: 'send_message',
        data: {
          chat: chatRoomId,
          body: text,
        },
        request_id: new Date().getTime(),
      })
    );

    setText('');
  };
  function dateToFromNowDaily(myDate) {
    // get from-now for this date
    const fromNow = moment(myDate).fromNow();

    // ensure the date is displayed with today and yesterday
    return moment(myDate).calendar(null, {
      // when the date is closer, specify custom values
      lastWeek: 'MM/DD/YYYY',
      lastDay: '[Yesterday]',
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      nextWeek: 'dddd',
      // when the date is further away, use from-now functionality
      sameElse () {
        return `[${  fromNow  }]`;
      },
    });
  }
  const groupBy = (chats, key) => {
    Object.keys(chats).forEach((key) => {
      chats[key].time = moment(chats[key].sent_at).format('hh:mm a');
      chats[key].day = moment(chats[key].sent_at).format('MM/DD/YYYY');
    });
    const newChat = chats.reduce((result1, currentValue) => {
      (result1[currentValue[key]] = result1[currentValue[key]] || []).push(
        currentValue
      );
      return result1;
    }, {});
    const returnChat = Object.keys(newChat).map((key, idx) => {
      const arrToReview = newChat[key];
      let chatsHtml = React.createContext();
      chatsHtml = (
        <Grid container component={Paper} key={idx}>
          <Grid item xs={20}>
            <div align='center'>
              <ListItemText
                style={{
                  paddingTop: '10px',
                  fontSize: '13px',
                  position: 'sticky',
                  background: '#fff',
                  top: '0',
                  zIndex: '99999',
                  align: 'center',
                }}
                secondary={dateToFromNowDaily(key)}
              />{' '}
              {arrToReview
                .map((obj1, key1) => (
                  <div key={idx}>
                    <div>
                      <ListItem
                        style={{ display: 'block', listStyleType: 'none' }}
                      >
                        <div className={classes.avtarHeader}>
                          <Avatar
                            alt='Cindy Baker'
                            sx={{ width: 35, height: 35, mr: 1 }}
                            src=''
                          />
                          <ListItemText
                            primary={
                              `${obj1.sender.first_name
                              } ${
                                obj1.sender.last_name}`
                            }
                            className={classes.Avatarname}
                          />

                          <ListItemText
                            primary={tConvert(
                              moment.utc(obj1.sent_at).local().format('HH:mm')
                            )}
                            className={classes.time}
                          />
                        </div>
                        <div style={{ marginLeft: '40px', width: '100%' }}>
                          <ListItemText
                            style={{ margin: '0' }}
                            className={classes.messageColor}
                            primary={obj1.body}
                          />
                        </div>
                      </ListItem>
                    </div>

                    <div align='right' />
                  </div>
                ))
                .reverse()}
            </div>
          </Grid>
        </Grid>
      );

      return chatsHtml;
    });
    return returnChat.reverse();
  };

  useEffect(() => {
    if (!isMounted.current) {
      groupBy(chats, 'day');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chats]);

  useEffect(() => {
    isMounted.current = false;
  }, []);

  const classes = useStylesChat();
  return (
    <div className={classes.chatBox}>
      <Accordion
        expanded={expanded}
        onChange={(event, exp) => setExpended(!expanded)}
        style={{ borderTopRightRadius: '15px', borderTopLeftRadius: '15px' }}
      >
        <AccordionSummary
          expandIcon={<ExpandLessIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography className='messages-chats'>Messaging</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: '0' }}>
          <Grid container component={Paper}>
            <Grid item xs={20}>
              <List style={{ display: 'none' }}>
                <ListItem button key='RemySharp'>
                  <ListItemIcon>
                    <Avatar
                      alt='Cindy Baker'
                      sx={{ width: 35, height: 35 }}
                      // src='https://material-ui.com/static/images/avatar/2.jpg'
                    />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.logo}
                    primary={
                      patient
                        ? `${patient.first_name} ${patient.last_name}`
                        : ''
                    }
                  />
                  <ListItemIcon>
                    <MoreHorizIcon />
                  </ListItemIcon>
                </ListItem>
              </List>
              <Divider />
              <div className={classes.chatList}>
                {groupBy(chats, 'day')}
                <div ref={messagesEndRef} />
              </div>
              <div>
                <Box className={classes.chatInput}>
                  <TextField
                    id='standard-multiline-static'
                    multiline
                    rows={rows}
                    // maxRows={5}
                    placeholder='Write a message...'
                    variant='standard'
                    style={{ width: '100%', margin: '8px' }}
                    className={classes.chat}
                    value={text}
                    onChange={(e) => {
                      setText(e.target.value);
                    }}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter' && !event.shiftKey) {
                        event.preventDefault();
                        sendMessage();
                      } else if (event.shiftKey) {
                        setRows();
                      }
                    }}
                  />
                  <Button
                    variant='contained'
                    className={classes.sendButton}
                    endIcon={
                      <SendIcon
                        style={{
                          transform: 'rotate(312deg)',
                          margin: 'auto',
                          position: 'absolute',
                          top: '25%',
                          left: '0',
                          right: '0',
                        }}
                      />
                    }
                    onClick={sendMessage}
                    style={{ top: '93%', margin: 'auto' }}
                  />
                </Box>
              </div>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default Chats;

// time convert
function tConvert(time) {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    time = time.slice(1);
    time[5] = +time[0] < 12 ? 'AM' : 'PM';
    time[0] = +time[0] % 12 || 12;
  }
  return time.join('');
}
