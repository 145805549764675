import * as React from 'react';
import Button from '@mui/material/Button';
import 'styles/components/atoms/Button/Button.scss';
import { useStyles } from 'styles/components/atoms/Button/ButtonStyles.js';

export default function ButtonComponent(props) {
  const classes = useStyles();

  return (
    <Button
      sx={
        props.sx
          ? props.sx
          : props.bigButton
          ? { width: '343px' }
          : props.bigButton
          ? { width: '343px' }
          : { width: '295px' }
      }
      className={`${classes.root} ${
        props.primary
          ? classes.primary
          : props.secondary
          ? classes.secondary
          : props.other
          ? classes.other
          : classes.primary
      }`}
      onClick={props.onClick}
      disabled={props.disabled}
      startIcon={props.startIcon}
      type={props.type}
    >
      {props.title}
    </Button>
  );
}
