import {
  getSingleHypertension,
  patchHypertension,
} from 'redux/proxies/hypertension';
import {
  getRequiredLogs,
  patchRequiredLogs,
  postRequiredLogs,
} from 'redux/proxies/requiredLogs';
import { TIME_ARRAY_CAPITAL } from 'redux/constants/timeFrameConstants';
import { trackMixPanel } from 'redux/proxies/mixpanel';
import {
  TRACK_HYPERTENSION_SAVED,
  TRACK_HYPERTENSION_SAVE_FAILURE,
} from 'redux/constants/mixPanel';
import axiosFetch from 'redux/config/axios';
import {
  HYPERTENSION_DETAILS_FAIL,
  HYPERTENSION_DETAILS_SUCCESS,
  HYPERTENSION_DETAILS_REQUEST,
  HYPERTENSION_DETAILS_RESET,
  HYPERTENSION_SAVE_REQUEST,
  HYPERTENSION_SAVE_SUCCESS,
  HYPERTENSION_SAVE_FAIL,
  HYPERTENSION_UPDATE,
} from '../constants/hypertensionConstants';

export const showHypertension = (patient_id) => async (dispatch, getState) => {
  try {
    dispatch({ type: HYPERTENSION_DETAILS_REQUEST });

    const { timeFrameDetails } = getState();
    const { timeframes } = timeFrameDetails;
    // const { data } = await getSingleHypertension(patient_id);
    // const { data: requiredLogs } = await getRequiredLogs(patient_id);

    const [{data}, { data: requiredLogs },{data:pressureEntries}] =
      await Promise.all([
        await getSingleHypertension(patient_id),
       await getRequiredLogs(patient_id),
       await axiosFetch.get(`/pressure-entries/?patient_id=${patient_id}`)
      ]);

    const newData = { ...data };
    newData.requiredLogs = requiredLogs.filter((log) => log.type === 'BP');

    // Checks if requiredLogs object is available then it will be used to update the data
    if (newData?.requiredLogs[0]?.timeframes?.length > 0) {
      newData.logsExists = newData?.requiredLogs[0]?.id;
      newData?.requiredLogs[0]?.timeframes.map((logTimeframe) => {
        timeframes.map((timeframe) => {
          if (timeframe.id === logTimeframe) {
            newData[timeframe.name] = { checked: true, id: timeframe.id };
          }
        });
      });
    }

    newData.pressureEntries=pressureEntries;
    dispatch({ type: HYPERTENSION_DETAILS_SUCCESS, payload: newData });
  } catch (error) {
    dispatch({ type: HYPERTENSION_DETAILS_FAIL, payload: error });
  }
};

export const saveHypertension = (patient_id) => async (dispatch, getState) => {
  try {
    dispatch({ type: HYPERTENSION_SAVE_REQUEST });

    const { hypertensionDetails } = getState();
    const { hypertension } = hypertensionDetails;
    const { data } = await patchHypertension(patient_id, hypertension);

    const requiredLogsTimeframes = [];
    TIME_ARRAY_CAPITAL.map((time) => {
      if (hypertension[time]?.checked) {
        requiredLogsTimeframes.push(hypertension[time].id);
      }
    });

    if (hypertension?.logsExists) {
      const { data } = await patchRequiredLogs(hypertension?.logsExists, {
        type: 'BP',
        timeframes: requiredLogsTimeframes,
        patient: patient_id,
      });
    } else {
      const { data } = await postRequiredLogs({
        type: 'BP',
        timeframes: requiredLogsTimeframes,
        patient: patient_id,
      });
      dispatch({
        type: HYPERTENSION_UPDATE,
        typeUpdate: 'logsExists',
        dataUpdate: data.id,
      });
    }

    trackMixPanel(TRACK_HYPERTENSION_SAVED);
    dispatch({ type: HYPERTENSION_SAVE_SUCCESS, payload: {} });
  } catch (error) {
    trackMixPanel(TRACK_HYPERTENSION_SAVE_FAILURE);
    dispatch({ type: HYPERTENSION_SAVE_FAIL, payload: error });
  }
};
