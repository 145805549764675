import axiosFetch from 'redux/config/axios';

export const getHypertension = async () => {
  const res = await axiosFetch.get('/hypertension/');
  return res;
};

export const getSingleHypertension = async (patient_id) => {
  const res = await axiosFetch.get(`/hypertension/${patient_id}/`);
  return res;
};

export const postHypertension = async (data) => {
  const res = await axiosFetch.post(`/hypertension/`, data);
  return res;
};

export const putHypertension = async (patient_id, data) => {
  const res = await axiosFetch.put(`/hypertension/${patient_id}/`, data);
  return res;
};

export const patchHypertension = async (patient_id, data) => {
  const res = await axiosFetch.patch(`/hypertension/${patient_id}/`, data);
  return res;
};

export const deleteHypertension = async (patient_id) => {
  const res = await axiosFetch.delete(`/hypertension/${patient_id}/`);
  return res;
};
