import axiosFetch from 'redux/config/axios';

export const getPatients = async () => {
  const res = await axiosFetch.get('/patients/');
  return res;
};

export const getSinglePatient = async (id) => {
  const res = await axiosFetch.get(`/patients/${id}/`);
  return res;
};

export const postPatient = async (data) => {
  const res = await axiosFetch.post('/patients/', data);
  return res;
};

export const putPatient = async (id, data) => {
  const res = await axiosFetch.put(`/change-user-data/${id}`, data);
  return res;
};

export const patchPatient = async (id, data) => {
  const res = await axiosFetch.patch(`/change-user-data/${id}`, data);
  return res;
};
