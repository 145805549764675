import React, { useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SectionTitle from 'components/atoms/PatientChart/SectionTitle';
import BellNotification from 'components/atoms/BellNotification';
import { usePatient } from 'redux/reusables/patients';
import { useSettings } from 'redux/reusables/settings';

export default function PatientInfo() {
  const { patient, loading } = usePatient();
  const { states } = useSettings();

  const stateName = useMemo(() => {
    const fullState = states.find(state => state.id === patient.state);

    return fullState?.name || patient.state
  }, [patient.state, states]);

  return (
    <div
      style={{
        maxWidth: '326px',
        minHeight: '820px',
        borderRadius: '16px',
        background: 'white',
      }}
      className='overview-sections'
    >
      <Grid container p={3}>
        {/* Main Picture and name here */}
        <Grid
          container
          item
          xs={12}
          justifyContent='center'
          alignItems='center'
          direction='column'
        >
          <AccountCircle
            sx={{ width: '100px', height: '100px', color: '#C4C4C4' }}
          />
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '22px',
              fontWeight: '700',
              color: '#282D36 !important',
            }}
            variant='p'
            pt={2}
          >
            {loading
              ? 'Patient Name'
              : `${patient.first_name} ${patient.last_name}`}
          </Typography>
          <BellNotification
            unread_count={patient?.chatDataFull?.unread_count}
          />
        </Grid>

        {/* Contact Info here */}
        <Grid
          container
          item
          xs={12}
          pt={5}
          justifyContent='center'
          alignItems='center'
          direction='column'
        >
          <SectionTitle title='Contact info' link="contact" />

          {/* Info here */}
          {loading
            ? 'loading'
            : [
                { sub: 'First name', info: patient.first_name },
                { sub: 'Last name', info: patient.last_name },
                { sub: 'Sex', info: patient.sex === 'M' ? 'Male' : 'Female' },
                {
                  sub: 'Date of birthday',
                  info: patient.birthdate
                    ? `${patient.birthdate.slice(
                        5,
                        7
                      )}-${patient.birthdate.slice(
                        8,
                        10
                      )}-${patient.birthdate.slice(0, 4)}`
                    : '',
                },
                { sub: 'Email', info: patient.email },
                { sub: 'Phone', info: patient.mobile_phone },
                { sub: 'State', info: stateName },
              ].map((info, index) => (
                <Grid item container direction='column' mt={2} key={index}>
                  <Typography className='heading-info-overview'>
                    {info.sub}
                  </Typography>
                  <Typography
                    variant='p'
                    sx={{
                      marginTop: '10px',
                    }}
                    className='data-info-overview'
                  >
                    {info.info}
                  </Typography>
                </Grid>
              ))}
          {/* Medical Provider here */}
          <SectionTitle title='Medical provider' pt={2} />

          <Grid item container direction='column' mt={2}>
            <Typography variant='p' className='data-info-overview'>
              Pod 1
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
