import { makeStyles } from '@mui/styles';

// Styles for autocomplete according to figma
export const useStyles = makeStyles({
  root: {
    width: '784px',
    borderRadius: '8px',

    '& .MuiInputBase-root': {
      borderRadius: '8px',
      padding: '0px',
      height: '40px',
      paddingLeft: '8px',
    },

    '& .MuiInputBase-input-MuiOutlinedInput-input': {
      borderRadius: '8px',
    },

    '& .MuiFormHelperText-root': {
      marginTop: '8px',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      background: '#F1F3F7',
    },
  },
});

// Styles for autocomplete multi valued according to figma
export const useStylesMulti = makeStyles({
  root: {
    width: '784px',
    borderRadius: '8px',
    height: 'auto !important',
    '& .MuiInputBase-root': {
      borderRadius: '8px',
      padding: '0px',
      height: 'auto !important',
      paddingLeft: '8px',
    },

    '& .MuiInputBase-input-MuiOutlinedInput-input': {
      borderRadius: '8px',
    },

    '& .MuiFormHelperText-root': {
      marginTop: '8px',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      background: '#F1F3F7',
    },
  },
});
