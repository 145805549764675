import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    borderRadius: '8px',
    height: '60px',
    textTransform: 'capitalize',
    color: 'white',
    // styleName: Bold/Body;
    fontFamily: 'Work Sans, serif',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.30px',
  },
  primary: {
    background: '#1A598D',
    '&:hover': {
      background: '#6690B3',
    },
    '&:active': {
      background: '#99CEEE',
    },
  },
  secondary: {
    background: '#EBF5FC',
    color: '#1A598D',
    '&:hover': {
      background: '#EBF5FC99',
    },
    '&:active': {
      background: '#99CEEE',
      color: 'white',
    },
  },
  other: {
    background: '#67C57E',

    '&:hover': {
      background: '#A4DCB2',
    },
    '&:active': {
      background: '#67C57E',
    },
  },
  disabled: {
    background: '#E0E0E1',
  },
});
