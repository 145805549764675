import { Grid, Typography } from '@mui/material';
import React from 'react';
import Switch from 'components/atoms/Switch';
import { useTimeframes } from 'redux/reusables/timeframes';
import { capitalize } from 'redux/actions/helperFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { HYPERTENSION_UPDATE } from 'redux/constants/hypertensionConstants';

function SettingBGCheck() {
  const { timeframes } = useTimeframes();
  const dispatch = useDispatch();
  const { hypertension } = useSelector((state) => state.hypertensionDetails);
  return (
    <div>
      <Typography
        variant='h4'
        gutterBottom
        sx={{
          // styleName: Bold/Title 3;
          fontFamily: 'Comfortaa',
          fontSize: '20px',
          fontWeight: 700,
          lineHeight: '25px',
          letterSpacing: '0.3799999952316284px',
          textAlign: 'left',
          color: '#004680',
        }}
      >
        Blood Pressure Checks
      </Typography>
      <Grid xs={5} container mt={4}>
        <Grid xs={12} container>
          <Grid xs={4} item>
            <Typography
              sx={{
                // styleName: Regular/Callout;
                fontFamily: 'Work Sans, Serif',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '21px',
                letterSpacing: '-0.3100000023841858px',
                textAlign: 'left',
                color: '#7E8186',
              }}
            >
              Meal
            </Typography>
          </Grid>
          <Grid xs={4} item>
            <Typography
              sx={{
                // styleName: Regular/Callout;
                fontFamily: 'Work Sans, Serif',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '21px',
                letterSpacing: '-0.3100000023841858px',
                textAlign: 'left',
                color: '#7E8186',
              }}
            >
              Time
            </Typography>
          </Grid>
          <Grid xs={4} item>
            <Typography
              sx={{
                // styleName: Regular/Callout;
                fontFamily: 'Work Sans, Serif',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '21px',
                letterSpacing: '-0.3100000023841858px',
                textAlign: 'right',
                color: '#7E8186',
              }}
            >
              Blood Pressure Check
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} mt={2} sx={{ borderTop: '1px solid #E8ECF1' }} />
        {timeframes ? (
          timeframes.map((timeframe) => (
              <Grid xs={12} container mt={3}>
                <Grid xs={4} item>
                  <Typography
                    sx={{
                      // styleName: Regular/Callout;
                      // styleName: Bold/Body;
                      fontFamily: 'Work Sans, Serif',
                      fontSize: '18px',
                      fontWeight: 500,
                      lineHeight: '24px',
                      letterSpacing: '-0.30000001192092896px',
                      textAlign: 'left',
                      color: '#004680',
                    }}
                  >
                    {capitalize(timeframe?.name?.replace('_', ' '))}
                  </Typography>
                </Grid>
                <Grid xs={4} item>
                  <Typography
                    sx={{
                      fontFamily: 'Work Sans, Serif',
                      fontSize: '17px',
                      fontWeight: 400,
                      lineHeight: '24px',
                      letterSpacing: '-0.4099999964237213px',
                      textAlign: 'left',
                      color: '#282D36',
                    }}
                  >
                    {timeframe?.start?.slice(0, 5)} -{' '}
                    {timeframe?.stop?.slice(0, 5)}
                  </Typography>
                </Grid>
                <Grid xs={4} item container justifyContent='right'>
                  <Switch
                    onChange={(e) => {
                      dispatch({
                        type: HYPERTENSION_UPDATE,
                        typeUpdate: timeframe.name,
                        dataUpdate: {
                          checked: !hypertension[timeframe.name]?.checked,
                          id: timeframe.id,
                        },
                      });
                    }}
                    checked={hypertension[timeframe.name]?.checked}
                    name={timeframe.name}
                  />
                </Grid>
              </Grid>
            ))
        ) : (
          <></>
        )}
      </Grid>
    </div>
  );
}

export default SettingBGCheck;
