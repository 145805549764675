import { makeStyles } from '@mui/styles';

const mealTableStyles = makeStyles({
  tableHeadings: {
    // styleName: Regular/Callout;
    fontFamily: 'Work Sans, sans-serif',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '21px',
    letterSpacing: '-0.31px',
    textAlign: 'left',
    color: '#7E8186',
  },
  timeNames: {
    // styleName: Bold/Body;

    fontFamily: 'Work Sans, serif',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.30px',
    textAlign: 'left',
    color: '#004680',
    textTransform: 'lowercase',
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
  timeValues: {
    // styleName: Bold/Body;

    fontFamily: 'Work Sans, serif',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.40px',
    textAlign: 'left',
    color: '#282D36',
  },
  root: {
    '& .MuiInputBase-root': {
      height: '40px',
      width: '88px',
      borderRadius: '8px',
      paddingRight: '6px',
    },

    '& .MuiInputBase-input-MuiOutlinedInput-input': {
      borderRadius: '8px',
    },

    '& .MuiFormHelperText-root': {
      marginTop: '8px',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      background: '#F1F3F7',
    },
  },
});

export default mealTableStyles;
