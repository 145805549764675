export const DIABETES_DETAILS_REQUEST = 'DIABETES_DETAILS_REQUEST';
export const DIABETES_DETAILS_SUCCESS = 'DIABETES_DETAILS_SUCCESS';
export const DIABETES_DETAILS_FAIL = 'DIABETES_DETAILS_FAIL';

export const DIABETES_ORIGINAL_REQUEST = 'DIABETES_ORIGINAL_REQUEST';
export const DIABETES_ORIGINAL_SUCCESS = 'DIABETES_ORIGINAL_SUCCESS';
export const DIABETES_ORIGINAL_FAIL = 'DIABETES_ORIGINAL_FAIL';

export const DIABETES_UPDATE_REQUEST = 'DIABETES_UPDATE_REQUEST';
export const DIABETES_UPDATE_SUCCESS = 'DIABETES_UPDATE_SUCCESS';
export const DIABETES_UPDATE_FAIL = 'DIABETES_UPDATE_FAIL';

export const DIABETES_SAVE_REQUEST = 'DIABETES_SAVE_REQUEST';
export const DIABETES_SAVE_SUCCESS = 'DIABETES_SAVE_SUCCESS';
export const DIABETES_SAVE_FAIL = 'DIABETES_SAVE_FAIL';

export const DIABETES_DETAILS_RESET = 'DIABETES_DETAILS_RESET';
