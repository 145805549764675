import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LogStyles from 'styles/components/atoms/PatientCharts/LogsTable';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import { Typography } from '@mui/material';
import { formatDate, getTime } from 'redux/actions/helperFunctions';
import TooltipCustom from 'components/atoms/Tooltip';
import { usePatient } from 'redux/reusables/patients';
import { useTimeframes } from 'redux/reusables/timeframes';
import { getSingleDiabetes } from 'redux/proxies/diabetes';

function createData(name, name_org, key, start, stop) {
  return { name, name_org, key, start, stop };
}

function DoneIconStyled() {
  return <DoneIcon style={{ color: '#67C57E' }} />;
}

function CloseIconStyled() {
  return <CloseIcon style={{ color: '#F3A551' }} />;
}

function RemoveIconStyled() {
  return <RemoveIcon style={{ color: '#D4D5D7' }} />;
}

const returnToolTipData = (first, second) => (
    <>
      <p
        style={{
          fontFamily: 'Work Sans, Serif',
          fontSize: '17px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '24px',
          letterSpacing: '-0.40px',
          textAlign: 'left',
          color: '#282D36',
          marginBottom: '-4px',
        }}
      >
        Taken:{' '}
        {first
          ? first.map((item, index) => index !== first?.length - 1
                ? `${item.name},  `
                : `${item.name}  `)
          : ''}
      </p>
      <p
        style={{
          fontFamily: 'Work Sans, Serif',
          fontSize: '15px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '20px',
          letterSpacing: '-0.23px',
          textAlign: 'left',
          color: '#A9ABAF',
        }}
      >
        Not Taken:{' '}
        {second
          ? second.map((item, index) => index !== second?.length - 1
                ? `${item.name},  `
                : `${item.name}  `)
          : ''}
      </p>
    </>
  );

export default function LogsTable() {
  const classes = LogStyles();

  const { patient } = usePatient();
  const { timeframes } = useTimeframes();


  const [requiredToTimeframes, setRequiredToTimeframes] = React.useState([]);
  const [entriesToTimeframes, setEntriesToTimeframes] = React.useState([]);
  const [datesMain, setDatesMain] = React.useState([]);
  const [notTakenMeds, SetNotTakenMeds] = React.useState([]);
  const [diffMeds, setDiffMeds] = React.useState(returnToolTipData());

  const rows = [
    createData(
      'Wake up',
      'WAKE_UP',
      timeframes[0]?.id,
      timeframes[0]?.start,
      timeframes[0]?.stop
    ),
    createData(
      'Breakfast',
      'BREAKFAST',

      timeframes[1]?.id,
      timeframes[1]?.start,
      timeframes[1]?.stop
    ),
    createData(
      'Lunch',
      'LUNCH',

      timeframes[2]?.id,
      timeframes[2]?.start,
      timeframes[2]?.stop
    ),
    createData(
      'Dinner',
      'DINNER',

      timeframes[3]?.id,
      timeframes[3]?.start,
      timeframes[3]?.stop
    ),
    createData(
      'Sleep',
      'SLEEP',

      timeframes[4]?.id,
      timeframes[4]?.start,
      timeframes[4]?.stop
    ),
  ];

  React.useEffect(() => {
    if (patient.medData) {
      // Here we get medication data , do some sorting on date, then remove duplicate dates
      let temp = patient.medData;
      temp = temp.filter((x) => x.timeframes?.length > 0);
      temp = temp.sort((a, b) =>
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero. medDate.timeframes.includes(time.id) ? medDate.id : []
         new Date(b.date) - new Date(a.date)
      );

      const dates = [...Array(7)].map((_, i) => {
        const d = new Date();
        d.setDate(d.getDate() - i);
        return formatDate(d.toISOString());
      });

      const mainArray = {};
      temp.map((medDate, index) => {
        // mainArray[index] = [];
        mainArray[medDate.date] = mainArray[medDate.date]
          ? mainArray[medDate.date]
          : [];
        timeframes.map((time) => {
          mainArray[medDate.date][time.name] = mainArray[medDate.date][
            time.name
          ]
            ? mainArray[medDate.date][time.name]
            : [];
          if (medDate.timeframes.includes(time.id)) {
            mainArray[medDate.date][time.name].push({
              id: medDate.id,
              name: medDate.medName,
            });
          }
        });
      });
      const keys = Object.keys(mainArray);
      const last = keys[keys.length - 1];

      const newMainArray = {};
      dates.map((date) => {
        if (mainArray[last]) {
          newMainArray[date.date] = mainArray[last];
        } else {
          newMainArray[date.date] = [];
        }
      });

      const tempEntries = {};
      const difference = {};

      dates.map((date) => {
        tempEntries[date.date] = [];
        difference[date.date] = [];
      });

      patient.medicationEntries.map((medDate, index) => {
        // mainArray[index] = [];

        const date = medDate.date
          ? `${medDate.date.slice(5, 7)}/${medDate.date.slice(
              8,
              10
            )}/${medDate.date.slice(0, 4)}`
          : '01/01/2000';

        tempEntries[date] = tempEntries[date] ? tempEntries[date] : [];
        timeframes.map((time) => {
          tempEntries[date][time.name] = tempEntries[date][time.name]
            ? tempEntries[date][time.name]
            : [];
          if (medDate.timeframe === time.id) {
            tempEntries[date][time.name].push({
              id: medDate.required_medication,
              name: medDate.medName,
            });

            // set differences in timeframes
            if (tempEntries[date] && newMainArray[date]) {
              if (
                tempEntries[date][time.name] &&
                newMainArray[date][time.name]
              ) {
                difference[date][time.name] = newMainArray[date][
                  time.name
                ].filter(
                  ({ id: id1 }) =>
                    !tempEntries[date][time.name].some(
                      ({ id: id2 }) => id2 === id1
                    )
                );
              }
              if (difference[date][time.name]?.length === 0) {
                difference[date][time.name] = [];
              }
            }

            tempEntries[date][time.name] = [
              ...new Set(tempEntries[date][time.name]),
            ];
          }
        });
      });

      setEntriesToTimeframes(tempEntries);
      setRequiredToTimeframes(mainArray);
      SetNotTakenMeds(difference);
      setRequiredToTimeframes(newMainArray);
      setDatesMain(dates);
      // RequiredMeds
    }
  }, [patient.medData, patient.medDataDose]);

  const [created_at, setCreated_at] = React.useState(null);

  // TODO: (START) REMOVE AFTER REFACTORING
  const fetchDiabetesData = async () => {
    try{
      const {data} = await getSingleDiabetes(patient.pk);

      setCreated_at(data.created_at);
    }catch(err){
      console.error(err);
      setCreated_at(null);
    }
  }

  React.useEffect(() => {
    fetchDiabetesData()
  }, [patient.pk]);

  // TODO: (END) REMOVE AFTER REFACTORING

  const checkTaken = (key, time) => {
    if (notTakenMeds[key]) {
      if (notTakenMeds[key][time]) {
        setDiffMeds(
          returnToolTipData(
            entriesToTimeframes[key][time],
            notTakenMeds[key][time]
          )
        );
      } else {
        setDiffMeds(returnToolTipData([], requiredToTimeframes[key][time]));
      }
    }
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: 'none !important',
        overflowX: 'auto',
        paddingBottom: '30px',
      }}
    >
      <Table
        sx={{ minWidth: 650, tableLayout: 'fixed' }}
        aria-label='simple table'
        // tableLayout='fixed'
      >
        <TableHead>
          <TableRow
            sx={{
              fontFamily: 'Comfortaa, cursive !important',
              fontWeight: '600',
              fontSize: '16px',
              ' th': {
                border: 0,
              },
              marginBottom: '20px',
            }}
          >
            <TableCell style={{ width: '180px' }} />

            {entriesToTimeframes &&
            requiredToTimeframes &&
            datesMain?.length !== 0 &&
            timeframes[0]
              ? datesMain.map((key, index) => {
                  let date1 = key?.date?.split('/');
                  date1 = new Date(date1[2], date1[0] - 1, date1[1]);
                  const dayNames = [
                    'Sun',
                    'Mon',
                    'Tue',
                    'Wed',
                    'Thu',
                    'Fri',
                    'Sat',
                  ];

                  const dayName = dayNames[date1.getDay()];
                  return (
                    <TableCell align='center' className={classes.tableCellHead}>
                      <Typography className={classes.tableCellHeadDate}>
                        {key?.date?.slice(0, 5)}
                      </Typography>
                      <Typography className={classes.tableCellHeadDay}>
                        {dayName}
                      </Typography>
                    </TableCell>
                  );
                })
              : false}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            const color = index % 2 == 0 ? '#FAFBFE' : '';
            return (
              <TableRow
                key={row.name}
                sx={{
                  ' td,th': {
                    border: 0,
                    borderRadius: '8px',
                  },
                  background: color,
                }}
              >
                <TableCell
                  component='th'
                  scope='row'
                  className={classes.timeframeName}
                >
                  {row.name}
                </TableCell>
                {entriesToTimeframes && requiredToTimeframes && created_at
                  ? Object.keys(requiredToTimeframes).map((
                      key,
                      index
                    ) => {
                      let todayDate = formatDate(new Date().toISOString());
                      todayDate = todayDate.date;
                      const time = getTime();
                      let arrived = false;
                      const regex = new RegExp(':', 'g');

                      if (
                        parseInt(time?.replace(regex, ''), 10) >
                          parseInt(row?.stop?.replace(regex, ''), 10) ||
                        new Date(todayDate) > new Date(key)
                      ) {
                        arrived = true;
                      }

                      // Checks if patient was created on this date or not
                      let is_included = false;
                      const patient_created_date = formatDate(created_at);

                      if (
                        new Date(patient_created_date.date) <= new Date(key)
                      ) {
                        is_included = true;
                      }

                      if (Object.keys(requiredToTimeframes)?.length !== 0) {
                        return (
                          <TableCell
                            align='center'
                            onMouseEnter={() => checkTaken(key, row.name_org)}
                            onMouseLeave={() => setDiffMeds(<></>)}
                          >
                            <TooltipCustom text={diffMeds}>
                              <div>
                                {requiredToTimeframes[key][row.name_org]
                                  ?.length > 0 && is_included ? (
                                  arrived ? (
                                    Object.keys(entriesToTimeframes).length !==
                                    0 ? (
                                      JSON.stringify(
                                        entriesToTimeframes[key][
                                          row.name_org
                                        ]?.sort()
                                      ) ===
                                        JSON.stringify(
                                          requiredToTimeframes[key][
                                            row.name_org
                                          ]?.sort()
                                        ) &&
                                      entriesToTimeframes[key][row.name_org]
                                        .length > 0 ? (
                                        <DoneIconStyled />
                                      ) : (
                                        <CloseIconStyled />
                                      )
                                    ) : (
                                      <CloseIconStyled />
                                    )
                                  ) : (
                                    <RemoveIconStyled />
                                  )
                                ) : (
                                  ''
                                )}
                              </div>
                            </TooltipCustom>
                          </TableCell>
                        );
                      }
                    })
                  : false}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
