import React from 'react';
import { Grid, Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import ModalCustom from 'components/atoms/Modal';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updatePatientData } from 'redux/actions/patientChartActions';
import { TRACK_PATIENT_OPENED } from 'redux/constants/mixPanel';
import { trackMixPanel } from 'redux/proxies/mixpanel';
import {
  F2F_TIMER_TYPE,
  RPM_TIMER_TYPE
} from 'redux/constants/trakerConstants';

function OpenModal({
  handleClosePatientModal,
  patientModalOpen,
  index,
  row,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleOpenPatient = (trackerType) => {
    dispatch(updatePatientData({ type: 'tab', value: 0 }));
    navigate(`/patient-chart/${row.pk}/${trackerType}`);
  };

  return (
    <ModalCustom
      open={patientModalOpen}
      handleClose={() => handleClosePatientModal(index)}
    >
      <Grid container alignItems="center" justifyContent='center'>
        <Grid item xs={12} mb={2}>
          <Typography
            sx={{
              // styleName: Bold/Title 1;
              fontFamily: 'Comfortaa',
              fontSize: '28px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '34px',
              letterSpacing: '0.37px',
              textAlign: 'left',
            }}
          >
            {`${row.first_name} ${row.last_name}`}
          </Typography>
        </Grid>
        <Grid xs={12} mb={4}>
          <Typography
            sx={{
              // styleName: regular / body for web app ;
              fontFamily: 'Work Sans, serif',
              fontSize: '17px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              letterSpacing: '-0.4099999964237213px',
              textAlign: 'left',
            }}
          >
            What would you like to do?
          </Typography>
        </Grid>
        <Grid xs='6' item container justifyContent='left'>
          <Button
            onClick={() => {
              trackMixPanel(TRACK_PATIENT_OPENED('Start E&M'));
              handleOpenPatient(F2F_TIMER_TYPE);
            }}
            title='Start E&M'
            sx={{
              width: '173px',
              height: '55px !important',
              background: 'none !important',
              color: '#004680 !important',
              border: '1px solid #004680',
            }}
          />
        </Grid>
        <Grid xs='6' item container justifyContent='center'>
          <Button
            onClick={() => {
              trackMixPanel(TRACK_PATIENT_OPENED('Chart Check'));
              handleOpenPatient(RPM_TIMER_TYPE);
            }}
            title='Chart Check'
            secondary
            sx={{
              width: '173px',
              height: '55px !important',
              background: 'none !important',
              color: '#004680 !important',
              border: '1px solid #004680',
            }}
          />
        </Grid>
      </Grid>
    </ModalCustom>
  );
}

export default OpenModal;
