import axiosFetch from 'redux/config/axios';

export const getDiabetes = async () => {
  const res = await axiosFetch.get('/diabetes/');
  return res;
};

export const getSingleDiabetes = async (patient_id) => {
  const res = await axiosFetch.get(`/diabetes/${patient_id}/`);
  return res;
};

export const postDiabetes = async (data) => {
  const res = await axiosFetch.post(`/diabetes/`, data);
  return res;
};

export const putDiabetes = async (patient_id, data) => {
  const res = await axiosFetch.put(`/diabetes/${patient_id}/`, data);
  return res;
};

export const patchDiabetes = async (patient_id, data) => {
  const res = await axiosFetch.patch(`/diabetes/${patient_id}/`, data);
  return res;
};

export const deleteDiabetes = async (patient_id) => {
  const res = await axiosFetch.delete(`/diabetes/${patient_id}/`);
  return res;
};
