import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    height: '50px',
    width: '250px',
    borderRadius: '16px !important',
    background: '#F7F9FE',
    outline: 'none !important',
    border: 'none !important',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
  },

  dropdownStyle: {
    '& .Mui-selected': {
      background: (props) => (props.noStyleSelect ? '' : '#004680 !important'),
      color: (props) => (props.noStyleSelect ? '' : 'white !important'),
      height: '30px',
      width: '250px',
    },
  },
});
