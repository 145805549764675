import React from 'react';
import Button from 'components/atoms/Button';
import { makeStyles } from '@mui/styles';
import { Grid, ToggleButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setOnboardingDetails } from 'redux/actions/onboardingActions';
import { useOnboarding } from 'redux/reusables/onboarding';

// Toggle modified for color and size changes
const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  buttonColor: {
    '&.Mui-selected': {
      backgroundColor: '#E0F0FA',
      '&:hover': {
        backgroundColor: '#E0F0FA',
      },
    },
  },
});

export default function Step2(props) {
  // Redux to dispatch values
  const classes = useStyles();
  const dispatch = useDispatch();

  const { onboarding } = useOnboarding();

  // Default values set
  const [selected1, setSelected1] = React.useState(false);
  const [selected2, setSelected2] = React.useState(false);
  const [value, setValue] = React.useState('');

  return (
    <div>
      <Grid
        xs={12}
        container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: '400px',
        }}
      >
        <Grid xs={12} item container mt={2}>
          <Grid xs={12}>
            <ToggleButton
              value='M'
              selected={onboarding.gender === 'M' ? true : selected1}
              onChange={(event) => {
                // Set value as female for gender and deselect male toggle

                setSelected2(false);
                setSelected1(!selected1);
                setValue(!selected1 ? event.target.value : '');
                dispatch(
                  setOnboardingDetails({
                    type: 'gender',
                    value: !selected1 ? event.target.value : '',
                  })
                );
              }}
              sx={{
                width: '560px',
                marginTop: '10px',
                marginBottom: '20px',
              }}
              className={`${classes.buttonColor} ${classes.root}`}
            >
              Male
            </ToggleButton>
          </Grid>

          <ToggleButton
            value='F'
            selected={onboarding.gender === 'F' ? true : selected2}
            onChange={(event) => {
              // Set value as male for gender and deselect female toggle
              setSelected1(false);
              setSelected2(!selected2);
              setValue(!selected2 ? event.target.value : '');
              dispatch(
                setOnboardingDetails({
                  type: 'gender',
                  value: !selected2 ? event.target.value : '',
                })
              );
            }}
            sx={{ width: '560px' }}
            className={`${classes.buttonColor} ${classes.root}`}
          >
            Female
          </ToggleButton>
        </Grid>

        <Grid xs={12} item container mt={5}>
          <Button
            title="Next"
            onClick={props.onClick}
            value={value}
            disabled={onboarding.gender === ''}
            secondary={onboarding.gender === ''}
          />
        </Grid>
      </Grid>
    </div>
  );
}
