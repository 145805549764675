import React from 'react';
import { CircularProgress, Grid, Typography, Tab } from '@mui/material';
// organism imports

import Footer from 'components/atoms/Footer';
// Redux imports
import { useDispatch } from 'react-redux';
import Settings from 'components/organisms/diabetesUi/Settings';

import Tabs from 'components/atoms/Tabs';

import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import LogsTable from 'components/atoms/Diabetes/LogsTable';
import ModalCustom from 'components/atoms/Modal';
import Button from 'components/atoms/Button';
import { saveDiabetes, showDiabetes } from 'redux/actions/diabetesActions';
import { DIABETES_DETAILS_SUCCESS } from 'redux/constants/diabetesConstants';
import { useParams } from 'react-router-dom';
import BackdropCustom from 'components/atoms/Backdrop';
import { useDiabetes, useDiabetesOrg } from 'redux/reusables/diabetes';
import { useTimeframes } from 'redux/reusables/timeframes';
import { trackMixPanel } from 'redux/proxies/mixpanel';
import { TRACK_DIABETES_LOGS_OPENED } from 'redux/constants/mixPanel';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{ overflowX: 'auto' }}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function Home({ hideNav }) {
    const dispatch = useDispatch();
    const { error, timeframes } = useTimeframes();
    const { diabetes, loading: diabetesLoading } = useDiabetes();
    const { diabetes: diabetesOrg } = useDiabetesOrg();

    const [valueTabs, setValueTabs] = React.useState(1);
    const [warningModal, setWarningModal] = React.useState(false);

    const { pk } = useParams();

    React.useEffect(() => {
        if (!diabetes?.patient) {
            dispatch(showDiabetes(pk));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeTabs = (event, newValue) => {
        if (
            JSON.stringify(diabetes.diabetesOrgValues) !== JSON.stringify(diabetesOrg) &&
            valueTabs === 1
        ) {
            setWarningModal(true);
            return false;
        }
        if (!(!diabetes.requiredMedsDiabetes && newValue === 0)) {
            setValueTabs(newValue);
            trackMixPanel(TRACK_DIABETES_LOGS_OPENED);
        }
    };

    // Handle save/discard changes
    const acceptWarning = () => {
        setWarningModal(false);

        if (valueTabs === 1) {
            dispatch(saveDiabetes());
        }
    };

    const discardChanges = () => {
        setWarningModal(false);

        if (valueTabs === 1) {
            dispatch({
                type: DIABETES_DETAILS_SUCCESS,
                payload: {
                    ...diabetes,
                    ...diabetesOrg,
                },
            });
        }
    };

    return (
        <div
            style={{
                overflowX: 'hidden',
                overflowY: 'hidden',
            }}
        >
            <BackdropCustom open={diabetesLoading} />
            {diabetesLoading || !diabetes?.patient ? (
                <div
                    style={{
                        display: 'flex',
                        minHeight: '90vh',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: hideNav ? '20px' : '100px',
                    }}
                >
                    {/* <CircularProgress /> */}
                </div>
            ) : error ? (
                <div
                    style={{
                        display: 'flex',
                        minHeight: '80vh',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: hideNav ? '20px' : '100px',
                    }}
                >
                    <h1>Please Reload</h1>
                </div>
            ) : timeframes ? (
                timeframes.length > 0 ? (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: hideNav ? '20px' : '100px',
                            paddingTop: '15px',
                            background: '#ffffff',
                            borderRadius: '16px',
                        }}
                    >
                        <Tabs value={valueTabs} onChange={handleChangeTabs}>
                            <Tab
                                label='Logs'
                                sx={{
                                    textTransform: 'initial !important',
                                    fontSize: '16px',
                                    color: '#7E8186',
                                }}
                            />
                            <Tab
                                label='Settings'
                                sx={{
                                    textTransform: 'initial !important',
                                    fontSize: '16px',
                                    color: '#7E8186',
                                }}
                            />
                        </Tabs>
                        <Grid container xs={12}>
                            {/* Modal to handle save/discard changes */}
                            <ModalCustom
                                open={warningModal}
                                handleClose={() => setWarningModal(false)}
                            >
                                <Grid container alignItems='center' justifyContent='center'>
                                    <Grid item xs='12' mb={2} container justifyContent='center'>
                                        <Typography sx={{ fontSize: '20px' }}>
                                            There are unsaved changes.
                                        </Typography>
                                    </Grid>
                                    <Grid xs='6' item container justifyContent='center'>
                                        <Button
                                            disabled={diabetes?.diabetesErrorSave}
                                            secondary={diabetes?.diabetesErrorSave}
                                            onClick={acceptWarning}
                                            title='Save Changes'
                                            sx={{ width: '170px', height: '55px !important' }}
                                        />
                                    </Grid>
                                    <Grid xs='6' item container justifyContent='center'>
                                        <Button
                                            onClick={() => discardChanges()}
                                            title='Discard Changes'
                                            secondary
                                            sx={{ width: '170px', height: '55px !important' }}
                                        />
                                    </Grid>
                                </Grid>
                            </ModalCustom>
                            {/* Modal ends here */}

                            <TabPanel value={valueTabs} index={0}>
                                <LogsTable />
                            </TabPanel>
                            <TabPanel value={valueTabs} index={1}>
                                <Settings hideNav={hideNav} />
                            </TabPanel>
                        </Grid>
                    </div>
                ) : (
                    <CircularProgress />
                )
            ) : (
                <CircularProgress />
            )}
            <Footer />
        </div>
    );
}

export default Home;
