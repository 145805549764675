import { Grid, ToggleButton, Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import ModalCustom from 'components/atoms/Modal';
import React from 'react';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import { usePatient } from 'redux/reusables/patients';
import axiosFetch from 'redux/config/axios';
import { useDispatch } from 'react-redux';
import { updatePatientData } from 'redux/actions/patientChartActions';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  buttonColor: {
    '&:hover': {
      backgroundColor: '#E0F0FA',
      borderColor: '#E0F0FA',
    },
    '&.Mui-selected': {
      backgroundColor: '#E0F0FA',
      '&:hover': {
        backgroundColor: '#E0F0FA',
      },
    },
  },
});

function AddTitration() {
  const [openModalPatient, setOpenModalPatient] = React.useState(false);
  const classes = useStyles();
  const { patient } = usePatient();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = React.useState(false);
  React.useEffect(() => {
    if (patient.titrationEnabled) {
      setDisabled(true);
    }
  }, [patient]);

  const [titrationSelected, setTitrationSelected] = React.useState(false);

  const onSubmitHandler = async () => {
    if (titrationSelected) {
      await axiosFetch.post('/hypertension/', {
        patient: patient.pk,
      });
      setTitrationSelected(false);
      setDisabled(true);
      setOpenModalPatient(false);
      dispatch(updatePatientData({ type: 'titrationEnabled', value: true }));
    }
  };
  return (
    <>
      <Button
        title='Add titration'
        secondary={disabled}
        disabled={disabled}
        startIcon={<AddIcon />}
        onClick={() => setOpenModalPatient(true)}
        sx={{
          textTransform: 'initial !important',
          width: '167px !important',
          height: '37px !important',
          borderRadius: '8px !important',
          color: '#fff !important',
          fontSize: '16px !important',
          fontWeight: '600 !important',
        }}
      />
      <ModalCustom
        open={openModalPatient}
        handleClose={() => setOpenModalPatient(false)}
      >
        <Grid container alignItems="center" justifyContent='center'>
          <Grid item xs={12} mb={2}>
            <Typography
              sx={{
                // styleName: Bold/Title 1;
                fontFamily: 'Comfortaa',
                fontSize: '28px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '34px',
                letterSpacing: '0.37px',
                textAlign: 'left',
              }}
            >
              Add titration
            </Typography>
          </Grid>
          <Grid xs={12} mb={4}>
            <Typography
              sx={{
                // styleName: regular / body for web app ;
                fontFamily: 'Work Sans, serif',
                fontSize: '17px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '-0.4099999964237213px',
                textAlign: 'left',
              }}
            >
              Select the titration you would like to add:
            </Typography>
          </Grid>
          <Grid xs={12}>
            <ToggleButton
              value='Hypertension'
              selected={titrationSelected}
              onChange={(event) => {
                setTitrationSelected(!titrationSelected);
              }}
              sx={{
                // width: '560px',
                width: '96%',
                marginTop: '10px',
                marginBottom: '20px',
                textTransform: 'capitalize',
                fontFamily: 'Work Sans, serif',
                fontSize: '15px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '-0.4099999964237213px',
                textAlign: 'left',
              }}
              className={`${classes.buttonColor} ${classes.root}`}
            >
              Hypertension
            </ToggleButton>
          </Grid>

          <Grid xs='6' item container justifyContent='left'>
            <Button
              onClick={() => {
                setOpenModalPatient(false);
              }}
              title='Cancel'
              sx={{
                width: '173px',
                height: '55px !important',
                background: 'none !important',
                color: '#004680 !important',
                border: '1px solid #004680',
              }}
            />
          </Grid>
          <Grid xs='6' item container justifyContent='center'>
            <Button
              onClick={() => {
                // setOpenModalPatient(true);
                onSubmitHandler();
              }}
              title='Add'
              primary
              sx={{
                width: '173px',
                height: '55px !important',
                // background: 'none !important',
                // color: '#004680 !important',
                border: '1px solid #004680',
              }}
            />
          </Grid>
        </Grid>
      </ModalCustom>
    </>
  );
}

export default AddTitration;
