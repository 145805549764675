export const ONBOARDING_DETAILS_REQUEST = 'ONBOARDING_DETAILS_REQUEST';
export const ONBOARDING_DETAILS_SUCCESS = 'ONBOARDING_DETAILS_SUCCESS';
export const ONBOARDING_DETAILS_FAIL = 'ONBOARDING_DETAILS_FAIL';

export const ONBOARDING_UPDATE_REQUEST = 'ONBOARDING_UPDATE_REQUEST';
export const ONBOARDING_UPDATE_SUCCESS = 'ONBOARDING_UPDATE_SUCCESS';
export const ONBOARDING_UPDATE_FAIL = 'ONBOARDING_UPDATE_FAIL';

export const ONBOARDING_SAVE_REQUEST = 'ONBOARDING_SAVE_REQUEST';
export const ONBOARDING_SAVE_SUCCESS = 'ONBOARDING_SAVE_SUCCESS';
export const ONBOARDING_SAVE_FAIL = 'ONBOARDING_SAVE_FAIL';
