import * as React from 'react';
import ListItemsSelects from 'components/molecules/diabetesUi/ListItemsSelects';

// Material UI imports
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import listStyles from 'styles/components/organisms/diabetesUI/ListStyles';
import { useDiabetes } from 'redux/reusables/diabetes';

export default function BoxSx(props) {
    const { loading, diabetes } = useDiabetes();
    const classes = listStyles();

    return loading ? (
        <CircularProgress />
    ) : (
        <Box
            className={classes.boxContainer}
            sx={{
                borderLeft: '0px !important',
                borderRight: '0px !important',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '100%',
                }}
            >
                {/* Here we select all three insulins populated through api */}
                <ListItemsSelects
                    listItem='Basal'
                    data={diabetes ? diabetes.basal_insulin : []}
                    name='basal'
                />
                <ListItemsSelects
                    listItem='Bolus'
                    data={diabetes ? diabetes.bolus_insulin : []}
                    name='bolus'
                />
                <ListItemsSelects
                    listItem='Mixed'
                    data={diabetes ? diabetes.mixed_insulin : []}
                    name='mixed'
                />
            </div>
        </Box>
    );
}
