import React from 'react';
import { Grid, Typography } from '@mui/material';
import SectionTitle from 'components/atoms/PatientChart/SectionTitle';
import { useSelector, useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { updatePatientData } from 'redux/actions/patientChartActions';
import { usePatient } from 'redux/reusables/patients';
import { getSimilarInputsFrequency } from '../MedicationBuilder/similarInputs';

export default function Timeframes({
  frequency,
  dose,
  unit,
  medId,
  setWarning,
  setNoDoseAddedWarning,
  noDoseAddedWarning,
  index,
  medName,
}) {
  const { patient } = usePatient();

  // const [timeframesLeft, setTimeFramesLeft] = React.useState(0);
  const [timeframesLeft, setTimeFramesLeft] = React.useState(0);
  const [requiredTimeframes, setRequiredTimeframes] = React.useState(0);

  const [initialLoad, setInitialLoad] = React.useState(true);

  const dispatch = useDispatch();

  React.useEffect(() => {
    setTimeFramesLeft(5);
    if (getSimilarInputsFrequency(frequency) === 'QD') {
      setRequiredTimeframes(1);
    } else if (getSimilarInputsFrequency(frequency) === 'BiD') {
      setRequiredTimeframes(2);
    } else if (getSimilarInputsFrequency(frequency) === 'TiD') {
      setRequiredTimeframes(3);
    } else if (getSimilarInputsFrequency(frequency) === 'QiD') {
      setRequiredTimeframes(4);
    } else {
      setRequiredTimeframes(1);
    }
  }, []);

  React.useEffect(() => {
    // This sets the total timeframes left to check condition for popup
    if (initialLoad && timeframesLeft !== 0) {
      dispatch(
        updatePatientData({
          type: 'totalTimeframeLeft',
          value: patient.totalTimeframeLeft
            ? patient.totalTimeframeLeft + timeframesLeft
            : 0 + timeframesLeft,
        })
      );
      setInitialLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeframesLeft]);

  const [timeframesSelect, setTimeframesSelect] = React.useState({
    WAKE_UP: false,
    BREAKFAST: false,
    LUNCH: false,
    DINNER: false,
    SLEEP: false,
  });

  // Used to revert any changes and resets local state of component
  React.useEffect(() => {
    setTimeframesSelect({
      WAKE_UP: false,
      BREAKFAST: false,
      LUNCH: false,
      DINNER: false,
      SLEEP: false,
    });
  }, [patient.timeframesMeds]);

  React.useEffect(() => {
    if (
      frequency &&
      Object.values(timeframesSelect).filter((item) => item === true).length !==
        requiredTimeframes &&
      Object.values(timeframesSelect).filter((item) => item === true).length !==
        0
    ) {
      setWarning(true);
    }
    if (Object.values(timeframesSelect).every((item) => item === false)) {

      dispatch(
        updatePatientData({
          type: 'totalTimeframeLeft',
          value: -1,
        })
      );
    }
  }, [patient.totalTimeframeLeft]);

  React.useEffect(() => {
    if (patient.timeframesMeds) {
      patient.timeframesMeds.map((time) => {
        if (time.id === medId) {
          time.times.map((item) => {
            setTimeframesSelect((preVal) => ({
                ...preVal,
                [item]: true,
              }));

            // Set total timeframes left for popup
            setTimeFramesLeft((preVal) => preVal - 1);
            dispatch(
              updatePatientData({
                type: 'totalTimeframeLeft',
                value: patient.totalTimeframeLeft - 1,
              })
            );
          });
        }
      });
    }
  }, [patient.timeframesMeds]);

  const timeframesChangeHandler = React.useCallback(
    (name, value) => {
      setTimeframesSelect((preVal) => ({
          ...preVal,
          [name]: value,
        }));
    },
    [timeframesLeft]
  );

  const addTime = React.useCallback(
    (time) => {
      setTimeFramesLeft((preVal) => preVal + 1);
      setWarning(false);
      dispatch(
        updatePatientData({
          type: 'totalTimeframeLeft',
          value: patient.totalTimeframeLeft + 1,
        })
      );
      let data = patient.timeframesMeds ? patient.timeframesMeds : [];
      const current = data.filter((x) => x.id === medId)[0];
      if (current) {
        current.times = current.times.filter((x) => x !== time);
        data = data.filter((x) => x.id !== medId);
        data.push(current);
      }
      dispatch(
        updatePatientData({
          type: 'timeframesMeds',
          value: data,
        })
      );
    },
    [timeframesLeft]
  );

  const removeTime = React.useCallback(
    (time) => {
      setTimeFramesLeft((preVal) => preVal - 1);
      setWarning(false);
      dispatch(
        updatePatientData({
          type: 'totalTimeframeLeft',
          value: patient.totalTimeframeLeft - 1,
        })
      );

      let data = patient.timeframesMeds ? patient.timeframesMeds : [];
      const current = data.filter((x) => x.id === medId)[0];
      if (current) {
        current.times.push(time);
        data = data.filter((x) => x.id !== medId);
        data.push(current);
      } else {
        data.push({ id: medId, times: [time], medName });
      }
      dispatch(
        updatePatientData({
          type: 'timeframesMeds',
          value: data,
        })
      );
    },
    [timeframesLeft]
  );

  // Checks if no frequecy is available and dose is checked
  const checkNoDoseSelected = () => {
    const temp = noDoseAddedWarning;
    temp[index] = true;
    setNoDoseAddedWarning(temp);
  };

  // Checked if dose is not selected so return back to default
  const uncheckNoDoseSelected = () => {
    const temp = noDoseAddedWarning;
    temp[index] = false;
    setNoDoseAddedWarning(temp);

  };

  return (
    <>
      <Grid item xs={1}>
        <Typography
          sx={{
            color: '#282D36',
            fontSize: '17px',
            fontWeight: '400',
            textAlign: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            // if (timeframesLeft > 0 || timeframesSelect.WAKE_UP) {
            if (timeframesSelect.WAKE_UP) {
              addTime('WAKE_UP');
            } else {
              removeTime('WAKE_UP');
            }

            timeframesChangeHandler('WAKE_UP', !timeframesSelect.WAKE_UP);
            // }
          }}
        >
          {timeframesSelect.WAKE_UP ? (
            `${dose || ''} ${unit || ''}`
          ) : (
            <AddIcon sx={{ color: '#004680' }} />
          )}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography
          sx={{
            color: '#282D36',
            fontSize: '17px',
            fontWeight: '400',
            textAlign: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            // if (timeframesLeft > 0 || timeframesSelect.BREAKFAST) {
            if (timeframesSelect.BREAKFAST) {
              addTime('BREAKFAST');
            } else {
              removeTime('BREAKFAST');
            }

            timeframesChangeHandler('BREAKFAST', !timeframesSelect.BREAKFAST);
            // }
          }}
        >
          {timeframesSelect.BREAKFAST ? (
            `${dose || ''} ${unit || ''}`
          ) : (
            <AddIcon sx={{ color: '#004680' }} />
          )}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography
          sx={{
            color: '#282D36',
            fontSize: '17px',
            fontWeight: '400',
            textAlign: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            // if (timeframesLeft > 0 || timeframesSelect.LUNCH) {
            if (timeframesSelect.LUNCH) {
              addTime('LUNCH');
            } else {
              removeTime('LUNCH');
            }

            timeframesChangeHandler('LUNCH', !timeframesSelect.LUNCH);
            // }
          }}
        >
          {timeframesSelect.LUNCH ? (
            `${dose || ''} ${unit || ''}`
          ) : (
            <AddIcon sx={{ color: '#004680' }} />
          )}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography
          sx={{
            color: '#282D36',
            fontSize: '17px',
            fontWeight: '400',
            textAlign: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            // if (timeframesLeft > 0 || timeframesSelect.DINNER) {
            if (timeframesSelect.DINNER) {
              addTime('DINNER');
            } else {
              removeTime('DINNER');
            }

            timeframesChangeHandler('DINNER', !timeframesSelect.DINNER);
            // }
          }}
        >
          {timeframesSelect.DINNER ? (
            `${dose || ''} ${unit || ''}`
          ) : (
            <AddIcon sx={{ color: '#004680' }} />
          )}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography
          sx={{
            color: '#282D36',
            fontSize: '17px',
            fontWeight: '400',
            textAlign: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            // if (timeframesLeft > 0 || timeframesSelect.SLEEP) {
            if (timeframesSelect.SLEEP) {
              addTime('SLEEP');
            } else {
              removeTime('SLEEP');
            }

            timeframesChangeHandler('SLEEP', !timeframesSelect.SLEEP);
            // }
          }}
        >
          {timeframesSelect.SLEEP ? (
            `${dose || ''} ${unit || ''}`
          ) : (
            <AddIcon sx={{ color: '#004680' }} />
          )}
        </Typography>
      </Grid>
      <Grid item xs={1} sx={{ color: 'red', fontSize: '11px' }}>
        {!frequency &&
        Object.values(timeframesSelect).filter((item) => item === true)
          ?.length !== 0
          ? checkNoDoseSelected()
          : uncheckNoDoseSelected()}
      </Grid>
    </>
  );
}
