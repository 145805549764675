import {
  ENCOUNTERS_NOTES_FETCH_SUCCESS,
  ENCOUNTERS_NOTES_FETCH_FAIL,
  ENCOUNTERS_NOTES_FETCH_REQUEST,
  ENCOUNTERS_SINGLE_FETCH_SUCCESS,
  ENCOUNTERS_SINGLE_FETCH_FAIL,
  ENCOUNTERS_SINGLE_FETCH_REQUEST,
  ENCOUNTERS_SINGLE_FETCH_RESET,
  ENCOUNTERS_NOTES_CREATE_SUCCESS,
  ENCOUNTERS_NOTES_CREATE_FAIL,
  ENCOUNTERS_NOTES_CREATE_REQUEST,
  ENCOUNTERS_NOTES_CREATE_RESET,
  ENCOUNTERS_NOTES_EDITOR_REQUEST,
  ENCOUNTERS_NOTES_EDITOR_SUCCESS,
  ENCOUNTERS_NOTES_EDITOR_FAIL,
} from '../constants/encountersNotesConstants';

export const getEncountersNotesReducer = (state = { notes: [] }, action) => {
  switch (action.type) {
    case ENCOUNTERS_NOTES_FETCH_REQUEST:
      return { loading: true, notes: [] };
    case ENCOUNTERS_NOTES_FETCH_SUCCESS:
      return { loading: false, notes: action.payload };
    case ENCOUNTERS_NOTES_FETCH_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getEncountersSingleReducer = (state = { note: {} }, action) => {
  switch (action.type) {
    case ENCOUNTERS_SINGLE_FETCH_REQUEST:
      return { loading: true, note: {} };
    case ENCOUNTERS_SINGLE_FETCH_SUCCESS:
      return { loading: false, note: action.payload };
    case ENCOUNTERS_SINGLE_FETCH_FAIL:
      return { loading: false, error: action.payload };
    case ENCOUNTERS_SINGLE_FETCH_RESET:
      return { loading: false, note: {} };
    default:
      return state;
  }
};

export const saveEncountersNotesReducer = (
  state = { savedNote: {} },
  action
) => {
  switch (action.type) {
    case ENCOUNTERS_NOTES_CREATE_REQUEST:
      return { loading: true, savedNote: {} };
    case ENCOUNTERS_NOTES_CREATE_SUCCESS:
      return { loading: false, savedNote: action.payload };
    case ENCOUNTERS_NOTES_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ENCOUNTERS_NOTES_CREATE_RESET:
      return { loading: false, savedNote: {} };
    default:
      return state;
  }
};

export const getEncountersEditorData = (state = { editor: [] }, action) => {
  switch (action.type) {
    case ENCOUNTERS_NOTES_EDITOR_REQUEST:
      return { loading: true, editor: [] };
    case ENCOUNTERS_NOTES_EDITOR_SUCCESS:
      return { loading: false, editor: action.payload.editor,noMeds:action.payload.noMeds };
    case ENCOUNTERS_NOTES_EDITOR_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
