import * as React from 'react';
// Material UI imports
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Select from 'components/atoms/Select';
import Typography from '@mui/material/Typography';
import { MenuItem } from '@mui/material';
import { useDispatch } from 'react-redux';
import { updateDiabetes } from 'redux/actions/diabetesActions';
import { TIME_ARRAY_CAPITAL } from 'redux/constants/timeFrameConstants';
import listStyles, {
  listStylesSelect,
} from 'styles/components/organisms/diabetesUI/ListStyles';
import { useDiabetes } from 'redux/reusables/diabetes';

export default function BasicGrid(props) {
  const classes = listStyles();
  const classesSelect = listStylesSelect();

  const [selectValue, setSelectValue] = React.useState('');
  const dispatch = useDispatch();
  const { diabetes } = useDiabetes();

  const onChangerSelect = (event) => {
    setSelectValue(event.target.value);

    // Checks if any of the dose row matches the selected insulin then resets that row if insulin is changed
    if (event?.target?.value === 0) {
      TIME_ARRAY_CAPITAL.map((time) => {
        if (diabetes[`insulin_type_unit_${time}`] === `${props.name}_insulin`) {
          dispatch(
            updateDiabetes({
              type: `insulin_type_unit_${time}`,
              value: '',
            })
          );
        }
        return true;
      });
    }

    // Checks if select type is corr_take then
    // Performs tasks accordingly

    if (props.name === 'corr_take') {
      dispatch(
        updateDiabetes({
          type: props.name,
          value: event.target.value,
        })
      );
      return false;
    }
    dispatch(
      updateDiabetes({
        type: `selected_insulin_${props.name}`,
        value: event.target.value,
      })
    );
  };

  // This useEffect sets the default values that were saved from dose card previously
  React.useEffect(() => {
    if (
      diabetes[`selected_insulin_${props.name}`] !== '' &&
      props.name !== 'corr_take'
    ) {
      setSelectValue(diabetes[`selected_insulin_${props.name}`]);
    }
    if (props.name === 'corr_take' && diabetes.corr_take !== 0) {
      setSelectValue(diabetes.corr_take);
    }
  }, [diabetes, props.name]);

  return (
    <Box my={1}>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={3}>
          <Typography variant='h6' component='p' className={classes.listText}>
            {props.listItem}
          </Typography>
        </Grid>
        <Grid item xs={9} style={{ display: 'flex' }}>
          <Select
            value={selectValue}
            onChange={onChangerSelect}
            classes={classesSelect}
          >
            {props.data
              ? props.data.map((insulin, index) => (
                    <MenuItem
                      key={index}
                      value={insulin.trade_name || insulin.value}
                    >
                      {insulin.trade_name || insulin.value}
                    </MenuItem>
                  ))
              : ''}
          </Select>
        </Grid>
      </Grid>
    </Box>
  );
}
