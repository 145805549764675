import * as React from 'react';
import TextField from '@mui/material/TextField';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckIcon from '@mui/icons-material/Check';
import { useStyles } from 'styles/components/atoms/Input/InputStyles';

const Input = React.forwardRef((props, ref) => {
  const classes = useStyles();
  return (
    <TextField
      sx={props.sx}
      inputRef={ref}
      id='outlined-basic'
      variant='outlined'
      label={props.label}
      format={props.format}
      placeholder={props.placeholder}
      helperText={props.helperText}
      className={`${classes.root} ${props.classes ? props.classes.root : ''}`}
      error={props.error}
      disabled={props.disabled}
      autoFocus={!!props.success}
      color={props.success ? 'success' : ''}
      value={props.value}
      onClick={props.onClick}
      onChange={props.onChange}
      name={props.name}
      onFocus={props.onFocus}
      type={props.type}
      min={0}
      onBlur={props.onBlur}
      onKeyUp={props.onKeyUp}
      InputProps={{
        endAdornment: props.error ? (
          <WarningAmberIcon style={{ color: 'red' }} />
        ) : props.success ? (
          <CheckIcon style={{ color: 'green' }} />
        ) : (
          ''
        ),
      }}
      inputProps={props.inputProps}
      autoComplete='off'
      key={props.key}
    />
  );
});

Input.defaultProps = {
  disabled: false,
  autoFocus: false,
};

export default Input;
