import { makeStyles } from '@mui/styles';

const LogStyles = makeStyles((theme) => ({
  tableCellHead: {
    width: '110px',
  },
  tableCellHeadDate: {
    // styleName: Bold/Callout;
    fontFamily: 'Work Sans, sans-serif',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '21px',
    letterSpacing: '-0.31px',
    textAlign: 'center',
    color: '#53575E',
  },
  tableCellHeadDay: {
    // styleName: Regular/Subheadline;
    fontFamily: 'Work Sans, sans-serif',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '-0.23px',
    textAlign: 'center',
    color: '#7E8186',
  },
  timeframeName: {
    fontFamily: 'Comfortaa, cursive !important',
    fontWeight: '700',
    fontSize: '17px',
    letterSpacing: '-0.43px',
    lineHeight: '22px',
    color: '#004680',
  },
}));

export default LogStyles;
