/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
import axiosFetch from 'redux/config/axios';
import { getSingleDiabetes, patchDiabetes, postDiabetes } from 'redux/proxies/diabetes';
import { TIME_ARRAY, TIME_ARRAY_CAPITAL } from 'redux/constants/timeFrameConstants';
import {
    deleteRequiredMedications,
    getRequiredMedications,
    postRequiredMedications,
} from 'redux/proxies/requiredMedications';
import { getTimeframes, putTimeframes } from 'redux/proxies/timeframes';
import { getGlucoseEntries } from 'redux/proxies/glucoseEntries';
import { getRequiredLogs } from 'redux/proxies/requiredLogs';
import { trackMixPanel } from 'redux/proxies/mixpanel';
import { TRACK_DIABETES_SAVE_FAILURE, TRACK_DIABETES_SAVE_SUCCESS } from 'redux/constants/mixPanel';
import { getOrgValues, formatDate } from './helperFunctions';

import {
    DIABETES_DETAILS_FAIL,
    DIABETES_DETAILS_SUCCESS,
    DIABETES_DETAILS_REQUEST,
    DIABETES_UPDATE_REQUEST,
    DIABETES_SAVE_REQUEST,
    DIABETES_SAVE_SUCCESS,
    DIABETES_SAVE_FAIL,
    DIABETES_ORIGINAL_SUCCESS,
    DIABETES_ORIGINAL_FAIL,
} from '../constants/diabetesConstants';

// Function to show diabetes api content
export const showDiabetes = (patient_id) => async (dispatch) => {
    // If exists try this
    try {
        dispatch({ type: DIABETES_DETAILS_REQUEST });

        const res = await getSingleDiabetes(patient_id);
        // Data retrieved and pushed to array for further operations
        const data = [{}];
        data[0] = res.data;

        // Gets all insulins
        const all_insulin = await axiosFetch.get(`/medication-rules/`);

        all_insulin.data = all_insulin?.data?.sort((a, b) => {
            if (a?.trade_name < b?.trade_name) {
                return -1;
            }
            if (a?.trade_name > b?.trade_name) {
                return 1;
            }
            return 0;
        });

        // Checks and sets default insulins
        const selected_insulin_basal = res.data.basal_insulin
            ? all_insulin.data.filter((x) => {
                  if (x.id === res.data.basal_insulin) {
                      return x.trade_name;
                  }
                  return false;
              })
            : '';
        const selected_insulin_bolus = res.data.bolus_insulin
            ? all_insulin.data.filter((x) => {
                  if (x.id === res.data.bolus_insulin) {
                      return x.trade_name;
                  }
                  return false;
              })
            : '';
        const selected_insulin_mixed = res.data.mixed_insulin
            ? all_insulin.data.filter((x) => {
                  if (x.id === res.data.mixed_insulin) {
                      return x.trade_name;
                  }
                  return false;
              })
            : '';

        // Used for setting the selected basal,bolus and mixed insulins

        data[0].selected_insulin_basal = selected_insulin_basal
            ? selected_insulin_basal[0].trade_name
            : 0;
        data[0].selected_insulin_bolus = selected_insulin_bolus
            ? selected_insulin_bolus[0].trade_name
            : 0;
        data[0].selected_insulin_mixed = selected_insulin_mixed
            ? selected_insulin_mixed[0].trade_name
            : 0;

        const basal_insulin = all_insulin.data.filter((x) => x.type === 'BASAL_INSULIN');
        const bolus_insulin = all_insulin.data.filter((x) => x.type === 'BOLUS_INSULIN');
        const mixed_insulin = all_insulin.data.filter((x) => x.type === 'MIXED_INSULIN');

        // Set initial values
        data[0].basal_insulin = basal_insulin;
        data[0].bolus_insulin = bolus_insulin;
        data[0].mixed_insulin = mixed_insulin;

        // Used to store codes for selected insulins
        data[0].selected_insulin_basal_code = '';
        data[0].selected_insulin_bolus_code = '';
        data[0].selected_insulin_mixed_code = '';
        // Used to store dose units
        data[0].dose_wake_selected = '';
        data[0].dose_breakfast_selected = '';
        data[0].dose_lunch_selected = '';
        data[0].dose_dinner_selected = '';
        data[0].dose_bedtime_selected = '';
        data[0].wakeRanges = [{ name: '0-0', dose: 0 }];
        data[0].breakfastRanges = [{ name: '0-0', dose: 0 }];
        data[0].lunchRanges = [{ name: '0-0', dose: 0 }];
        data[0].dinnerRanges = [{ name: '0-0', dose: 0 }];
        data[0].sleepRanges = [{ name: '0-0', dose: 0 }];

        data[0].dose_wake = '';

        // Used to store global state for enabling or disabling meal rows
        // Sets defaults if any
        data[0].switch_wake = false;
        data[0].switch_breakfast = false;
        data[0].switch_lunch = false;
        data[0].switch_dinner = false;
        data[0].switch_bedtime = false;

        data[0].overloaded = false;
        // Fetches data to see if already exists
        let requiredMedsChecker = await getRequiredMedications(patient_id);
        let timeframesChecker = await getTimeframes(patient_id);

        const newTimeframes = [];
        TIME_ARRAY_CAPITAL.map((time) => {
            newTimeframes.push(timeframesChecker?.data?.filter((x) => x.name === time)[0]);
            return true;
        });
        timeframesChecker = [...newTimeframes];

        let requiredLogsChecker = await getRequiredLogs(patient_id);

        requiredLogsChecker = requiredLogsChecker.data;

        // sorts required logs by created_at es6
        requiredLogsChecker = requiredLogsChecker?.sort((a, b) => {
            if (a.created_at < b.created_at) {
                return -1;
            }
            if (a.created_at > b.created_at) {
                return 1;
            }
            return 0;
        });

        requiredMedsChecker = requiredMedsChecker.data.filter(
            (x) => x.is_insulin === true && (x.dose_prescribed !== null || x.dose_prescribed !== 0),
        );

        // For medication entries
        let medicationEntriesDiabetes = await axiosFetch(
            `/medication-entries/?is_insulin=true&patient_id=${patient_id}`,
        );

        medicationEntriesDiabetes = medicationEntriesDiabetes.data;

        medicationEntriesDiabetes = medicationEntriesDiabetes.sort(
            (a, b) =>
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero. medDate.timeframes.includes(time.id) ? medDate.id : []
                new Date(b.created_at) - new Date(a.created_at),
        );

        data[0].medicationEntriesDiabetes = medicationEntriesDiabetes;

        // For glucose Entries
        const glucoseEntries = await getGlucoseEntries(patient_id);

        data[0].glucoseEntries = glucoseEntries.data;

        // For RequiredMedicationDiabetes
        const tempMeds = [];
        const medTimeframeTemp = [];
        requiredMedsChecker.map((rule) => {
            // Here we map and set the values for
            // Diabetes Logs

            if (rule.timeframes.length > 0) {
                const tempNames = [];

                rule.timeframes.map((time) => {
                    timeframesChecker.map((timeInner) => {
                        if (time === timeInner.id) {
                            tempNames.push(timeInner.name);
                        }
                        return true;
                    });
                    return true;
                });
                medTimeframeTemp.push({
                    id: rule.id,
                    medName: rule.display_name,
                    times: tempNames,
                    timesIds: rule.timeframes,
                });
            }
            const created_at_custom = formatDate(rule.created_at);
            tempMeds.push({
                id: rule.id,
                medName: rule.display_name,
                nickname: rule.nickname,
                dose: rule.dose_prescribed,
                date: created_at_custom.date,
                day: created_at_custom.day,
                timeframes: rule.timeframes,
            });
            return true;
        });
        data[0].requiredMedsDiabetes = tempMeds;

        data[0].requiredLogs = requiredLogsChecker;

        // To turn on glucose check and corrections check
        if (requiredMedsChecker.length > 0) {
            requiredMedsChecker.map((rule, indexOuter) => {
                // if (rule.id === 'ERxqJJVAfnF6u4wbHRCdqb') {
                //     console.log(` >> skipping rule for testing`, rule);
                //     return;
                // }
                TIME_ARRAY.map((time, index) => {
                    let correctionalOn = false;
                    if (rule.timeframes[0] === timeframesChecker[index].id) {
                        if (!(Object.keys(timeframesChecker[index].dose_card).length === 0)) {
                            const temp = JSON.parse(timeframesChecker[index].dose_card);
                            correctionalOn = temp.diabetes.correctionalOn;
                        }

                        data[0][`corr_${time}`] = false;
                        data[0][`gluc_${time}`] = false;
                        if (requiredLogsChecker.length > 0) {
                            requiredLogsChecker[0].timeframes.map((log) => {
                                if (correctionalOn) {
                                    data[0][`corr_${time}`] = true;
                                    data[0][`gluc_${time}`] = true;
                                } else if (rule.timeframes[0] === log) {
                                    data[0][`gluc_${time}`] = true;
                                }
                                return true;
                            });
                        }
                        if (correctionalOn) {
                            data[0][`corr_${time}`] = correctionalOn;
                            data[0][`gluc_${time}`] = correctionalOn;
                        }

                        const prescribed = rule.dose_prescribed
                            ? parseInt(rule.dose_prescribed, 10)
                            : undefined;

                        if (prescribed) {
                            data[0][`dose_${time}`] = prescribed;
                            data[0][`dose_${time}_selected`] = rule.display_name;
                            data[0][`dose_${time}_selected_code`] = rule.medication_rule;
                            data[0][`switch_${time}`] = true;
                        }
                    }
                    return true;
                });
                return true;
            });
        }

        // Check if required logs and no required med then turn on
        timeframesChecker.map((time) => {
            let timeLower = '';
            if (time.name?.split('_')[1]) {
                timeLower = time.name?.split('_')[0]?.toLowerCase();
            } else {
                timeLower = time.name?.toLowerCase();
            }
            if (requiredLogsChecker.length > 0) {
                requiredLogsChecker[0].timeframes.map((log) => {
                    if (time.id === log) {
                        data[0][`gluc_${timeLower}`] = true;
                        data[0][`switch_${timeLower}`] = true;
                    }
                    return true;
                });
            }
            return true;
        });

        // Used for following switches
        data[0].corr_take = res.data.corr_take !== 0 ? res.data.corr_take : 0;

        // Used to determine types for insulin for specific meals
        data[0].insulin_type_unit_WAKE_UP = '';
        data[0].insulin_type_unit_BREAKFAST = '';
        data[0].insulin_type_unit_LUNCH = '';
        data[0].insulin_type_unit_DINNER = '';
        data[0].insulin_type_unit_SLEEP = '';

        /// Check for updates in diabetes values
        data[0].diabetesOrgValues = getOrgValues(data[0]);
        dispatch({
            type: DIABETES_ORIGINAL_SUCCESS,
            payload: getOrgValues(data[0]),
        });

        /// ///////
        dispatch({ type: DIABETES_DETAILS_SUCCESS, payload: data[0] });
    } catch (error) {
        dispatch({ type: DIABETES_DETAILS_FAIL, payload: error });
        dispatch({ type: DIABETES_ORIGINAL_FAIL, payload: error });
    }
};

// Reusable function for limits, sets one less than the next
let data = {};
const test = (name1, name2, diabetes) => {
    if (diabetes[name1] >= diabetes[name2]) {
        diabetes[name1] = diabetes[name2] - 1;
        if (diabetes[name1] < 0) {
            diabetes[name1] = 0;
        }
    }
    data = diabetes;
};

export const updateDiabetes = (details) => async (dispatch, getState) => {
    try {
        dispatch({ type: DIABETES_UPDATE_REQUEST });

        // Get current state
        const { diabetesDetails } = getState();
        const { diabetes } = diabetesDetails;

        diabetes[details.type] = details.value;

        if (
            details.type === 'wakeRanges' ||
            details.type === 'breakfastRanges' ||
            details.type === 'lunchRanges' ||
            details.type === 'dinnerRanges' ||
            details.type === 'sleepRanges'
        ) {
            return false;
        }

        if (diabetes[details.type] < 0) {
            diabetes[details.type] = 0;
        }

        // Checks if corr_above is greater then impossible then decrements corr above
        if (diabetes.corr_above > diabetes.impossible) {
            diabetes.corr_above = diabetes.impossible;
        }

        // checks if corr_above is less then hypo then sets it greater then hypo
        if (diabetes.corr_above <= diabetes.hypo && diabetes.impossible !== 0) {
            diabetes.corr_above = diabetes.hypo + 1;
            if (diabetes.corr_above > diabetes.impossible) {
                diabetes.corr_above = diabetes.impossible;
            }
        }

        // If corr every is 0 or empty the set it to 1
        if (diabetes.corr_for_every === 0 || diabetes.corr_for_every === '') {
            diabetes.corr_for_every = 1;
        }

        // Here we get the insulin codes for selected insulines
        ['basal', 'bolus', 'mixed'].map((insulin_name) => {
            if (
                diabetes[`insulin_${insulin_name}`] !== '' ||
                diabetes[`insulin_${insulin_name}`] !== 0
            ) {
                diabetes[`${insulin_name}_insulin`].filter((x) => {
                    if (x.trade_name === diabetes[`selected_insulin_${insulin_name}`]) {
                        diabetes[`selected_insulin_${insulin_name}_code`] = x.id;
                    }
                    return false;
                });
            }
            return false;
        });

        // Goes through the values 4 times to check all values if previous is greater than next
        for (let i = 0; i < 4; i++) {
            test('hypo', 'goal', diabetes);
            test('goal', 'far', diabetes);
            test('far', 'danger', diabetes);
            test('danger', 'impossible', diabetes);
        }

        /// Check for updates in diabetes values
        diabetes.diabetesOrgValues = getOrgValues(diabetes);
        /// ///////////////////

        dispatch({ type: DIABETES_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: DIABETES_DETAILS_FAIL, payload: error });
    }
};

const saveTimeFrames = async (timeframe, diabetes, ranges) => {
    if (diabetes) {
        const seen = new Set();

        const filteredRanges = ranges.filter((el) => {
            const duplicate = seen.has(el.rangeMin);
            seen.add(el.rangeMin);
            return !duplicate;
        });

        const newTimeframes = timeframe;
        newTimeframes.dose_card = `{"diabetes": {"correctionalOn": true, "correctionalInsulin": {"type": "blood glucose ranges", "ranges": ${JSON.stringify(
            filteredRanges,
        )}}}}`;
        const res = await putTimeframes(newTimeframes);
    } else {
        const seen = new Set();

        const filteredRanges = ranges.filter((el) => {
            const duplicate = seen.has(el.rangeMin);
            seen.add(el.rangeMin);
            return !duplicate;
        });

        const newTimeframes = timeframe;
        newTimeframes.dose_card = `{"diabetes": {"correctionalOn": false, "correctionalInsulin": {"type": "blood glucose ranges", "ranges": ${JSON.stringify(
            filteredRanges,
        )}}}}`;

        const res = await putTimeframes(newTimeframes);
    }
};

// Here we post the current tables data to the api at /diabetes link
// Method :: POST
// Link :: /diabetes
export const saveDiabetes = () => async (dispatch, getState) => {
    try {
        dispatch({ type: DIABETES_SAVE_REQUEST });

        // Get current state
        const { diabetesDetails } = getState();
        const { diabetes } = diabetesDetails;

        const { timeFrameDetails } = getState();
        const { timeframes } = timeFrameDetails;

        const newTimeframes = [];
        TIME_ARRAY_CAPITAL.map((time) => {
            newTimeframes.push(timeframes.filter((x) => x.name === time)[0]);
            return true;
        });

        // Get current data and time
        const today = new Date();
        const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
        const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
        const dateTime = `${date}T${time}`;

        // Data prepared to post and save
        const post_data = {
            patient: diabetes.patient,
            created_at: diabetes.created_at,
            updated_at: dateTime,
            treating_md: diabetes.treating_md,
            premade_reg: diabetes.premade_reg,
            corr_for_every: diabetes.corr_for_every,
            corr_above: diabetes.corr_above,
            corr_take: diabetes.corr_take,
            hypo: diabetes.hypo,
            goal: diabetes.goal,
            far: diabetes.far,
            danger: diabetes.danger,
            impossible: diabetes.impossible,
            basal_insulin:
                diabetes.selected_insulin_basal !== 0 ? diabetes.selected_insulin_basal_code : '',
            bolus_insulin:
                diabetes.selected_insulin_bolus !== 0 ? diabetes.selected_insulin_bolus_code : '',
            mixed_insulin:
                diabetes.selected_insulin_mixed !== 0 ? diabetes.selected_insulin_mixed_code : '',
        };

        let res;
        if (diabetes.new) {
            res = await postDiabetes(post_data);
        } else res = await patchDiabetes(diabetes.patient, post_data);

        // Here we delete all existing rules related to currently activated timeframes

        const resDeleter = await getRequiredMedications(diabetes.patient);
        await resDeleter.data.map((rule) => {
            if (
                rule.patient === diabetes.patient &&
                rule.timeframes.length > 0 &&
                rule.is_insulin &&
                !rule.is_correctional
            )
                deleteRequiredMedications(rule.id);
            return true;
        });

        TIME_ARRAY.map((time, index) => {
            const timeIndex = index;

            saveTimeFrames(
                newTimeframes[timeIndex],
                diabetes[`corr_${time}`],
                diabetes[`${time}Ranges`],
            );

            return true;
        });

        // Here we save the new timeframes

        setTimeout(() => {
            TIME_ARRAY.map((time, index) => {
                if (diabetes[`switch_${time}`]) {
                    if (diabetes[`dose_${time}_selected`]) {
                        const required_medications = {
                            display_name: diabetes[`dose_${time}_selected`],
                            patient: diabetes.patient,
                            timeframes: [newTimeframes[index].id],
                            medication_rule: diabetes[`dose_${time}_selected_code`],
                            dose_prescribed: Number(diabetes[`dose_${time}`]),
                        };
                        postRequiredMedications(required_medications);
                    }
                }

                return false;
            });
        }, 1000);

        // Here we save required logs for glucose toggle:
        const requiredLogs = [];
        TIME_ARRAY.map((time, index) => {
            const timeIndex = index;

            if (diabetes[`gluc_${time}`]) {
                newTimeframes.map((timeframe) => {
                    if (timeIndex === 0) {
                        if (`${time.toUpperCase()}_UP` === timeframe.name) {
                            requiredLogs.push(timeframe.id);
                            return false;
                        }
                    } else if (time.toUpperCase() === timeframe.name) {
                        requiredLogs.push(timeframe.id);
                        return false;
                    }
                    return true;
                });
            }

            return false;
        });

        try {
            if (!diabetes.requiredLogs.length > 0) {
                await axiosFetch.post(`/required-logs/`, {
                    patient: diabetes.patient,
                    type: 'BG',
                    timeframes: requiredLogs,
                });
            } else if (requiredLogs?.length > 0)
                await axiosFetch.put(`/required-logs/${diabetes.requiredLogs[0].id}/`, {
                    patient: diabetes.patient,
                    type: 'BG',
                    timeframes: requiredLogs,
                });
        } catch (err) {}

        trackMixPanel(TRACK_DIABETES_SAVE_SUCCESS);
        dispatch({ type: DIABETES_SAVE_SUCCESS, payload: data });
        dispatch({ type: DIABETES_ORIGINAL_SUCCESS, payload: getOrgValues(data) });
    } catch (error) {
        trackMixPanel(TRACK_DIABETES_SAVE_FAILURE);
        dispatch({ type: DIABETES_SAVE_FAIL, payload: error });
    }
};
