import React from 'react';
import { Grid, Typography } from '@mui/material';
import Input from 'components/atoms/Input';
import { textStyle } from 'styles/components/molecules/hypertension/SettingLimit';
import { useDispatch, useSelector } from 'react-redux';
import { HYPERTENSION_UPDATE_FULL } from 'redux/constants/hypertensionConstants';
import 'styles/components/molecules/hypertension/SettingLimit.scss';

function SettingsLimits() {
  const textClass = textStyle();
  const dispatch = useDispatch();

  const { hypertension } = useSelector((state) => state.hypertensionDetails);

  // Initialize current tempdata by API retrieved data
  const [tempData, setTempData] = React.useState({
    systolic_low: hypertension.systolic_low,
    systolic_goal: hypertension.systolic_goal,
    systolic_high: hypertension.systolic_high,
    systolic_impossible: hypertension.systolic_impossible,
    diastolic_low: hypertension.diastolic_low,
    diastolic_goal: hypertension.diastolic_goal,
    diastolic_high: hypertension.diastolic_high,
    diastolic_impossible: hypertension.diastolic_impossible,
    heart_rate_low: hypertension.heart_rate_low,
    heart_rate_goal: hypertension.heart_rate_goal,
    heart_rate_high: hypertension.heart_rate_high,
    heart_rate_impossible: hypertension.heart_rate_impossible,
  });

  // Checks and fixes low<goal<high<impossible
  const checkEquality = (name1, name2, hypTemp) => {
    if (hypTemp[name1] >= hypTemp[name2]) {
      hypTemp[name1] = hypTemp[name2] - 1;
      setTempData((preVal) => ({ ...preVal, [name1]: hypTemp[name2] - 1 }));
      if (hypTemp[name1] < 0) {
        setTempData((preVal) => ({ ...preVal, [name1]: 0 }));
      }
    }
  };

  // Onchange event handler and limit checker
  const updater = (e) => {
    if (e.target.value > 300 || e.target.value < 0) return;
    setTempData((preVal) => ({
      ...preVal,
      [e.target.name]: Number(e.target.value),
    }));
  };

  // Fixes equality on blur
  const onBlurChecker = (e) => {
    let {name} = e.target;

    switch (name) {
      case 'systolic_low':
      case 'systolic_goal':
      case 'systolic_high':
      case 'systolic_impossible':
        name = 'systolic';
        break;
      case 'diastolic_low':
      case 'diastolic_goal':
      case 'diastolic_high':
      case 'diastolic_impossible':
        name = 'diastolic';
        break;
      case 'heart_rate_low':
      case 'heart_rate_goal':
      case 'heart_rate_high':
      case 'heart_rate_impossible':
        name = 'heart_rate';
        break;
      default:
        break;
    }

    for (let i = 0; i < 3; i++) {
      checkEquality(`${name}_low`, `${name}_goal`, tempData);
      checkEquality(`${name}_goal`, `${name}_high`, tempData);
      checkEquality(`${name}_high`, `${name}_impossible`, tempData);
    }

    dispatch({
      type: HYPERTENSION_UPDATE_FULL,
      dataUpdate: { ...tempData },
    });
  };

  return (
    <div>
      <Grid container>
        <Grid xs={8} item container mb={2}>
          <Grid xs={4} item container>
            <Typography
              sx={{
                // styleName: Bold/Title 3;
                fontFamily: 'Comfortaa',
                fontSize: '20px',
                fontWeight: 700,
                lineHeight: '25px',
                letterSpacing: '0.3799999952316284px',
                textAlign: 'left',
                color: '#004680',
              }}
            >
              Limit
            </Typography>
          </Grid>
          <Grid xs={2} item>
            <Typography className="textstyleheadingsmall">Low</Typography>
          </Grid>
          <Grid xs={2} item>
            <Typography className="textstyleheadingsmall">Goal</Typography>
          </Grid>
          <Grid xs={2} item>
            <Typography className="textstyleheadingsmall">High</Typography>
          </Grid>
          <Grid xs={2} item>
            <Typography className="textstyleheadingsmall">
              Impossible
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={8} item container my={1}>
          <Grid xs={4} item container alignItems='center'>
            <Typography className='textstyleheadingrow'>Systolic</Typography>
          </Grid>
          <Grid
            xs={2}
            item
            container
            alignItems='center'
            justifyContent='center'
          >
            <Grid item xs={6}>
              <Input
                classes={textClass}
                name='systolic_low'
                value={tempData.systolic_low?.toString()}
                onChange={(e) => updater(e)}
                type='number'
                onBlur={onBlurChecker}
              />
            </Grid>
          </Grid>
          <Grid
            xs={2}
            item
            container
            alignItems='center'
            justifyContent='center'
          >
            <Grid item xs={6}>
              <Input
                classes={textClass}
                name='systolic_goal'
                value={tempData.systolic_goal?.toString()}
                onChange={(e) => updater(e)}
                type='number'
                onBlur={onBlurChecker}
              />
            </Grid>
          </Grid>
          <Grid
            xs={2}
            item
            container
            alignItems='center'
            justifyContent='center'
          >
            <Grid item xs={6}>
              <Input
                classes={textClass}
                name='systolic_high'
                value={tempData.systolic_high?.toString()}
                onChange={(e) => updater(e)}
                type='number'
                onBlur={onBlurChecker}
              />
            </Grid>
          </Grid>
          <Grid
            xs={2}
            item
            container
            alignItems='center'
            justifyContent='center'
          >
            <Grid item xs={6}>
              <Input
                classes={textClass}
                name='systolic_impossible'
                value={tempData?.systolic_impossible?.toString()}
                onChange={(e) => updater(e)}
                type='number'
                onBlur={onBlurChecker}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Second row */}
        <Grid xs={8} item container my={1}>
          <Grid xs={4} item container alignItems='center'>
            <Typography className='textstyleheadingrow'>Diastolic</Typography>
          </Grid>
          <Grid
            xs={2}
            item
            container
            alignItems='center'
            justifyContent='center'
          >
            <Grid item xs={6}>
              <Input
                classes={textClass}
                name='diastolic_low'
                value={tempData.diastolic_low?.toString()}
                onChange={(e) => updater(e)}
                type='number'
                onBlur={onBlurChecker}
              />
            </Grid>
          </Grid>
          <Grid
            xs={2}
            item
            container
            alignItems='center'
            justifyContent='center'
          >
            <Grid item xs={6}>
              <Input
                classes={textClass}
                name='diastolic_goal'
                value={tempData.diastolic_goal?.toString()}
                onChange={(e) => updater(e)}
                type='number'
                onBlur={onBlurChecker}
              />
            </Grid>
          </Grid>
          <Grid
            xs={2}
            item
            container
            alignItems='center'
            justifyContent='center'
          >
            <Grid item xs={6}>
              <Input
                classes={textClass}
                name='diastolic_high'
                value={tempData.diastolic_high?.toString()}
                onChange={(e) => updater(e)}
                type='number'
                onBlur={onBlurChecker}
              />
            </Grid>
          </Grid>
          <Grid
            xs={2}
            item
            container
            alignItems='center'
            justifyContent='center'
          >
            <Grid item xs={6}>
              <Input
                classes={textClass}
                name='diastolic_impossible'
                value={tempData.diastolic_impossible?.toString()}
                onChange={(e) => updater(e)}
                type='number'
                onBlur={onBlurChecker}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Third row */}
        <Grid xs={8} item container my={1}>
          <Grid xs={4} item container alignItems='center'>
            <Typography className='textstyleheadingrow'>Heart Rate</Typography>
          </Grid>
          <Grid
            xs={2}
            item
            container
            alignItems='center'
            justifyContent='center'
          >
            <Grid item xs={6}>
              <Input
                classes={textClass}
                name='heart_rate_low'
                value={tempData.heart_rate_low?.toString()}
                onChange={(e) => updater(e)}
                type='number'
                onBlur={onBlurChecker}
              />
            </Grid>
          </Grid>
          <Grid
            xs={2}
            item
            container
            alignItems='center'
            justifyContent='center'
          >
            <Grid item xs={6}>
              <Input
                classes={textClass}
                name='heart_rate_goal'
                value={tempData.heart_rate_goal?.toString()}
                onChange={(e) => updater(e)}
                type='number'
                onBlur={onBlurChecker}
              />
            </Grid>
          </Grid>
          <Grid
            xs={2}
            item
            container
            alignItems='center'
            justifyContent='center'
          >
            <Grid item xs={6}>
              <Input
                classes={textClass}
                name='heart_rate_high'
                value={tempData.heart_rate_high?.toString()}
                onChange={(e) => updater(e)}
                type='number'
                onBlur={onBlurChecker}
              />
            </Grid>
          </Grid>
          <Grid
            xs={2}
            item
            container
            alignItems='center'
            justifyContent='center'
          >
            <Grid item xs={6}>
              <Input
                classes={textClass}
                name='heart_rate_impossible'
                value={tempData.heart_rate_impossible?.toString()}
                onChange={(e) => updater(e)}
                type='number'
                onBlur={onBlurChecker}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default SettingsLimits;
