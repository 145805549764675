import { makeStyles } from '@mui/styles';

const listStyles = makeStyles({
  boxContainer: {
    width: '100%',
    height: 409,
    position: 'relative',
    // boxShadow: '1px 2px 6px #886c6c85;',
    border: '1px solid #E8ECF1',
    borderTop: '0px',

    padding: '15px',
  },
  listText: {
    // styleName: bold / body for web app;
    fontFamily: 'Work Sans, serif',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.40px',
    textAlign: 'left',
    color: '#282D36',
  },
  root: {
    '& .MuiInputBase-root': {
      height: '40px',
      width: '297px',
      //   left: '119px',
      //   top: '0px',
      background: '#FCFDFE',
      borderRadius: '8px',
    },

    '& .MuiInputBase-input-MuiOutlinedInput-input': {
      borderRadius: '8px',
    },

    '& .MuiFormHelperText-root': {
      marginTop: '8px',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      background: '#F1F3F7',
    },
  },

  selectStyles: {
    root: {
      height: '40px',
      width: '297px',
      borderRadius: '8px',
      background: '#F7F9FE',
      outline: 'none !important',
      border: 'none !important',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none !important',
      },
    },

    dropdownStyle: {
      '& .Mui-selected': {
        background: (props) =>
          props.noStyleSelect ? '' : '#004680 !important',
        color: (props) => (props.noStyleSelect ? '' : 'white !important'),
        height: '30px',
        width: '250px',
      },
    },
  },
});

export const listStylesSelect = makeStyles({
  root: {
    height: '40px',
    width: '297px',
    borderRadius: '8px !important',
    background: '#F7F9FE',
    outline: 'none !important',
    fontFamily: 'Work Sans, serif',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.23px',
    textAlign: 'left',
    border: 'none !important',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
  },

  dropdownStyle: {
    // styleName: Bold/Subheadline;
    fontFamily: 'Work Sans, serif !important',
    padding: '0px',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.23px',
    textAlign: 'left',
    '& .MuiMenuItem-root': {
      fontFamily: 'Work Sans, serif !important',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '-0.23px',
      textAlign: 'left',
      width: '297px',
    },

    '& .Mui-selected': {
      background: '#004680 !important',
    },
  },
});

export default listStyles;
