import {
  ENCOUNTERS_NOTES_FETCH_SUCCESS,
  ENCOUNTERS_NOTES_FETCH_FAIL,
  ENCOUNTERS_NOTES_FETCH_REQUEST,
  ENCOUNTERS_SINGLE_FETCH_SUCCESS,
  ENCOUNTERS_SINGLE_FETCH_FAIL,
  ENCOUNTERS_SINGLE_FETCH_REQUEST,
  ENCOUNTERS_NOTES_CREATE_SUCCESS,
  ENCOUNTERS_NOTES_CREATE_FAIL,
  ENCOUNTERS_NOTES_CREATE_REQUEST,
  ENCOUNTERS_NOTES_EDITOR_FAIL,
  ENCOUNTERS_NOTES_EDITOR_REQUEST,
  ENCOUNTERS_NOTES_EDITOR_SUCCESS,
} from '../constants/encountersNotesConstants';
import axiosFetch from 'redux/config/axios';
import { capitalize } from './helperFunctions';
import moment from 'moment';
import { trackMixPanel } from 'redux/proxies/mixpanel';
import {
  TRACK_ENCOUNTERS_NOTES_FETCHED_FAIL,
  TRACK_ENCOUNTERS_NOTES_FETCHED_SUCCESS,
  TRACK_ENCOUNTERS_NOTE_EDITING,
  TRACK_ENCOUNTERS_NOTE_EDITING_FAILED,
  TRACK_ENCOUNTERS_NOTE_OPENED,
  TRACK_ENCOUNTERS_NOTE_OPEN_FAILED,
  TRACK_ENCOUNTERS_SAVE_FAILURE,
  TRACK_ENCOUNTERS_SAVE_SUCCESS,
} from 'redux/constants/mixPanel';

export const showEncounters = (patient_id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ENCOUNTERS_NOTES_FETCH_REQUEST });

    const { data } = await axiosFetch.get(
      `/signed-encounters-notes?patient_id=${patient_id}`
    );

    trackMixPanel(TRACK_ENCOUNTERS_NOTES_FETCHED_SUCCESS);
    dispatch({ type: ENCOUNTERS_NOTES_FETCH_SUCCESS, payload: data });
  } catch (error) {
    trackMixPanel(TRACK_ENCOUNTERS_NOTES_FETCHED_FAIL);
    dispatch({ type: ENCOUNTERS_NOTES_FETCH_FAIL, payload: error });
  }
};

export const showSingleEncounters = (id) => async (dispatch) => {
  try {
    dispatch({ type: ENCOUNTERS_SINGLE_FETCH_REQUEST });

    const { data } = await axiosFetch.get(`/signed-encounters-notes/${id}/`);

    let newData = data;
    newData['Value'] = moment('2015-01-16T12:00:00').format('hh:mm:ss a');

    const time = moment.utc(data?.encounter_time).format('HH:mm');

    const timex = time.split(':');

    newData['timePiker'] = `${timex[0]} : ${timex[1]}`;

    trackMixPanel(TRACK_ENCOUNTERS_NOTE_OPENED);
    dispatch({ type: ENCOUNTERS_SINGLE_FETCH_SUCCESS, payload: newData });
  } catch (error) {
    trackMixPanel(TRACK_ENCOUNTERS_NOTE_OPEN_FAILED);
    dispatch({ type: ENCOUNTERS_SINGLE_FETCH_FAIL, payload: error });
  }
};

export const saveEncounters = (postData, mode, id) => async (dispatch) => {
  try {
    dispatch({ type: ENCOUNTERS_NOTES_CREATE_REQUEST });

    let response = {};
    if (mode === 'create') {
      const { data } = await axiosFetch.post(
        `/signed-encounters-notes/`,
        postData
      );
      response = data;
    } else if (mode === 'update') {
      const { data } = await axiosFetch.put(
        `/signed-encounters-notes/${id}/`,
        postData
      );

      response = data;
    }

    trackMixPanel(TRACK_ENCOUNTERS_SAVE_SUCCESS);
    dispatch({ type: ENCOUNTERS_NOTES_CREATE_SUCCESS, payload: response });
  } catch (error) {
    trackMixPanel(TRACK_ENCOUNTERS_SAVE_FAILURE);
    dispatch({ type: ENCOUNTERS_NOTES_CREATE_FAIL, payload: error });
  }
};

export const getEncountersEditorData = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ENCOUNTERS_NOTES_EDITOR_REQUEST });

    //Gets data from diagnoses and medication to be displayed in the encounters notes
    const { patientData } = getState();
    const { patient } = patientData;
    const { medData, diagnosisData } = patient;

    let newDiagnoses = [];
    diagnosisData.map((diagnosis) => {
      let tempCodes = [];
      medData?.filter(x=>x.code.length>0)?.map((med) => {
        med.code.map((codeSingle) => {
          if (diagnosis.code === codeSingle.code) {
            tempCodes.push({
              id: med.id,
              name: med.medName
                ? `${capitalize(med.medName)} ${med.dose ?? med.dose}${
                    med.units ?? med.units
                  } ${med.route ?? med.route}`
                : '',
            });
          }
          return true;
        });
        return true;
      });
      let uniqtempCodes = [...new Set(tempCodes)];
      newDiagnoses.push({
        id: diagnosis.id,
        label: diagnosis.label,
        code: diagnosis.code,
        nickname: diagnosis.nickname,
        medications: uniqtempCodes,
      });
      return true;
    });

    //filter medData to get only medication without code
    let newMedications = [];
    medData?.filter(x=>x.code.length===0)?.map((med) => {
      newMedications.push({
        ...med,
        
      });
      return true;
    });


    trackMixPanel(TRACK_ENCOUNTERS_NOTE_EDITING);
    dispatch({
      type: ENCOUNTERS_NOTES_EDITOR_SUCCESS,
      payload: {editor:newDiagnoses,noMeds:newMedications},
    });
  } catch (error) {
    trackMixPanel(TRACK_ENCOUNTERS_NOTE_EDITING_FAILED);
    dispatch({ type: ENCOUNTERS_NOTES_EDITOR_FAIL, payload: error });
  }
};
