import { makeStyles } from '@mui/styles';

const LogStyles = makeStyles((theme) => ({
  tableCellHead: {
    width: '120px',
  },
  tableCellHeadDate: {
    // styleName: Bold/Callout;
    fontFamily: 'Work Sans, sans-serif',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '21px',
    letterSpacing: '-0.31px',
    textAlign: 'center',
    color: '#53575E',
  },
  tableCellHeadDay: {
    // styleName: Regular/Subheadline;
    fontFamily: 'Work Sans, sans-serif',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '-0.23px',
    textAlign: 'center',
    color: '#7E8186',
  },
  tableCellHeadType: {
    // styleName: Regular/Callout;
    fontFamily: 'Work Sans, serif',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '21px',
    letterSpacing: '-0.31px',
    textAlign: 'center',
    color: '#004680',
  },
  timeframeName: {
    fontFamily: 'Comfortaa, cursive !important',
    fontWeight: '700',
    fontSize: '17px',
    letterSpacing: '-0.43px',
    lineHeight: '22px',
    color: '#004680',
  },
  rowText: {
    // styleName: regular / body for web app ;
    fontFamily: 'Work Sans, serif !important',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.40px',
    textAlign: 'center',
    color: '#282D36',
  },
  dangerGlucose: {
    height: '32px',
    width: '53px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: '235px',
    top: '466px',
    borderRadius: '4px',
    padding: '4px,16px,4px,16px',
    background: '#EC5D57',
    color: 'white',
  },
}));

export default LogStyles;
