import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';

export default function ControlledCheckbox(props) {
  const [checked, setChecked] = React.useState(false);
  React.useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);
  return (
    <Checkbox
      checked={props.checked}
      onChange={props.onChange}
      inputProps={{ 'aria-label': 'controlled' }}
      name={props.name}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      checked={checked}
      disabled={props.disabled}
    />
  );
}
