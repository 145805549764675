import React from 'react'
import { Navigate } from 'react-router-dom'
import { Tokens } from 'utils/Tokens'

interface IProps {
  children: React.ComponentType
}

export function PrivateRoute({ children }: IProps) {
  const isAuthenticated = Tokens.getAccess()
  const isRefreshAvailable = Tokens.getRefresh()

  return isAuthenticated || isRefreshAvailable ? children : <Navigate to='/signin' />
}
