import * as React from 'react';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles({
  root: {
    height: '60px',
    width: '700px',
    borderRadius: '16px',
    boxShadow: 'none',
    marginLeft: '20px',
    '& .MuiInputBase-root': {
      borderRadius: '16px',
      background: 'white',
      boxShadow: 'none',
    },

    '& .MuiInputBase-input-MuiOutlinedInput-input': {
      borderRadius: '16px',
    },

    '& .MuiFormHelperText-root': {
      marginTop: '8px',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      background: '#F1F3F7',
    },
  },
});

export default function SearchBar(props) {
  const classes = useStyles();
  return (
    <TextField
      id='outlined-basic'
      variant='outlined'
      sx={props.sx}
      placeholder={props.placeholder}
      helperText={props.helperText}
      className={`${classes.root} ${props.classes ? props.classes.root : ''}`}
      error={props.error}
      disabled={props.disabled}
      autoFocus={!!props.success}
      color={props.success ? 'success' : ''}
      value={props.value}
      onClick={props.onClick}
      onChange={props.onChange}
      onBlur={props.onBlur}
      InputProps={{
        startAdornment: <SearchIcon sx={props.iconSx} />,
      }}
    />
  );
}

SearchBar.defaultProps = {
  disabled: false,
};
