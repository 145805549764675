import React from 'react';
import Button from 'components/atoms/Button';
import Select from 'components/atoms/Select';
import { MenuItem , Grid } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { setOnboardingDetails } from 'redux/actions/onboardingActions';
import { useOnboarding } from 'redux/reusables/onboarding';
import { useSettings } from 'redux/reusables/settings';
import { useForceUpdate } from 'hooks/useForceUpdate';

// Styling dropdown
const useStyles = makeStyles({
  root: {
    height: '50px',
    marginBottom:  '25px',
    width: '560px',
    borderRadius: '16px !important',
    background: '#F7F9FE',
    outline: 'none !important',
    border: 'none !important',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
  },

  dropdownStyle: {
    '& .Mui-selected': {
      background: '#004680 !important',
      color: 'white',
      height: '30px',
      width: '560px',
      fontSize: '17px',
    },
  },
});

export default function Step3(props) {
  const classes = useStyles();

  // Redux for default values
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();

  const { onboarding } = useOnboarding();
  const { states } = useSettings();

  const {medProvider, state} = onboarding;

  // Set values for select on change
  const handleChangeSelect = (key, value) => {
    dispatch(
      setOnboardingDetails({
        type: key,
        value,
      })
    );
    forceUpdate();
  };

  return (
    <div>
      <Grid
        xs={12}
        container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: '400px',
        }}
      >
      <Grid xs={12} container>
        <Select
          classes={classes}
          value={medProvider}
          onChange={e => handleChangeSelect('medProvider', e.target.value)}
          disableSelect
        >
          <MenuItem key={1} value="Pod1">
            Pod1
          </MenuItem>
        </Select>
        <Select
          classes={classes}
          value={state}
          onChange={e => handleChangeSelect('state', e.target.value)}
          disableSelect
        >
          {states.map(state => (
            <MenuItem key={state.id} value={state.id}>{state.name}</MenuItem>
          ))}
        </Select>
      </Grid>

        <Grid xs={12} item container mt={5}>
          <Button
            title="Next"
            onClick={props.onClick}
            disabled={onboarding.medProvider === ''}
            secondary={onboarding.medProvider === ''}
          />
        </Grid>
      </Grid>
    </div>
  );
}
