import * as React from 'react';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    height: '24px',
    width: '44px',
    padding: '0px',
    borderRadius: '12px',
    '& .MuiSwitch-switchBase': {
      padding: '0px',
      height: '24px',
      width: '24px',
    },
    '& .Mui-checked': {
      color: 'white ',
    },
    '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
      background: '#1A90D9',
    },
  },
});

export default function BasicSwitches(props) {
  const classes = useStyles();

  return (
    <div>
      <Switch
        checked={props.checked}
        className={classes.root}
        onChange={props.onChange}
        value={props.value}
        disabled={props.disabled}
      />
    </div>
  );
}

BasicSwitches.defaultProps = {
  disabled: false,
};
