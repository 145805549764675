import axiosFetch from 'redux/config/axios';

export const getGlucoseEntries = async (id) => {
  let res;
  if (id) res = await axiosFetch.get(`/glucose-entries/?patient_id=${id}`);
  else res = await axiosFetch.get('/glucose-entries/');

  return res;
};

export const getSingleGlucoseEntries = async (id) => {
  const res = await axiosFetch.get(`/glucose-entries/${id}/`);
  return res;
};

export const postGlucoseEntries = async (data) => {
  const res = await axiosFetch.post('/glucose-entries/', data);
  return res;
};

export const putGlucoseEntries = async (id, data) => {
  const res = await axiosFetch.put(`/glucose-entries/${id}/`, data);
  return res;
};

export const patchGlucoseEntries = async (id, data) => {
  const res = await axiosFetch.patch(`/glucose-entries/${id}/`, data);
  return res;
};

export const deleteGlucoseEntries = async (id) => {
  const res = await axiosFetch.delete(`/glucose-entries/${id}/`);
  return res;
};
