import React from 'react';
import { Grid, Typography } from '@mui/material';
import SectionTitle from 'components/atoms/PatientChart/SectionTitle';
import { usePatient } from 'redux/reusables/patients';
import RichEditor from '../Editor/RichEditor';

export default function SurgicalHistory() {
  // Redux
  const { patient } = usePatient();

  // Default content
  const [content, setContent] = React.useState(
    '{"blocks":[{"key":"1k8j6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}'
  );

  // Change content on state change
  const patientCheck = patient.SURGICAL;
  React.useEffect(() => {
    if (patientCheck) {
      setContent(patientCheck);
    }
  }, [patientCheck]);

  return (
    <div
      style={{
        maxWidth: '676px',
        minHeight: '352px',
        width: '100%',
        height: '100%',
        borderRadius: '16px',
        background: 'white',
      }}
    >
      <Grid container p={3}>
        <SectionTitle
          title='Surgical history'
          name='SURGICAL'
          editable
          defaultValue={content}
          label="Surgical History"
        />
        {/* <Grid
          container
          item
          pt={3}
          mb={1}
          sx={{ borderBottom: '1px solid #D4D5D7', paddingBottom: '15px' }}
        > */}
          {/* HEadings here */}
          {/* <Grid item xs={10}>
            <Typography
              sx={{ color: '#7E8186', fontSize: '16px', fontWeight: '400' }}
            >
              Surgery
            </Typography>
          </Grid> */}

          {/* <Grid item xs={2}>
            <Typography
              sx={{
                color: '#7E8186',
                fontSize: '16px',
                fontWeight: '400',
              }}
            >
              Date
            </Typography>
          </Grid> */}
        {/* </Grid> */}

        {/* Data here */}
        {/* {[
          {
            diagnosis: 'The longest surgery could name up to 480 px',
            code: '10/25/2021',
          },
          {
            diagnosis: 'Surgery name # 2',
            code: '10/25/2019',
          },
        ].map((data) => (
          <Grid container item pt={3} alignItems='center'>
            <Grid item xs={10}>
              <Typography
                sx={{ color: '#004680', fontSize: '17px', fontWeight: '500' }}
                variant='p'
              >
                {data.diagnosis}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                sx={{ color: '#282D36', fontSize: '17px', fontWeight: '400' }}
              >
                {data.code}
              </Typography>
            </Grid>
          </Grid>
        ))} */}
        <Grid xs={12}>
          <RichEditor readOnly defaultValue={content} />
        </Grid>
      </Grid>
    </div>
  );
}
