import { makeStyles } from '@mui/styles';

export const useStylesAuto = makeStyles({
  root: {
    width: '160px',
    borderRadius: '8px',
    border: 'none',
    '& .MuiInputBase-root': {
      borderRadius: '8px',
      padding: '0px',
      height: '40px',
      paddingLeft: '8px',
      outline: 'none',
    },

    '& .MuiInputBase-input-MuiOutlinedInput-input': {
      borderRadius: '8px',
    },

    '& .MuiFormHelperText-root': {
      marginTop: '8px',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      background: '#F1F3F7',
    },
  },
});
// Here we style the Input fields

export const useStylesInput = makeStyles({
  root: {
    width: '100%',
    padding: '0 5px!important',
    borderRadius: '8px',
    '& .MuiInputBase-root': {
      borderRadius: '8px',
      padding: '0px',
      height: '40px',
      paddingRight: '10px',
    },

    '& .MuiInputBase-input-MuiOutlinedInput-input': {
      borderRadius: '8px',
      padding: '0px',
    },

    '& .MuiFormHelperText-root': {
      marginTop: '8px',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      background: '#F1F3F7',
    },
  },
});

export const useStylesInputSearch = makeStyles({
  root: {
    width: '148px',
    borderRadius: '8px',
    '& .MuiInputBase-root': {
      borderRadius: '8px',
      padding: '0px',
      height: '35px',
    },

    '& .MuiInputBase-input-MuiOutlinedInput-input': {
      borderRadius: '8px',
      padding: '0px',
    },

    '& .MuiFormHelperText-root': {
      marginTop: '8px',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      background: '#F1F3F7',
    },
  },
});
// Here we style the input fields

export const useStyles = makeStyles({
  root: {
    height: '44px',
    width: '223px',
    borderRadius: '4px!important',
    fontSize: '15px',
    // background: '#F7F9FE',
    outline: 'none !important',
    border: 'none !important',
    paddingLeft: '24px',
    // background: 'gray',
    background: 'none',

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
      //   background: 'red',
      //   padding: '0px !Important',
    },
    '& .MuiOutlinedInput-input': {
      background: (props) => props.color,
      padding: '0px 0px  0px 15px!important',
      maxWidth: '162px !important',
      height: '28px',
      alignItems: 'center',
      display: 'inline-flex ',
      //   paddingLeft: '5px',
    },
  },

  dropdownStyle: {
    padding: '0px',
    paddingLeft: '24px',
    fontSize: '15px',
    marginTop: '5px',
    width: '196px',
    background: 'white !important',
    display: 'flex',
    '& .Mui-selected': {
      // background: 'red',
      width: '177px',
      height: '28px',
      borderRadius: '4px',
      marginTop: '2px',
      color: 'white',
    },
  },
});
