import * as React from 'react';
import { useState , useEffect , useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import 'styles/components/templates/loginUi/index.scss';
import { API_URL } from 'constant';

import { Tokens } from 'redux/config/getToken';

import BackdropCustom from 'components/atoms/Backdrop';

export default function SignIn({ setStep }) {
  useEffect(() => {
    console.clear();
    const validate = Tokens.getAccess();
    if (validate) {
      navigate('/dashboard');
    }

  }, []);
  const navigate = useNavigate();
  const theme = createTheme();
  const [loading, setLoading] = useState(false);

  const [LoginError, setLoginError] = useState(false);
  const [Mobile, setMobile] = useState('');

  //   validation area
  const reducer = (state, action) => {
    if (action.type === 'Mobile') {
      if (Mobile.length < 10) {
        //  setDisableBtn(true)
        return (state = 'Invalid Number');
      }
    }
  };
  const [state, dispatch] = useReducer(reducer, '');
  // valdation area over
  const handleSubmit = async (event) => {
    event.preventDefault();
    axios.defaults.withCredentials = true;
    setLoading(true);
    // Validate Api
    try {
      const res = await axios.post(`${API_URL}/api/get-sms-password-reset/`, {
        mobile_phone: `+1${  Mobile}`,
      });

      event.preventDefault();
      if (res.status === 200) {
        // history.push({ pathname: '/OTP', state: { detail: '+1' + Mobile } });
        setStep(2);
      }
      setLoading(false);
    } catch (err) {
      setLoginError(true);
      setLoading(false);
    }
  };
  // Regex for numeric value
  function handleChange1(event) {
    const Mobile = event.target.value;
    setMobile(
      Mobile.replace(/[A-Za-z!\-?=@`~;:._'"\[\]\ \+\{}|#$%^&*()\\//]/g, '')
    );
  }
  return (
    <div className="loginSection">
      <ThemeProvider theme={theme} style={{ backgroundColor: 'red' }}>
        <Container component='main'>
          <BackdropCustom open={loading} />
          <CssBaseline />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            className="loginBox"
          >
            <img
              src='/images/PrecinaLogo.png'
              style={{ width: '130px', height: '40px', marginBottom: '30px' }}
              alt='logo here'
            />

            <Box
              component='form'
              onSubmit={(e) => handleSubmit(e)}
              noValidate
              sx={{ mt: 1 }}
              style={{ display: 'block', width: '100%' }}
            >
              <Typography
                variant='h5'
                component='h5'
                style={{
                  fontFamily: 'Comfortaa',
                  fontWeight: '700!important',
                  fontSize: '22px!important',
                  lineHeight: ' 48px',
                  letterSpacing: ' 0.35px',
                  color: '#282D36',
                  marginBottom: '25px',
                }}
              >
                Reset Password
              </Typography>
              <Typography className="labeltxt">phone number*</Typography>
              <TextField
                margin='normal'
                required
                fullWidth
                name='mobile'
                placeholder='Your Mobile Number'
                type='mobile'
                id='mobile'
                autoComplete='mobile'
                value={Mobile}
                onChange={handleChange1}
                InputProps={{ startAdornment: '+1' }}
                className="inputfield"
                inputProps={{ maxLength: 10 }}
                onBlur={() => dispatch({ type: 'Mobile' })}
              />
              <div style={{ textAlign: '-webkit-right' }}>
                <p style={{ color: 'red' }}>
                  <b>{state}</b>
                </p>
              </div>

              <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
                className="Button"
                // disabled={disableBtn}
              >
                Next
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
}
