export const getSimilarInputsUnits = (input) => {
  switch (input) {
    case 'g':
    case 'grams':
      return 'g';

    case 'mg':
    case 'milligrams':
      return 'mg';

    case 'mL':
    case 'milliliter':
      return 'mL';

    case 'L':
    case 'Liter':
      return 'L';

    case 'U':
    case 'Units':
      return 'U';

    case 'mcg':
    case 'micogrtams':
      return 'mcg';

    case 'mEq':
    case 'Milliequivalents':
      return 'mEq';

    case 'IU':
    case 'International Units':
      return 'IU';

    case 'tabs':
    case 'tab(s)':
    case 'tablet(s)':
      return 'tabs';

    case 'capsules':
    case 'capsule(s)':
      return 'capsules';

    case 'doses':
    case 'dose(s)':
      return 'doses';

    case 'puffs':
    case 'puff(s)':
      return 'puffs';

    case 'patches':
    case 'patch(s)':
      return 'patches';

    case 'applications':
    case 'application(s)':
      return 'applications';

    default:
      return '';
  }
};

export const getSimilarInputsRoutes = (input) => {
  switch (input) {
    case 'By mouth':
    case 'PO':
    case 'Per Os':
    case 'Oral':
      return 'PO';

    case 'Subcutaneus':
    case 'SubQ':
    case 'SQ':
      return 'SubQ';

    case 'Intramuscular':
    case 'IM':
      return 'IM';

    case 'Intravenous':
    case 'IV':
      return 'IV';

    case 'Rectally':
    case 'PR':
    case 'Per Rectum':
      return 'PR';

    case 'Vaginally':
    case 'PV':
    case 'Per Vagina':
      return 'PV';

    case 'Sublingually':
    case 'SL':
    case 'Sublingual':
      return 'SL';

    case 'Intranasal':
    case 'IN':
      return 'IN';

    case 'OD':
    case 'RIGHT EYE':
      return 'OD';

    case 'OS':
    case 'LEFT EYE':
      return 'OS';

    case 'OU':
    case 'BOTH EYES':
      return 'OU';

    case 'Inhaled':
    case 'INH':
      return 'INH';

    case 'Transdermal':
    case 'TD':
      return 'TD';

    case 'Topical':
      return 'Topical';

    default:
      return '';
  }
};

export const getSimilarInputsFrequency = (input) => {
  switch (input?.toLowerCase()) {
    case 'q24h':
    case 'qday':
    case 'qd':
    case 'daily':
    case '1x/day':
      return 'qD';

    case 'q12h':
    case 'bid':
    case 'twice a day':
    case '2x/day':
      return 'BiD';

    case 'q8h':
    case 'tid':
    case 'three times a day':
    case '3x/d':
      return 'TiD';

    case 'q6h':
    case 'qid':
    case 'four times a day':
    case '4x/d':
      return 'QiD';

    case 'qpm':
    case 'qhs':
    case 'at night':
    case 'bedtime':
      return 'qHs';

    case 'qam':
    case 'wake Up':
      return 'qAM';

    case 'qac':
    case 'breakfast':
    case 'dinner':
    case 'lunch':
    case 'with meals':
      return 'qAC';

    case 'qwk':
    case 'weekly':
    case 'every week':
      return 'qwk';

    case 'qmo':
    case 'monthly':
    case 'every Month':
      return 'qMo';

    default:
      return '';
  }
};

export const getFrequencyNumbers = (input) => {
  switch (input?.toLowerCase()) {
    case 'q24h':
    case 'qday':
    case 'qd':
    case 'daily':
    case '1x/day':
      return 1;

    case 'q12h':
    case 'bid':
    case 'twice a day':
    case '2x/day':
      return 2;

    case 'q8h':
    case 'tid':
    case 'three times a day':
    case '3x/d':
      return 3;

    case 'q6h':
    case 'qid':
    case 'four times a day':
    case '4x/d':
      return 4;

    case 'qpm':
    case 'qhs':
    case 'at night':
    case 'bedtime':
      return 1;

    case 'qam':
    case 'wake Up':
      return 1;

    case 'qac':
    case 'breakfast':
    case 'dinner':
    case 'lunch':
    case 'with meals':
      return 1;

    case 'qwk':
    case 'weekly':
    case 'every week':
      return 1;

    case 'qmo':
    case 'monthly':
    case 'every Month':
      return 1;

    default:
      return 1;
  }
};
