import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LogStyles from 'styles/components/atoms/Diabetes/LogsTable';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import { Typography, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { formatDate, getTime } from 'redux/actions/helperFunctions';
import { useDiabetes } from 'redux/reusables/diabetes';
import { useTimeframes } from 'redux/reusables/timeframes';

function createData(name, name_org, key, start, stop) {
    return { name, name_org, key, start, stop };
}

function CloseIconStyled() {
    return <CloseIcon style={{ color: '#F3A551' }} />;
}

function RemoveIconStyled() {
    return <RemoveIcon style={{ color: '#D4D5D7' }} />;
}

export default function LogsTable() {
    const classes = LogStyles();

    const { timeframes } = useTimeframes();

    const { diabetes } = useDiabetes();

    const [requiredToTimeframes, setRequiredToTimeframes] = React.useState([]);
    const [entriesToTimeframes, setEntriesToTimeframes] = React.useState([]);
    const [glucoseToTimeframes, setGlucoseToTimeframes] = React.useState([]);

    const rows = [
        createData(
            'Wake up',
            'WAKE_UP',
            timeframes[0]?.id,
            timeframes[0]?.start,
            timeframes[0]?.stop,
        ),
        createData(
            'Breakfast',
            'BREAKFAST',

            timeframes[1]?.id,
            timeframes[1]?.start,
            timeframes[1]?.stop,
        ),
        createData(
            'Lunch',
            'LUNCH',

            timeframes[2]?.id,
            timeframes[2]?.start,
            timeframes[2]?.stop,
        ),
        createData(
            'Dinner',
            'DINNER',

            timeframes[3]?.id,
            timeframes[3]?.start,
            timeframes[3]?.stop,
        ),
        createData(
            'Sleep',
            'SLEEP',

            timeframes[4]?.id,
            timeframes[4]?.start,
            timeframes[4]?.stop,
        ),
    ];

    React.useEffect(() => {
        if (diabetes.requiredMedsDiabetes) {
            // Here we get medication data , do some sorting on date, then remove duplicate dates
            let temp = diabetes.requiredMedsDiabetes;
            temp = temp.filter((x) => x.timeframes?.length > 0);
            temp = temp.sort(
                (a, b) =>
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero. medDate.timeframes.includes(time.id) ? medDate.id : []
                    new Date(b.date) - new Date(a.date),
            );

            const dates = [...Array(7)].map((_, i) => {
                const d = new Date();
                d.setDate(d.getDate() - i);
                return formatDate(d.toISOString());
            });

            const mainArray = {};
            temp.map((medDate, index) => {
                // mainArray[index] = [];
                mainArray[medDate.date] = mainArray[medDate.date] ? mainArray[medDate.date] : [];
                timeframes.map((time) => {
                    mainArray[medDate.date][time.name] = mainArray[medDate.date][time.name]
                        ? mainArray[medDate.date][time.name]
                        : [];
                    if (medDate.timeframes.includes(time.id)) {
                        mainArray[medDate.date][time.name] = medDate.dose;
                    }
                });
            });

            const keys = Object.keys(mainArray);
            const last = keys[keys.length - 1];

            // Medication Entries start from here:
            const tempEntries = {};
            const tempEntriesGlucose = {};

            dates.map((date) => {
                tempEntries[date.date] = [];
                tempEntriesGlucose[date.date] = [];
            });

            diabetes.medicationEntriesDiabetes.map((medDate, index) => {
                // mainArray[index] = [];
                const date = medDate.date
                    ? `${medDate.date.slice(5, 7)}/${medDate.date.slice(
                          8,
                          10,
                      )}/${medDate.date.slice(0, 4)}`
                    : '01/01/2000';

                tempEntries[date] = tempEntries[date] ? tempEntries[date] : [];
                timeframes.map((time) => {
                    tempEntries[date][time.name] = tempEntries[date][time.name]
                        ? tempEntries[date][time.name]
                        : [];
                    if (medDate.timeframe === time.id) {
                        if (
                            tempEntries[date][time.name]?.timeframe === medDate.timeframe &&
                            tempEntries[date][time.name].dose

                            // tempEntries[date][time.name]?.id === medDate.required_medication
                        ) {
                            let corrDose = tempEntries[date][time.name]?.dose;
                            if (medDate.dose_taken === null) {
                                return true;
                            }
                            if (Number(corrDose)) {
                                corrDose = `${medDate.dose_taken} + ${corrDose}`;
                            }

                            tempEntries[date][time.name] = {
                                id: medDate.required_medication,
                                dose: corrDose,
                                timeframe: medDate.timeframe,
                            };
                        } else {
                            tempEntries[date][time.name] = {
                                id: medDate.required_medication,
                                dose: medDate.dose_taken,
                                timeframe: medDate.timeframe,
                            };
                        }
                    }
                });
            });

            setEntriesToTimeframes(tempEntries);
            setRequiredToTimeframes(mainArray);

            // Medication entries ends here

            diabetes.glucoseEntries.map((medDate, index) => {
                // mainArray[index] = [];

                const date = medDate.date
                    ? `${medDate.date.slice(5, 7)}/${medDate.date.slice(
                          8,
                          10,
                      )}/${medDate.date.slice(0, 4)}`
                    : '01/01/2000';

                tempEntriesGlucose[date] = tempEntriesGlucose[date] ? tempEntriesGlucose[date] : [];
                timeframes.map((time) => {
                    tempEntriesGlucose[date][time.name] = tempEntriesGlucose[date][time.name]
                        ? tempEntriesGlucose[date][time.name]
                        : [];
                    if (medDate.timeframe === time.id) {
                        tempEntriesGlucose[date][time.name] = medDate.value;
                    }
                });
            });

            setGlucoseToTimeframes(tempEntriesGlucose);

            const newMainArray = {};
            dates.map((date) => {
                if (mainArray[last]) newMainArray[date.date] = mainArray[last];
                else {
                    newMainArray[date.date] = [];
                }
            });

            setRequiredToTimeframes(newMainArray);

            // RequiredMeds
        }
    }, [diabetes.requiredMedsDiabetes]);

    const sortCorrectionals = (dose) => {
        if (Number(dose)) {
            return dose;
        }
        if (dose) {
            return `${dose?.split(' + ')[1]} + ${dose?.split(' + ')[0]}`;
        }
    };
    return (
        <TableContainer
            component={Paper}
            sx={{
                boxShadow: 'none !important',
                overflowX: 'auto',
                paddingBottom: '30px',
            }}
        >
            <Table
                sx={{ minWidth: 650, tableLayout: 'fixed' }}
                aria-label='simple table'
                // tableLayout='fixed'
            >
                <TableHead>
                    <TableRow
                        sx={{
                            fontFamily: 'Comfortaa, cursive !important',
                            fontWeight: '600',
                            fontSize: '16px',
                            ' th': {
                                border: 0,
                            },
                            marginBottom: '20px',
                        }}
                    >
                        <TableCell style={{ width: '180px' }} />

                        {entriesToTimeframes && requiredToTimeframes
                            ? Object.keys(requiredToTimeframes).map((key, index) => {
                                  let date1 = key.split('/');
                                  date1 = new Date(date1[2], date1[0] - 1, date1[1]);
                                  const dayNames = [
                                      'Sun',
                                      'Mon',
                                      'Tue',
                                      'Wed',
                                      'Thu',
                                      'Fri',
                                      'Sat',
                                  ];

                                  const dayName = dayNames[date1.getDay()];
                                  return (
                                      <TableCell align='center' className={classes.tableCellHead}>
                                          <Typography className={classes.tableCellHeadDate}>
                                              {key.slice(0, 5)}
                                          </Typography>
                                          <Typography className={classes.tableCellHeadDay}>
                                              {dayName}
                                          </Typography>
                                          <Grid xs={12} container>
                                              <Grid item xs={6}>
                                                  <Typography className={classes.tableCellHeadType}>
                                                      Blood Glucose
                                                  </Typography>
                                              </Grid>
                                              <Grid item xs={6}>
                                                  <Typography className={classes.tableCellHeadType}>
                                                      Insulin
                                                  </Typography>
                                              </Grid>
                                          </Grid>
                                      </TableCell>
                                  );
                              })
                            : false}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => {
                        const color = index % 2 == 0 ? '#FAFBFE' : '';
                        return (
                            <TableRow
                                key={row.name}
                                sx={{
                                    ' td,th': {
                                        border: 0,
                                        borderRadius: '8px',
                                    },
                                    background: color,
                                }}
                            >
                                <TableCell
                                    component='th'
                                    scope='row'
                                    className={classes.timeframeName}
                                >
                                    {row.name}
                                </TableCell>
                                {entriesToTimeframes && requiredToTimeframes
                                    ? Object.keys(requiredToTimeframes).map((key, index) => {
                                          let todayDate = formatDate(new Date().toISOString());
                                          todayDate = todayDate.date;
                                          const time = getTime();
                                          let arrived = false;
                                          const regex = new RegExp(':', 'g');

                                          if (
                                              parseInt(time.replace(regex, ''), 10) >
                                                  parseInt(row.stop.replace(regex, ''), 10) ||
                                              new Date(todayDate) > new Date(key)
                                          ) {
                                              arrived = true;
                                          }

                                          return (
                                              <TableCell align='center'>
                                                  {arrived ? (
                                                      Object.keys(entriesToTimeframes).length !==
                                                      0 ? (
                                                          <Grid
                                                              xs={12}
                                                              container
                                                              className={classes.rowText}
                                                          >
                                                              <Grid
                                                                  item
                                                                  xs={6}
                                                                  container
                                                                  justifyContent='center'
                                                              >
                                                                  <Typography
                                                                      className={
                                                                          glucoseToTimeframes[key][
                                                                              row.name_org
                                                                          ] < diabetes.hypo &&
                                                                          glucoseToTimeframes[key][
                                                                              row.name_org
                                                                          ] > 0
                                                                              ? `${classes.dangerGlucose} ${classes.rowText}`
                                                                              : classes.rowText
                                                                      }
                                                                  >
                                                                      {
                                                                          glucoseToTimeframes[key][
                                                                              row.name_org
                                                                          ]
                                                                      }
                                                                  </Typography>
                                                              </Grid>
                                                              <Grid item xs={6}>
                                                                  {sortCorrectionals(
                                                                      entriesToTimeframes[key][
                                                                          row.name_org
                                                                      ]?.dose,
                                                                  )}
                                                              </Grid>
                                                          </Grid>
                                                      ) : (
                                                          <CloseIconStyled />
                                                      )
                                                  ) : (
                                                      <RemoveIconStyled />
                                                  )}
                                              </TableCell>
                                          );
                                      })
                                    : false}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
