import * as React from 'react';
import './indexedit.scss';
import Editors from 'components/organisms/patientChartUi/Editor';

export default function Edit() {
  const [content, setContent] = React.useState(
    '{"blocks":[{"key":"1k8j6","text":"Scratch ...","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}'
  );

  return (
    <div className="patient-chart-root" style={{minHeight:'0vh'}}>
      <Editors
        defaultValue={content}
        setValue={(e) => setContent(e)}
        name="SCRATCH"
        label="Scratch"
      />
    </div>
  );
}
